import { TrafficLightDomain } from "app-domain";
import { DrawOptions } from "./draw-options";
import { Polygon } from "../../../../diagrams/utils/polygon/polygon";
import { PolygonOptions, InitialPhaseParams, DragOptions, PointerIconParams } from "./phase-view.types";

export class PhaseView {
    /** Признак является ли фаза последней */
    public isLast: boolean = false;
    public polygons: Polygon[] = [];
    /** Признак является ли фаза первой */
    public isFirst: boolean = false;
    public allow: boolean = false;
    /** Номер фазы */
    public phaseNumber: number = 0;
    /** Параметры для отрисовки фазы */
    public drawOptions: DrawOptions = new DrawOptions();
    /** Параметры для отрисовки зеленой волны прямого направления */
    public polygonForwardOptions: PolygonOptions[] = [];
    /** Параметры для отрисовки зеленой волны обратного направления */
    public polygonBackwardOptions: PolygonOptions[] = [];
    /** Время фазы с пром тактами */
    public tPhase: number = 0;
    /** Время фазы без пром тактов */
    public tBasic: number = 0;
    /** Минимальное время фазы без пром тактов */
    public tMin: number = 0;
    /** Признак отрисовки точки где произашел сдвиг, иконка в зависимости от разрешена ли фаза */
    public pointerIconParams: Nullable<PointerIconParams> = null;
    /** Первоначальное состояние фазы */
    public initialPhase: InitialPhaseParams = {
        phaseShiftFromStart: 0,
        tPhase: 0,
    };
    public dragOptions: DragOptions = {
        duplicateIndex: 0,
    };
    /** Направление надодящиеся в групе координации */
    public direction?: TrafficLightDomain.Direction;
    public prevPhase?: PhaseView;
    public nextPhase?: PhaseView;

    public get drawTProm() {
        if (!this.nextPhase) return true;
        return this.allow !== this.nextPhase.allow;
    }
}
