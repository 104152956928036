import {
    REACT_APP_MAP_STYLES_API,
    REACT_APP_MAP_API_USER_NAME,
    REACT_APP_MAP_FRAGMENT_STATIC_TILE_ID,
    REACT_APP_MAP_STATIC_TOKEN,
} from "env-data";
import { MapFragmentProps } from "./map-fragment.types";

type Options = Omit<MapFragmentProps, "alt" | "size"> & {
    size: number;
};

export const createImageSrc = (opts: Options) => {
    const { lat, lng, zoom = 16.35, size, token = REACT_APP_MAP_STATIC_TOKEN } = opts;
    return `${REACT_APP_MAP_STYLES_API}/${REACT_APP_MAP_API_USER_NAME}/${REACT_APP_MAP_FRAGMENT_STATIC_TILE_ID}/static/${lng},${lat},${zoom}/${
        size * 2
    }x${size * 2}?access_token=${token}&attribution=false`;
};
