import { Map as MapBoxMap } from "mapbox-gl";
import { TrafficLightDispatcher } from "trafficlight-dispatcher";
import { VisualizationEquipmentOnMap } from "lib";
import { TrafficLightController } from "../traffic-light-controller";
import { CooGroupController } from "../coo-group";
import { RadarDetectorController } from "../radar-detector";
import { RouteBuildingController } from "../route-building-controller";
import { TrafficLightDetectorController } from "../trafficlight-detector";
import { ActiveSubstrate } from "../active-substrate";
import { DtmTrafficController } from "../dtm-traffic-controller";
import { WarehouseCameraController } from "../warehouse-camera-controller";
import { WarehouseSpeedcamController } from "../warehouse-speedcam-controller";
import { WarehouseDetectorController } from "../warehouse-detector-controller";
import { KsoddController } from "../ksodd-controller";
import { ILayerController } from "../layer-controller";
import { WorkingMode, LayerVisibilityByType, ControllerType } from "./controller-collection.constants";

/**
 * Коллекция контроллеров слоев карты
 * Позволяет производить групповые операции над контроллерами слоев
 * Например переключение видимости
 */
export class ControllerCollection {
    private isInitialized = false;
    private collection = new Map<ControllerType, ILayerController>();
    private workingMode = WorkingMode.DEFAULT;

    public init(map: MapBoxMap, dispatcher: TrafficLightDispatcher) {
        this.collection.set(ControllerType.Ksodd, new KsoddController(map));
        this.collection.set(ControllerType.TrafficLightDetector, new TrafficLightDetectorController(map, dispatcher));
        this.collection.set(ControllerType.TrafficLight, new TrafficLightController(map));
        this.collection.set(ControllerType.CooGroup, new CooGroupController(map));
        this.collection.set(ControllerType.DtmTraffic, new DtmTrafficController(map));
        this.collection.set(ControllerType.RadarDetector, new RadarDetectorController(map, dispatcher));
        this.collection.set(ControllerType.RouteBuilding, new RouteBuildingController(map));
        this.collection.set(ControllerType.WarehouseCamera, new WarehouseCameraController(map));
        this.collection.set(ControllerType.WarehouseSpeedcam, new WarehouseSpeedcamController(map));
        this.collection.set(ControllerType.WarehouseDetector, new WarehouseDetectorController(map));
        this.collection.set(ControllerType.ActiveSubstrate, new ActiveSubstrate(map));
        this.isInitialized = true;
        this.setWorkingMode(this.workingMode);
    }

    public setWorkingMode(workingMode: WorkingMode) {
        this.workingMode = workingMode;
        if (!this.isInitialized) return;
        const workingModeOptions = LayerVisibilityByType[workingMode];
        this.collection.forEach((controller, type) => {
            const layerWorkingMode = workingModeOptions[type];
            controller.setWorkingMode?.(layerWorkingMode);
        });
    }

    public get ksoddController() {
        return this.getLayerController<CooGroupController>(ControllerType.Ksodd);
    }

    public get cooGroupController() {
        return this.getLayerController<CooGroupController>(ControllerType.CooGroup);
    }

    public get routeBuildingLayerController() {
        return this.getLayerController<RouteBuildingController>(ControllerType.RouteBuilding);
    }

    public get trafficLightController() {
        return this.getLayerController<TrafficLightController>(ControllerType.TrafficLight);
    }

    public get dtmTrafficController() {
        return this.getLayerController<DtmTrafficController>(ControllerType.DtmTraffic)!;
    }

    public get warehouseCameraController() {
        return this.getLayerController<VisualizationEquipmentOnMap>(ControllerType.WarehouseCamera)!;
    }

    public get warehouseSpeedcamController() {
        return this.getLayerController<VisualizationEquipmentOnMap>(ControllerType.WarehouseSpeedcam)!;
    }

    public get warehouseDetectorController() {
        return this.getLayerController<VisualizationEquipmentOnMap>(ControllerType.WarehouseDetector)!;
    }

    public get trafficLightDetectorController() {
        return this.getLayerController<TrafficLightDetectorController>(ControllerType.TrafficLightDetector);
    }

    public get activeSubstrate() {
        return this.getLayerController<ActiveSubstrate>(ControllerType.ActiveSubstrate);
    }

    public destroy() {
        this.collection.forEach((controller) => controller.destroy?.());
    }

    private getLayerController<T extends ILayerController>(type: ControllerType) {
        if (!this.isInitialized) return null;
        return this.collection.get(type) as T;
    }
}
