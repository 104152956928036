type controllerType = {
    [key: string]: AbortController;
};

const abortControllers: controllerType = {};

export const newSignal = (type: string) => {
    abortControllers[type]?.abort();

    const controller = new AbortController();
    const signal = controller.signal;
    abortControllers[type] = controller;

    return signal;
};
