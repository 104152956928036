import styled, { css } from "styled-components/macro";

const growingCss = css`
    flex-grow: 1;
`;

export const Container = styled.div<{ isGrowing: boolean }>`
    display: flex;
    flex-flow: column nowrap;
    ${(props) => props.isGrowing && growingCss};
`;
