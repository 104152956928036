import { TrafficLightDispatcher } from "./trafficlight-dispatcher";
import { TrafficLightDispatcherItemBase, TrafficLightDispatcherItemType } from "./trafficlight-dispatcher-item";

export interface RadarDetectorDirectionInfo {
    id: number;
    name: string;
    lanes: number[];
    kind: number;
}

export interface RadarDetectorProps {
    dispatcher: TrafficLightDispatcher;
    id: number;
    num: string;
    address: string;
    location: {
        lat: number;
        lng: number;
    };
    directions: RadarDetectorDirectionInfo[];
}

interface RadarDetectorEvents {
    fixation: (args: {
        received: number;
        dateTime: number;
        lane: number;
        vehicleClass: number;
        speed: number;
        vehicleLength: number;
    }) => void;

    lanePresence: (args: { lane: number; isBusy: boolean }) => void;
}

export class RadarDetector extends TrafficLightDispatcherItemBase<RadarDetectorEvents> {
    readonly id: number;
    readonly num: string;
    readonly address: string;
    readonly directions: RadarDetectorDirectionInfo[];
    readonly location: {
        lat: number;
        lng: number;
    };

    constructor(args: RadarDetectorProps) {
        super(args.dispatcher, TrafficLightDispatcherItemType.radarDetector);
        this.id = args.id;
        this.num = args.num;
        this.address = args.address;
        this.location = args.location;
        const directions = args.directions.sort((a, b) => a.lanes[0] - b.lanes[0]);
        this.directions = directions;
    }

    async fetchPresenceLog(filter: { from: Date; to: Date }) {
        if (!this.dispatcher) return null;
        let url = `detector/${this.id}/presence?count=10000`;

        if (filter.from) {
            url += `&from=${filter.from.toISOString()}`;
        }

        if (filter.to) {
            url += `&to=${filter.to.toISOString()}`;
        }

        const data = await this.dispatcher.fetchRadarDetectorJson(url);
        return data;
    }
}

export type RadarDetectorCollection = { [key: number]: RadarDetector };
