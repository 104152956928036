import React from "react";
import { Icons as UiKitIcons } from "@megapolis/uikit/icons";
import { Button } from "@megapolis/uikit/button";
import { useHandleClick } from "./issues.hooks";
import * as Markup from "./issues.styles";
import type { Props } from "./issues.types";

export const Issues = React.memo((props: Props) => {
    const { handleClick } = useHandleClick(props);

    return (
        <Markup.Container className={props.className} data-cypress="camera-short-card__body__issues">
            <Markup.Caption>Заявки</Markup.Caption>
            <Markup.Count>{props.issueCount}</Markup.Count>
            <Button
                content={Button.Content.Icon}
                priority="quaternary"
                kind="info"
                icon={<UiKitIcons.Monochrome.ChevronRightS />}
                size="s"
                onClick={handleClick}
            />
        </Markup.Container>
    );
});
