import * as Types from "./app-state-manager.connections.types";

const equipmentPopupFocus = (args: Types.EquipmentPopupFocusArgs) => {
    const { emitter } = args;

    emitter.on(emitter.events.Adding, (guid: string) => {
        args.popupFocus.setActive(guid);
    });

    emitter.on(emitter.events.Remove, (guid: string) => {
        if (args.popupFocus.active !== guid) return;

        args.popupFocus.setActive(null);
    });
};

const equipmentDetails = (args: Types.EquipmentDetailsArgs) => {
    const emitter = args.listShortCard.emitter;

    /* #region Разворачивание */
    const handlerMaximizeCard = (source: SourceEquipmentCardArgs) => {
        if (args.fullCard.uid !== null) args.listShortCard.add(args.fullCard.source);
        args.listShortCard.remove(source.options.uid);
        args.fullCard.setData(source);
        args.fullCard.load();
    };

    emitter.on(emitter.events.MaximizeCard, handlerMaximizeCard);
    /* #endregion */

    /* #region Схлопывание */
    const handlerMinimizeCard = (source: SourceEquipmentCardArgs) => {
        args.listShortCard.add(source);
        args.fullCard.onBackwardClick();
    };

    args.fullCard.emitter.on(args.fullCard.emitter.events.MinimizeCard, handlerMinimizeCard);
    /* #endregion */

    /* #region Выбор детектора из списка */
    args.detectorsList.emitter.on(emitter.events.SelectDetector, handlerMaximizeCard);
    /* #endregion */
};

export const connections = (args: Types.ConnectionsArgs) => {
    const { popupFocus } = args;

    equipmentPopupFocus({ emitter: args.listShortCardOnWarehouseCamera.emitter, popupFocus });
    equipmentPopupFocus({ emitter: args.listShortCardOnWarehouseSpeedcam.emitter, popupFocus });
    equipmentPopupFocus({ emitter: args.listShortCardOnWarehouseDetector.emitter, popupFocus });
    equipmentPopupFocus({ emitter: args.detectorFullCard.emitter, popupFocus });
    equipmentPopupFocus({ emitter: args.tvCameraStore.emitter, popupFocus });

    equipmentDetails({
        listShortCard: args.listShortCardOnWarehouseDetector,
        fullCard: args.detectorFullCard,
        detectorsList: args.detectorsList,
        tvCameraStore: args.tvCameraStore,
    });
};
