import { createGlobalStyle } from "styled-components";
import { globalCss } from "@megapolis/uikit/theme";

export const GlobalStyles = createGlobalStyle`
  ${globalCss}

  html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  #root-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(8, 8, 8, 0.6);
    z-index: 100;

    &:empty {
      display: none;
    }
  }

  .user-auth-popup {
    z-index: 9999;
  }
`;
