import { MapControllerTypes } from "map-controller";
import { pointColors } from "shared/constants";

export const getIconByViewType = (viewType: MapControllerTypes.MarkerViewType, keyIcon: string) => {
    const svg_string = `
    <svg class="coo-group-point-marker-icon" width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd_917_258047)">
            <mask id="path-1-outside-1_917_258047" maskUnits="userSpaceOnUse" x="8.00098" y="4" width="32" height="38" fill="black">
                <rect fill="white" x="8.00098" y="4" width="32" height="38"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0719 34.8582C33.3761 33.8495 39.001 27.5816 39.001 20C39.001 11.7157 32.2852 5 24.001 5C15.7167 5 9.00098 11.7157 9.00098 20C9.00098 27.5816 14.6258 33.8495 21.9301 34.8582L24.001 39L26.0719 34.8582Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0719 34.8582C33.3761 33.8495 39.001 27.5816 39.001 20C39.001 11.7157 32.2852 5 24.001 5C15.7167 5 9.00098 11.7157 9.00098 20C9.00098 27.5816 14.6258 33.8495 21.9301 34.8582L24.001 39L26.0719 34.8582Z" fill="${pointColors[viewType]}"/>
            <path d="M26.0719 34.8582L25.9351 33.8676L25.4131 33.9397L25.1774 34.411L26.0719 34.8582ZM21.9301 34.8582L22.8245 34.411L22.5889 33.9397L22.0669 33.8676L21.9301 34.8582ZM24.001 39L23.1065 39.4472L24.001 41.2361L24.8954 39.4472L24.001 39ZM38.001 20C38.001 27.0754 32.7514 32.9263 25.9351 33.8676L26.2087 35.8488C34.0008 34.7728 40.001 28.0879 40.001 20H38.001ZM24.001 6C31.733 6 38.001 12.268 38.001 20H40.001C40.001 11.1634 32.8375 4 24.001 4V6ZM10.001 20C10.001 12.268 16.269 6 24.001 6V4C15.1644 4 8.00098 11.1634 8.00098 20H10.001ZM22.0669 33.8676C15.2505 32.9263 10.001 27.0754 10.001 20H8.00098C8.00098 28.0879 14.0011 34.7728 21.7933 35.8488L22.0669 33.8676ZM24.8954 38.5528L22.8245 34.411L21.0357 35.3054L23.1065 39.4472L24.8954 38.5528ZM25.1774 34.411L23.1065 38.5528L24.8954 39.4472L26.9663 35.3054L25.1774 34.411Z" fill="white" mask="url(#path-1-outside-1_917_258047)"/>
        </g>
        <g transform="translate(-1207 -594)">
            <text id="H" transform="translate(1231 618)" fill="#fff" font-size="14px" font-family="Arial" font-weight="700" text-anchor="middle" x="0">
                ${keyIcon}
            </text>
        </g>
        <circle cx="24.001" cy="46" r="3" fill="white"/>
        <circle cx="24.001" cy="46" r="2" fill="${pointColors[viewType]}"/>
        <defs>
            <filter id="filter0_dd_917_258047" x="0.000976562" y="0" width="48" height="53.2363" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="4"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.356863 0 0 0 0 0.407843 0 0 0 0 0.443137 0 0 0 0.24 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_917_258047"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.12549 0 0 0 0 0.141176 0 0 0 0.32 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_917_258047" result="effect2_dropShadow_917_258047"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_917_258047" result="shape"/>
            </filter>
            <clipPath id="clip0_917_258047">
                <rect width="24" height="24" fill="white" transform="translate(12.001 8)"/>
            </clipPath>
        </defs>
    </svg>
`;

    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg_string)))}`;
};
