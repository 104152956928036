import { useEffect, useState } from "react";

type AsyncFn<Data> = () => Promise<Data>;

type AsyncState<Data> = {
    isLoading: boolean;
    data: Data | null;
};

const initialState: AsyncState<null> = {
    isLoading: false,
    data: null,
};

export const useQuery = <Data>(fn: AsyncFn<Data>) => {
    const [state, setState] = useState<AsyncState<Data | null>>(initialState);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            setState((prev) => ({ ...prev, isLoading: true }));
            const data = await fn();
            if (!isMounted) return;
            setState({ isLoading: false, data });
        };
        load();
        return () => {
            isMounted = false;
        };
    }, [fn]);

    return state;
};
