export class FileResponseHelper {
    static async getFileMetaData(args: { response: Response; defaultFileName?: string }): Promise<[Blob, string]> {
        const { response, defaultFileName = "file" } = args;

        const contentDisposition = response.headers.get("content-disposition");

        const strFields = contentDisposition?.split(";")?.reduce((acc: { [key: string]: string }, str) => {
            const _str = str.trim();
            const [key, val] = _str.split(/=/gi);
            if (key && val) acc[key] = val.replace("UTF-8''", "");
            return acc;
        }, {});

        const fileName = strFields ? decodeURI(strFields["filename*"] || strFields["filename"]) : defaultFileName;
        const blob = await response.blob();
        return [blob, fileName];
    }
}
