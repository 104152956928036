import { makeAutoObservable } from "mobx";
import { Enums } from "app-domain/traffic-light";
import { CodeListFilter } from "lib";

export class Filter {
    public readonly statuses = new CodeListFilter<Enums.StatusCode>();
    public readonly conditions = new CodeListFilter<Enums.ConditionType>();
    public readonly controlModes = new CodeListFilter<Enums.ControlModeCode>();
    public search: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    public get shape() {
        return {
            statuses: this.statuses.list,
            conditions: this.conditions.list,
            controlModes: this.controlModes.list,
            search: this.search,
        };
    }

    public clearSearch = () => {
        this.search = "";
    };

    public setSearch = (value: string) => {
        this.search = value;
    };

    public reset() {
        this.statuses.reset();
        this.conditions.reset();
        this.controlModes.reset();
    }
}
