import { Suspense, lazy } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { lightTheme } from "@megapolis/uikit/theme";
import { browserHistory, ThemeProvider } from "shared/globals";
import { BlockLoader } from "components/block-loader";
import { MapControllerProvider } from "map-controller";
import { RegisterGlobalBroker } from "event-brokers";
import { AppContextProvider } from "./store";
import { GlobalStyles } from "./app.styles";
import "@megapolis/uikit/theme/fonts.css";

// Установка глобальной темы, которая будет использоваться вне контекста React(например: в canvas);
const themeProvider = ThemeProvider.getInstance();
themeProvider.theme = lightTheme;

const MainPage = lazy(() => import("pages/main"));
const pageLoading = <BlockLoader size="48px" />;

export const App = () => (
    <Router history={browserHistory}>
        <StyledThemeProvider theme={lightTheme}>
            <GlobalStyles />
            <Suspense fallback={pageLoading}>
                <Switch>
                    <Route path="*">
                        <AppContextProvider>
                            <MapControllerProvider>
                                <RegisterGlobalBroker />
                                <MainPage />
                            </MapControllerProvider>
                        </AppContextProvider>
                    </Route>
                </Switch>
            </Suspense>
        </StyledThemeProvider>
    </Router>
);
