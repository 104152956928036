export type TimescaleDataInput = {
    totalTime: number;
    width: number;
    pixelsPerSecond: number;
    height: number;
};

export type TimescaleData = {
    totalTime: number;
    width: number;
    pixelsPerSecond: number;
    lineWidth: number;
    timeScaleHeight: number;
    timeLineHeight: number;
    top: number;
    bottom: number;
    strokeStyle: string;
};

const top = 16;
const lineWidth = 0.5;
const strokeStyle = "#03284C85";
const timeScaleHeight = 8;
export const timeLineHeight = 24;

export const initialData: TimescaleData = {
    top,
    strokeStyle,
    timeScaleHeight,
    timeLineHeight,
    lineWidth,
    width: 0,
    totalTime: 0,
    pixelsPerSecond: 0,
    bottom: 0,
};

export const createTimescaleData = (args?: TimescaleDataInput): TimescaleData =>
    args
        ? {
              ...initialData,
              totalTime: args.totalTime,
              width: args.width,
              pixelsPerSecond: args.pixelsPerSecond,
              bottom: args.height - timeLineHeight,
          }
        : initialData;
