import React from "react";
import { REACT_APP_API_BASEURL } from "env-data";

interface Props {
    facilityId: number;
    versionId: number;
    phaseNumber: number;
    className?: string;
}

export const Media = React.memo((props: Props) => (
    <img
        width="108px"
        height="108px"
        alt="phase"
        className={props.className}
        src={`${REACT_APP_API_BASEURL}/facility/${props.facilityId}/version/${props.versionId}/image/phase/${props.phaseNumber}`}
    />
));
