import { createContext, useContext } from "react";
import { CommandLaunchPopupState } from "./command-launch-popup-state";

type ContextValue = {
    state: CommandLaunchPopupState;
};

export const CommandLaunchPopupContext = createContext({} as ContextValue);

export const useCommandLaunchPopupContext = () => {
    return useContext(CommandLaunchPopupContext);
};

export const useCommandLaunchPopupState = () => {
    return useCommandLaunchPopupContext().state;
};
