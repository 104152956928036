import { useCallback } from "react";
import { CooGroupDomain, TrafficLightDomain } from "app-domain";
import { Hooks } from "shared";
import { useTrafficLightStore } from "./use-traffic-light-store";

export const useQueryCooGroupTrafficLights = (cooGroup: CooGroupDomain.CooGroup | null) => {
    const trafficLightStore = useTrafficLightStore();

    const loadItems = useCallback(async () => {
        if (!cooGroup) return Promise.resolve(null);
        const items = await trafficLightStore.loadItemsDataByIds(
            cooGroup.facilities.reduce((ids, facility) => {
                if (facility.facilityId) {
                    ids.push(facility.facilityId);
                }
                return ids;
            }, [] as number[])
        );
        return items.filter(Boolean) as TrafficLightDomain.TrafficLight[];
    }, [trafficLightStore, cooGroup]);

    return Hooks.useQuery(loadItems);
};
