import { CooGroupDomain } from "app-domain";
import { v4 } from "uuid";
import { getMarkerViewType } from "shared/utils";

export const pointsMapper = (point: CooGroupDomain.Point, index: number, list: CooGroupDomain.Point[]) => ({
    id: v4(),
    viewType: getMarkerViewType(index, list.length),
    lngLat: {
        lng: point.lng,
        lat: point.lat,
    },
});
