interface GetDataCypressArgs {
    moduleName: string;
    isLoaded?: boolean;
    dataCypress?: string;
}

export const getDataCypress = (args: GetDataCypressArgs) => {
    if (!args.dataCypress) return undefined;

    return `${args.dataCypress}__${args.moduleName}`;
};
