import { useCallback, useEffect } from "react";
import { useSideBarFilter } from "./use-sidebar-filter";

export const useCloseSideBarFilterOnUnmount = () => {
    const { closeFilter, isOpened } = useSideBarFilter();
    const handleClose = useCallback(() => {
        if (!isOpened) return;
        closeFilter();
    }, [isOpened, closeFilter]);
    useEffect(() => handleClose, [handleClose]);
};
