import { makeAutoObservable, runInAction } from "mobx";
import { TrafficLightDomain } from "app-domain";
import { BooleanProperty } from "lib";
import {
    HoldingEntity,
    ITrafficLightRemoteControlService,
    RunCommandParams,
} from "./traffic-light-remote-control.types";
import { Popup, PopupType } from "./popup";
import { CycleEditorState } from "./cycle-editor-state";

/** Пульт управления светофорным объектом */
export class TrafficLightRemoteControl {
    /** Значение длительности в секундах по умолчанию */
    public static defaultDurationSeconds = 300;
    /** Минимальная продолжительность команды в секундах (1 мин) */
    public static minDurationTime = 60;
    /** Обсервер, следящий за видимостью кнопок "Завершить" на фазах и статусах */
    public finishButtonObserver: IntersectionObserver;
    public isFinishButtonVisible = false;
    public editorState: CycleEditorState;
    public readonly popup = new Popup();
    private _holdingEntity: HoldingEntity | null = null;
    private _isCollapsed = new BooleanProperty(true);

    constructor(
        public id: number,
        private _governance: TrafficLightDomain.Governance,
        private _currentUserId: string,
        private _service: ITrafficLightRemoteControlService,
        private _onRunCommand: (control: TrafficLightRemoteControl, isPlanned: boolean) => void
    ) {
        this.finishButtonObserver = new IntersectionObserver(this.changeFinishButtonVisibility);
        this.editorState = new CycleEditorState(this.popup);
        makeAutoObservable(this, {
            finishButtonObserver: false,
        });
    }

    public get holdingEntity() {
        return this._holdingEntity;
    }

    /** Свернут ли пульт */
    public get isCollapsed() {
        return this._isCollapsed.value;
    }

    public get isControlledByCurrentUser() {
        return this.user.id === this._currentUserId;
    }

    public get governanceId() {
        return this.governance.id;
    }

    public get isPlanned() {
        return this._governance.isPlanned;
    }

    public get holdExpireDate() {
        return this._governance.data?.holdExpireDate ?? null;
    }

    public get facilityId() {
        return this._governance.facilityId;
    }

    public get createdAt() {
        return this.governance.data?.start;
    }

    public get isUnderUserControl() {
        return this.governance.isUnderUserControl;
    }

    /** Данные пользователя, управляющего пультом */
    public get user() {
        return {
            id: this.governance?.data?.profileId ?? "",
            name: this.governance?.data?.username ?? "",
        };
    }

    public get isRunningCommand() {
        return !!this.runningCommand;
    }

    public get runningCommand() {
        return this.governance.command;
    }

    public get isHoldingCycle() {
        return this.runningCommand instanceof TrafficLightDomain.HoldCycleCommand;
    }

    public get isHoldingPhase() {
        return this.runningCommand instanceof TrafficLightDomain.HoldPhaseCommand;
    }

    public get isHoldingStatus() {
        return this.runningCommand instanceof TrafficLightDomain.HoldStatusCommand;
    }

    public get phaseControlsDisabled() {
        if (!this.runningCommand) return false;
        return !this.isHoldingPhase;
    }

    public get statusControlsDisabled() {
        if (!this.runningCommand) return false;
        return !this.isHoldingStatus;
    }

    public get cycleControlsDisabled() {
        if (!this.runningCommand) return false;
        return !this.isHoldingCycle;
    }

    public get activePhaseNumber() {
        if (!(this.runningCommand instanceof TrafficLightDomain.HoldPhaseCommand)) return null;
        return this.runningCommand.phase;
    }

    public get activeStatus() {
        if (!(this.runningCommand instanceof TrafficLightDomain.HoldStatusCommand)) return null;
        return this.runningCommand.status;
    }

    public updateGovernance(data: TrafficLightDomain.Governance) {
        this.governance = data;
    }

    /** Добавляет элемент для наблюдения за его видимостью (кнопки включения/выключения фаз/статусов) */
    public addObservableElement = (element: HTMLElement | null) => {
        if (!element) return;
        this.finishButtonObserver.disconnect();
        this.finishButtonObserver.observe(element);
    };

    public async finishCommand() {
        this.governance = await this._service.finishCommand(this.id);
    }

    public async runCommand(entity: HoldingEntity) {
        this._holdingEntity = entity;
        this.popup.setParams({ type: PopupType.CommandLaunch, onSubmit: this._runCommand });
        this.popup.open();
    }

    private _runCommand = async (params: RunCommandParams) => {
        if (!this.holdingEntity) return;
        const service = this._service;
        const trafficLightId = this.id;

        let governance: TrafficLightDomain.Governance | void;

        if (this.holdingEntity.type === "phase") {
            governance = await service.holdPhase({
                ...params,
                id: trafficLightId,
                data: this.holdingEntity.data,
            });
        }
        if (this.holdingEntity.type === "status") {
            governance = await service.holdStatus({
                ...params,
                id: trafficLightId,
                data: this.holdingEntity.data,
            });
        }

        if (this.holdingEntity.type === "cycle") {
            const data = this.holdingEntity.data.map((phase) => ({
                number: phase.phaseNumber,
                time: phase.tBasic,
                promTime: phase.tProm,
            }));
            governance = await service.holdCycle({
                ...params,
                data,
                id: trafficLightId,
            });
        }

        if (typeof governance !== "undefined") {
            this.governance = governance;
        }

        this._onRunCommand(this, !!params.startAt);
        this.popup.close();
    };

    public async extendCommandRuntime() {
        if (!this.runningCommand) return;
        const trafficLightId = this.id;
        const diff = Math.floor((new Date(this.runningCommand.expiresAt).getTime() - Date.now()) / 1000);

        const duration = diff + TrafficLightRemoteControl.defaultDurationSeconds;

        let governance: TrafficLightDomain.Governance | void;

        if (this.runningCommand instanceof TrafficLightDomain.HoldPhaseCommand) {
            governance = await this._service.holdPhase({
                id: trafficLightId,
                data: this.runningCommand.phase,
                duration,
            });
        }
        if (this.runningCommand instanceof TrafficLightDomain.HoldStatusCommand) {
            governance = await this._service.holdStatus({
                id: trafficLightId,
                data: this.runningCommand.status,
                duration,
            });
        }

        if (this.runningCommand instanceof TrafficLightDomain.HoldCycleCommand) {
            governance = await this._service.holdCycle({
                id: trafficLightId,
                data: this.runningCommand.phases,
                duration,
            });
        }

        if (typeof governance !== "undefined") {
            this.governance = governance;
        }
    }

    public expand() {
        this._isCollapsed.setFalsy();
    }

    public collapse() {
        this._isCollapsed.setTruly();
    }

    public toggleCollapse = () => {
        this._isCollapsed.toggle();
    };

    public destroy() {
        this.finishButtonObserver.disconnect();
    }

    private get governance() {
        return this._governance;
    }

    private set governance(value: TrafficLightDomain.Governance) {
        this._governance = value;
    }

    /**
     * Изменяет видимость кнопки "Завершить" в футере пульта управления,
     * в зависимости от видимости кнопки "Завершить" на конкретной фазе / статусе
     */
    private changeFinishButtonVisibility = (entries: IntersectionObserverEntry[]) => {
        if (!entries.length) return;

        const entry = entries[0];
        runInAction(() => {
            this.isFinishButtonVisible = !entry.isIntersecting;
        });
    };
}
