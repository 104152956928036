import React, { ReactNode, useEffect, useState } from "react";
import { useAccessToken, useUserId } from "@megapolis/react-auth";
import { configuration } from "api";
import { BlockLoader } from "components";
import { AppStateContext } from "./app-state-context";
import { AppStateManager } from "./app-state-manager";
import { useForceUpdate } from "shared/hooks";

export type AppContextProviderProps = {
    children: ReactNode;
};

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
    const [appStateContext, setAppStateContext] = useState<Nullable<AppStateManager>>(null);
    const token = useAccessToken();
    const userId = useUserId();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (!token) return;
        configuration.setToken(token);
    }, [token]);

    useEffect(() => {
        if (!token || appStateContext || !userId) return;

        setAppStateContext(new AppStateManager({ token, userId }));
    }, [token, userId, appStateContext]);

    useEffect(() => {
        if (!appStateContext) return;
        appStateContext.on("initialized", forceUpdate);
        return () => {
            appStateContext.off("initialized", forceUpdate);
        };
    }, [appStateContext, forceUpdate]);

    if (!appStateContext || !appStateContext.isInitialized) return <BlockLoader size="48px" />;

    return <AppStateContext.Provider value={appStateContext}>{children}</AppStateContext.Provider>;
};
