import styled, { css } from "styled-components";
import { ContainerStyleProps } from "./menu-item.types";

export const Container = styled.button<ContainerStyleProps>(
    ({ theme: { spacings, colors }, padding, isActive }) =>
        css`
            display: flex;
            align-items: center;
            padding: ${padding ?? spacings[3].str};
            border-radius: ${spacings[2].str};
            background-color: ${isActive ? colors.containerBackground.tertiary.active : "inherit"};
            border-color: ${isActive ? colors.content.quinary : "transparent"};

            &:hover {
                background-color: ${colors.content.quinary};
            }

            &:disabled {
                background-color: transparent;
                cursor: default;
                color: ${colors.content.tertiary};
            }
        `
);
