import { ShortCardList } from "lib";
import { Interfaces } from "interfaces";
import * as Units from "./units";

export class FixationCameras extends ShortCardList {
    public collection: Store.ListShortCardOnWarehouseSpeedcamUnitsItem.Instance[] = [];
    constructor() {
        super(
            (args: SourceEquipmentCardArgs, handlers: Interfaces.Store.ListShortCard.Handlers) =>
                new Units.Item(args, handlers)
        );
    }
}
