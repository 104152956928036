import { CooGroupDomain } from "app-domain";
import { APIRegistry, APITypes } from "api";
import { Mappers } from "services";
import {
    ICooGroupRemoteControlService,
    IEnableCustomCycleParams,
    IEnableCycleParams,
} from "./coo-group-remote-control.types";

export class CooGroupRemoteControlAdapter implements ICooGroupRemoteControlService {
    private cooGroupAPI = APIRegistry.cooGroupAPI;
    private manageAPI = APIRegistry.manageAPI;
    private mapper = new Mappers.CooGroupCycleMapper();

    public async createNewCycle(
        cooGroupId: number,
        cycle: CooGroupDomain.CustomCycle
    ): Promise<ResultWithValidation<CooGroupDomain.Cycle>> {
        const result = await this.cooGroupAPI.createCooGroupCycle(cooGroupId, this.mapper.toCycleInput(cycle));
        return result.type === "cycle"
            ? {
                  isOk: true,
                  data: this.mapper.fromAPI(result.data),
              }
            : {
                  isOk: false,
                  data: result.data.detailed as string[],
              };
    }

    public async activateCycle(
        cooGroupId: number,
        params: IEnableCycleParams
    ): Promise<ResultWithValidation<CooGroupDomain.Governance>> {
        const result = await this.manageAPI.activateCooGroupCycle(cooGroupId, params);
        return result.value.isOk
            ? {
                  isOk: true,
                  data: this.createGovernance(result.value.data),
              }
            : {
                  isOk: false,
                  data: result.value.data,
              };
    }

    public async activateCustomCycle(
        cooGroupId: number,
        params: IEnableCustomCycleParams
    ): Promise<ResultWithValidation<CooGroupDomain.Governance>> {
        const { cycle } = params;
        const cycleData =
            cycle.type === CooGroupDomain.CycleType.Coordination
                ? { cycles: this.mapCoordinationCyclesToAPI(cycle) }
                : {
                      greenStreetPhases: cycle.greenLinePhases,
                  };
        const result = await this.manageAPI.activateCooGroupCustomCycle(cooGroupId, {
            duration: params.duration,
            startAt: params.startAt,
            stopAt: params.stopAt,
            ...cycleData,
        });
        return result.value.isOk
            ? {
                  isOk: true,
                  data: this.createGovernance(result.value.data),
              }
            : {
                  isOk: false,
                  data: result.value.data,
              };
    }

    public async finishCycle(cooGroupId: number): Promise<CooGroupDomain.Governance> {
        const result = await this.manageAPI.cleanCooGroupGovernance(cooGroupId);
        return this.createGovernance(result);
    }

    public async extendCycle(cooGroupId: number, duration: number): Promise<CooGroupDomain.Governance> {
        const response = await this.manageAPI.prolongCooGroupCycle(cooGroupId, duration);
        return this.createGovernance(response);
    }

    private mapCoordinationCyclesToAPI(cycle: CooGroupDomain.CustomCycle) {
        return cycle.cycles?.map((cycle) => ({
            facilityId: cycle.facilityId,
            number: cycle.number,
            phases: cycle.phases?.map((phase) => ({ number: phase.phaseNumber, time: phase.tPhase })) ?? [],
            shift: cycle.shift,
        }));
    }

    private createGovernance(data: APITypes.CooGroupGovernance) {
        const mappedData: CooGroupDomain.GovernanceData = {
            ...data,
            start: data.start ? new Date(data.start) : void 0,
            finish: data.finish ? new Date(data.finish) : void 0,
            holdExpireDate: data.holdExpireSeconds
                ? new Date(Date.now() + data.holdExpireSeconds * 1000)
                : data.holdExpireDate
                ? new Date(data.holdExpireDate)
                : void 0,
            commandStart: data.commandStart ? new Date(data.commandStart) : void 0,
        };
        const governance = new CooGroupDomain.Governance(data.id, data.cooGroupId, data.cycleType);
        governance.setData(mappedData);
        return governance;
    }
}
