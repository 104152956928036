type Key = string;

type Value = string | number | boolean | undefined | null;

export class QueryParamsBuilder {
    private params: URLSearchParams;

    constructor() {
        this.params = new URLSearchParams();
    }

    public addRepeatingParam<V extends Value>(key: Key, value?: Iterable<V>) {
        if (value) {
            Array.from(value).forEach((v) => this.params.append(key, String(v)));
        }
        return this;
    }

    public addIterableParam<V extends Value>(key: Key, value?: Iterable<V>) {
        if (value) {
            const list = Array.from(value);
            if (list.length === 0) return this;
            const strValue = list.join(",");
            this.params.append(key, strValue);
        }
        return this;
    }

    public addParam(key: Key, value?: Value) {
        if (Boolean(value)) {
            this.params.append(key, String(value));
        }
        return this;
    }

    public toString(prefix?: boolean) {
        const string = this.params.toString();
        return prefix && string.length > 0 ? "?" + string : string;
    }
}
