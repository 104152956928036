import { TrafficLightDomain } from "app-domain";

const initialData = {
    x: 0,
    text: "",
    font: "",
    fillStyle: "",
    forwardY: 0,
    backwardY: 0,
    hasBackwardDirection: false,
};

export type DirectionLabelInput = {
    hasBackwardDirection: boolean;
    x: number;
    y: number;
    directionBarHeight: number;
    directionBarGap: number;
    text: string;
    font: string;
    fillStyle: string;
    directionType?: TrafficLightDomain.Enums.DirectionTypeCode;
};

export type DirectionLabelData = {
    x: number;
    text: string;
    font: string;
    fillStyle: string;
    forwardY: number;
    backwardY: number;
    hasBackwardDirection: boolean;
    directionType?: TrafficLightDomain.Enums.DirectionTypeCode;
};

export const createDirectionLabelData = (args?: DirectionLabelInput): DirectionLabelData =>
    !args
        ? initialData
        : {
              x: args.x,
              text: args.text,
              font: args.font,
              fillStyle: args.fillStyle,
              forwardY: args.y + args.directionBarHeight / 2,
              backwardY: args.y + args.directionBarGap + args.directionBarHeight + args.directionBarHeight / 2,
              hasBackwardDirection: args.hasBackwardDirection,
              directionType: args.directionType,
          };
