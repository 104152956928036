import { makeAutoObservable } from "mobx";
import { Enums } from "app-domain/detector";
import { CodeListFilter } from "lib";

export class Filter {
    public readonly issueStatuses = new CodeListFilter<Enums.IssueStatusCode>();
    public readonly monitoringStatus = new CodeListFilter<Enums.MonitoringStatusCode>();
    public search: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    public get shape() {
        return {
            issueStatus: this.issueStatuses.list,
            monitoringStatus: this.monitoringStatus.list,
            search: this.search,
        };
    }

    public clearSearch = () => {
        this.search = "";
    };

    public setSearch = (value: string) => {
        this.search = value;
    };
}
