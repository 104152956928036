import TrackVisual from "./track-visual";
import TrafficLightVisual from "./traffic-light-visual";
import { TrackAppearance } from "./paint";

const appearenceWhite: TrackAppearance = {
    transportColor: "rgba(255,255,255,.9)",
    pedestrianColor: "rgba(255,255,255,.9)",
    urbantransColor: "rgba(255,255,255,.9)",
    lineWidth: 7,
    arrowSize: 36,
    fontSize: 32,
    textStroke: true,
};

const appearenceColored: TrackAppearance = {
    transportColor: "#009113",
    pedestrianColor: "#8f0000",
    urbantransColor: "#35acb5",
    lineWidth: 5,
    arrowSize: 30,
    fontSize: 32,
    textStroke: false,
};

type TArgs = {
    number: number;
    type: number;
    tracks: any[];
};

export default class DirectionVisual {
    trafficlight: TrafficLightVisual;
    num: number;
    type: number;
    tracks: TrackVisual[];

    constructor(trafficlight: TrafficLightVisual, args: TArgs) {
        this.trafficlight = trafficlight;
        this.num = args.number;
        this.type = args.type;

        this.tracks = [];
        if (args.tracks) {
            for (let i = 0, len = args.tracks.length; i < len; i++) {
                this.tracks.push(new TrackVisual(this, args.tracks[i]));
            }
        }
    }

    draw(ctx: any, x: any, y: any, size: any) {
        for (let i = 0, len = this.tracks.length; i < len; i++) {
            this.tracks[i].draw(ctx, x, y, size, appearenceWhite);
        }

        for (let i = 0, len = this.tracks.length; i < len; i++) {
            this.tracks[i].draw(ctx, x, y, size, appearenceColored);
        }
    }
}
