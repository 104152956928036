import { makeAutoObservable } from "mobx";
import { CooGroupDomain } from "app-domain";
import { GreenStreetForm, CoordinationCycleForm } from "./forms";
import { ICooGroupRemoteControlService, IRunCycleParams } from "./coo-group-remote-control.types";

type ValidationErrorsType = "create" | "enable";

type ValidationErrors = {
    type: ValidationErrorsType;
    list: string[];
};

export class CooGroupRemoteControl {
    /** Минимальная продолжительность команды в секундах (15 мин) */
    public static cycleMinRunTime = 900;
    private _isVisible: boolean = false;
    private _coordinationForm = new CoordinationCycleForm();
    private _greenStreetForm = new GreenStreetForm();
    private _activeForm: GreenStreetForm | CoordinationCycleForm = this._coordinationForm;
    private _isNewCycleDialogVisible = false;
    private _cooGroup?: CooGroupDomain.CooGroup;
    private _isCreating: boolean = false;
    private _isLaunchTimePopupVisible = false;
    private _validationErrors: ValidationErrors | null = null;

    constructor(
        public id: number,
        private _governance: CooGroupDomain.Governance,
        private service: ICooGroupRemoteControlService
    ) {
        makeAutoObservable(this);
    }

    public get isCreating() {
        return this._isCreating;
    }

    public get isNewCycleDialogVisible() {
        return this._isNewCycleDialogVisible;
    }

    public get activeForm() {
        return this._activeForm;
    }

    public get cycle() {
        return this.activeForm.cycle;
    }

    public get greenStreetForm() {
        return this._greenStreetForm;
    }

    public get coordinationForm() {
        return this._coordinationForm;
    }

    public get isCoordinationForm() {
        return this._activeForm instanceof CoordinationCycleForm;
    }

    public get isGreenStreetForm() {
        return this._activeForm instanceof GreenStreetForm;
    }

    public get governance() {
        return this._governance;
    }

    public set governance(governance: CooGroupDomain.Governance) {
        this._governance = governance;
    }

    public get isVisible() {
        return this._isVisible;
    }

    public get isUnderUserControl() {
        return this.governance.isUnderUserControl;
    }

    public get isRunningCycle() {
        return this.governance.isRunningCommand;
    }

    public get currentCycle() {
        return this._activeForm.cycle;
    }

    public get hasChanges() {
        return this.activeForm.hasChanges;
    }

    public get validationErrors() {
        return this._validationErrors;
    }

    public get isLaunchTimePopupVisible() {
        return this._isLaunchTimePopupVisible;
    }

    public clearValidationErrors = () => {
        this._validationErrors = null;
    };

    public checkIsCurrentUser(userId: string) {
        return this.governance.compareUser(userId);
    }

    public setCooGroup(cooGroup: CooGroupDomain.CooGroup) {
        this._cooGroup = cooGroup;
    }

    public setIsVisible(value: boolean) {
        this._isVisible = value;
    }

    public setActiveForm(value: "coordination" | "greenStreet") {
        const form = value === "coordination" ? this.coordinationForm : this.greenStreetForm;
        this._activeForm = form;
    }

    public setCycle(cycle: CooGroupDomain.Cycle) {
        this._activeForm.setCycle(cycle);
    }

    public openNewCycleDialog = () => {
        this._isNewCycleDialogVisible = true;
    };

    public closeNewCycleDialog = () => {
        this._isNewCycleDialogVisible = false;
        this.activeForm.resetName();
    };

    public openLaunchTimePopup = () => {
        this._isLaunchTimePopupVisible = true;
    };

    public closeLaunchTimePopup = () => {
        this._isLaunchTimePopupVisible = false;
    };

    public runCycle = async (params: IRunCycleParams) => {
        const cooGroupId = this.governance.cooGroupId;
        const cycle = this.hasChanges ? this.activeForm.build() : this.activeForm.cycle;
        let result: ResultWithValidation<CooGroupDomain.Governance>;
        if (!cycle) return;
        result = await (this.hasChanges
            ? this.service.activateCustomCycle(cooGroupId, { cycle, ...params })
            : this.service.activateCycle(cooGroupId, { cycleId: cycle.id!, ...params }));
        if (result.isOk) {
            this.governance = result.data as CooGroupDomain.Governance;
        } else {
            this.setValidationErrors("enable", result.data);
        }
    };

    public finishCycle = async () => {
        const governance = await this.service.finishCycle(this.governance.cooGroupId);
        this.governance = governance;
    };

    public extendRunningCycle = async () => {
        if (!this.governance.isRunningCommand || !this._cooGroup) return;
        const governance = await this.service.extendCycle(this._cooGroup.id, CooGroupRemoteControl.cycleMinRunTime);
        this.governance = governance;
    };

    public saveCycleAs = async () => {
        const cycle = this.activeForm.build();
        if (!this._cooGroup || !cycle) return;
        this.setIsCreating(true);
        const result = await this.service.createNewCycle(this._cooGroup.id, cycle);
        if (result.isOk) {
            this._cooGroup.addCycle(result.data);
            this.setCycle(result.data);
        } else {
            this.setValidationErrors("create", result.data);
        }
        this.setIsCreating(false);
        this.closeNewCycleDialog();
    };

    public resetForm = () => {
        this.activeForm.reset();
    };

    private setIsCreating(value: boolean) {
        this._isCreating = value;
    }

    private setValidationErrors(type: ValidationErrorsType, list: string[]) {
        this._validationErrors = {
            type,
            list,
        };
    }
}
