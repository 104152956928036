export const statuses = Object.freeze({
    success: {
        uid: "1",
        caption: "Работает",
        color: "rgb(40, 159, 70)",
        backgroundColor: "rgba(220, 245, 225, 0.6)",
    },
    warning: {
        uid: "2",
        caption: "Работает с замечаниями",
        color: "rgb(254, 148, 0)",
        backgroundColor: "rgba(255, 247, 213, 0.6)",
    },
    danger: {
        uid: "3",
        caption: "Критические ошибки",
        color: "rgb(204, 46, 37)",
        backgroundColor: "rgba(255, 222, 220, 0.6)",
    },
    disable: {
        uid: "4",
        caption: "Нет связи",
        color: "rgba(3, 40, 76, 0.66)",
        backgroundColor: "rgb(230, 233, 235)",
    },
    notConnected: {
        uid: "5",
        caption: "Не подключен",
        color: "#C4C4C4",
        backgroundColor: "rgba(196, 196, 196, 0.3)",
    },
});
