import { CooGroupDomain } from "app-domain";
import { useTheme } from "styled-components";

export const useCooGroupStatusColor = (status: CooGroupDomain.Status): string => {
    const { colors } = useTheme();

    if (status === CooGroupDomain.Status.Coordination) return colors.indigo.basic;
    if (status === CooGroupDomain.Status.GreenStreet) return colors.green.basic;
    return colors.gray.basic;
};
