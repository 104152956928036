import { SeparatedInlineList } from "../separated-inline-list";
import { Circle } from "../circle";
import styled from "styled-components/macro";

const Separator = styled(Circle).attrs(({ theme }) => ({
    color: theme.colors.content.secondary,
}))``;

export const SeparatedCaptionList = styled(SeparatedInlineList).attrs((props) => ({
    ...props,
    separator: <Separator size={2} />,
}))`
    color: ${({ theme }) => theme.colors.content.secondary};
`;
