interface IconCoords {
    x: number;
    y: number;
}

export type DirectionStartAnchorsInput = {
    y: number;
    shift: number;
    pixelsPerSecond: number;
    cycleDurationTime: number;
    hasBackwardDirection: boolean;
    visibleCycleRepeatCount: number;
};

export type DirectionStartAnchorsData = {
    isLarge: boolean;
    coords: IconCoords[];
};

export const createDirectionStartAnchorsData = (args: DirectionStartAnchorsInput): DirectionStartAnchorsData => {
    const coords = [];

    for (let i = 0; i < args.visibleCycleRepeatCount; i++) {
        const shiftInPixels = args.shift * args.pixelsPerSecond;
        const cycleDurationTimeInPixels = args.cycleDurationTime * args.pixelsPerSecond;
        const x = i * cycleDurationTimeInPixels + shiftInPixels;
        const startAnchorX = x - 2;
        coords.push({
            x: startAnchorX,
            y: args.y - 6,
        });
    }

    return {
        isLarge: args.hasBackwardDirection,
        coords,
    };
};
