import { TrafficLightDomain } from "app-domain";
import { TrafficLights } from "services";
import { TrafficLightStore } from "../traffic-light-store";

/** @deprecated Функционал по получению состояния светофора понемногу будет переезжать в TrafficLightStore */
export class TrafficLightManager {
    constructor(
        private trafficLightStore: TrafficLightStore,
        private trafficLightService: TrafficLights.TrafficLightService
    ) {}

    public async initTrafficLight(trafficLight: TrafficLightDomain.TrafficLight) {
        if (trafficLight.isInitializing || trafficLight.isInitialized) return;
        trafficLight.isInitializing = true;
        const metaData = await this.trafficLightService.getTrafficLightMetaData(trafficLight.id);
        trafficLight.setMetaData(metaData);
        trafficLight.isInitializing = false;
    }

    public async initTrafficCycles(trafficLight: TrafficLightDomain.TrafficLight) {
        if (trafficLight.isCyclesInitialized) return;
        trafficLight.cycles = await this.trafficLightService.getCycles(trafficLight.id);
    }

    public initTrafficLightById(id: number) {
        const trafficLight = this.trafficLightStore.getById(id);
        if (!trafficLight) return;
        return this.initTrafficLight(trafficLight);
    }
}
