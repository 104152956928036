import { APIRegistry, DtmTraffic } from "api";
import { Mappers } from "shared";
import { ITrafficForecastHistoryService } from "./traffic-forecast-history.types";

export class TrafficForecastHistoryAdapter implements ITrafficForecastHistoryService {
    private dtmTrafficApi: DtmTraffic.DtmTrafficAPI = APIRegistry.dtmTrafficAPI;
    private mapper = new Mappers.TrafficScoreMapper();

    public async loadTrafficScoreGroup(date: string) {
        const result = await this.dtmTrafficApi.getScoringCity(date);
        return this.mapper.fromAPI(result);
    }
}
