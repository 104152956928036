import { GridDrawerOptions } from "./grid-time-drawer.types";

export class GridTimeDrawer {
    static readonly SCALE_LINE_WIDTH = 0.5;
    static readonly STROKE_STYLE = "rgba(255,255,255,0.3)";

    static draw(ctx: CanvasRenderingContext2D, options: GridDrawerOptions) {
        ctx.lineWidth = GridTimeDrawer.SCALE_LINE_WIDTH;
        ctx.strokeStyle = GridTimeDrawer.STROKE_STYLE;
        ctx.beginPath();
        for (let x = options.x; x < options.x + options.width; x += options.pixelPerSecond) {
            ctx.moveTo(x, options.y);
            ctx.lineTo(x, options.y + options.height);
        }
        ctx.stroke();
    }
}
