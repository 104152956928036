import { memo, ReactNode } from "react";
import { TrafficLightDomain } from "app-domain";
import { Indicator } from "../base";
import * as Markup from "./traffic-light.styles";

const { statusCodeDictionaryMap, controlModeCodeDictionaryMap } = TrafficLightDomain.Constants;

export type Props = {
    icon?: ReactNode;
    status: TrafficLightDomain.Enums.StatusCode;
    controlMode: TrafficLightDomain.Enums.ControlModeCode;
    isVisibleDot?: boolean;
};

const StyledTrafficLightIcon = <Markup.Icon />;

export const TrafficLight = memo((props: Props) => {
    const inlineColor = statusCodeDictionaryMap[props.status]?.color;
    const outlineColor = controlModeCodeDictionaryMap[props.controlMode]?.color;
    const isVisibleDot = !!props.isVisibleDot;

    return (
        <Indicator
            icon={props.icon ?? StyledTrafficLightIcon}
            inlineColor={inlineColor}
            outlineColor={outlineColor}
            isVisibleDot={isVisibleDot}
        />
    );
});
