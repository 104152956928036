import { makeAutoObservable } from "mobx";
import { DateUtils } from "shared/utils";

type Options = Partial<{
    date: NullableDate;
    time: string;
    allDay: boolean;
}>;

export class DateTimeShapeFilter {
    public date: NullableDate;
    public time: string;
    public allDay: boolean;

    constructor(private options?: Options) {
        this.date = options?.date ?? null;
        this.time = options?.time ?? "";
        this.allDay = options?.allDay ?? false;
        makeAutoObservable(this);
    }

    public get isEmpty() {
        return !this.date && !this.time;
    }

    public get hours() {
        return parseInt(this.time) || 0;
    }

    public setDate(value: NullableDate) {
        this.date = value;
    }

    public setTime(value: NullableString) {
        this.time = value ?? "";
    }

    public setAllDay(value: boolean) {
        this.allDay = value;
    }

    public reset() {
        this.date = this.options?.date ?? null;
        this.time = this.options?.time ?? "";
        this.allDay = this.options?.allDay ?? false;
    }

    public toString(separator = " ") {
        if (!this.date) return "";
        const formattedDate = DateUtils.format(this.date);
        if (this.allDay) return formattedDate;
        return this.time ? `${formattedDate}${separator}${this.time}` : formattedDate;
    }
}
