import styled, { css } from "styled-components/macro";

export const Container = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.content.quinary};
    border-radius: ${({ theme }) => theme.borderRadiuses[0].str};
`;

const commonMapCss = css`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const MapFragmentContainer = styled.div`
    display: flex;
    & > img {
        border-image-width: 0;
    }
`;

export const TracksCanvas = styled.canvas`
    ${commonMapCss}
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
`;
