import { Drawer } from "../drawer";
import { Data } from "./rounded-rectangle-drawer.types";

export class RoundedRectangleDrawer extends Drawer<Data> {
    protected draw(): void {
        if (!this.data) return;
        let { x, y, width, height, radius, fillStyle } = this.data;
        let topRadius = Array.isArray(radius) ? radius[0] : radius; 
        let bottomRadius = Array.isArray(radius) ? radius[1] : radius; 
        if (width < 2 * topRadius) topRadius = width / 2;
        if (height < 2 * topRadius) topRadius = height / 2;
        if (width < 2 * bottomRadius) bottomRadius = width / 2;
        if (height < 2 * bottomRadius) bottomRadius = height / 2;
        this.ctx.beginPath();
        this.ctx.fillStyle = fillStyle;
        this.ctx.moveTo(x + topRadius, y);
        this.ctx.arcTo(x + width, y, x + width, y + height, topRadius);
        this.ctx.arcTo(x + width, y + height, x, y + height, bottomRadius);
        this.ctx.arcTo(x, y + height, x, y, bottomRadius);
        this.ctx.arcTo(x, y, x + width, y, topRadius);
        this.ctx.closePath();
        this.ctx.fill();
    }
}
