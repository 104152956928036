import { makeAutoObservable } from "mobx";

export class ChangeHistory<D> {
    private _stateIndex: number = 0;
    private _states: D[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public get state() {
        return this._states[this._stateIndex] ?? null;
    }

    public get hasPrevState() {
        return this._stateIndex > 0;
    }

    public get hasNextState() {
        return this._stateIndex < this._states.length - 1;
    }

    public pushState(state: D) {
        this._states.push(state);
        this._stateIndex = this._states.length - 1;
    }

    public setPrevState() {
        if (!this.hasPrevState) return;
        return this._stateIndex--;
    }

    public setNextState() {
        if (!this.hasNextState) return;
        this._stateIndex++;
    }

    public clear() {
        this._stateIndex = 0;
        this._states = [];
    }
}
