export enum TrafficLightErrorCode {
    /** Неисправны часы */
    ClockMalfunction = 1,
    /** Нет сетевого питания */
    NoPower = 2,
    /** Критическая неисправность лампочек */
    LampCritical = 4,
    /** Некритическая неисправность лампочек */
    LampNotCritical = 8,
    /** Неисправность ВПУ */
    RemoteControlMalfunction = 16,
    /** Открыта дверь шкафа */
    DoorOpened = 32,
    /** Критическая неисправность периферии */
    ExternalCritical = 64,
    /** Некритическая неисправность периферии */
    ExternalNotCritical = 128,
    /** Критическая неисправность оборудования */
    HardwareCritical = 256,
    /** Некритическая неисправность оборудования */
    HardwareNotCritical = 512,
    /** Критическая неисправность ПО */
    SoftwareCritical = 1024,
    /** Некритическая неисправность ПО */
    SoftwareNotCritical = 2048,
}

export interface TrafficLightError {
    code: TrafficLightErrorCode;
    name: string;
    description?: string;
    isCritical: boolean;
}

export const trafficLightErrorTypes: { [key in TrafficLightErrorCode]: TrafficLightError } = {
    [TrafficLightErrorCode.ClockMalfunction]: {
        code: TrafficLightErrorCode.ClockMalfunction,
        name: "Неисправны часы",
        isCritical: false,
    },
    [TrafficLightErrorCode.NoPower]: {
        code: TrafficLightErrorCode.NoPower,
        name: "Нет сетевого питания",
        isCritical: true,
    },
    [TrafficLightErrorCode.LampCritical]: {
        code: TrafficLightErrorCode.LampCritical,
        name: "Критическая неисправность лампочек",
        isCritical: true,
    },
    [TrafficLightErrorCode.LampNotCritical]: {
        code: TrafficLightErrorCode.LampNotCritical,
        name: "Некритическая неисправность лампочек",
        isCritical: true,
    },
    [TrafficLightErrorCode.RemoteControlMalfunction]: {
        code: TrafficLightErrorCode.RemoteControlMalfunction,
        name: "Неисправность ВПУ",
        isCritical: false,
    },
    [TrafficLightErrorCode.DoorOpened]: {
        code: TrafficLightErrorCode.DoorOpened,
        name: "Открыта дверь шкафа",
        isCritical: false,
    },
    [TrafficLightErrorCode.ExternalCritical]: {
        code: TrafficLightErrorCode.ExternalCritical,
        name: "Критическая неисправность периферии",
        isCritical: true,
    },
    [TrafficLightErrorCode.ExternalNotCritical]: {
        code: TrafficLightErrorCode.ExternalNotCritical,
        name: "Некритическая неисправность периферии",
        isCritical: false,
    },
    [TrafficLightErrorCode.HardwareNotCritical]: {
        code: TrafficLightErrorCode.HardwareNotCritical,
        name: "Некритическая неисправность оборудования",
        isCritical: false,
    },
    [TrafficLightErrorCode.HardwareCritical]: {
        code: TrafficLightErrorCode.HardwareCritical,
        name: "Критическая неисправность оборудования",
        isCritical: true,
    },
    [TrafficLightErrorCode.SoftwareCritical]: {
        code: TrafficLightErrorCode.SoftwareCritical,
        name: "Критическая неисправность ПО",
        isCritical: true,
    },
    [TrafficLightErrorCode.SoftwareNotCritical]: {
        code: TrafficLightErrorCode.SoftwareNotCritical,
        name: "Некритическая неисправность ПО",
        isCritical: false,
    },
};
