type Point = {
    x: number;
    y: number;
};

export type GreenWaveData = {
    fillStyle: string;
    points: Point[];
};

export const createGreenWaveData = (args?: GreenWaveData) =>
    args
        ? args
        : {
              fillStyle: "",
              points: [],
          };
