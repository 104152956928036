import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: ${({ theme }) => theme.spacings[1].str};
`;

export const Action = styled.div`
    display: block;
    width: 32px;
    height: 32px;
`;
