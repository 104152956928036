import { CanvasSVG } from "lib/canvas-svg";
import { getTrafficLightDirectionIconURL } from "shared/utils";
import { Props } from "./traffic-light-cycle-visualizer.types";
import { VisualizationData, PhaseVisData } from "./visualizers";

const createPhaseVisData = (passportPhase: Props["passportPhases"][0], cyclePhase: Props["cyclePhases"][0]) => ({
    directionByNum: passportPhase.directionByNum,
    phaseNumber: cyclePhase.phaseNumber,
    tBasic: cyclePhase.tBasic,
    tMin: cyclePhase.tMin,
    tPhase: cyclePhase.tPhase,
    tProm: cyclePhase.tProm,
});

export const createVisualizationData = async (props: Omit<Props, "cycleType">): Promise<VisualizationData> => {
    const { directions, cyclePhases, passportPhases, cycleTime } = props;
    const promises: Promise<HTMLImageElement>[] = [];

    const data = {
        cycleTime,
        directions: directions.map((direction) => {
            const icon = new CanvasSVG(getTrafficLightDirectionIconURL(direction.type));
            promises.push(icon.load());
            return {
                ...direction,
                icon,
            };
        }),
        phases:
            cyclePhases?.reduce((result, cyclePhase) => {
                const passportPhase = passportPhases.find((phase) => phase?.number === cyclePhase.phaseNumber);
                if (!passportPhase) return result;
                result.push(createPhaseVisData(passportPhase, cyclePhase));
                return result;
            }, [] as PhaseVisData[]) ?? [],
    };
    try {
        await Promise.all(promises);
    } catch {}
    return data;
};
