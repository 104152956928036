import React, { memo } from "react";
import { DividerOrientation } from "./divider.types";
import * as Markup from "./divider.styles";

export type DividerProps = {
    /** Кастомный класс */
    className?: string;
    /** Отображение элемента в DOM дереве */
    as?: "li" | "hr" | "span";
    /** Ориентация разделителя */
    orientation?: DividerOrientation;
    /** Толщина */
    thickness?: string;
    /** Отступы */
    margin?: string;
    /** Поля */
    padding?: string;
    /** Цвет разделителя */
    color?: string;
};

const DividerComponent = (props: DividerProps) => {
    const { orientation = "horizontal", ...restProps } = props;
    return <Markup.Divider orientation={orientation} {...restProps} />;
};

export const Divider = memo(DividerComponent);
