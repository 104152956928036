import { action, computed, makeObservable, observable } from "mobx";
import { CooGroupDomain } from "app-domain";
import { Utils } from "shared";
import { Form } from "./abstract-form";

export class GreenStreetForm extends Form {
    constructor() {
        super();
        makeObservable(this, {
            activePhases: observable,
            setActivePhaseNumber: action,
            hasChanges: computed,
        });
    }

    public activePhases: {
        [key: number]: number;
    } = {};

    public setCycle(cycle: CooGroupDomain.Cycle): void {
        super.setCycle(cycle);
        this.setUpDefaults();
    }

    public build(): CooGroupDomain.CustomCycle | null {
        if (!this.originCycle) return null;
        const result = Utils.omitKeys(this.originCycle, "id");
        return {
            ...result,
            name: this.name.value,
            greenLinePhases: Object.entries(this.activePhases).map(([facilityId, phaseNumber]) => ({
                facilityId: Number(facilityId),
                phaseNumber,
            })),
        };
    }

    public get hasChanges() {
        return this.originCycle?.greenLinePhases?.some((greenLinePhase) => {
            const activePhaseNumber = this.activePhases[greenLinePhase.facilityId];
            if (typeof activePhaseNumber === "undefined") return false;
            return activePhaseNumber !== greenLinePhase.phaseNumber;
        });
    }

    public reset(): void {
        super.reset();
        this.setUpDefaults();
    }

    public getActivePhaseNumber(trafficLightId: number): NullableNumber {
        return this.activePhases[trafficLightId] ?? null;
    }

    public setActivePhaseNumber(trafficLightId: number, phaseNumber: number) {
        this.activePhases[trafficLightId] = phaseNumber;
    }

    private setUpDefaults() {
        if (this.cycle?.greenLinePhases?.length === 0) {
            return;
        }
        this.cycle?.greenLinePhases?.forEach(
            (greenLinePhase) => (this.activePhases[greenLinePhase.facilityId] = greenLinePhase.phaseNumber)
        );
    }
}
