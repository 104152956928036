export class Issue {
    public id: number;
    public description: NullableString;
    public num: NullableNumber;
    public lastUpdate: NullableDate;
    public execute: NullableDate;
    public executor: NullableString;
    public type: {
        id: number;
        name: NullableString;
    };
    public status: {
        id: number;
        name: NullableString;
        color: NullableString;
    };

    constructor(data: IssueProps) {
        this.id = data.id;
        this.num = data.num ?? null;
        this.description = data.description ?? null;
        this.lastUpdate = data.lastUpdate ?? null;
        this.execute = data.execute ?? null;
        this.executor = data.executor ?? null;
        this.type = {
            id: data.id,
            name: data.type.name ?? null,
        };
        this.status = {
            id: data.status.id,
            name: data.status.name ?? null,
            color: data.status.color ?? null,
        };
    }
}

export type IssueProps = Partial<Omit<Issue, "id" | "type" | "status">> & {
    id: number;
    type: {
        id: number;
        name?: string;
    };
    status: {
        id: number;
        name?: string;
        color?: string;
    };
};
