import { makeAutoObservable, runInAction, reaction } from "mobx";
import { TVCamera } from "app-domain";
import { Filter } from "./filter";
import * as utils from "./tv-camera-list-adapter.utils";
import { Selection } from "../selection";
import { ITVCameraListService, LoadTVCameraListFilterShape } from "./tv-camera-list.types";

/** TODO: разобраться как объеденить со стором TVCameras что в src\app\store\tv-cameras\tv-cameras.ts */
export class TVCameraList {
    public isInitialized = false;
    public readonly filter: Filter = new Filter();

    private selectedItems = new Selection();
    private filterChangeEffect!: VoidFunction;

    constructor(private service: ITVCameraListService) {
        makeAutoObservable(this);

        this.setUpEffects();
    }

    public cameras: TVCamera.ITVCameraListMap = new Map();

    public get itemsToList() {
        return utils.convertArrayTvCamerasGroup(this.cameras);
    }

    public get count() {
        return this.cameras.size;
    }

    public async loadTvCamerasGroup(filter?: LoadTVCameraListFilterShape) {
        const filterShape = filter ?? this.filter.shape;
        this.isInitialized = false;
        const collectionMap = await this.service.loadTVCameraListMap(filterShape);
        this.isInitialized = true;

        runInAction(() => {
            this.cameras = collectionMap;
        });
    }

    private setUpEffects() {
        this.filterChangeEffect = reaction(
            () => this.filter.shape,
            (shape) => {
                this.loadTvCamerasGroup(shape);
            }
        );
    }

    public isSelectedItem(id: number) {
        return this.selectedItems.isSelected(id);
    }

    public chooseItem(id: number) {
        this.selectedItems.clear();
        this.selectedItems.add(id);
    }

    public clearSelectedItems() {
        this.selectedItems.clear();
    }

    /** TODO: уточнить необходимость уничтожения реакции */
    // public destroy = () => {
    //     this.filterChangeEffect();
    // };

    public getById(id: number) {
        return this.cameras.get(id) ?? null;
    }
}
