import styled, { css } from "styled-components/macro";

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        gap: ${theme.spacings[5].str};
        padding: ${theme.spacings[1].str} ${theme.spacings[5].str} ${theme.spacings[5].str} ${theme.spacings[5].str};
        align-items: center;
        flex-grow: 1;
    `
);

export const Controls = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacings[3].str};
`;
