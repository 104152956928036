import React, { useMemo, memo, useCallback, ReactNode, useState } from "react";
import { AccordionContext, ContextValue } from "./accordion.context";
import { Container } from "./accordion-styles";

type ChangeHandlerData = {
    value: boolean;
    event: React.MouseEvent<HTMLDivElement>;
};

export type AccordionProps = {
    children: ReactNode;
    isExpanded?: boolean;
    isDefaultExpanded?: boolean;
    onChange?: (data: ChangeHandlerData) => void;
    isDisabled?: boolean;
    isGrowing?: boolean;
    className?: string;
};

const AccordionComponent = (props: AccordionProps) => {
    const { children, onChange, isExpanded, isDefaultExpanded = true, isDisabled = false, isGrowing = false } = props;
    const [innerExpanded, setInnerExpanded] = useState(isDefaultExpanded);

    const isControlled = typeof isExpanded === "boolean";

    const handleToggle: ContextValue["onToggle"] = useCallback(
        (event) => {
            if (isDisabled) return;
            if (!isControlled) return setInnerExpanded(!innerExpanded);
            onChange?.({ value: !isExpanded, event });
        },
        [onChange, isExpanded, isDisabled, isControlled, innerExpanded]
    );

    const computedIsExpanded = useMemo(() => {
        if (isDisabled) return false;
        if (isControlled) return isExpanded as boolean;
        return innerExpanded;
    }, [isExpanded, isDisabled, isControlled, innerExpanded]);

    const contextValue: ContextValue = useMemo(
        () => ({ isExpanded: computedIsExpanded, onToggle: handleToggle, isDisabled }),
        [handleToggle, computedIsExpanded, isDisabled]
    );

    return (
        <AccordionContext.Provider value={contextValue}>
            <Container className={props.className} isGrowing={isGrowing}>
                {children}
            </Container>
        </AccordionContext.Provider>
    );
};

export const Accordion = memo(AccordionComponent);
