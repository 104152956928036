import styled from "styled-components/macro";
import { VerticalList as List } from "@megapolis/uikit/vertical-list";

type Props = {
    gap?: number;
};

export const VerticalList = styled(List)<Props>`
    gap: ${({ gap }) => (gap ? `${gap}px` : "unset")};
    width: 100%;
`;
