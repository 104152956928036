import { RouteDomain } from "app-domain";
import { MapControllerTypes } from "map-controller";

const getMarkerViewType = (index: number, length: number): MapControllerTypes.MarkerViewType => {
    if (index === 0) return "first";
    if (index === length - 1) return "last";
    return "middle";
};

export const pointsMapper = (item: RouteDomain.Point, index: number, array: RouteDomain.Point[]) => ({
    id: item.id,
    lngLat: { lng: item.longitude, lat: item.latitude },
    viewType: getMarkerViewType(index, array.length),
});
