import { memo, useContext, useCallback } from "react";
import { Button as UIButton } from "@megapolis/uikit/button";
import { Context } from "../context";
import { ButtonWrapper } from "./button.styles";

type Props = {
    moreText?: string;
    lessText?: string;
    isFullWidth?: boolean;
};

const ButtonComponent = (props: Props) => {
    const { isShowingMore, setIsShowingMore, isButtonVisible } = useContext(Context);
    const { moreText = "Больше", lessText = "Меньше", isFullWidth = false } = props;

    const text = isShowingMore ? lessText : moreText;

    const handleClick = useCallback(() => {
        setIsShowingMore((prev) => !prev);
    }, [setIsShowingMore]);

    if (!isButtonVisible) return null;

    return (
        <ButtonWrapper isFullWidth={isFullWidth}>
            <UIButton content={UIButton.Content.Text} text={text} size="s" priority="quaternary" onClick={handleClick} />
        </ButtonWrapper>
    );
};

export const Button = memo(ButtonComponent);
