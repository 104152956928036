import { ReactNode } from "react";
import styled from "styled-components/macro";

type Props = {
    children: ReactNode;
};

const Text = styled.span`
    color: ${({ theme }) => theme.colors.content.secondary};
`;

export const CaptionText = (props: Props) => <Text>{props.children}</Text>;
