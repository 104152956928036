import { format } from "date-fns";
import { DashboardDomain } from "app-domain";
import { emptyModelFactory } from "./history-status-factory.empty";

export const historyStatusFactory = (
    data?: DashboardDomain.Summary["trafficlightHistory"]
): Store.Dashboard.TrafficLights["history"] => {
    const empty = emptyModelFactory();

    if (!data) return empty;

    const result = data?.reduce((acc, status) => {
        if (!status.timestamp) return acc;

        const dateTime = format(new Date(status.timestamp), "HH:mm");

        acc.labels.push(dateTime);
        acc.statuses.success.values.push(status?.onlineCount ?? null);
        acc.statuses.warning.values.push(status?.warningCount ?? null);
        acc.statuses.danger.values.push(status?.criticalCount ?? null);
        acc.statuses.disable.values.push(status?.offlineCount ?? null);
        acc.statuses.notConnected.values.push(status?.notConnectedCount ?? null);

        return acc;
    }, empty);

    return result;
};
