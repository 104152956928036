import { lightTheme } from "@megapolis/uikit/theme";
import { ControlMode } from "../enums";

const { colors } = lightTheme;

export const getControlModeColor = (controlMode: ControlMode) => {
    if (controlMode === ControlMode.Scheduled) return colors.teal.basic;
    if (controlMode === ControlMode.User) return colors.blue.basic;
    if (controlMode === ControlMode.Inactive) return colors.gray.basic;
    if (controlMode === ControlMode.Story) return colors.blue.basic;
    return colors.black.basic;
};
