import { QueryParamsBuilder } from "lib";
import { PaginationOptions, FacilityLogsFilter } from "./facility.types";

type Params = PaginationOptions & {
    filter?: FacilityLogsFilter;
    includeMalfunctionDetails?: boolean;
    includeRaw?: boolean;
};

export class FacilityLogsParamsFilter {
    private builder = new QueryParamsBuilder();

    constructor(private params: Params) {}

    public toString() {
        const { count, page, filter, includeMalfunctionDetails, includeRaw } = this.params;
        return this.builder
            .addParam("count", count)
            .addParam("page", page)
            .addParam("start", filter?.start)
            .addParam("finish", filter?.finish)
            .addIterableParam("eventtype", filter?.eventTypes)
            .addIterableParam("malfunctiontype", filter?.malfunctionTypes)
            .addIterableParam("mode", filter?.modes)
            .addIterableParam("status", filter?.statuses)
            .addIterableParam("controlmode", filter?.controlModes)
            .addParam("includemalfunctiondetails", includeMalfunctionDetails)
            .addParam("includeRaw", includeRaw)
            .toString();
    }
}
