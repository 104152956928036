import { YandexGeocode } from "api";
import { RouteDomain } from "app-domain";

export class YandexGeocodeService {
    constructor(private api: YandexGeocode.YandexGeocodeAPI) {}

    public getAddressesByLngLat = async (point: LatLng, center?: LatLng): Promise<Nullable<RouteDomain.Point>> => {
        const result = await this.api.getAddressByLatLng(point, center);
        if (!result) return null;
        const featureMember = result.response.GeoObjectCollection.featureMember;
        if (!featureMember.length) return null;
        return new RouteDomain.Point({
            id: new Date().getTime(),
            fullAddress: featureMember[0]?.GeoObject.metaDataProperty.GeocoderMetaData.text,
        });
    };
}
