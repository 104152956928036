import * as Markup from "./phase-times.styles";

type Props = {
    /** Т основное */
    tBase: number;
    /** Т промтакта */
    tProm: number;
    /** Т мин */
    tMin?: number;
};

export const PhaseTimes = (props: Props) => (
    <Markup.Container>
        <Markup.Row>
            <span>Т осн</span>
            {!!props.tBase && (
                <span>
                    <Markup.ValueContainer>{props.tBase}</Markup.ValueContainer> сек
                </span>
            )}
        </Markup.Row>
        <Markup.Row>
            <span>Т пром</span>
            {!!props.tProm && (
                <span>
                    <Markup.ValueContainer>{props.tProm}</Markup.ValueContainer> сек
                </span>
            )}
        </Markup.Row>
        <Markup.Row>
            <span>Т мин</span>
            {!!props.tMin && (
                <span>
                    <Markup.ValueContainer>{props.tMin}</Markup.ValueContainer> сек
                </span>
            )}
        </Markup.Row>
    </Markup.Container>
);
