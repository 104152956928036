import React, { memo } from "react";
import { AccordionHeaderProps } from "./accordion-header-description.types";
import { Container } from "./accordion-header-description.styles";

const stopPropagation: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
};

const AccordionHeaderDescriptionComponent = (props: AccordionHeaderProps) => {
    const { children, contentPosition = "start" } = props;

    return (
        <Container contentPosition={contentPosition}>
            {React.Children.map(children, (child) => {
                return <span onClick={stopPropagation}>{child}</span>;
            })}
        </Container>
    );
};

export const AccordionHeaderDescription = memo(AccordionHeaderDescriptionComponent);
