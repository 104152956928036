import { ReactNode } from "react";
import { lightTheme } from "@megapolis/uikit/theme";
import * as Markup from "./base.styles";

export type Props = {
    icon: ReactNode;
    inlineColor: string;
    outlineColor?: string;
    isVisibleDot?: boolean;
    indicatorColor?: string;
};

export const BaseIndicator = (props: Props) => {
    return (
        <Markup.Wrapper>
            <Markup.Outline color={props.outlineColor}>
                <Markup.Inline color={props.inlineColor}>{props.icon}</Markup.Inline>
            </Markup.Outline>
            {props.isVisibleDot && <Markup.Indicator color={props.indicatorColor || lightTheme.colors.orange.basic} />}
        </Markup.Wrapper>
    );
};
