import { APITypes } from "api";
import { TrafficLightDomain } from "app-domain";

export class TrafficLightStateMapper {
    public fromAPI(state: APITypes.FacilityState): TrafficLightDomain.TrafficLightState {
        return {
            ...state,
            malfunctions: state.malfunctions?.map((malfunction) => ({
                name:
                    TrafficLightDomain.Constants.malfunctionCodeDictionaryMap[
                        malfunction.type as TrafficLightDomain.Enums.MalfunctionType
                    ]?.name ?? "Неисправность",
                description: malfunction.description,
                code: malfunction.type,
                isCritical: malfunction.isCritical,
            })),
            governanceInfo: state.governance?.id
                ? new TrafficLightDomain.GovernanceInfo(
                      state.governance.id,
                      state.governance.userId,
                      state.governance.username
                  )
                : null,
        };
    }
}
