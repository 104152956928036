import { PhaseType } from "../enums";

export const PhaseTypeName = {
    [PhaseType.Basic]: "Основная",
    [PhaseType.Conditional]: "Вызывная",
    [PhaseType.ConditionalAlternate]: "Вызывная(подменная)",
    [PhaseType.Special]: "Специальная",
} as {
    [key in PhaseType]: string;
};
