import { memo, ReactNode } from "react";
import { Indicator } from "../base";
import * as Markup from "./detector.styles";

export type Props = {
    inlineColor: string;
    outlineColor: string;
    icon?: ReactNode;
};

const StyledTransportDetectorIcon = <Markup.Icon />;

export const Detector = memo((props: Props) => {
    const { icon, inlineColor, outlineColor } = props;

    return (
        <Indicator icon={icon ?? StyledTransportDetectorIcon} inlineColor={inlineColor} outlineColor={outlineColor} />
    );
});
