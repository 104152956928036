import styled from "styled-components";
import { InlineList as InlineListComponent } from "@megapolis/uikit/inline-list";

export type InlineListProps = {
    isWrap?: boolean;
    padding?: string;
    margin?: string;
    gap?: number;
};

export const InlineList = styled(InlineListComponent)<InlineListProps>`
    flex-wrap: ${({ isWrap }) => (isWrap ? "wrap" : "nowrap")};
    padding: ${({ padding }) => (padding ? padding : 0)};
    margin: ${({ margin }) => (margin ? margin : 0)};
    gap: ${({ gap }) => (gap ? `${gap}px` : "unset")};
`;
