import { memo } from "react";
import { SimpleDataGridProps } from "./simple-data-grid.types";
import { defaultColumnRenderer, defaultCellRenderer } from "./simple-data-grid.utils";
import * as Markup from "./simple-data-grid.styles";

const DataGridComponent = (props: SimpleDataGridProps) => {
    const { className, columns, rows, renderColumn = defaultColumnRenderer, renderCell = defaultCellRenderer } = props;

    return (
        <Markup.Table className={className}>
            <thead>
                <Markup.HeaderRow>{columns.map(renderColumn)}</Markup.HeaderRow>
            </thead>
            <tbody>
                {rows.map((row) => (
                    <Markup.Row key={row.id}>
                        {columns.map((column) => (
                            <Markup.Cell key={`row-${row.id}-${column.label}`}>
                                {renderCell({ row, column, value: row[column.key] })}
                            </Markup.Cell>
                        ))}
                    </Markup.Row>
                ))}
            </tbody>
        </Markup.Table>
    );
};

export const DataGrid = memo(DataGridComponent);
