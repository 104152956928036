import { Drawer } from "diagrams/drawers";
import { OverlayData } from "./overlay-drawer.types";

export class OverlayDrawer extends Drawer<OverlayData> {
    private readonly fillStyle: string = "rgba(255, 255, 255, 0.6)";

    protected draw = () => {
        if (!this.data) return;
        this.ctx.fillStyle = this.fillStyle;
        for (let item of this.data) {
            this.ctx.fillRect(item.x, item.y, item.width, item.height);
        }
    };
}
