export enum ControlSource {
    /** Не активно */
    Inactive = 0,
    /** Адаптив */
    Adaptive = 1,
    /** Лента координации */
    CooGroup = 2,
    /** Диспетчерское управления */
    User = 3,
}
