import { memo, ReactNode } from "react";
import { Indicator } from "../base";
import * as Markup from "./tv-camera.styles";

export type Props = {
    inlineColor: string;
    outlineColor: string;
    icon?: ReactNode;
};

const StyledTvCameraIcon = <Markup.Icon />;

export const TVCamera = memo((props: Props) => {
    const { icon, inlineColor, outlineColor } = props;

    return <Indicator icon={icon ?? StyledTvCameraIcon} inlineColor={inlineColor} outlineColor={outlineColor} />;
});
