import { PolygonDrawer, Drawer } from "diagrams/drawers";
import { GreenWaveData } from "./green-wave.types";

export class GreenWaveDrawer extends Drawer<GreenWaveData> {
    private polygonDrawer: PolygonDrawer;

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.polygonDrawer = new PolygonDrawer(ctx);
    }

    protected draw = () => {
        if (!this.data) return;
        this.data.forEach(this.polygonDrawer.draw);
    };
}
