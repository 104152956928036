import { useEffect, useState } from "react";
import { TrafficLightDomain } from "app-domain";

const checkAllInitialized = (trafficLightList: TrafficLightDomain.TrafficLight[]) =>
    trafficLightList.every((trafficLight) => trafficLight.isCyclesInitialized);

export const useTrafficLightsGroupCyclesInitialized = (trafficLights: TrafficLightDomain.TrafficLight[]) => {
    const [isAllInitialized, setAllInitialized] = useState(() => checkAllInitialized(trafficLights));

    useEffect(() => {
        const handleTrafficLightCyclesChange = () => {
            setAllInitialized(checkAllInitialized(trafficLights));
        };

        trafficLights.forEach((trafficLight) => {
            trafficLight.on("cyclesChanged", handleTrafficLightCyclesChange);
        });

        return () => {
            trafficLights.forEach((trafficLight) => {
                trafficLight.off("cyclesChanged", handleTrafficLightCyclesChange);
            });
        };
    }, [trafficLights]);

    return isAllInitialized;
};
