export const TRAFFIC_LIGHT_DETAILS_URL_TYPE = "traffic-light";

export const TRAFFIC_LIGHT_LIST_URL_TYPE = "traffic-lights";

export const TRAFFIC_LIGHT_LIST_BASE_URL = `/${TRAFFIC_LIGHT_LIST_URL_TYPE}`;

export const TRAFFIC_LIGHT_DETAILS_BASE_URL = `/${TRAFFIC_LIGHT_DETAILS_URL_TYPE}`;

export const TRAFFIC_LIGHT_LIST_ROUTE_PATH = `/${TRAFFIC_LIGHT_LIST_URL_TYPE}/:id?`;

export const TRAFFIC_LIGHT_DETAILS_ROUTE_PATH = `/${TRAFFIC_LIGHT_DETAILS_URL_TYPE}/:id/:tab?`;

export const TRAFFIC_LIGHT_ROUTE_MATCHER = [TRAFFIC_LIGHT_LIST_ROUTE_PATH, TRAFFIC_LIGHT_DETAILS_ROUTE_PATH];
