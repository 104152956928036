import { memo } from "react";
import { MonthView } from "./month-view";
import { YearView } from "./year-view";
import { DateNavigation } from "./date-navigation";
import { useDateState, useViewState } from "./calendar.hooks";
import { useInitLocale, LocaleContext } from "./locale-context";
import { CalendarContext, CalendarContextValue } from "./calendar-context";
import { ViewType } from "./calendar.types";
import { Props } from "./calendar.types";
import * as Utils from "./calendar.utils";
import * as Markup from "./calendar.styles";

export const Calendar = memo((props: Props) => {
    const locale = useInitLocale(props.locale);
    const dateState = useDateState(props.value, props.onChange);
    const viewState = useViewState(props.value, props.onViewDateChange);
    const weekends = props.weekends && Utils.transformDateListToMap(props.weekends);

    const contextValue: CalendarContextValue = {
        dateState,
        viewState,
        weekends,
    };

    const view = viewState.view === ViewType.Month ? <MonthView /> : <YearView />;

    return (
        <CalendarContext.Provider value={contextValue}>
            <LocaleContext.Provider value={locale}>
                <Markup.Container isFullWidth={props.isFullWidth}>
                    <DateNavigation />
                    {view}
                </Markup.Container>
            </LocaleContext.Provider>
        </CalendarContext.Provider>
    );
});
