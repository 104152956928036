import type { PointData, PointParams } from "./point.types";

export class Point implements PointData {
    public id: number;
    public osmid: number;
    public name: NullableString;
    public housenumber: string;
    public street: string;
    public city: NullableString;
    public distance: number;
    public latitude: number;
    public longitude: number;
    private _fullAddress?: string;

    constructor(params: PointParams) {
        this.id = params.id ?? 0;
        this.osmid = params.osmid ?? 0;
        this.name = params.name ?? "";
        this.housenumber = params.housenumber ?? "";
        this.street = params.street ?? "";
        this.city = params.city ?? "";
        this.distance = params.distance ?? 0;
        this.latitude = params.latitude ?? 0;
        this.longitude = params.longitude ?? 0;
        this._fullAddress = params.fullAddress;
    }

    public get fullAddress() {
        return (
            this._fullAddress ??
            `${this.city ? `г. ${this.city} ` : ""}${this.street ? `${this.street} ` : ""}${
                this.housenumber ? `д. ${this.housenumber}` : ""
            }`
        );
    }
}
