import { TypedEmitter } from "tiny-typed-emitter";
import * as signalR from "@microsoft/signalr";
// import * as signalRMsgPack from "@aspnet/signalr-protocol-msgpack";
import { REACT_APP_API_DETECTOR_SERVICE } from "env-data";
import type { Signal } from "@megapolis/speed-detector-visualization";
import "@aspnet/signalr-protocol-msgpack";

enum Events {
    Fixation = "",
}

type Handlers = {
    [Events.Fixation]: (signals: Signal[]) => void;
};

export class Signals extends TypedEmitter<Handlers> {
    public readonly events = Events;
    private uid: number | null = null;
    private connection: signalR.HubConnection;
    /** Интервал переподключения */
    private readonly intervalReconnect: number = 3000;
    /** Отложений процесс */
    private timerReconnectId?: number;
    /** Предыдущая подписка */
    private prevListener?: () => void;

    constructor() {
        super();

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${REACT_APP_API_DETECTOR_SERVICE}/events`)
            .configureLogging(signalR.LogLevel.Information)
            // .withHubProtocol(new signalRMsgPack.MessagePackHubProtocol())
            .build();
    }

    public getId = () => this.uid;

    public setId = (id: number | null) => {
        if (this.uid === id) return;

        this.uid = id;

        this.stop();
        if (this.uid !== null) this.start();
    };

    private start = async () => {
        if (this.timerReconnectId) window.clearTimeout(this.timerReconnectId);

        const { uid } = this;

        try {
            /** Останавливаем подключение */
            await this.connection.stop();

            if (uid === null) return Promise.resolve();

            /** Запускаем подключение */
            await this.connection.start();

            /** Подписываемся */
            this.connection.stream("DetectorEvent", uid).subscribe({
                next: (message: any) => {
                    const fixations = [
                        {
                            laneNumber: message.lane,
                            carSpeed: message.speed,
                            carType: message.vehicleClass,
                            averageSpeed: 50,
                        },
                    ];

                    this.emit(this.events.Fixation, fixations);
                },
                error: (error) => {
                    console.warn("Signals::start -> connection.subscribe.error", error);
                    if (this.uid === uid && this.uid !== null) {
                        this.reConnect();
                    }
                },
                complete: () => console.info("complete"),
            });
        } catch (error) {
            console.warn("Signals::start -> connection.subscribe", error);
            this.reConnect();
        }
    };

    private stop = () => {
        this.connection.stop();
    };

    private reConnect = () => {
        if (this.timerReconnectId) window.clearTimeout(this.timerReconnectId);

        this.timerReconnectId = window.setTimeout(() => {
            if ("Connected" === this.connection.state) return;
            this.start();
        }, this.intervalReconnect);
    };
}
