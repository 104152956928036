import { BBoxSize, Utils } from "./traffic-light-layer";
import Paint, { TrackAppearance, TrackLabel } from "./paint";
import DirectionVisual from "./direction-visual";

interface Options {
    type: string;
    nVisible: boolean;
    nLat: number;
    nLng: number;
}
interface Point {
    x: number;
    y: number;
}
export default class TrackVisual {
    points: Point[];
    direction: DirectionVisual;
    _canvasSize: number;
    path: Array<Point>;
    trackType: string;
    label?: TrackLabel;

    constructor(direction: DirectionVisual, opt: Options) {
        this.direction = direction;

        const tl = direction.trafficlight;
        this.trackType = opt.type;
        this.points = Paint.getTrackPoints(opt, direction.type, tl.center);
        this.path = this.points.map((p) => ({ x: p.x, y: p.y }));
        this._canvasSize = 0;

        if (opt.nVisible) {
            const labelPos = Utils.project({ lat: opt.nLat, lng: opt.nLng });
            this.label = {
                x: labelPos.x - tl.center.x,
                y: -labelPos.y + tl.center.y,
                label: `${direction.num}`,
            };
        }
    }

    draw(ctx: CanvasRenderingContext2D, x: number, y: number, size: number, appearence: TrackAppearance) {
        const points = this.points;
        const drawScale = size / (BBoxSize * 2);
        const zoom = this.direction.trafficlight.owner.map!.getZoom();

        for (let i = 0, len = this.path.length; i < len; i++) {
            const p = this.path[i];
            points[i].x = x + p.x * drawScale + size / 2;
            points[i].y = y + p.y * drawScale + size / 2;
        }

        let label: TrackLabel | undefined;
        if (this.label) {
            label = {
                x: x + this.label.x * drawScale + size / 2,
                y: y + this.label.y * drawScale + size / 2,
                label: this.label.label,
            };
        }

        Paint.drawTrack(ctx, appearence, this.direction.type, points, size * (zoom > 17 ? 0.7 : 1), 20, label);
    }
}
