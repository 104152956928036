import { memo, FC } from "react";
import { MenuContext } from "./menu.context";
import { ClickHandler } from "./menu.types";
import * as Markup from "./menu.styles";

interface Props {
    width?: string;
    height?: string;
    onClick?: ClickHandler;
}

export const Menu: FC<Props> = memo(({ children, onClick, width, height }) => (
    <MenuContext.Provider value={{ onClick }}>
        <Markup.Container width={width} height={height}>
            {children}
        </Markup.Container>
    </MenuContext.Provider>
));
