import { CooGroupDomain } from "app-domain";
import { useTheme } from "styled-components";

export const useCooGroupControlModeColor = (controlMode: CooGroupDomain.ControlMode): string => {
    const { colors } = useTheme();
    if (controlMode === CooGroupDomain.ControlMode.Scheduled) return colors.teal.basic;
    if (controlMode === CooGroupDomain.ControlMode.User) return colors.blue.basic;
    if (controlMode === CooGroupDomain.ControlMode.Inactive) return colors.gray.basic;
    if (controlMode === CooGroupDomain.ControlMode.Story) return colors.blue.basic;
    return colors.black.basic;
};
