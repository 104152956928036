import { BaseApi } from "api/utils";
import { GetFacilityCurrentCycleResult } from "./state-api.types";
import { CooGroupGovernance, FacilityGovernance, CooGroupState, FacilityState } from "../types";

export class StateAPI extends BaseApi {
    /** Возвращает информацию о сервере. Нужен клиенту для синхронизации времени */
    public getStateInfo(): Promise<{ date: DateTimeString }> {
        const url = this.buildUrl(`/info`);
        return this.request({ url });
    }

    public geCooGroupStateList(): Promise<CooGroupState[]> {
        const url = this.buildUrl("/coogroup");
        return this.request({ url });
    }

    public getStateCooGroupById(id: number): Promise<CooGroupState> {
        const url = this.buildUrl(`/${id}`);
        return this.request({ url });
    }

    public getCurrentUserStateGovernance(): Promise<FacilityGovernance[]> {
        const url = this.buildUrl(`/governance/my`);
        return this.request({ url });
    }

    public getCurrentUserCooGroupGovernanceList(): Promise<CooGroupGovernance[]> {
        const url = this.buildCooGroupURL(`/governance/my`);
        return this.request({ url });
    }

    public async getCooGroupGovernance(id: number): Promise<CooGroupGovernance | null> {
        const url = this.buildCooGroupURL(`/${id}/governance`);
        try {
            return await this.request({ url, as: "json" });
        } catch {
            return null;
        }
    }

    public getFacilityStatusStarted(facilityId: number): Promise<DateTimeString> {
        const url = this.buildUrl(`/${facilityId}/status/started`);
        return this.request({ url });
    }

    public getFacilityGovernanceList(facilityId: number): Promise<FacilityGovernance[]> {
        const url = this.buildUrl(`/${facilityId}/governance`);
        return this.request({ url });
    }

    public getFacilityGovernanceById(governanceId: number): Promise<FacilityGovernance | void> {
        const url = this.buildUrl(`/governance/${governanceId}`);
        return this.request({ url });
    }

    public async getCooGroupGovernanceById(governanceId: number): Promise<CooGroupGovernance | null> {
        const url = this.buildCooGroupURL(`/governance/${governanceId}`);
        return (await this.request({ url })) ?? null;
    }

    /** Получает активную программу СО с контроллера */
    public getFacilityCurrentCycle(id: number): Promise<GetFacilityCurrentCycleResult> {
        const url = this.buildUrl(`/${id}/cycle/current`);
        return this.request({ url });
    }

    /** Возвращает состояния всех светофорных объектов */
    public getFacilitiesStates(): Promise<FacilityState[]> {
        const url = this.buildUrl();
        return this.request({ url });
    }

    /** Возвращает список запущенных отложенных команд*/
    public async getPlannedControls(): Promise<FacilityGovernance[]> {
        const url = this.buildUrl(`/governance/planned/executing`);
        const response = await this.request({ url });

        return response;
    }

    protected buildUrl(endpoint: string = "") {
        return super.buildUrl(`/state${endpoint}`);
    }

    private buildCooGroupURL(endpoint: string = "") {
        return this.buildUrl(`/cooGroup${endpoint}`);
    }
}
