import { PureComponent, ReactNode, ComponentType } from "react";

type State = {
    hasError: boolean | null;
};

type ErrorBoundaryProps = {
    fallback?: ReactNode;
};

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, State> {
    state: State = { hasError: false };

    static getDerivedStateFromError(error: Error) {
        return {
            error: error.message,
        };
    }

    render() {
        const { children, fallback } = this.props;
        if (this.state.hasError) return fallback ?? null;
        return children;
    }
}

export const withErrorBoundary = <Props extends Object = {}>(
    Component: ComponentType<Props>,
    boundaryProps?: ErrorBoundaryProps
) => (props: Props) => {
    return (
        <ErrorBoundary {...boundaryProps}>
            <Component {...props} />
        </ErrorBoundary>
    );
};
