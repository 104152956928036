import { TypedEmitter } from "tiny-typed-emitter";

enum Events {
    OpenShortCard = "open-short-card",
}

interface Handlers {
    [Events.OpenShortCard]: (args: SourceEquipmentCardArgs) => void;
}

export class Emitter extends TypedEmitter<Handlers> {
    events = Events;
}
