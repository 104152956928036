export enum EventTypeCode {
    //BaseEvent = 0,

    /** Синхронизация текущего состояния с контроллером */
    Sync = 1,

    /** Переключение фазы */
    PhaseSwitch = 2,

    /** Начало промтакта */
    PromStart = 3,

    /** Смена сотояния */
    StatusSwitch = 4,

    /** Кнопка вызова пешеходной фазы */
    TVPButton = 5,

    /** Смена состояния временного плана координированного управления */
    CoomgmtPlanState = 6,

    /** Смена режима управления */
    ControlModeChange = 7,

    /**Активировано диспетчерское управление */
    TakeGovernanceEvent = 9,

    /**Отключено диспетчерское управление */
    ReleaseGovernanceEvent = 10,

    /** Смена статуса применённости режима управления */
    FacilityControlModeAppliedChange = 11,

    /** Обновление объекта диспетчерского управления */
    UpdateGovernanceEvent = 12,

    /**Запуск отложенной команды произойдет через час */
    PlannedGovernanceTimeEvent = 15,

    /** Неисправность */
    BaseMalfunction = 1024,

    /** Устранение неисправности */
    BaseRepair = 1025,

    BaseCommand = 2048,

    /** Переключение фазы */
    SwitchPhaseCommand = 2049,

    /**Переключение статуса */
    SwitchStatusCommand = 2050,

    /** Переключение программы*/
    SwitchCycleCommand = 2051,

    /**Запрос диспетчерсого управления*/
    TakeGovernanceCommand = 2052,

    /**Запрос на снятие диспетчерсого управления*/
    ReleaseGovernanceCommand = 2053,

    /** Переключение режима работы группы координации  */
    SwitchCooGroupModeCommand = 2054,

    /** Включение\отключение группы координации   */
    SetEnabledCooGroupCommand = 2055,

    /** Запрос программ с контроллера    */
    QueryCyclesCommand = 2056,

    /** Включение координированного управления*/
    CoordinatedManagementCommand = 2057,

    /** Запрос текущей программы*/
    QueryCurrentCycleCommand = 2058,

    /** Применение временного плана*/
    ApplyTemporaryCycleCommand = 2059,

    /** Включение\отключение адаптивного режима*/
    SetGuidedAdaptiveAllowanceCommand = 2060,

    /** Событие с детектора*/
    DetectorEvent = 4096,
}
