import styled, { css } from "styled-components/macro";
import { Button } from "@megapolis/uikit";

export const ControlPanelButton = styled(Button)<React.ComponentProps<typeof Button>>(({ theme }) => {
    const color = theme.colors.blue.basic;

    return css`
        border-radius: 16px;
        position: static;
        background-color: ${color};
        border-color: ${color};

        flex-shrink: 1;
        overflow: hidden;

        & span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &:not(:disabled) span {
            /** Временно пока не поправят кнопку в uikit */
            color: #ffffff !important;
        }

        &:hover:not(:disabled) {
            background-color: ${color};
            border-color: ${color};
        }
    `;
});
