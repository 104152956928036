import mapboxgl from "mapbox-gl";
import { Layer } from "../layer";

export class CooGroupLayer extends Layer<mapboxgl.LineLayer> {
    protected _layerId = "COO_GROUP_LAYER";
    protected layer: mapboxgl.LineLayer = {
        id: this.layerId,
        type: "line",
        source: this.layerId,
        layout: {
            "line-join": "round",
            "line-cap": "round",
        },
        paint: {
            "line-color": "#03284c",
            "line-gap-width": 0.01,
            "line-width": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 7, 12, 9, 18, 18],
            "line-opacity": 0.31,
        },
    };

    constructor(protected map: mapboxgl.Map) {
        super(map);
    }
}
