import { Drawer } from "diagrams/drawers";
import { TimeScaleData } from "./time-scale-drawer.types";

export class TimeScaleDrawer extends Drawer<TimeScaleData> {
    protected draw = () => {
        if (!this.data || this.data.pixelsPerSecond === 0) return;
        this.ctx.lineWidth = this.data.lineWidth;
        this.ctx.strokeStyle = this.data.strokeStyle;
        this.ctx.beginPath();
        const dropTime = this.data.width / this.data.totalTime > 5 ? 10 : 20;
        const xTime = dropTime === 20 ? this.data.pixelsPerSecond * 5 : this.data.pixelsPerSecond;

        for (let x = 0, time = 0; x <= this.data.width + 2; x += xTime) {
            this.ctx.moveTo(x, time % dropTime === 0 ? this.data.top : this.data.top + this.data.timeScaleHeight);
            this.ctx.lineTo(
                x,
                this.data.top + (time % dropTime === 0 ? this.data.timeScaleHeight : this.data.timeScaleHeight / 2)
            );
            this.ctx.moveTo(x, this.data.bottom);
            this.ctx.lineTo(
                x,
                this.data.bottom + (time % dropTime === 0 ? this.data.timeScaleHeight : this.data.timeScaleHeight / 2)
            );
            time++;
        }
        this.ctx.fillRect(0, this.data.top + this.data.timeScaleHeight, this.data.width, 0.5);
        this.ctx.fillRect(0, this.data.bottom, this.data.width, 0.5);
        this.ctx.stroke();
    };
}
