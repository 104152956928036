import { Map, MapMouseEvent } from "mapbox-gl";
import { TrafficLightDomain } from "app-domain";
import { mapConstants } from "shared/constants";
import { BooleanStorageManager } from "lib";
import { TrafficLightLayer } from "./traffic-light-layer";
import { ILayerController } from "../layer-controller";
import { storageKey } from "./traffic-light-controller.constants";
import { Emitter } from "./utils";

const { LAYER_IDS } = mapConstants;

export class TrafficLightController implements ILayerController {
    public readonly layer: TrafficLightLayer;
    public readonly emitter = new Emitter();
    private isLayerHidden: BooleanStorageManager;

    constructor(private readonly map: Map) {
        this.isLayerHidden = new BooleanStorageManager(storageKey);

        this.layer = new TrafficLightLayer(map, {
            id: LAYER_IDS.trafficLight,
            visibility: !this.isLayerHidden.value ? "visible" : "none",
            onTrafficLightVisualRender: this.handleTrafficLightVisualRender,
        });

        if (!this.isLayerHidden.value) {
            this.subscribe();
        }
        this.map.on("styledata", this.handleStyleLoad);
    }

    public getVisibility = () => !this.isLayerHidden.value;

    public setData(trafficLights: TrafficLightDomain.TrafficLight[]) {
        this.layer.setItems(trafficLights);
    }

    public setVisibility = (isVisible: boolean) => {
        if (isVisible) {
            this.subscribe();
        } else {
            this.unsubscribe();
        }
        this.isLayerHidden.value = !isVisible;
        this.layer.visibility = isVisible ? "visible" : "none";
    };

    public setSelectedEvent(value: Nullable<TrafficLightDomain.HistoryEvent>) {
        this.layer.setSelectedEvent(value);
    }

    public setActive(trafficLight: TrafficLightDomain.TrafficLight) {
        if (this.layer.activeItemId === trafficLight?.id || !trafficLight) return;
        this.layer.setActive(trafficLight.id);
    }

    public resetActive() {
        this.layer.resetActive();
    }

    private handleTrafficLightVisualRender = (trafficLight: TrafficLightDomain.TrafficLight) => {
        this.emitter.emit(this.emitter.events.trafficLightVisualRender, trafficLight);
    };

    private handleResize = () => {
        this.map.resize();
    };

    private handleClick = (event: MapMouseEvent) => {
        const features = this.map.queryRenderedFeatures(event.point) ?? [];
        const radarDetectorFeatures = features.filter((f) => f.layer!.id.startsWith(LAYER_IDS.radarDetector));
        if (radarDetectorFeatures && radarDetectorFeatures.length) {
            /** @note Временно */
            console.log("set radarDetector");
            return;
        }

        const visual = this.layer?.hitTest(event.lngLat) ?? null;

        if (visual !== null) {
            const id = visual.trafficLight.id;
            this.layer?.setActive(id);
            this.emitter.emit(this.emitter.events.activeChanged, visual.trafficLight);
            return;
        }

        const trafficLightGroupFeatures = features.filter((f) => f.layer!.id.startsWith(LAYER_IDS.cooGroup));

        /** @note Временно */
        if (trafficLightGroupFeatures && trafficLightGroupFeatures.length) console.log("set active group");
    };

    public readonly destroy = () => {
        this.layer?.destroy();
        this.emitter.removeAllListeners();
        this.unsubscribe();
        this.map.off("styledata", this.handleStyleLoad);
    };

    public readonly setWorkingMode = (value: boolean) => {
        this.layer.isClickable = value;
        if (value && !this.isLayerHidden.value) {
            this.map.on("click", this.handleClick);
        } else {
            this.map.off("click", this.handleClick);
        }
    };

    private handleStyleLoad = () => {
        if (this.map.getLayer(LAYER_IDS.trafficLight)) return;
        this.layer.addLayer();
    };

    private subscribe = () => {
        if (this.layer.isClickable) {
            this.map.on("click", this.handleClick);
        }
        window.addEventListener("resize", this.handleResize);
    };

    private unsubscribe = () => {
        this.map.off("click", this.handleClick);
        window.removeEventListener("resize", this.handleResize);
    };
}
