export enum StatusCode {
    /**
     * Выключен
     */
    Disabled = 0,

    /**
     * Цикл
     */
    Cycle = 1,

    /**
     * Желтое мигание
     */
    YellowBlink = 2,

    /**
     * Везде красный
     */
    AllRed = 3,

    /**
     * Удержание фазы
     */
    Hold = 4,

    /**
     * Режим координации
     */
    Coordination = 5,
    /**
     * Критическая неисправность
     */
    CriticalError = 6,

    /** Локальный адаптив */
    LocalAdaptive = 7,

    /**
     * Нет данных
     */
    Unknown = 100,
}
