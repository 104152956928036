import { TrafficLightDomain } from "app-domain";
import { createPhaseLabelData, PhaseLabelData } from "./phase-label-data";
import { createTPromDataItems, TPromDataItem } from "./t-prom-data";

// export const initialData = {
//     x: 0,
//     width: 0,
//     height: 0,
//     forwardY: 0,
//     backwardY: 0,
//     fillColor: "",
//     drawTProm: false,
//     pixelsPerSecond: 0,
//     dragAnchorWidth: 0,
//     hasBackwardDirection: false,
// };

const colors = {
    GREEN_COLOR: "#32C758",
    RED_COLOR: "#FF3A2F",
};

export type DirectionPhaseDataInput = {
    x: number;
    y: number;
    width: number;
    fillColor: string;
    strokeStyle: string;
    drawTProm: boolean;
    phaseNumber: number;
    phaseTime: number;
    isAllow: boolean;
    pixelsPerSecond: number;
    dragAnchorWidth: number;
    directionBarGap: number;
    directionBarHeight: number;
    hasBackwardDirection: boolean;
    direction?: TrafficLightDomain.Direction;
};

export type DirectionPhaseData = {
    x: number;
    width: number;
    forwardY: number;
    backwardY: number;
    fillColor: string;
    drawTProm: boolean;
    pixelsPerSecond: number;
    dragAnchorWidth: number;
    height: number;
    hasBackwardDirection: boolean;
    labelData: PhaseLabelData;
    tPromData?: TPromDataItem[];
};

const addTPromData = (args: DirectionPhaseDataInput, data: DirectionPhaseData) => {
    data.tPromData = createTPromDataItems({
        x: args.x + args.width,
        y: args.y,
        isAllow: args.isAllow,
        direction: args.direction,
        pixelsPerSecond: args.pixelsPerSecond,
        directionBarGap: args.directionBarGap,
        directionBarHeight: args.directionBarHeight,
        hasBackwardDirection: args.hasBackwardDirection,
    });
};

export const createDirectionPhaseData = (args: DirectionPhaseDataInput): DirectionPhaseData => {
    const forwardY = args.y;
    const backwardY = args.y + args.directionBarHeight + args.directionBarGap;

    const data: DirectionPhaseData = {
        x: args.x,
        forwardY,
        backwardY,
        width: args.width,
        drawTProm: args.drawTProm,
        height: args.directionBarHeight,
        dragAnchorWidth: args.dragAnchorWidth,
        pixelsPerSecond: args.pixelsPerSecond,
        hasBackwardDirection: args.hasBackwardDirection,
        fillColor: args.isAllow ? colors.GREEN_COLOR : colors.RED_COLOR,
        labelData: createPhaseLabelData({
            width: args.width,
            phaseTime: args.phaseTime,
            x: args.x + args.width / 2,
            strokeStyle: args.strokeStyle,
            phaseNumber: args.phaseNumber,
            hasBackwardDirection: args.hasBackwardDirection,
            forwardY: forwardY + args.directionBarHeight / 2,
            backwardY: backwardY + args.directionBarHeight / 2,
        }),
    };

    if (args.drawTProm) addTPromData(args, data);

    return data;
};
