import { DrawOptions } from "./polygon-drawer.types";

export class PolygonDrawer {
    constructor(private ctx: CanvasRenderingContext2D) {}

    /** Рисует многоугольник */
    public readonly draw = (options: DrawOptions) => {
        const { fillStyle, points } = options;
        if (!points.length) return;
        this.ctx.fillStyle = fillStyle;
        this.ctx.beginPath();
        this.ctx.moveTo(points[0].x, points[0].y);
        points.slice(1)?.forEach((line) => {
            this.ctx.lineTo(line.x, line.y);
        });
        this.ctx.closePath();
        this.ctx.fill();
    };
}
