import { Drawer } from "diagrams/drawers";
import { calculateTime } from "./time-drawer.utils";
import { TimeData } from "./time-drawer.types";

export class TimeDrawer extends Drawer<TimeData> {
    protected draw = () => {
        if (!this.data) return;
        const dropTime = this.data.width / this.data.totalTime > 5 ? 10 : 20;
        for (let i = 0; i <= Math.floor(this.data.totalTime / dropTime); i++) {
            const x = i * this.data.pixelsPerSecond * dropTime;
            const value = calculateTime(i * dropTime);
            this.ctx.save();
            let finalX = x;
            const text = String(value);
            const measure = this.ctx.measureText(text);

            this.ctx.textAlign = "center";

            if (x - measure.width < 0) {
                finalX = 0;
                this.ctx.textAlign = "left";
            }
            this.ctx.textBaseline = "top";
            this.ctx.font = this.data.font;
            this.ctx.fillStyle = this.data.fillStyle;
            this.ctx.globalAlpha = 0.52;
            this.ctx.fillText(text, finalX, this.data.top);
            this.ctx.fillText(text, finalX, this.data.bottom);
            this.ctx.restore();
        }
    };
}
