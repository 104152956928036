import styled, { css } from "styled-components/macro";
import type { Position } from "./floating-modal.types";

export const Container = styled.div<{ defaultPosition?: Position; zIndex?: number }>(
    ({ theme, defaultPosition, zIndex }) => css`
        position: fixed;
        border-radius: ${theme.spacings[5].str};
        overflow: hidden;
        background-color: #ffffff;
        box-shadow: 0 0 1px rgba(26, 32, 36, 0.32), 0 12px 24px rgba(91, 104, 113, 0.24);
        z-index: ${zIndex ?? 3};

        &[aria-selected="true"] {
            z-index: ${zIndex ? zIndex + 1 : 4};
        }
    `
);
