import { REACT_APP_API_BASEURL } from "env-data";

export const url = `${REACT_APP_API_BASEURL}/hub/notification`;

export enum StreamName {
    trafficLight = "TrafficLightEventShort",
    cooGroup = "CooGroupStateShort",
    detectorState = "DetectorState",
    detectorTrigger = "DetectorTrigger",
}
