export enum PageType {
    Details = "coogroup",
    List = "coogroups",
    EditorGroup = "coo-group-editor",
    EditorGroupNew = "coo-group-editor/new",
}

export enum TabType {
    Info = "info",
    Cycles = "cycles",
}
