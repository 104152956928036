type OnResizeCallback = (canvas: HTMLCanvasElement) => void;

export class CanvasContainer {
    public view: HTMLCanvasElement;
    public ctx: CanvasRenderingContext2D;
    public onResize?: OnResizeCallback;
    public isDestroyed: boolean = false;

    constructor(private container: HTMLElement, onResize?: OnResizeCallback) {
        this.view = document.createElement("canvas");
        this.view.style.width = "100%";
        this.view.style.height = "100%";
        this.container.appendChild(this.view);
        this.onResize = onResize;
        const ctx = this.view.getContext("2d");
        if (!ctx) throw new Error("Can not initialize canvas context 2d");
        this.ctx = ctx;
    }

    public init() {
        this._updateView();
        globalThis.addEventListener("resize", this.handleGlobalResize);
    }

    public updateViewOffsetSizes({ width, height }: { width?: number; height?: number }) {
        if (this.isDestroyed) return;
        if (typeof width === "number") this.view.style.width = `${width}px`;
        if (typeof height === "number") this.view.style.height = `${height}px`;
        this._updateView();
    }

    public destroy() {
        globalThis.removeEventListener("resize", this.handleGlobalResize);
        this.container.removeChild(this.view);
        // @ts-ignore
        this.view = null;
        this.isDestroyed = true;
    }

    private handleGlobalResize = () => {
        this._updateView();
        this.onResize?.(this.view);
    };

    private _updateView = () => {
        const width = this.view.offsetWidth;
        const height = this.view.offsetHeight;
        const ratio = window.devicePixelRatio || 1;
        this.view.width = width * ratio;
        this.view.height = height * ratio;
        this.ctx.scale(ratio, ratio);
    };
}
