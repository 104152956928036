import { useCallback } from "react";
import { CooGroupDomain } from "app-domain";
import { useAppState } from "./use-app-state";

/**
 * @observable
 */
export const useCooGroupListSearch = () => {
    const { cooGroupStore } = useAppState();

    const setSearchInput = useCallback(
        (value: string) => {
            cooGroupStore.filter.search = value;
        },
        [cooGroupStore]
    );

    /** Получает по Id ЛК данные поиска */
    const getGroupSearchEntries = useCallback(
        (cooGroup: CooGroupDomain.CooGroup) => {
            const entries = cooGroupStore.getSearchEntriesByCooGroupId(cooGroup.id);
            if (!entries) return null;
            return {
                cycles: entries.cycleIds.map((id) => cooGroup.getCycleById(id)),
            };
        },
        [cooGroupStore]
    );

    return {
        /** Признак того, что производился поиск */
        hasSearchResults: cooGroupStore.hasSearchResults,
        /** Текст запроса поиска */
        searchInput: cooGroupStore.filter.search,
        /** Получает результаты поиска по ЛК */
        getGroupSearchEntries,
        /** Обработчик установки строки поиска */
        setSearchInput,
    };
};
