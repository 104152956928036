import { makeAutoObservable } from "mobx";
import { Schedule, SchedulePeriod } from "app-domain/common";

const emptyDays: DaysOfWeekNumTuple = [null, null, null, null, null, null, null];

const emptyPeriod = {
    start: "00:00:00",
    stop: "00:00:00",
};

const withFormat = (fn: (value: string) => void) => (value: string) => fn(value + ":00");

export class ScheduleForm {
    public daysOfWeek: DaysOfWeekNumTuple;
    public period: SchedulePeriod;
    private initialDaysOfWeek: DaysOfWeekNumTuple;

    constructor(private data?: Schedule) {
        const initialPeriod = data?.periods[0] ?? emptyPeriod;
        this.daysOfWeek = data?.daysOfWeek ? this.prepareDaysOfWeek(data.daysOfWeek) : emptyDays;
        this.initialDaysOfWeek = this.daysOfWeek.slice() as DaysOfWeekNumTuple;
        this.period = {
            start: initialPeriod.start,
            stop: initialPeriod.stop,
        };
        makeAutoObservable(this);
    }

    public get hasChanges() {
        if (!this.data) return false;
        return this.hasPeriodChanges() || this.hasDaysOfWeekChanges();
    }

    public setDaysOfWeek = (newDays: DaysOfWeekNumTuple) => {
        this.daysOfWeek = newDays;
    };

    public setPeriodStart = withFormat((value: string) => {
        this.period.start = value;
    });

    public setPeriodEnd = withFormat((value: string) => {
        this.period.stop = value;
    });

    public toSchedule(): Schedule {
        return {
            daysOfWeek: this.daysOfWeek.filter((item) => item) as number[],
            periods: [this.period],
        };
    }

    private hasDaysOfWeekChanges() {
        return this.initialDaysOfWeek.some((day, index) => this.daysOfWeek[index] !== day);
    }

    private hasPeriodChanges() {
        if (!this.data) return false;
        const initialPeriod = this.data.periods[0];
        return this.period.start !== initialPeriod.start || this.period.stop !== initialPeriod.stop;
    }

    private prepareDaysOfWeek(daysOfWeek: number[]) {
        const newDays = emptyDays.slice() as DaysOfWeekNumTuple;
        for (let dayOfWeek of daysOfWeek) {
            newDays[dayOfWeek - 1] = dayOfWeek as DayOfWeekNum;
        }
        return newDays;
    }
}
