import { makeAutoObservable, runInAction } from "mobx";
import { TrafficScoreDomain } from "app-domain";
import { Utils } from "shared";
import { getDaysFromInterval, getViewDateFromInterval } from "./traffic-forecast-history.utils";
import { ITrafficForecastHistoryService } from "./traffic-forecast-history.types";

const dayTimestamp = 86400000;
const minuteTimestamp = 60000;

export class TrafficForecastHistoryStore {
    public viewDate: Date = new Date();
    public viewportStartTimestamp: number = 0;
    public viewportEndTimestamp: number = 0;
    private fetchedTimeList = new Set();
    private scoresGroup: Map<number, TrafficScoreDomain.TrafficScoreGroup> = new Map();
    /** Выбранная отметка времени на диаграмме по каторой запрашиваются таилы на карте */
    private _selectedTimestamp: NullableNumber = null;
    private isPopupOpenValue = false;
    private _viewportGroupLevel: TrafficScoreDomain.Split = TrafficScoreDomain.Split.By_30_Minutes;

    constructor(private service: ITrafficForecastHistoryService) {
        makeAutoObservable(this);
    }

    public get viewMode() {
        return this.isPopupOpen && this.selectedTimestamp
            ? TrafficScoreDomain.ViewMode.Range
            : TrafficScoreDomain.ViewMode.LastPeriod;
    }

    public get isPopupOpen() {
        return this.isPopupOpenValue;
    }

    public get selectedTimestamp() {
        return this._selectedTimestamp;
    }

    public get viewportGroupLevel() {
        return this._viewportGroupLevel;
    }

    public get trafficScores() {
        const days = getDaysFromInterval(this.viewportStartTimestamp, this.viewportEndTimestamp);
        return days.reduce((exportInterval: TrafficScoreDomain.TrafficScore[], day) => {
            const currentGroup = this.scoresGroup.get(day);
            this.loadTrafficScore(day);
            if (currentGroup) {
                exportInterval = exportInterval.concat(currentGroup[this.viewportGroupLevel]);
            } else {
                const emptyModelData: TrafficScoreDomain.TrafficScore[] = [];
                let currentIterTimestamp = day;
                const nextDayTimestamp = day + dayTimestamp;
                const incrementSize = this.viewportGroupLevel * minuteTimestamp;

                while (currentIterTimestamp < nextDayTimestamp) {
                    emptyModelData.push({
                        time: currentIterTimestamp,
                        score: -1,
                        type: undefined,
                    });
                    currentIterTimestamp += incrementSize;
                }

                exportInterval = exportInterval.concat(emptyModelData);
            }
            return exportInterval;
        }, []);
    }

    public togglePopupOpen = () => {
        this.isPopupOpenValue = !this.isPopupOpenValue;
    };

    public onGroupLevelChange = (value: TrafficScoreDomain.Split) => {
        this._viewportGroupLevel = value;
    };

    public readonly setViewportInterval = (viewportStartTimestamp: number, viewportEndTimestamp: number) => {
        runInAction(() => {
            this.viewDate = getViewDateFromInterval(viewportStartTimestamp, viewportEndTimestamp);
            this.viewportStartTimestamp = viewportStartTimestamp;
            this.viewportEndTimestamp = viewportEndTimestamp;
        });
    };

    public readonly viewDateChange = (newDate: NullableDate) => {
        let date = new Date();
        let dateTime = date;

        if (!!newDate) {
            date = newDate;
        } else if (!!this.selectedTimestamp) {
            date = new Date(this.selectedTimestamp);
            dateTime = date;
        }

        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        const seconds = dateTime.getSeconds();
        const milliseconds = dateTime.getMilliseconds();

        date.setHours(hours, minutes, seconds, milliseconds);
        runInAction(() => {
            this.viewDate = date;
            const diff = this.viewportEndTimestamp - this.viewportStartTimestamp;
            this.viewportStartTimestamp = date.valueOf() - diff / 2;
            this.viewportEndTimestamp = date.valueOf() + diff / 2;
        });
    };

    public readonly loadTrafficScore = async (dayTimestamp: number) => {
        if (this.fetchedTimeList.has(dayTimestamp)) return;

        this.fetchedTimeList.add(dayTimestamp);

        const date = Utils.DateUtils.format(dayTimestamp, { format: "yyyy-MM-dd" });

        const result = await this.service.loadTrafficScoreGroup(date);

        this.scoresGroup.set(dayTimestamp, result);
    };

    public readonly setSelectedTimestamp = (value: NullableNumber) => {
        this._selectedTimestamp = value;
    };
}
