import { reaction } from "mobx";
import { MapController } from "map-controller";
import { TrafficScoreDomain } from "app-domain";
import { Store } from "app";

export class TrafficForecastHistoryBroker {
    private selectedTimestampChangeEffect: VoidFunction = () => {};
    private viewportGroupLevelChangeEffect: VoidFunction = () => {};
    private viewModeChangeEffect: VoidFunction = () => {};

    constructor(protected mapController: MapController, private state: Store.TrafficForecastHistoryStore) {
        this.subscribe();
    }

    public destroy() {
        this.mapController.off(this.mapController.events.load, this.initSubs);
        this.selectedTimestampChangeEffect?.();
        this.viewportGroupLevelChangeEffect?.();
        this.viewModeChangeEffect?.();
    }

    private subscribe() {
        this.mapController.on(this.mapController.events.load, this.initSubs);
    }

    private handleSelectedTimestampChange = (value: NullableNumber) => {
        if (this.state.viewMode === TrafficScoreDomain.ViewMode.LastPeriod) return;
        this.mapController.layerControllers.dtmTrafficController.setRange(value, this.state.viewportGroupLevel);
    };

    private handleViewportGroupLevelChange = (value: TrafficScoreDomain.Split) => {
        if (this.state.viewMode === TrafficScoreDomain.ViewMode.LastPeriod) return;
        this.mapController.layerControllers.dtmTrafficController.setRange(this.state.selectedTimestamp, value);
    };

    private handleViewModeChange = (value: TrafficScoreDomain.ViewMode) => {
        this.mapController.layerControllers.dtmTrafficController.setViewMode(value);
    };

    private initSubs = () => {
        this.selectedTimestampChangeEffect = reaction(
            () => this.state.selectedTimestamp,
            this.handleSelectedTimestampChange
        );
        this.viewportGroupLevelChangeEffect = reaction(
            () => this.state.viewportGroupLevel,
            this.handleViewportGroupLevelChange
        );
        this.viewModeChangeEffect = reaction(() => this.state.viewMode, this.handleViewModeChange);
    };
}
