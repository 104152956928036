import React, { memo, ReactNode, useState } from "react";
import { Context } from "./context";

type Props = {
    limit: number;
    children: ReactNode;
    isDefaultUnlimited?: boolean;
};

const ListComponent = (props: Props) => {
    const { limit, children, isDefaultUnlimited = false } = props;
    const [isShowingMore, setIsShowingMore] = useState(isDefaultUnlimited);
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    return (
        <Context.Provider value={{ limit, isShowingMore, setIsShowingMore, setIsButtonVisible, isButtonVisible }}>
            {children}
        </Context.Provider>
    );
};

export const List = memo(ListComponent);
