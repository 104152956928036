import { GovernanceInfo, TrafficLightMessage } from "../models";

/** Временный ремаппер для обратной совместимости */
export function messageFromLog(item: any): TrafficLightMessage {
    return {
        id: item.id,
        dateTime: new Date(item.timestamp).getTime() / 1000,
        controllerDateTime: item.data?.cTimestamp ? new Date(item.data?.cTimestamp).getTime() / 1000 : undefined,
        eventType: item.type,
        data: item.data,
        state: {
            id: item.id,
            secondsGone: 0,
            malfunctionType: item.malfunctionType,
            program: item.program,
            nextPhase: 0,
            phaseTime: item.phaseTime,
            phase: item.phase,
            prePhase: item.prePhase,
            isPromTime: item.isPromTime,
            governanceInfo: item.governanceId ? new GovernanceInfo(item.governanceId) : null,
            controlMode: item.controlMode,
            status: item.status,
            preStatus: item.preStatus,
            preControlMode: item.preControlMode,
            condition: item.condition,
            isGuidedAdaptiveAllowed: item.isGuidedAdaptiveAllowed,
            isLocalAdaptiveEnabled: item.isLocalAdaptiveEnabled,
        },
    };
}
