import mapboxgl from "mapbox-gl";
import { Options } from "./route-arrow-layer.types";

export class RouteArrowLayer implements mapboxgl.SymbolLayer {
    public readonly id: string;
    public readonly type: "symbol" = "symbol";
    public readonly source: string;
    public readonly minzoom: number = 8;
    public readonly layout: mapboxgl.SymbolLayout = {
        "icon-image": "actualRouteArrow",
        "symbol-placement": "line",
        "symbol-spacing": 60,
        "symbol-avoid-edges": true,
        "icon-allow-overlap": true,
        "icon-size": {
            stops: [
                [0, 0],
                [12, 0.7],
                [14, 1],
                [16, 1.1],
                [20, 1.7],
            ],
        },
    };
    public readonly paint: mapboxgl.SymbolPaint = {
        "icon-opacity": [
            "case",
            ["boolean", ["feature-state", "isVisible"], false],
            1,
            ["boolean", ["feature-state", "isActive"], false],
            1,
            0,
        ],
    };
    public readonly filter = ["all", ["==", "type", "path"]];

    constructor(options: Options) {
        this.id = options.id;
        this.source = options.source;
    }
}
