import { memo, useContext, useCallback, ReactNode } from "react";
import { FloatingModalContext } from "../floating-modal-context";

type Props = {
    children: ReactNode;
    className?: string;
};

const DragComponent = (props: Props) => {
    const { setDragElement } = useContext(FloatingModalContext);

    const setRefFn = useCallback(
        (node: HTMLElement | null) => {
            if (!node) return;
            setDragElement(node);
        },
        [setDragElement]
    );

    return (
        <div className={props.className} ref={setRefFn}>
            {props.children}
        </div>
    );
};

export const Drag = memo(DragComponent);
