import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { Input } from "./cycle-time-input.styles";

type Props = {
    value: number;
    onChange: (value: number) => void;
    validate: (value: number) => boolean;
};

export const CycleTimeInput = memo((props: Props) => {
    const { value, onChange, validate } = props;

    const [currentValue, setCurrentValue] = useState<string>(String(value));

    useEffect(() => {
        setCurrentValue(String(value));
    }, [value]);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
    }, []);

    const handleBlur = useCallback(() => {
        const finalValue = parseInt(currentValue) || 0;
        if (!validate(finalValue)) {
            return setCurrentValue(String(value));
        }
        onChange(finalValue);
    }, [validate, value, onChange, currentValue]);

    return <Input type="number" value={currentValue} onChange={handleChange} onBlur={handleBlur} />;
});
