export const DETECTOR_DETAILS_URL_TYPE = "detector";

export const DETECTOR_LIST_URL_TYPE = "detectors";

export const DETECTOR_LIST_BASE_URL = `/${DETECTOR_LIST_URL_TYPE}`;

export const DETECTOR_DETAILS_BASE_URL = `/${DETECTOR_DETAILS_URL_TYPE}`;

export const DETECTOR_LIST_ROUTE_PATH = `/${DETECTOR_LIST_URL_TYPE}/:id?`;

export const DETECTOR_DETAILS_ROUTE_PATH = `/${DETECTOR_DETAILS_URL_TYPE}/:id?`;

export const DETECTOR_ROUTE_MATCHER = [DETECTOR_LIST_ROUTE_PATH, DETECTOR_DETAILS_ROUTE_PATH];
