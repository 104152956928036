import { ControllerCollection } from "map-controller/layer-controllers/controller-collection";
import { Map } from "mapbox-gl";
import { makeAutoObservable } from "mobx";
import { layerKeyIdMap, layerKeyStateMap, VISIBILITY_PROP } from "./map-controls-state.constants";
import { LayerKey, LayerId, VisibilityPropValue } from "./map-controls-state.types";

export class MapControlsState {
    /** TODO Надо придумать как после иницилизации слоев задать видимость */
    public isKsoddLayerVisible: boolean;
    public isTrafficLightLayerVisible: boolean;
    public isCooGroupLayerVisible: boolean = false;
    public areDetectorLayersVisible: boolean = false;
    public areDtmTrafficLayersVisible: boolean;
    public areWarehouseCameraLayersVisible: boolean;
    public areWarehouseSpeedcamLayersVisible: boolean;
    public areWarehouseDetectorLayersVisible: boolean;
    private layerControllers: ControllerCollection;

    constructor(private map: Map, layerControllers: ControllerCollection) {
        this.layerControllers = layerControllers;
        makeAutoObservable(this);
        this.isKsoddLayerVisible = layerControllers.ksoddController?.getVisibility() ?? false;
        this.isCooGroupLayerVisible = layerControllers.cooGroupController?.getVisibility() ?? false;
        this.areDtmTrafficLayersVisible = layerControllers.dtmTrafficController?.getVisibility() ?? false;
        this.areWarehouseCameraLayersVisible = layerControllers.warehouseCameraController?.getVisibility() ?? false;
        this.areWarehouseSpeedcamLayersVisible = layerControllers.warehouseSpeedcamController?.getVisibility() ?? false;
        this.areWarehouseDetectorLayersVisible = layerControllers.warehouseDetectorController?.getVisibility() ?? false;
        this.isTrafficLightLayerVisible = layerControllers.trafficLightController?.getVisibility() ?? true;
    }

    public toggleLayerVisibility(key: LayerKey) {
        const layerId = layerKeyIdMap[key];
        const stateKey = layerKeyStateMap[key];
        const stateValue = this[stateKey];
        const newValue = !stateValue;
        const newPropValue = this.getVisibilityPropValue(!stateValue);
        this[stateKey] = newValue;

        this.setMapLayerVisibilityProp(layerId, newPropValue);
    }

    public toggleKsoddLayersVisibility = () => {
        const { ksoddController } = this.layerControllers;
        const current = ksoddController?.getVisibility() ?? false;
        ksoddController?.setVisibility?.(!current);
        this.isKsoddLayerVisible = !current;
    };

    public toggleTrafficLightLayerVisibility = () => {
        const { trafficLightController } = this.layerControllers;
        const current = trafficLightController?.getVisibility();
        const next = !(current ?? false);
        trafficLightController?.setVisibility(next);
        this.isTrafficLightLayerVisible = next;
    };

    public toggleCooGroupLayersVisibility = () => {
        const { cooGroupController } = this.layerControllers;
        const current = cooGroupController?.getVisibility();
        const next = !(current ?? false);
        cooGroupController?.setVisibility?.(next);
        this.isCooGroupLayerVisible = next;
    };

    public toggleDtmTrafficLayersVisibility = () => {
        const { dtmTrafficController } = this.layerControllers;
        const current = dtmTrafficController?.getVisibility();
        const next = !(current ?? false);
        dtmTrafficController?.setVisibility?.(next);
        this.areDtmTrafficLayersVisible = next;
    };

    public toggleWarehouseCameraLayersVisibility = () => {
        const { warehouseCameraController } = this.layerControllers;
        const current = warehouseCameraController?.getVisibility();
        const next = !(current ?? false);
        warehouseCameraController?.setVisibility?.(next);
        this.areWarehouseCameraLayersVisible = next;
    };

    public toggleWarehouseSpeedcamLayersVisibility = () => {
        const { warehouseSpeedcamController } = this.layerControllers;
        const current = warehouseSpeedcamController?.getVisibility();
        const next = !(current ?? false);
        warehouseSpeedcamController?.setVisibility?.(next);
        this.areWarehouseSpeedcamLayersVisible = next;
    };

    public toggleWarehouseDetectorLayersVisibility = () => {
        const { warehouseDetectorController } = this.layerControllers;
        const current = warehouseDetectorController?.getVisibility();
        const next = !(current ?? false);
        warehouseDetectorController?.setVisibility?.(next);
        this.areWarehouseDetectorLayersVisible = next;
    };

    private getVisibilityPropValue(state: boolean) {
        return state ? "visible" : "none";
    }

    private setMapLayerVisibilityProp(layerId: LayerId, value: VisibilityPropValue) {
        this.map.setLayoutProperty(layerId, VISIBILITY_PROP, value);
    }
}
