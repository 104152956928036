import { TypedEmitter } from "tiny-typed-emitter";
import { storageKey, themeMap } from "./theme-manager.constants";
import { Theme } from "./enums";
import { ThemeStorageManager } from "./theme-storage-manager";

enum Events {
    Change = "theme-change",
}

type Handlers = {
    [Events.Change]: VoidFunction;
};

export class ThemeManager extends TypedEmitter<Handlers> {
    public readonly events = Events;
    private storageKeyManager = new ThemeStorageManager(storageKey);
    private _theme: Theme = this.storageKeyManager.value;

    public get theme() {
        return this._theme;
    }

    public get themeParams() {
        return themeMap[this.theme];
    }

    public readonly changeTheme = (value: Theme) => {
        this._theme = value;
        this.storageKeyManager.value = value;
        this.emit(Events.Change);
    };
}
