import { memo, ReactNode } from "react";
import * as Markup from "./details-panel.styles";

export type DetailsPanelProps = {
    header: ReactNode;
    tabs: ReactNode;
    content: ReactNode;
    footer?: ReactNode;
};

export const DetailsPanel = memo((props: DetailsPanelProps) => {
    const { header, tabs, content, footer } = props;

    return (
        <Markup.Panel>
            <Markup.Header>{header}</Markup.Header>
            <Markup.Tabs>{tabs}</Markup.Tabs>
            <Markup.Content>{content}</Markup.Content>
            {footer && <Markup.Footer>{footer}</Markup.Footer>}
        </Markup.Panel>
    );
});
