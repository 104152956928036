import { Common } from "app-domain";
import { dateConstants } from "shared/constants";

export class ScheduleHelper {
    public static formatSchedules(schedules: Common.Schedule[]) {
        return schedules.map(ScheduleHelper.formatSchedule) ?? [];
    }

    public static formatSchedule(schedule: Common.Schedule) {
        let result = "";
        let periods = "";

        schedule?.daysOfWeek?.forEach(
            (dayNum, index, arr) =>
                (result += `${dateConstants.DaysOfWeekByNums[dayNum as DayOfWeekNum]}${
                    index === arr.length - 1 ? " " : ", "
                }`)
        );
        schedule?.periods?.forEach(
            (period, index, arr) =>
                (periods += `${ScheduleHelper.preparePeriodTime(period.start)}-${ScheduleHelper.preparePeriodTime(
                    period.stop
                )}${index === arr.length - 1 ? "" : ", "}`)
        );

        return `${result ?? ""}${periods}`;
    }

    public static preparePeriodTime(time: DateTimeString) {
        if (time.length <= 5) return time;
        return time.replace(":00", "");
    }

    public static formatPeriod(period: Common.SchedulePeriod) {
        return `${period.start}-${period.stop}`;
    }

    public static areEqual(first: Common.Schedule, second: Common.Schedule) {
        const firstPeriods = first.periods.reduce((string, period) => string + ScheduleHelper.formatPeriod(period), "");
        const secondPeriods = second.periods.reduce(
            (string, period) => string + ScheduleHelper.formatPeriod(period),
            ""
        );
        return first.daysOfWeek.length === second.daysOfWeek.length && firstPeriods === secondPeriods;
    }
}
