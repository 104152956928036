export enum Status {
    /** Не активна */
    Inactive = 0,
    /**  Координация */
    Coordination = 1,
    /** Зелёная улица */
    GreenStreet = 2,
    /** Всем красный */
    AllRed = 3,
    /** Адаптивная координация */
    AdaptiveCoordination = 4,
}
