import { PhaseView } from "../../../phase-view";

export type ResizeHandleDataInput = {
    y: number;
    phases: PhaseView[];
    hasBackwardDirection: boolean;
};

export type ResizeHandlePoint = {
    x: number;
    y: number;
    isStart: boolean;
};

export type ResizeHandleData = {
    isLarge: boolean;
    coords: ResizeHandlePoint[];
};

const resizeHandleWidth = 24;

export const createResizeHandleData = (args?: ResizeHandleDataInput): ResizeHandleData => {
    const result: ResizeHandleData = {
        coords: [],
        isLarge: false,
    };

    if (!args) return result;

    const coords = args.phases.map((phase) => ({
        isStart: phase.isLast,
        x: phase.drawOptions.phaseEndX - resizeHandleWidth / 2,
        y: args.y - 2,
    }));

    result.coords = coords;
    result.isLarge = args.hasBackwardDirection;

    return result;
};
