import { BaseApi } from "api/utils";
import { APIMinimalCycleData } from "./cycle-api.types";
import { FacilityCustomCycle } from "../types";

export class CycleAPI extends BaseApi {
    /**
     * Возвращает список программ(циклов) СО
     * Программы из паспорта + созданные пользователем
     */
    public getFacilityCycles(id: string | number): Promise<FacilityCustomCycle[]> {
        const url = this.buildFacilityURL(`/${id}`);
        return this.request({ url });
    }

    /** Удаляет программу СО */
    public removeFacilityCycle(id: string | number): Promise<void> {
        const url = this.buildURL(`/${id}`);
        return this.request({ url, method: "delete", as: "raw" });
    }

    /**
     * Валидирует цикл СО
     * @returns список ошибок
     */
    public validateFacilityCycle(id: number, cycle: APIMinimalCycleData): Promise<string[]> {
        const url = this.buildFacilityURL(`/${id}/validation`);
        return this.request({ url, method: "POST", body: JSON.stringify(cycle) });
    }

    /**
     * Создает цикл СО
     */
    public createFacilityCycle(id: number, cycle: APIMinimalCycleData) {
        const url = this.buildFacilityURL(`/${id}`);
        return this.request({ url, method: "POST", body: JSON.stringify(cycle) });
    }

    private buildFacilityURL(url: string, params?: string) {
        return this.buildURL(`/facility${url}`, params);
    }

    protected buildURL(url: string, params?: string) {
        return super.buildUrl(`/cycle${url}${params ? `?${params}` : ""}`);
    }
}
