import { DtmRouter } from "api";
import { CooGroupDomain, RouteDomain, RouterInputDomain } from "app-domain";

export class DtmRouterService {
    constructor(private api: DtmRouter.DtmRouterAPI) {}

    public getRoute = async (params: RouteDomain.Point[]): Promise<RouteDomain.MapRouteFeatures> => {
        const body = new RouterInputDomain.RouteInput({
            trafficTypes: {},
            costing: RouterInputDomain.RouteCosting.Auto,
            useTruckGraph: false,
            saveRoute: false,
            locations: params.map((item) => ({
                lng: item.longitude,
                lat: item.latitude,
            })),
        });
        try {
            const bodyToJson = JSON.stringify(body);
            const result = await this.api.getRoute(bodyToJson);
            const mapRouteFeatures = new RouteDomain.MapRouteFeatures();
            mapRouteFeatures.trafficLightFeatures = result?.trafficLights;
            mapRouteFeatures.routeFeatures = result?.paintedPath;
            return mapRouteFeatures;
        } catch (error) {
            return Promise.reject(error);
        }
    };

    public getRouteByPoints = async (points: CooGroupDomain.Point[]) => {
        const body = new RouterInputDomain.RouteInput({
            trafficTypes: {},
            costing: RouterInputDomain.RouteCosting.Auto,
            useTruckGraph: false,
            saveRoute: false,
            locations: points.map(({ lng, lat }) => ({ lng, lat })),
        });
        try {
            const bodyToJson = JSON.stringify(body);
            const result = await this.api.getRoute(bodyToJson);
            const mapRouteFeatures = new RouteDomain.MapRouteFeatures();
            mapRouteFeatures.trafficLightFeatures = result?.trafficLights;
            mapRouteFeatures.routeFeatures = result?.paintedPath;
            return mapRouteFeatures;
        } catch (error) {
            return Promise.reject(error);
        }
    };
}
