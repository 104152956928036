import { TrafficLightDomain } from "app-domain";

export const getPhaseNumFromState = (status: TrafficLightDomain.Enums.StatusCode, phaseNum: number): number => {
    let phase = phaseNum;
    if (status === TrafficLightDomain.Enums.StatusCode.AllRed) phase = -1;
    else if (status === TrafficLightDomain.Enums.StatusCode.YellowBlink) phase = -2;
    else if (status === TrafficLightDomain.Enums.StatusCode.Disabled) phase = -3;
    else if (status === TrafficLightDomain.Enums.StatusCode.CriticalError) phase = -4;
    return phase;
};
