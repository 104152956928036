import React from "react";
import * as Markup from "./header.styles";
import type { Props } from "./header.types";

export const useNumber = (props: Props) => {
    const { isLoaded, number, dataCypress } = props;

    const result = React.useMemo(() => {
        if (!isLoaded) {
            const _dataCypress = dataCypress ? `${dataCypress}__number-placeholder` : undefined;

            return <Markup.NumberPlaceholder data-cypress={_dataCypress}>0000</Markup.NumberPlaceholder>;
        }

        const _dataCypress = dataCypress ? `${dataCypress}__number-value` : undefined;

        return (
            <Markup.NumberValue data-cypress={_dataCypress} title={number}>
                {number}
            </Markup.NumberValue>
        );
    }, [isLoaded, number, dataCypress]);

    return { number: result };
};

export const useAddress = (props: Props) => {
    const { isLoaded, address, dataCypress } = props;

    const result = React.useMemo(() => {
        if (!isLoaded) {
            const _dataCypress = dataCypress ? `${dataCypress}__address-placeholder` : undefined;

            return (
                <Markup.AddressPlaceholder data-cypress={_dataCypress}>
                    Данные в процессе загрузки
                </Markup.AddressPlaceholder>
            );
        }

        const _dataCypress = dataCypress ? `${dataCypress}__address-value` : undefined;

        return (
            <Markup.Address data-cypress={_dataCypress} title={address}>
                {address}
            </Markup.Address>
        );
    }, [isLoaded, address, dataCypress]);

    return { address: result };
};
