import { GeoJSONSourceRaw } from "mapbox-gl";

export const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: [],
};

export const source: GeoJSONSourceRaw = {
    type: "geojson",
    data,
};
