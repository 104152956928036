export enum Prefix {
    TVCamera = "camera",
    Detector = "detector",
    FixationCamera = "speedcam",
    ECHDCamera = "echd",
}

export type Props = {
    monitoringStatus: number;
    statusByIssue: number;
    prefix: Prefix;
};
