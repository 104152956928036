type Params = {
    width?: number;
    height?: number;
};

export class CanvasSVG {
    public img: HTMLImageElement;
    public isLoaded: boolean = false;
    private promise?: Promise<HTMLImageElement>;

    constructor(public url: string, params?: Params) {
        this.img = new Image(params?.width, params?.height);
    }

    public load(): Promise<HTMLImageElement> {
        if (this.url.length === 0) return Promise.resolve(this.img);
        if (this.isLoaded && this.promise) return this.promise;

        this.promise = new Promise<HTMLImageElement>((resolve, reject) => {
            this.img.onload = () => {
                this.isLoaded = true;
                resolve(this.img);
            };
            this.img.onerror = () => reject(new Error("Can not load image"));
        });
        this.img.src = this.url;
        return this.promise;
    }

    public reload() {
        this.isLoaded = false;
        return this.load();
    }
}
