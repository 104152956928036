export enum TrafficSet {
    Default = "default",
    UseStops = "use-stops",
}

export type TrafficInput = {
    externalSystemIDs?: number[];
    externalSystemSet?: TrafficSet;
};

export enum RouteCosting {
    Auto = "Auto",
    Multimodal = "Multimodal",
    Cargo = "Cargo",
    Pedestrian = "Pedestrian",
    Template = "Template",
}

export enum TrafficUse {
    /** не использовать трафик при построении пути */
    No = "no",
    /** использовать трафик при поиске пути */
    Routing = "routing",
    /** использовать трафик для сравнения времени пути */
    Compare = "compare",
    /** использовать "слоеный" трафик при поиске пути */
    Layers = "layers",
}
