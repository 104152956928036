import { ReactNode, memo } from "react";
import { Container } from "./tag-label.styles";

type Props = {
    children: ReactNode;
    color?: string;
    className?: string;
};

const TagLabelComponent = (props: Props) => (
    <Container className={props.className} color={props.color}>
        {props.children}
    </Container>
);

export const TagLabel = memo(TagLabelComponent);
