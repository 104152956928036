import * as Env from "env-data";

export class URLHelper {
    public static getTrafficLightWarehousePageURL(id: number | string) {
        return `${Env.REACT_APP_WAREHOUSE_URL}/tls/${id}`;
    }
    public static getTrafficLightPassportPageURL(id: number | string) {
        return `${Env.REACT_APP_WAREHOUSE_URL}/rtf/${id}`;
    }
}
