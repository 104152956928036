import { useCallback } from "react";
import { useAppState } from "./use-app-state";

export const useSelectTVCamera = () => {
    const { tvCameraList } = useAppState();

    return useCallback(
        (id: number) => {
            const tvCamera = tvCameraList.getById(id);

            if (!tvCamera) return;

            tvCameraList.chooseItem(id);
        },
        [tvCameraList]
    );
};
