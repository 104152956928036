import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Routing } from "routing";
import { Types } from "shared";
import { useViewState } from "./use-view-state";

interface URLParams {
    id?: string;
    type: string;
}

export const useViewRoutingSynchronization = () => {
    const viewState = useViewState();

    const match = useRouteMatch<URLParams>(Routing.CooGroups.Paths.COO_GROUPS_MATCHER);

    const id = match?.params?.id ? +match.params.id : null;

    useEffect(() => {
        switch (match?.params.type) {
            case Routing.CooGroups.PageType.Details:
                viewState.setActiveCooGroup(id, Types.ViewState.CooGroupViewType.Details);
                break;
            case Routing.CooGroups.PageType.EditorGroup:
                viewState.setActiveCooGroup(id, Types.ViewState.CooGroupViewType.Editor);
                break;
            case Routing.CooGroups.PageType.List:
                viewState.setActiveCooGroup(id, Types.ViewState.CooGroupViewType.List);
                break;
            case Routing.TrafficLights.Paths.TRAFFIC_LIGHT_DETAILS_URL_TYPE:
                viewState.setActiveTrafficLight(id, Types.ViewState.TrafficLightViewType.Details);
                break;
            case Routing.TrafficLights.Paths.TRAFFIC_LIGHT_LIST_URL_TYPE:
                viewState.setActiveTrafficLight(id, Types.ViewState.TrafficLightViewType.List);
                break;
            default:
                viewState.setActiveDashboard();
        }
    }, [viewState, match, id]);
};
