import { TypedEmitter } from "tiny-typed-emitter";
import { browserHistory } from "shared/globals";
import { ServiceRegistry } from "services";
import { TrafficLightDispatcher } from "trafficlight-dispatcher";
import { TrafficLightSockets } from "api";
import { Common } from "app-domain";
import * as ENV from "env-data";
import { MapController } from "map-controller";
import { connections } from "./app-state-manager.connections";
import {
    AppStateManagerConfig,
    AppStateManagerEvents,
    MainPanelState,
    SideBarFilterState,
} from "./app-state-manager.types";
import { IssueStore } from "./issue-store";
import { CooGroupStore } from "./coo-group";
import { СooGroupEditor } from "./coo-group-editor";
import { TrafficLightHistoryStore } from "./traffic-light-history-store";
import { TrafficLightRemoteControls, TrafficLightRemoteControlsAdapter } from "./traffic-light-remote-controls";
import { CooGroupRemoteControls, CooGroupRemoteControlsAdapter } from "./coo-group-remote-controls";
import { HistoryFacade } from "./history-facade";
import { TrafficLightStore } from "./traffic-light-store";
import { TrafficLightManager } from "./traffic-light-manager";
import {
    TrafficLightStreamSubscriber,
    CooGroupStreamSubscriber,
    TrafficLightDetectorStateStreamSubscriber,
    TrafficLightDetectorTriggerStreamSubscriber,
} from "./subscribers";
import { TVCameras } from "./tv-cameras";
import { FixationCameras } from "./fixation-cameras";
import { DetectorService, Detectors } from "./detectors";
import { DashboardService, DashboardStore } from "./dashboard";
import { DetectorFullCardService, DetectorFullCardStore } from "./detector-full-card";
import { PopupFocus } from "./popup-focus";
import { ViewState } from "./view";
import { TrafficForecastHistoryStore, TrafficForecastHistoryAdapter } from "./traffic-forecast-history";
import { DetectorList, DetectorListAdapter } from "./detectors-list";
import { TVCameraList, TVCameraListAdapter } from "./tv-camera-list";
import { TVCameraStore } from "./tv-camera-store";

/**
 *  Абстрагирует UI от раздельной работы с состоянием
 *  @note Временно реализовывает интерфейс TypedEmitter
 */
export class AppStateManager extends TypedEmitter<AppStateManagerEvents> {
    public isInitialized: boolean = false;
    public mainPanelState: MainPanelState = "list";
    public serverInfo!: Common.ServerInfo;
    public readonly popupFocus = new PopupFocus();
    public readonly issueStore: IssueStore = new IssueStore(ServiceRegistry.issueService);
    public readonly cooGroupStore: CooGroupStore = new CooGroupStore(ServiceRegistry.cooGroupService);
    public readonly trafficLightHistoryStore: TrafficLightHistoryStore = new TrafficLightHistoryStore(
        ServiceRegistry.trafficLightHistoryService
    );
    public readonly cooGroupRemoteControls: CooGroupRemoteControls;
    public readonly trafficLightRemoteControlStore: TrafficLightRemoteControls;
    public readonly historyFacade: HistoryFacade = new HistoryFacade(browserHistory);
    public readonly trafficLightStore: TrafficLightStore = new TrafficLightStore(ServiceRegistry.trafficLightService);
    public readonly trafficLightManager: TrafficLightManager = new TrafficLightManager(
        this.trafficLightStore,
        ServiceRegistry.trafficLightService
    );
    public readonly cooGroupEditor = new СooGroupEditor(
        ServiceRegistry.cooGroupService,
        ServiceRegistry.dtmRouterService,
        ServiceRegistry.yandexGeocodeService,
        this.trafficLightStore,
        this.trafficLightManager
    );
    public readonly trafficForecastHistory = new TrafficForecastHistoryStore(new TrafficForecastHistoryAdapter());
    public readonly viewState = new ViewState();
    public readonly tvCameras = new TVCameras();
    public readonly fixationCameras = new FixationCameras();
    public readonly detectors = new Detectors(new DetectorService());
    public readonly dashboard = new DashboardStore(new DashboardService());
    public readonly detectorFullCard = new DetectorFullCardStore(new DetectorFullCardService());
    public readonly tvCameraStore = new TVCameraStore();
    public readonly mapController: MapController;
    public readonly token: string;
    public readonly userId: string;
    private readonly trafficLightMessageHub = new TrafficLightSockets.TrafficLightMessageStreamHub();
    public detectorListStore = new DetectorList(new DetectorListAdapter());
    public tvCameraList = new TVCameraList(new TVCameraListAdapter());

    private dispatcher: TrafficLightDispatcher;

    private _sideBarFilter: SideBarFilterState = {
        isOpened: false,
        filterKey: null,
    };

    constructor({ token, userId }: AppStateManagerConfig) {
        super();
        this.dispatcher = new TrafficLightDispatcher({
            trafficLightbaseUrl: ENV.REACT_APP_API_BASEURL,
            radarDetectorBaseUrl: ENV.REACT_APP_API_DETECTOR_SERVICE,
            token,
            trafficLightMessageHub: this.trafficLightMessageHub,
        });
        this.trafficLightRemoteControlStore = new TrafficLightRemoteControls(
            this.trafficLightStore,
            new TrafficLightRemoteControlsAdapter(),
            userId
        );
        this.cooGroupRemoteControls = new CooGroupRemoteControls(
            this.cooGroupStore,
            new CooGroupRemoteControlsAdapter(),
            userId
        );
        this.userId = userId;
        this.token = token;

        this.dashboard.loadOrReloadData();
        this.mapController = new MapController({ accessToken: token, dispatcher: this.dispatcher });

        connections({
            listShortCardOnWarehouseCamera: this.tvCameras,
            listShortCardOnWarehouseSpeedcam: this.fixationCameras,
            listShortCardOnWarehouseDetector: this.detectors,
            detectorFullCard: this.detectorFullCard,
            popupFocus: this.popupFocus,
            detectorsList: this.detectorListStore,
            tvCameraStore: this.tvCameraStore,
        });

        this.init();
    }

    public get sideBarFilter() {
        return this._sideBarFilter;
    }

    public openFilter = (filterKey: SideBarFilterState["filterKey"]) => {
        const newState: SideBarFilterState = {
            isOpened: true,
            filterKey,
        };
        this._sideBarFilter = newState;
        this.emit("sideBarFilterChanged", newState);
    };

    public closeFilter = () => {
        const newState = {
            isOpened: false,
            filterKey: null,
        };
        this._sideBarFilter = newState;
        this.emit("sideBarFilterChanged", newState);
    };

    public setMainPanelState(state: MainPanelState) {
        this.mainPanelState = state;
        this.emit("mainPanelStateChanged", state);
    }

    public get trafficLightDispatcher() {
        return this.dispatcher;
    }

    private async init() {
        await this.initServerInfo();
        await this.initStorages();
        await this.initHubs();
        this.isInitialized = true;
        this.emit("initialized");
    }

    private async initServerInfo() {
        this.serverInfo = await ServiceRegistry.stateService.getServerInfo();
    }

    private async initStorages() {
        this.trafficLightStore.serverInfo = this.serverInfo;
        this.cooGroupEditor.serverInfo = this.serverInfo;
        await this.trafficLightStore.loadItems();
        await this.detectorListStore.loadDetectorsGroup();
        await this.cooGroupRemoteControls.loadItems();
        await this.trafficLightRemoteControlStore.loadItems();
        await this.trafficLightRemoteControlStore.loadPlannedControls();
        await this.cooGroupStore.loadList();
        await this.tvCameraList.loadTvCamerasGroup();
    }

    private async initHubs() {
        await this.trafficLightMessageHub.init();
        this.trafficLightMessageHub.subscribeToTrafficLightStream(
            new TrafficLightStreamSubscriber.Subscriber(
                this.trafficLightStore,
                this.trafficLightRemoteControlStore,
                new TrafficLightStreamSubscriber.Service()
            )
        );
        this.trafficLightMessageHub.subscribeToCooGroupStream(
            new CooGroupStreamSubscriber.Subscriber(
                this.cooGroupStore,
                this.cooGroupRemoteControls,
                new CooGroupStreamSubscriber.Service()
            )
        );
        this.trafficLightMessageHub.subscribeToDetectorStateStream(
            new TrafficLightDetectorStateStreamSubscriber(this.trafficLightStore, this.mapController)
        );
        this.trafficLightMessageHub.subscribeToDetectorTriggerStream(
            new TrafficLightDetectorTriggerStreamSubscriber(this.trafficLightStore, this.mapController)
        );
    }
}

export default AppStateManager;
