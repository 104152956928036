import { memo } from "react";
import { Select as SelectBase, SelectProps } from "@megapolis/uikit/select";
import styled from "styled-components/macro";

const SelectWrapper = styled.div`
    & button {
        max-height: 32px;
        padding: 7px 12px 7px 7px;
        align-items: center;
    }
    & > div > div {
        z-index: 1;
        transform: none;
        top: 100%;
    }
`;

// Временная обертка над Select из Uikit
const SelectComponent = (props: SelectProps) => (
    <SelectWrapper>
        <SelectBase {...props} />
    </SelectWrapper>
);

export const Select = memo(SelectComponent);
