import { makeAutoObservable, runInAction, reaction } from "mobx";
import { Interfaces } from "interfaces";
import { Detector } from "app-domain";
import { Selection } from "../selection";
import { Filter } from "./filter";
import * as utils from "./detectors-list-adapter.utils";
import { IDetectorListService, LoadDetectorListMapFilterShape } from "./detectors-list.types";

export class DetectorList {
    public isInitialized = false;
    public readonly emitter = new Interfaces.Warehouse.Emitter();
    public readonly filter: Filter = new Filter();

    private selectedItems = new Selection();
    private filterChangeEffect!: VoidFunction;

    constructor(private service: IDetectorListService) {
        makeAutoObservable(this);

        this.setUpEffects();
    }

    public detectors: Detector.IDetectorListMap = new Map();

    public get itemsToList() {
        return utils.convertArrayDetectorsGroup(this.detectors);
    }

    public get count() {
        return this.detectors.size;
    }

    public selectDetector = (args: SourceEquipmentCardArgs) => {
        this.emitter.emit(this.emitter.events.SelectDetector, args);
    };

    public async loadDetectorsGroup(filter?: LoadDetectorListMapFilterShape) {
        const filterShape = filter ?? this.filter.shape;
        this.isInitialized = false;
        const collectionMap = await this.service.loadDetectorListMap(filterShape);
        this.isInitialized = true;

        runInAction(() => {
            this.detectors = collectionMap;
        });
    }

    private setUpEffects() {
        this.filterChangeEffect = reaction(
            () => this.filter.shape,
            (shape) => {
                this.loadDetectorsGroup(shape);
            }
        );
    }

    public isSelectedItem(id: number) {
        return this.selectedItems.isSelected(id);
    }

    public chooseItem(id: number) {
        this.selectedItems.clear();
        this.selectedItems.add(id);
    }

    public clearSelectedItems() {
        this.selectedItems.clear();
    }

    /** TODO: уточнить необходимость уничтожения реакции */
    // public destroy = () => {
    //     this.filterChangeEffect();
    // };

    public getById(id: number) {
        return this.detectors.get(id) ?? null;
    }
}
