import styled, { css } from "styled-components";
import { UI } from "components";

export const Container = styled.div(
    ({ theme: { spacings } }) => css`
        display: flex;
        flex-direction: column;
        padding: 0 ${spacings[5].str} ${spacings[5].str} ${spacings[5].str};
        gap: ${spacings[3].str};
    `
);

export const Input = styled(UI.NumberInput)`
    width: 100%;
`;

export const DatePickerWrapper = styled.div`
    width: 100%;
    input {
        width: 100%;
    }
`;

export const ManagementContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings[3].str};
    padding-bottom: ${({ theme }) => theme.spacings[3].str};
`;

export const ManagementContainer = styled.div``;

export const FlexContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacings[3].str};
`;
