import { InfoCardProps } from "./info-card.types";
import * as Markup from "./info-card.styles";

export const InfoCard = (props: InfoCardProps) => {
    const { title, caption, captionColor, className, size = "medium" } = props;
    return (
        <Markup.Container className={className} size={size}>
            <Markup.Title title={title}>{title}</Markup.Title>
            <Markup.Caption color={captionColor}>{caption}</Markup.Caption>
        </Markup.Container>
    );
};
