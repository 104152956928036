import { memo, useState, useEffect, useMemo, FC } from "react";
import { MapControllerHooks } from "map-controller";
import { Context } from "./context";
import { MapControlsState } from "./state";

export const Provider: FC = memo((props) => {
    const mapController = MapControllerHooks.useMapController();
    const [map, setMap] = useState<Nullable<mapboxgl.Map>>(null);
    const { layerControllers } = mapController;

    const mapControlsState = useMemo(() => {
        if (!map) return;
        return new MapControlsState(map, layerControllers);
    }, [map, layerControllers]);

    useEffect(() => {
        const handleMapLoad = (map?: mapboxgl.Map) => {
            setMap(map ?? null);
        };

        mapController.on(mapController?.events.load, handleMapLoad);

        return () => {
            mapController.off(mapController?.events.load, handleMapLoad);
        };
    }, [mapController]);

    if (!mapControlsState) return null;
    return <Context.Provider value={mapControlsState}>{props.children}</Context.Provider>;
});
