import { useRef } from "react";
import { useEffect } from "react";
import { TypedEmitter, ListenerSignature } from "tiny-typed-emitter";
import { useForceUpdate } from "../use-force-update";

export const useEmitterSubscription = <Events extends ListenerSignature<Events>, Emitter extends TypedEmitter<Events>>(
    emitter: Emitter | null,
    events: (keyof Events)[]
) => {
    const forceUpdate = useForceUpdate();
    const eventsRef = useRef(events);

    useEffect(() => {
        if (!emitter) return;
        const eventNames = eventsRef.current;
        eventNames.forEach((event) => {
            emitter.on(event, forceUpdate as any);
        });
        return () => {
            eventNames.forEach((event) => {
                emitter.off(event, forceUpdate as any);
            });
        };
    }, [forceUpdate, emitter]);
};
