import styled, { css } from "styled-components";

export const Container = styled.header(
    ({ theme }) => css`
        padding: 20px ${theme.spacings[6].str} ${theme.spacings[1].str} ${theme.spacings[6].str};
        font-weight: 600;
        line-height: 24px;
        color: ${theme.colors.content.primary};
    `
);
