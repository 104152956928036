import { WarehouseAPI } from "../warehouse-api";
import { AssetCameraDetails } from "./asset-camera.types";

export class AssetCameraAPI extends WarehouseAPI {
    /** Получение информации о экземпляре имущества по идентификатору */
    public getById(id: number): Promise<AssetCameraDetails> {
        const url = this.buildUrl(`/${id}`);
        const result = this.request({ url });
        return result;
    }

    protected buildUrl(url: string, params = "") {
        return super.buildUrl(`/assetcamera${url}${params}`);
    }
}
