import { Types } from "shared";

export enum EntityType {
    Dashboard = "dashboard",
    TrafficLight = "traffic-light",
    CooGroup = "coo-group",
}

export type StateViewType =
    | Types.ViewState.DashboardViewType
    | Types.ViewState.CooGroupViewType
    | Types.ViewState.TrafficLightViewType;
