import { TypedEmitter } from "tiny-typed-emitter";
import * as RouteDomain from "../../route";
import { ICooGroupState } from "./coo-group-state";
import { Cycle } from "./cycle";
import { Status, ControlMode, CycleType } from "../enums";
import type { CooGroupParams, Facility, Point, CooGroupLog } from "./coo-group.types";
import { GovernanceInfo } from "./governance-info";

export enum CooGroupEventNames {
    message = "message",
    isEnabledChange = "isEnabledChange",
    statusChanged = "statusChanged",
    controlModeChanged = "controlModeChanged",
    cyclesChanged = "cyclesChanged",
    governanceChanged = "governanceChanged",
    nonCriticalErrorCount = "nonCriticalErrorCount",
    criticalErrorCount = "criticalErrorCount",
}

export interface CooGroupEvents {
    [CooGroupEventNames.message]: (message: CooGroupLog) => void;
    [CooGroupEventNames.statusChanged]: (status: Status) => void;
    [CooGroupEventNames.isEnabledChange]: (value: boolean) => void;
    [CooGroupEventNames.controlModeChanged]: (controlMode: ControlMode) => void;
    [CooGroupEventNames.cyclesChanged]: (cycles: Cycle[]) => void;
    [CooGroupEventNames.governanceChanged]: (governanceId: NullableNumber) => void;
    [CooGroupEventNames.nonCriticalErrorCount]: (value: number) => void;
    [CooGroupEventNames.criticalErrorCount]: (value: number) => void;
}

export class CooGroup extends TypedEmitter<CooGroupEvents> {
    public id: number;
    public type: number;
    public name: string;
    public description: string;
    public priority: number;
    public facilities: Facility[];
    public points: Point[];
    public geometry: RouteDomain.MapRouteFeatures;
    public readonly events = CooGroupEventNames;
    private _isEnabled: boolean = false;
    private _nonCriticalErrorCount: number = 0;
    private _criticalErrorCount: number = 0;
    private _governanceInfo: Nullable<GovernanceInfo> = null;
    private _status: Status = Status.Inactive;
    private _activeCycleId: NullableNumber = null;
    private _controlMode: ControlMode = ControlMode.Disabled;
    private _cycles: Cycle[] = [];

    constructor(params: CooGroupParams) {
        super();
        this.id = params.id;
        this.type = params.type;
        this.name = params.name;
        this.points = params.points;
        this.description = params.description;
        this.priority = params.priority;
        this.facilities = params.facilities;
        this.geometry = params.geometry;
        this.isEnabled = params.isEnabled;
    }

    public get isEnabled() {
        return this._isEnabled;
    }

    public get nonCriticalErrorCount() {
        return this._nonCriticalErrorCount;
    }

    public get criticalErrorCount() {
        return this._criticalErrorCount;
    }

    public get controlMode() {
        return this._controlMode;
    }

    public get status() {
        return this._status;
    }

    public get activeCycleId() {
        return this._activeCycleId;
    }
    /** Активная программы (работающая сейчас) */
    public get activeCycle() {
        return this.cycles.find((cycle) => cycle.id === this.activeCycleId);
    }
    /** Текущая программы по расписанию */
    public get currentCycle() {
        return this.cycles.find((cycle) => cycle.isActiveNow);
    }

    public get fullAddress() {
        return this.points.reduce(
            (result, point, index) => `${(result += point.address)} ${index !== this.points.length - 1 ? "– " : ""}`,
            ""
        );
    }

    public set nonCriticalErrorCount(value: number) {
        this._nonCriticalErrorCount = value;
        this.emit(CooGroupEventNames.nonCriticalErrorCount, this.nonCriticalErrorCount);
    }

    public set criticalErrorCount(value: number) {
        this._criticalErrorCount = value;
        this.emit(CooGroupEventNames.criticalErrorCount, this.criticalErrorCount);
    }

    public set controlMode(controlMode: ControlMode) {
        this._controlMode = controlMode;
        this.emit(CooGroupEventNames.controlModeChanged, controlMode);
    }

    public set status(status: Status) {
        this._status = status;
        this.emit(CooGroupEventNames.statusChanged, status);
    }

    public set activeCycleId(activeCycleId: NullableNumber) {
        this._activeCycleId = activeCycleId;
    }

    public set isEnabled(value: boolean) {
        this._isEnabled = value;
        this.emit(CooGroupEventNames.isEnabledChange, value);
    }

    public set cycles(list: Cycle[]) {
        this._cycles = list;
        this.emitCyclesChanged();
    }

    public get cycles() {
        return this._cycles;
    }

    public get governanceInfo() {
        return this._governanceInfo;
    }

    public set governanceInfo(value: Nullable<GovernanceInfo>) {
        this._governanceInfo = value;
        this.emit(CooGroupEventNames.governanceChanged, value?.id ?? null);
    }

    public get coordinations() {
        return this.cycles.filter((cycle) => cycle.type === CycleType.Coordination);
    }

    public get greenStreets() {
        return this.cycles.filter((cycle) => cycle.type === CycleType.GreenStreet);
    }

    public updateState(state: ICooGroupState) {
        if (typeof state.controlMode === "number") this.controlMode = state.controlMode;
        if (typeof state.status === "number") this.status = state.status;
        if (state.activeCycleId !== undefined) this.activeCycleId = state.activeCycleId;
        if (state.governanceInfo !== undefined) this.governanceInfo = state.governanceInfo;
        if (state.critical !== this.criticalErrorCount) {
            this.criticalErrorCount = state.critical ?? 0;
        }
        if (state.notCritical !== this.nonCriticalErrorCount) {
            this.nonCriticalErrorCount = state.notCritical ?? 0;
        }
    }

    public processSignalMessage(message: CooGroupLog) {
        this.emit(CooGroupEventNames.message, message);
    }

    public getFacilityById(id: number) {
        return this.facilities.find((facility) => facility.facilityId === id) ?? null;
    }
    public getCycleById(id: number) {
        return this.cycles.find((cycle) => cycle.id === id) ?? null;
    }

    public addCycle(cycle: Cycle) {
        this.cycles.push(cycle);
        this.emitCyclesChanged();
    }

    public deleteCycle(cycleId: number) {
        this._cycles = this.cycles.filter((cycle) => cycle.id !== cycleId);
        this.emitCyclesChanged();
    }

    public updateCycle(updatedCycle: Cycle) {
        const index = this._cycles.findIndex((cycle) => cycle.id === updatedCycle.id);
        if (index === -1) return;
        this._cycles[index] = updatedCycle;
        this.emitCyclesChanged();
    }

    private emitCyclesChanged() {
        this.emit(CooGroupEventNames.cyclesChanged, this._cycles);
    }
}
