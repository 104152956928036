import { TrafficLightDomain } from "app-domain";

export const getDirectionByRoute = (directions: TrafficLightDomain.Direction[], persistentEdgeIds: string[]) => {
    const result = directions.find((direction) => {
        const tracks = direction?.tracks ?? [];
        return persistentEdgeIds.some((persistentEdgeId) =>
            tracks.some((track) => track?.dtmEdgeFrom === persistentEdgeId || track?.dtmEdgeTo === persistentEdgeId)
        );
    });
    return result ? [result] : [];
};
