import { CanvasObject } from "../canvas-object";
import { Container } from "../container";
import { InteractionEvent } from "./interaction-event";

export class InteractionManager {
    private static _currentObjectStatic: Nullable<CanvasObject> = null;
    private _currentObject: Nullable<CanvasObject> = null;
    private _draggableObject: Nullable<CanvasObject> = null;
    private _dropTarget: Nullable<CanvasObject> = null;
    private _isDragging?: boolean;

    constructor(private _canvas: HTMLCanvasElement, private _rootObject: Container) {
        global.addEventListener("mousemove", this._onMouseMove);
        global.addEventListener("click", this._onClick);
        global.addEventListener("mousedown", this._onMouseDown);
        this._canvas.addEventListener("mouseleave", this._onMouseLeave);
    }

    public destroy() {
        global.removeEventListener("mousemove", this._onMouseMove);
        global.removeEventListener("click", this._onClick);
        global.removeEventListener("mousedown", this._onMouseDown);
        this._canvas.removeEventListener("mouseleave", this._onMouseLeave);
        this._currentObject = null;
        this._draggableObject = null;
    }

    public static hitTestObject(
        x: number,
        y: number,
        object: Container | CanvasObject,
        excludeObject?: Container | CanvasObject
    ): Nullable<CanvasObject> {
        if (object === excludeObject) return this._currentObjectStatic;
        if (object instanceof Container) {
            for (const child of object.children) {
                InteractionManager.hitTestObject(x, y, child, excludeObject);
            }
        } else {
            const isInBounds = object.getBounds?.().isPointIn(x, y);
            if (isInBounds) {
                if (this._currentObjectStatic && object.zIndex < this._currentObjectStatic.zIndex) return null;
                this._currentObjectStatic = object;
            }
        }
        return this._currentObjectStatic;
    }

    private _hitTestRoot(e: MouseEvent, excludeObject?: CanvasObject) {
        this._hitTestContainer(e, this._rootObject, excludeObject);
    }

    private _hitTestContainer = (e: MouseEvent, object: Container, excludeObject?: CanvasObject) => {
        if (!object.isInteractive) return;
        for (const obj of object.children) {
            if (!obj.isInteractive) continue;
            this._hitTestChild(e, obj, excludeObject);
        }
    };

    private _hitTestChild = (e: MouseEvent, child: Container | CanvasObject, excludeObject?: CanvasObject) => {
        const { offsetX, offsetY } = e;
        if (child instanceof Container) return this._hitTestContainer(e, child, excludeObject);
        if (child.id === excludeObject?.id) return;
        const isInBounds = child.getBounds?.().isPointIn(offsetX, offsetY);
        if (!isInBounds) return;
        if (this._currentObject && child.zIndex < this._currentObject.zIndex) return;
        this._currentObject = child;
    };

    private _updateCursor = () => {
        document.body.style.cursor = this._currentObject?.cursor ?? "default";
    };

    private _updateDropTarget = (e: MouseEvent) => {
        if (!this._draggableObject) return null;
        InteractionManager._currentObjectStatic = null;
        this._dropTarget = InteractionManager.hitTestObject(
            e.offsetX,
            e.offsetY,
            this._rootObject,
            this._draggableObject
        );
    };

    private _onDragStart(e: MouseEvent, object: CanvasObject) {
        this._draggableObject = object;
        object.emit("dragstart", new InteractionEvent(e, object));
    }

    private _onDragLeave(e: MouseEvent, object: CanvasObject) {
        object.emit("dragleave", new InteractionEvent(e, object));
    }

    private _onDragMove(e: MouseEvent) {
        if (!this._draggableObject) return;
        const prevDropTarget = this._dropTarget;
        this._updateDropTarget(e);
        if (prevDropTarget !== null && prevDropTarget !== this._dropTarget) this._onDragLeave(e, prevDropTarget);
        if (this._dropTarget) this._onDragOver(e, this._dropTarget);
        this._draggableObject.emit("dragmove", new InteractionEvent(e, this._draggableObject));
    }

    private _onDragOver(e: MouseEvent, object: CanvasObject) {
        object.emit("dragover", new InteractionEvent(e, object));
    }

    private _onDragEnd(e: MouseEvent, draggableObject: CanvasObject) {
        if (this._dropTarget) {
            this._dropTarget.emit("drop", new InteractionEvent(e, this._dropTarget));
            this._dropTarget = null;
        }
        draggableObject.emit("dragend", new InteractionEvent(e, draggableObject));
        this._draggableObject = null;
    }

    private _emitMouseBtnEvent(e: MouseEvent, object: CanvasObject) {
        const event = new InteractionEvent(e, object);
        object.emit(e.type as "click" | "mousedown", event);
    }

    private _onMouseMove = (e: MouseEvent) => {
        if (!this._checkIsCanvasTarget(e)) return;
        if (this._currentObject && this._isDragging && !this._draggableObject)
            return this._onDragStart(e, this._currentObject);
        if (this._isDragging && this._draggableObject) return this._onDragMove(e);
        this._hitTestRoot(e);
        this._updateCursor();
        this._currentObject = null;
    };

    private _onMouseDown = (e: MouseEvent) => {
        global.addEventListener("mouseup", this._onMouseUp);
        if (!this._checkIsCanvasTarget(e)) return;
        this._hitTestRoot(e);
        if (!this._currentObject) return;
        this._isDragging = true;
        this._emitMouseBtnEvent(e, this._currentObject);
    };

    private _onMouseUp = (e: MouseEvent) => {
        if (this._draggableObject) this._onDragEnd(e, this._draggableObject);
        this._currentObject = null;
        this._isDragging = false;
        global.removeEventListener("mouseup", this._onMouseUp);
    };

    private _onMouseLeave = () => {
        this._currentObject = null;
        this._updateCursor();
    };

    private _onClick = (e: MouseEvent) => {
        if (!this._checkIsCanvasTarget(e)) return;
        this._hitTestRoot(e);
        if (!this._currentObject) return;
        this._emitMouseBtnEvent(e, this._currentObject);
    };

    private _checkIsCanvasTarget(e: MouseEvent) {
        return e.target === this._canvas;
    }
}
