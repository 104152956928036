import { CycleType, VerificationResultType } from "../enums";
import type { CycleSchedule } from "./cycle-schedule";

/** Времена фазы в программе */
export type CyclePhase = {
    /** Номер фазы */
    phaseNumber: number;
    /** @deprecated Свойство будет удалено */
    tProm: number;
    /** Время фазы с учетом пром тактов */
    tPhase: number;
    /** Время фазы без учета пром тактов */
    tBasic: number;
    /** Минимальное время фазы */
    tMin: number;
};

/** Программа СО по паспорту */
export type CycleData = {
    facilityId: number;
    number: number;
    time: number;
    type: CycleType;
    vResult: VerificationResultType;
    isActiveNow?: boolean;
    schedules?: CycleSchedule[];
    phases?: CyclePhase[];
    name?: string;
    vDate?: DateTimeString | Date;
    vDescription?: string;
};

export interface ICycle {
    /** Идентификатор СО */
    facilityId: number;
    number: number;
    time: number;
    type: CycleType;
    vResult: VerificationResultType;
    isActiveNow: boolean;
    /** Расписания программы */
    schedules?: CycleSchedule[];
    phases?: CyclePhase[];
    name?: string;
    /** Дата последней верификации программы с программой на СО */
    vDate?: Date;
    /** Текстовое опсиание результата верификации программы с контроллером */
    vDescription?: string;
}
export class Cycle implements ICycle {
    public facilityId: number;
    public number: number;
    public time: number;
    public type: CycleType;
    public vResult: VerificationResultType;
    public isActiveNow: boolean;
    public schedules?: CycleSchedule[];
    public phases?: CyclePhase[];
    public name?: string;
    public vDate?: Date;
    public vDescription?: string;

    constructor(data: CycleData) {
        this.facilityId = data.facilityId;
        this.number = data.number;
        this.time = data.time;
        this.type = data.type;
        this.schedules = data.schedules;
        this.vResult = data.vResult;
        this.phases = data.phases;
        this.name = data.name;
        this.vDate = data.vDate ? new Date(data.vDate) : void 0;
        this.vDescription = data.vDescription;
        this.isActiveNow = !!data.isActiveNow;
    }
}
