import styled, { DefaultTheme, css } from "styled-components";
import { Icons } from "@megapolis/uikit/icons";
import { MaskedInput } from "@megapolis/uikit/masked-input";

export const Container = styled.div<{ isFullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    ${(props) => props.isFullWidth && `width: 100%`};
`;

export const CalendarWrapper = styled.div`
    position: fixed;
    visibility: hidden;
    z-index: 1;
`;

export const ClearIcon = styled(Icons.Monochrome.CloseM)`
    &:hover {
        cursor: pointer;
    }
    fill: ${(props) => props.theme.colors.content.secondary};
`;

export const Input = styled(MaskedInput)`
    input {
        min-width: 12ch;
        width: 100%;
        flex-basis: auto;
    }
`;

// Максимум 2 иконки + отступ между ними в 4px
const getAfterBlockMinWidth = (isClearable: boolean, spacings: DefaultTheme["spacings"]) => {
    const iconCount = isClearable ? 2 : 1;

    return spacings[1].value + iconCount * spacings[6].value;
};

export const AfterBlock = styled.div<{ isClearable?: boolean }>(
    ({ theme: { spacings }, isClearable = false }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: ${spacings[1].str};
        height: 100%;
        min-width: ${getAfterBlockMinWidth(isClearable, spacings)}px;
    `
);

export const DatePickerIcon = styled(Icons.Monochrome.Schedule)<{ isDisabled?: boolean }>`
    cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
`;
