import React from "react";
import Player from "@megapolis/react-video-player";
import { REACT_APP_API_CAMERA_SERVICE } from "env-data";
import * as Markup from "./stream.styles";
import type { Props } from "./stream.types";

export const Stream = React.memo((props: Props) => {
    return (
        <Markup.Wrapper data-cypress="stream">
            <Markup.Container>
                <Markup.Header>
                    <Markup.Title>Поток №{props.stream}</Markup.Title>
                </Markup.Header>
                <Markup.Body>
                    <Player
                        url={REACT_APP_API_CAMERA_SERVICE}
                        id={String(props.uid)}
                        stream={props.stream}
                        token={props.getToken}
                        autoplay={false}
                        draggable
                    />
                </Markup.Body>
            </Markup.Container>
        </Markup.Wrapper>
    );
});
