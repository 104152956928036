import { reaction } from "mobx";
import type { IReactionDisposer } from "mobx";
import { AppStateManager } from "app/store";
import { MapController } from "map-controller";
import { AbsListener } from "./abs-listener";
import type { Equipment, HandlePrepareEquipment } from "./warehouse-equipment.types";
import { Interfaces } from "interfaces";

export class WarehouseEquipmentBroker {
    private isInitialized = false;
    private warehouseCameraListener?: AbsListener;
    private warehouseSpeedcamListener?: AbsListener;
    private warehouseDetectorListener?: AbsListener;

    private static getIsInitialized = (mapController: MapController, appState: AppStateManager) => {
        return mapController.isInitialized && appState.isInitialized;
    };

    constructor(protected mapController: MapController, private appState: AppStateManager) {
        this.handleInitializedUpdate();

        if (this.isInitialized) {
            this.createListeners();
        } else {
            mapController.on(mapController.events.load, this.handleInitializedUpdate);
            appState.on("initialized", this.handleInitializedUpdate);
        }
    }

    public destroy() {
        this.warehouseSpeedcamListener?.unsubscribe();
        this.warehouseCameraListener?.unsubscribe();
        this.warehouseDetectorListener?.unsubscribe();
        this.listenerActivePopup?.();
    }

    private listenerActivePopup?: IReactionDisposer;

    private handleInitializedUpdate = () => {
        if (this.isInitialized) return;

        this.isInitialized = WarehouseEquipmentBroker.getIsInitialized(this.mapController, this.appState);

        if (this.isInitialized) this.createListeners();
    };

    private createActiveSubstrate = (
        item: Interfaces.Store.EquipmentCard
    ): MapController.LayerControllers.ActiveSubstrate.Data["features"][number] => {
        return {
            id: "active-substrate",
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [item.coordinate.lng, item.coordinate.lat],
            },
            properties: {
                uid: item.uid,
            },
        };
    };

    private createFeature = (
        type: Equipment["type"],
        item: Interfaces.Store.EquipmentCard
    ): MapController.LayerControllers.ActiveSubstrate.Data["features"][number] => {
        return {
            id: item.guid,
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [item.coordinate.lng, item.coordinate.lat],
            },
            properties: {
                uid: item.uid,
                icon: `map-icons/${type}/${type}-${item.status.issueId ?? ""}-${item.status.monitoringId ?? ""}`,
            },
        };
    };

    private handlePrepareEquipment: HandlePrepareEquipment = (active) => (acc, equipment) => {
        // @ts-ignore
        return equipment.collection.reduce((model: any, item: any) => {
            if (!!active && item.guid === active) {
                model.selected.push(this.createActiveSubstrate(item));

                model.selected.push(this.createFeature(equipment.type, item));

                return model;
            }

            model.featureCollection.features.push(this.createFeature(equipment.type, item));

            return model;
        }, acc);
    };

    private createListeners() {
        const activeSubstrate = this.mapController.layerControllers.activeSubstrate!;

        this.warehouseCameraListener = new AbsListener({
            layerController: this.mapController.layerControllers.warehouseCameraController,
            popupFocus: this.appState.popupFocus,
            listShortCardStore: this.appState.tvCameras,
            mapFlyToViewPort: this.mapController.flyManager.forceSetViewPort,
        });

        this.warehouseSpeedcamListener = new AbsListener({
            layerController: this.mapController.layerControllers.warehouseSpeedcamController,
            popupFocus: this.appState.popupFocus,
            listShortCardStore: this.appState.fixationCameras,
            mapFlyToViewPort: this.mapController.flyManager.forceSetViewPort,
        });

        this.warehouseDetectorListener = new AbsListener({
            layerController: this.mapController.layerControllers.warehouseDetectorController,
            popupFocus: this.appState.popupFocus,
            listShortCardStore: this.appState.detectors,
            listDetailsCardStore: this.appState.detectorFullCard,
            tvCameraStore: this.appState.tvCameraStore,
            mapFlyToViewPort: this.mapController.flyManager.forceSetViewPort,
        });

        this.warehouseCameraListener.subscribe();
        this.warehouseSpeedcamListener.subscribe();
        this.warehouseDetectorListener.subscribe();

        this.listenerActivePopup = reaction(
            () => ({
                cameras: this.appState.tvCameras.collection,
                speedcam: this.appState.fixationCameras.collection,
                detectorShortCards: this.appState.detectors.collection,
                detectorFullCardUid: this.appState.detectorFullCard.uid,
                tvCameraUid: this.appState.tvCameraStore.uid,
                active: this.appState.popupFocus.active,
            }),
            ({ cameras, speedcam, detectorShortCards, detectorFullCardUid, active, tvCameraUid }) => {
                const source: Equipment[] = [
                    { collection: cameras, type: "camera" },
                    { collection: speedcam, type: "speedcam" },
                    { collection: detectorShortCards, type: "detector" },
                ];
                const callback = this.handlePrepareEquipment(active);
                const featureCollection: MapController.LayerControllers.ActiveSubstrate.Data = {
                    type: "FeatureCollection",
                    features: [],
                };
                const selected: MapController.LayerControllers.ActiveSubstrate.Data["features"] = [];
                const data = source.reduce(callback, { featureCollection, selected });

                if (detectorFullCardUid !== null) {
                    const { detectorFullCard } = this.appState;
                    const item: Interfaces.Store.EquipmentCard = {
                        uid: detectorFullCardUid,
                        guid: detectorFullCard.guid,
                        coordinate: detectorFullCard.source.options.coordinate,
                        status: {
                            issueId: detectorFullCard.source.options.statusOnIssues,
                            monitoringId: detectorFullCard.source.options.statusOnMonitoring,
                        },
                    };

                    if (detectorFullCard.guid === active) {
                        data.selected.push(this.createActiveSubstrate(item));
                        data.selected.push(this.createFeature("detector", item));
                    } else {
                        data.featureCollection.features.push(this.createFeature("detector", item));
                    }
                }

                if (tvCameraUid !== null) {
                    const { tvCameraStore } = this.appState;
                    const item: Interfaces.Store.EquipmentCard = {
                        uid: tvCameraUid,
                        guid: tvCameraStore.guid,
                        coordinate: tvCameraStore.source.options.coordinate,
                        status: {
                            issueId: tvCameraStore.source.options.statusOnIssues,
                            monitoringId: tvCameraStore.source.options.statusOnMonitoring,
                        },
                    };

                    if (tvCameraStore.guid === active) {
                        data.selected.push(this.createActiveSubstrate(item));
                        data.selected.push(this.createFeature("camera", item));
                    } else {
                        data.featureCollection.features.push(this.createFeature("camera", item));
                    }
                }

                // Объединение
                data.featureCollection.features.push(...data.selected);

                activeSubstrate.setSourceData(data.featureCollection);
            }
        );
    }
}
