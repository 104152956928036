import { memo } from "react";
import { Alert } from "@megapolis/uikit/alert";
import { TrafficLightDomain } from "app-domain";
import { IconsByMalfunctionType } from "./icons";
import * as Markup from "./traffic-light-malfunction.styles";

export type Props = {
    name: string;
    code: TrafficLightDomain.Enums.MalfunctionType | number;
    isCritical?: boolean;
    description?: string;
};

const MalfunctionComponent = (props: Props) => {
    const { description, code, isCritical = false, name } = props;

    const icon = IconsByMalfunctionType[code as TrafficLightDomain.Enums.MalfunctionType];

    const text = description ? `${name}: ${description}` : name;

    const Component = isCritical ? Alert.Danger : Alert.Warning;

    return (
        <Markup.Wrapper>
            <Component title={<Markup.Text title={text}>{text}</Markup.Text>} icon={icon} />
        </Markup.Wrapper>
    );
};

export const TrafficLightMalfunction = memo(MalfunctionComponent);
