import { memo } from "react";
import { DropdownBase } from "./dropdown-base";
import { DropdownPortal } from "./dropdown-portal";
import { Props } from "./dropdown.types";

type WrapperProps = Props & {
    usePortal?: boolean;
};

export const Dropdown = memo((props: WrapperProps) => {
    const usePortal = typeof props.usePortal === "undefined" ? true : props.usePortal;

    return usePortal ? <DropdownPortal {...props} /> : <DropdownBase {...props} />;
});
