import { makeAutoObservable, toJS } from "mobx";
import { BooleanProperty } from "lib";
import { Phase, CyclePhase, INewCustomCycle, CustomCycle } from "app-domain/traffic-light";
import { Popup, PopupType } from "./popup";
import { ChangeHistory } from "./change-history";

type CycleData = {
    time: number;
    phases: CyclePhase[];
};

type BaseCycleData = CycleData &
    Partial<{
        id: number;
        name: string;
        userId: string;
        userDisplayName: string;
    }>;

export type EditorData = {
    baseCycle: BaseCycleData;
    modifiedCycle: CycleData;
};

export class CycleEditorState {
    /** @observable */
    public editorData: Nullable<EditorData> = null;
    public areDirectionsVisible = new BooleanProperty(false);
    public readonly activePhaseNumber: number | null = null;
    public _history = new ChangeHistory<EditorData>();
    private _popup: Popup;

    constructor(popup: Popup) {
        this._popup = popup;
        makeAutoObservable(this);
    }

    public setActivePhaseNumber = (value: number) => {
        // @ts-ignore
        this.activePhaseNumber = value;
    };

    public clearActivePhase = () => {
        // @ts-ignore
        this.activePhaseNumber = null;
    };

    public get selectedCycleId() {
        return this.editorData?.baseCycle?.id ?? null;
    }

    public get time() {
        return this.editorData?.modifiedCycle.time ?? 0;
    }

    public get arePhaseCountsEqual() {
        return this.editorData?.baseCycle.phases.length === this.editorData?.modifiedCycle.phases.length;
    }

    public get isCycleTimeDisabled() {
        return !this.editorData?.modifiedCycle;
    }

    public get isDeleteBtnDisabled() {
        return this.activePhaseNumber === null;
    }

    public get isCancelChangeDisabled() {
        return !this._history.hasPrevState;
    }

    public get isRepeatChangeDisabled() {
        return !this._history.hasNextState;
    }

    public get isResetDisabled() {
        return !this._hasChanges;
    }

    public get isSaveDisabled() {
        return !this._hasChanges;
    }

    private get _hasChanges() {
        return !this.arePhaseCountsEqual || this._checkCycleTimeChanges() || this._checkPhaseChanges();
    }

    public onCancelChange = () => {
        if (!this._history.hasPrevState) return;
        this._history.setPrevState();
        this._setEditorDataFromHistoryState();
    };

    public onRepeatChange = () => {
        if (!this._history.hasNextState) return;
        this._history.setNextState();
        this._setEditorDataFromHistoryState();
    };

    public onAddPhaseToCycle = () => {
        if (!this.editorData) return;
        this._popup.setParams({
            type: PopupType.AddPhase,
            data: this.editorData.modifiedCycle.phases,
            onSubmit: this._addPhaseToCycle,
        });
        this._popup.open();
    };

    public onRemoveActivePhase = () => {
        const phaseNumber = this.activePhaseNumber;
        if (phaseNumber === null) return;
        this._popup.setParams({
            type: PopupType.RemovePhase,
            onClose: () => {
                this.clearActivePhase();
                this._popup.close();
            },
            onSubmit: () => {
                this._removePhase(phaseNumber);
                this._popup.close();
            },
        });
        this._popup.open();
    };

    public onSelectCycle(cycle?: CustomCycle | null) {
        if (!cycle || cycle.time === 0) return;
        this._history.clear();
        const phases = cycle.phases ?? [];
        const time = cycle.time;
        this.editorData = {
            baseCycle: {
                id: cycle.id,
                time,
                phases,
                name: cycle.name,
                userDisplayName: cycle.userDisplayName,
                userId: cycle.userId,
            },
            modifiedCycle: {
                time,
                phases,
            },
        };
        this._history.pushState(this.editorData);
    }

    public createNewCycle(): INewCustomCycle | null {
        if (!this.editorData) return null;
        const { baseCycle } = this.editorData;
        return {
            name: baseCycle.name,
            userDisplayName: baseCycle.userDisplayName,
            userId: baseCycle.userId,
            time: this.time,
            phases: (this.editorData ? toJS(this.editorData.modifiedCycle.phases) : baseCycle.phases) ?? [],
        };
    }

    public onCycleTimeChange(value: number) {
        if (!this.editorData) return;
        this.updateCycleData({
            time: value,
        });
    }

    public updateCycleData = (data: Partial<CycleData>) => {
        if (!this.editorData) return;
        this.editorData = {
            baseCycle: this.editorData.baseCycle,
            modifiedCycle: {
                ...this.editorData.modifiedCycle,
                ...data,
            },
        };
        this._history.pushState(this.editorData);
    };

    public onReset = () => {
        if (!this.editorData) return;
        this.editorData = {
            baseCycle: this.editorData.baseCycle,
            modifiedCycle: {
                time: this.editorData.baseCycle.time,
                phases: this.editorData.baseCycle.phases.slice(),
            },
        };
        this._history.clear();
        this._history.pushState(this.editorData);
    };

    public onSaveCycleAs = () => {
        const data = this.createNewCycle();
        if (!data) return;
        this._popup.setParams({ type: PopupType.SaveCycleAs, data, onSubmit: this._setSavedCycle });
        this._popup.open();
    };

    private _removePhase(phaseNumber: number) {
        if (!this.editorData) return;
        const { modifiedCycle } = this.editorData;
        let updatedCycle = modifiedCycle.phases.reduce(
            (result, phase) => {
                if (phase.phaseNumber === phaseNumber) {
                    result.time -= phase.tPhase;
                    return result;
                }
                result.phases.push(phase);
                return result;
            },
            { time: modifiedCycle.time, phases: [] } as CycleData
        );
        this.updateCycleData(updatedCycle);
    }

    private _setSavedCycle = (cycle: CustomCycle) => {
        this.onSelectCycle(cycle);
        this._popup.close();
    };

    private _addPhaseToCycle = (phase: Phase) => {
        if (!this.editorData) return;
        const newCyclePhase = {
            phaseNumber: phase.number,
            tBasic: phase.tBasic,
            tMin: phase.tMin,
            tPhase: phase.tPhase,
            tProm: phase.tProm,
        };
        this.updateCycleData({
            time: this.editorData.modifiedCycle.time + newCyclePhase.tPhase,
            phases: [...this.editorData.modifiedCycle.phases, newCyclePhase],
        });
        this._popup.close();
    };

    private _setEditorDataFromHistoryState() {
        const state = this._history.state;
        if (!state) return;
        this.editorData = {
            baseCycle: state.baseCycle,
            modifiedCycle: state.modifiedCycle,
        };
    }

    private _checkCycleTimeChanges() {
        if (!this.editorData) return false;
        return this.editorData.baseCycle.time !== this.editorData.modifiedCycle.time;
    }

    private _checkPhaseChanges() {
        if (!this.editorData) return false;
        const { modifiedCycle, baseCycle } = this.editorData;
        return baseCycle.phases.some((basePhase, index) => {
            const modifiedPhase = modifiedCycle.phases[index];
            if (!modifiedPhase) return true;
            return modifiedPhase.tPhase !== basePhase.tPhase || modifiedPhase.phaseNumber !== basePhase.phaseNumber;
        });
    }
}
