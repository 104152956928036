// import { GovernanceInfo } from 'app-domain/traffic-light';

import { ControlSource } from "app-domain/traffic-light";
import { makeAutoObservable, runInAction } from "mobx";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightStore } from "../traffic-light-store";
import { TrafficLightRemoteControl, TrafficLightRemoteControlAdapter } from "./traffic-light-remote-control";
import { ITrafficLightRemoteControlStoreService } from "./traffic-light-remote-controls.types";
// import { ControlSource } from "../../../enums";
// import {Governance} from
// import GovernanceInfo

type StopControlsResult = {
    promises: Promise<void>[];
    ids: number[];
};

/** Хранилище пультов управления СО текущего пользователя */
export class TrafficLightRemoteControls {
    private items: Map<number, TrafficLightRemoteControl> = new Map();

    constructor(
        private store: TrafficLightStore,
        private service: ITrafficLightRemoteControlStoreService,
        private currentUserId: string
    ) {
        makeAutoObservable(this);
    }

    public get remoteControlsList() {
        return Array.from(this.items.values()).filter((item) => !item.isPlanned);
    }

    public get allExpandedControls() {
        return Array.from(this.items.values()).filter((control) => !control.isCollapsed);
    }

    public get plannedControlsList() {
        return Array.from(this.items.values()).filter((item) => item.isPlanned);
    }

    /** Свернутые пульты, относящиеся к текущему пользователю, кроме планированных */
    public get currentUserCollapsedNotPlannedControls(): TrafficLightRemoteControl[] {
        return Array.from(this.items.values()).filter(
            (control) => control.isCollapsed && control.isControlledByCurrentUser && !control.isPlanned
        );
    }

    /** Загружает пульты управления СО с запущенными отложенными командами  */
    public loadPlannedControls = async () => {
        const plannedControlsList = await this.service.getPlannedControls();
        runInAction(() => {
            for (const plannedControls of plannedControlsList) {
                this.items.delete(plannedControls.facilityId);
                this.items.set(plannedControls.facilityId, this.createRemoteControl(plannedControls));
            }
        });
    };

    public async loadItems() {
        const governanceList = await this.service.getCurrentUserControls();
        runInAction(() => {
            for (const governance of governanceList) {
                this.items.set(governance.facilityId, this.createRemoteControl(governance));
            }
        });
    }

    public async loadRemoteControlByGovernanceId(governanceId: number) {
        const governance = await this.service.getRemoteControlData(governanceId);

        if (!governance) return;

        const existingControl = this.items.get(governance.facilityId);
        const control = existingControl ?? this.createRemoteControl(governance);

        if (existingControl && governance.data?.controlSource !== ControlSource.CooGroup) {
            existingControl.updateGovernance(governance);
        }

        runInAction(() => {
            this.items.set(control.id, control);
        });

        return control;
    }

    public removeRemoteControl(trafficLightId: number) {
        this.items.delete(trafficLightId);
        const trafficLight = this.store.getById(trafficLightId);
        if (!trafficLight) return;
        trafficLight.governanceInfo = null;
    }

    /** Создает пульт управления и берет его под контроль текущего пользователя */
    public startRemoteControl = async (trafficLightId: number) => {
        try {
            const governance = await this.service.startRemoteControl(trafficLightId);
            const trafficLight = this.store.getById(trafficLightId);

            if (!trafficLight) return;
            trafficLight.governanceInfo = new TrafficLightDomain.GovernanceInfo(
                governance.id,
                governance.data?.userId,
                governance.data?.username
            );

            runInAction(() => {
                const remoteControl = this.createRemoteControl(governance);
                this.items.delete(governance.facilityId);
                this.items.set(governance.facilityId, remoteControl);
                remoteControl.expand();
            });
        } catch (e) {}
    };

    /** Останавливает контроль пользователя над пультом и удаляет его */
    public stopRemoteControl = async (trafficLightId: number) => {
        const remoteControl = this.getRemoteControlByTrafficLightId(trafficLightId);
        if (!remoteControl) return;
        await this.service.stopRemoteControl(trafficLightId);
        remoteControl.destroy();
        this.removeRemoteControl(remoteControl.id);
    };

    public getRemoteControlByTrafficLightId(id: number) {
        return this.items.get(id);
    }

    /** Завершить управление всеми пультами */
    public stopAllRemoteControls = async () => {
        try {
            const { promises, ids } = this.remoteControlsList.reduce<StopControlsResult>(
                (result, control) => {
                    if (!control.runningCommand) {
                        result.ids.push(control.id);
                        result.promises.push(this.service.stopRemoteControl(control.id));
                    }
                    return result;
                },
                { promises: [], ids: [] }
            );
            await Promise.all(promises);
            runInAction(() => {
                for (const id of ids) {
                    this.items.delete(id);
                }
            });
        } catch (e) {}
    };

    private onRunCommand = (control: TrafficLightRemoteControl, isPlanned: boolean) => {
        if (!isPlanned) return;
        this.store.loadGovernancePlanned(control.id);
    };

    private createRemoteControl(governance: TrafficLightDomain.Governance) {
        const service = new TrafficLightRemoteControlAdapter();
        return new TrafficLightRemoteControl(
            governance.facilityId,
            governance,
            this.currentUserId,
            service,
            this.onRunCommand
        );
    }
}
