import { CooGroupDomain, TrafficLightDomain } from "app-domain";
import { CooGroupCycle, Cycle } from "./core";

export const hasDiff = (firstCycle: CooGroupDomain.CustomCycle, lastCycle: CooGroupDomain.CustomCycle): boolean => {
    if (!firstCycle.cycles || !lastCycle.cycles) return false;

    return firstCycle.cycles.some((cycle, index) => {
        const editableCycle = lastCycle.cycles?.[index];
        if (!editableCycle) return false;
        if (editableCycle.id !== cycle.id) return true;
        if (editableCycle.shift !== cycle.shift) return true;
        if (editableCycle.time !== cycle.time) return true;
        if (cycle.phases?.some((phase, index) => phase.tBasic !== editableCycle.phases?.[index].tBasic)) return true;
        return false;
    });
};

export const convertCycleEditData = (
    cycle: CooGroupDomain.CustomCycle,
    trafficLights: TrafficLightDomain.TrafficLight[],
    facilities: CooGroupDomain.Facility[]
): CooGroupCycle => ({
    ...cycle,
    cycles:
        cycle.cycles?.reduce((acc: Cycle[], cycle) => {
            const trafficLight = trafficLights.find((item) => item.id === cycle.facilityId);
            if (!trafficLight) return acc;
            const facility = facilities.find((item) => item.facilityId === trafficLight.id);
            if (!facility) return acc;
            acc.push({
                ...cycle,
                trafficLight,
                distance: facility.distanceFromStart ? Math.round(facility.distanceFromStart) : 0,
                directionNumber: facility.directionNumber ?? 0,
            });
            return acc;
        }, []) ?? [],
});

export const convertToEdit = (data: CooGroupCycle) => ({
    ...data,
    cycles: data.cycles.map(({ distance, directionNumber, trafficLight, ...cycle }) => cycle),
});

export const copyCycle = (cycle: CooGroupDomain.CustomCycle) => ({
    ...cycle,
    cycles: cycle.cycles?.map((cycle) => ({ ...cycle, phases: cycle.phases?.map((phase) => ({ ...phase })) })),
});
