import { TrafficLightDomain } from "app-domain";

export const convertPlannedGovernanceGroup = (
    plannedGovernanceGroup: TrafficLightDomain.PlannedGovernanceInfo[]
): TrafficLightDomain.PlannedGovernance[] => {
    const hourToSecond = 3600;

    const convertData = plannedGovernanceGroup
        .map((item) => item.plannedGovernances)
        .flat()
        .filter((item) => {
            const { startAt } = item;

            const currentDate = new Date();
            const startDate = new Date(startAt);
            const timeDiff = Math.abs(startDate.getTime() - currentDate.getTime());
            const timeDiffSecond = Math.ceil(timeDiff / 1000);

            return timeDiffSecond < hourToSecond;
        });

    return convertData;
};
