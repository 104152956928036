import mapboxgl from "mapbox-gl";
import { Options } from "./route-active-stroke-layer.types";

/** Слой отображает обводку у активной ЛК */
export class RouteActiveStrokeLayer implements mapboxgl.LineLayer {
    public readonly id: string;
    public readonly type: "line" = "line";
    public readonly source: string;
    public readonly layout: mapboxgl.LineLayout = {
        "line-join": "round",
        "line-cap": "round",
    };
    public readonly paint: mapboxgl.LinePaint = {
        "line-color": ["case", ["==", true, ["feature-state", "isActive"]], "#027BFF", "transparent"],
        "line-gap-width": ["interpolate", ["exponential", 1.5], ["zoom"], 8, 7, 12, 20, 16, 40, 18, 60],
        "line-width": ["interpolate", ["exponential", 1.5], ["zoom"], 8, 6, 10, 8, 12, 12, 16, 18],
        "line-opacity": 0.4,
    };
    public readonly filter = ["all", ["==", "type", "path"]];

    constructor(options: Options) {
        this.id = options.id;
        this.source = options.source;
    }
}
