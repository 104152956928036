export type HeadDataInput = {
    y: number;
    cycleHeadHeight: number;
    fontSize: number;
    cycleHeadGap: number;
    font: string;
    isEditMode: boolean;
    trafficLightNumber?: string;
    time: number;
};

export type HeadData = {
    x: number;
    y: number;
    text: string;
    font: string;
};

const initialData = {
    x: 0,
    y: 0,
    text: "",
    font: "",
};

export const createHeadData = (args?: HeadDataInput): HeadData =>
    args
        ? {
              x: 0,
              font: args.font,
              y: args.y - (args.cycleHeadHeight - args.fontSize) / 2 + args.cycleHeadGap,
              text: `СО ${args.trafficLightNumber}${!args.isEditMode && args.time ? ` \u{2022} ${args.time} с` : ""}`,
          }
        : initialData;
