import { memo } from "react";
import styled from "styled-components";
import { ArrowIcon } from "assets/icons";
import { Indication, IndicationProps } from "./indication";

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: ${({ theme }) => theme.spacings[3].str};
`;

type Props = {
    from: IndicationProps;
    to: IndicationProps;
};

const IndicationTransitionComponent = (props: Props) => (
    <Container>
        <Indication {...props.from} />
        <ArrowIcon />
        <Indication {...props.to} />
    </Container>
);

export const IndicationTransition = memo(IndicationTransitionComponent);
