import { CooGroupCycle } from "../editor.types";

export const copyCooGroupCycle = (cooGroupCycle: CooGroupCycle) => ({
    ...cooGroupCycle,
    cycles: cooGroupCycle.cycles.map((cycle) => ({
        ...cycle,
        phases: cycle.phases?.map((item) => ({
            ...item,
        })),
    })),
});
