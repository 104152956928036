export const getInitialTimes = (startTime: number): number => {
    const initialTime = Math.round((Date.now() - startTime) / 1000);

    if (initialTime > 0) return initialTime;

    /** TODO: временная мера, чтобы исключить старт таймера с отрицательного числа
     * (иногда возникает при смонтированном компоненте со списокм фаз в момент переключения фаз).
     */
    return 1;
};
