import React from "react";
import * as Markup from "./indicator.styles";
import type { Props } from "./indicator.types";

export const Indicator = React.memo((props: Props) => {
    const { isLoaded, color, caption, value, units, dataCypress } = props;

    const content = React.useMemo(() => {
        const _dataCypress = isLoaded ? `${dataCypress}_value` : `${dataCypress}_placeholder`;
        if (isLoaded) {
            return (
                <Markup.Value data-cypress={_dataCypress}>
                    {value}
                    {units}
                </Markup.Value>
            );
        }

        return <Markup.Placeholder data-cypress={_dataCypress}>00{units}</Markup.Placeholder>;
    }, [isLoaded, value, units, dataCypress]);

    return (
        <Markup.Container data-cypress={props.dataCypress}>
            <Markup.Caption>{caption}</Markup.Caption>
            <Markup.Data>
                {color && <Markup.Legend $color={color} />}
                {content}
            </Markup.Data>
        </Markup.Container>
    );
});
