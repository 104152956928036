import { averageTextWidth } from "./draw-options.constants";
import { DrawOptionsParams } from "./draw-options.types";

export class DrawOptions {
    public phaseShiftFromStart: number = 0;
    public phaseWidth: number = 0;
    public phaseTime: number = 0;
    public phaseStartX: number = 0;
    public fillColor: string = "silver";
    public label: string = "";
    public tProm: number = 0;

    private static GREEN_COLOR = "#32C758";
    private static RED_COLOR = "#FF3A2F";

    constructor(private params?: DrawOptionsParams) {
        if (!params) return;

        const { tBasic } = params;
        this.phaseShiftFromStart = params.phaseShiftFromStart;
        this.tProm = params.tPhase - tBasic;
        this.phaseTime = tBasic + this.tProm;
        this.phaseWidth = this.phaseTime * params.pixelsPerSecond;
        this.phaseStartX = params.phaseShiftFromStart * params.pixelsPerSecond;
        this.label =
            this.phaseWidth > averageTextWidth ? `Ф${params.phaseNumber} - ${this.phaseTime}с` : `${this.phaseTime}с`;

        if (params.allow !== undefined) {
            this.fillColor = params.allow ? DrawOptions.GREEN_COLOR : DrawOptions.RED_COLOR;
        }
    }

    public get labelX() {
        return this.phaseStartX + this.phaseWidth / 2;
    }

    public get phaseEndX() {
        return this.phaseStartX + this.phaseWidth;
    }
}
