import { ChangeEvent, memo } from "react";
import styled, { css } from "styled-components/macro";
import { SpeedKind } from "../../coo-group-cycle-editor.types";

const Container = styled.div(
    ({ theme }) => css`
        display: flex;
    `
);

const InputWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        padding: ${theme.spacings[1].str} ${theme.spacings[3].str};
        border: 1px solid ${theme.colors.content.quinary};
        border-radius: ${theme.borderRadiuses[1].str};
    `
);

const Units = styled.span(
    ({ theme }) => css`
        font: ${theme.typography.text2};
        color: ${theme.colors.content.secondary};
    `
);

const Input = styled.input(
    ({ theme }) => css`
        border: none;
        margin: 0;
        padding: 0;
        outline: none;
        caret-color: ${theme.input.default.caretColor};
        width: 2em;
        font: ${theme.typography.text2};
        color: ${theme.colors.content.primary};
        background: transparent;
    `
);

type Props = {
    kind: SpeedKind;
    value?: number;
    onChange?: (key: SpeedKind, value: number) => void;
};

export const CycleSpeedInput = memo((props: Props) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => props.onChange?.(props.kind, Number(e.target.value));
    return (
        <Container>
            <InputWrapper>
                <Input value={props.value} onChange={handleChange} />
                <Units>км/ч</Units>
            </InputWrapper>
        </Container>
    );
});
