import { Drawer, GridTimeDrawer, LineDrawer } from "diagrams/drawers";
import { LabelDrawer } from "./label";
import { PhaseData } from "./phase-drawer.types";

export class PhaseDrawer extends Drawer<PhaseData> {
    private labelDrawer: LabelDrawer;
    private lineDrawer: LineDrawer;

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.labelDrawer = new LabelDrawer(ctx);
        this.lineDrawer = new LineDrawer(ctx);
    }

    protected draw = () => {
        if (!this.data) return;

        this.ctx.fillStyle = this.data.fillColor;

        this.ctx.fillRect(this.data.x, this.data.forwardY, this.data.width, this.data.height);

        if (this.data.hasBackwardDirection) {
            this.ctx.fillRect(this.data.x, this.data.backwardY, this.data.width, this.data.height);
        }

        if (this.data.tPromData) this.lineDrawer.setData(this.data.tPromData);

        this.labelDrawer.setData(this.data.labelData);

        GridTimeDrawer.draw(this.ctx, {
            x: this.data.x,
            y: this.data.forwardY,
            width: this.data.width,
            height: this.data.height,
            pixelPerSecond: this.data.pixelsPerSecond,
        });

        if (this.data.hasBackwardDirection) {
            GridTimeDrawer.draw(this.ctx, {
                x: this.data.x,
                y: this.data.backwardY,
                width: this.data.width,
                height: this.data.height,
                pixelPerSecond: this.data.pixelsPerSecond,
            });
        }

        this.ctx.fillStyle = "white";

        this.ctx.fillRect(this.data.x, this.data.forwardY, this.data.dragAnchorWidth, this.data.height);

        if (this.data.hasBackwardDirection) {
            this.ctx.fillRect(this.data.x, this.data.backwardY, this.data.dragAnchorWidth, this.data.height);
        }
    };
}
