/** Состояние светофорного объекта */
export enum ConditionType {
    /** Не подключен */
    NotConnected = 0,
    /** Нет связи */
    Offline = 1,
    /** Критическая неисправность */
    Critical = 2,
    /** Работает с замечаниями */
    Warning = 3,
    /** Всё в порядке */
    Ok = 4,
}
