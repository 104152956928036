import styled from "styled-components";
import { Placeholder } from "components";
import * as Units from "./units";

export const Container = styled.div`
    display: flex;
    width: 404px;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings[3].str};
`;

export const Players = styled(Units.Players)``;
export const Details = styled(Units.Details)``;
export const Issues = styled(Units.Issues)``;

export const PlayerPlaceholder = styled(Placeholder)`
    background-color: ${({ theme }) => theme.colors.black.basic};
`;

export const DetailsPlaceholder = styled(Placeholder)`
    width: 144px;
    height: 32px;
    border-radius: ${({ theme }) => theme.spacings[2].str};
`;
