import React from "react";
import { Button } from "@megapolis/uikit/button";
import { Icons as UikitIcons } from "@megapolis/uikit/icons";
import * as Markup from "./actions.styles";
import type { Props } from "./actions.types";

export const Actions = React.memo((props: Props) => {
    const dataCypressShowOnMap = props.dataCypress ? `${props.dataCypress}__show-on-map` : undefined;
    const dataCypressMaximize = props.dataCypress ? `${props.dataCypress}__maximize` : undefined;
    const dataCypressClose = props.dataCypress ? `${props.dataCypress}__close` : undefined;
    return (
        <Markup.Container className={props.className}>
            <Markup.Action data-cypress={dataCypressShowOnMap}>
                <Button
                    content={Button.Content.Icon}
                    priority="quaternary"
                    kind="info"
                    icon={<UikitIcons.Monochrome.MapPin />}
                    onClick={props.onShowClick}
                />
            </Markup.Action>
            {props.onFullscreenClick && (
                <Markup.Action data-cypress={dataCypressMaximize}>
                    <Button
                        content={Button.Content.Icon}
                        priority="quaternary"
                        kind="info"
                        icon={<UikitIcons.Monochrome.Maximize />}
                        onClick={props.onFullscreenClick}
                    />
                </Markup.Action>
            )}
            <Markup.Action data-cypress={dataCypressClose}>
                <Button
                    content={Button.Content.Icon}
                    priority="quaternary"
                    kind="info"
                    icon={<UikitIcons.Monochrome.CloseM />}
                    onClick={props.onCloseClick}
                />
            </Markup.Action>
        </Markup.Container>
    );
});
