import { useCallback } from "react";
import { Utils } from "shared";
import { Routing } from "routing";
import { useAppState } from "./use-app-state";

export const useSelectCooGroup = () => {
    const { mapController, cooGroupStore } = useAppState();
    const navigate = Routing.CooGroups.Hooks.useCooGroupNavigator();

    return useCallback(
        (id: number, choose = false) => {
            const cooGroup = cooGroupStore.getById(id);
            if (!cooGroup) return;
            navigate({ type: Routing.CooGroups.PageType.List, id: id.toString() });
            if (choose) {
                cooGroupStore.chooseItem(id);
                const coordinates = Utils.covertFeaturesToPositionList(
                    cooGroup.geometry?.routeFeatures?.features ?? []
                );
                mapController.flyManager.handleCooGroupPosition(coordinates);
            } else cooGroupStore.toggleSelection(id);
        },
        [mapController, cooGroupStore, navigate]
    );
};
