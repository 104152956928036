import { CanvasSVG } from "lib";
import { Drawer } from "diagrams/drawers";
import StartAnchorLarge from "diagrams/icons/start-anchor.png";
import StartAnchor from "diagrams/icons/start-anchor-small.svg";
import { DirectionStartAnchorsData } from "./direction-start-anchors-drawer.types";

export class DirectionStartAnchorsDrawer extends Drawer<DirectionStartAnchorsData> {
    private directionStartAnchorIcon = new CanvasSVG(StartAnchor);
    private directionStartAnchorLargeIcon = new CanvasSVG(StartAnchorLarge);

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.initIcons();
    }
    protected draw = () => {
        if (!this.data) return;
        const icon = this.data.isLarge ? this.directionStartAnchorLargeIcon : this.directionStartAnchorIcon;
        if (!icon.img) return;
        for (let coords of this.data.coords) {
            this.ctx.drawImage(icon.img, coords.x, coords.y);
        }
    };

    private initIcons() {
        this.directionStartAnchorIcon.load();
        this.directionStartAnchorLargeIcon.load();
    }
}
