import mapboxgl from "mapbox-gl";

export enum Events {
    load = "@asudd.map.load",
    remove = "@asudd.map.remove",
    change = "@asudd.map.change",
}

export type Handlers = {
    [Events.load]: (map?: mapboxgl.Map) => void;
    [Events.remove]: () => void;
    [Events.change]: (id: number) => void;
};
