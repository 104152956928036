import { RouteDomain } from "app-domain";

export const initialRoutes = [new RouteDomain.Point({ id: -1 }), new RouteDomain.Point({ id: -2 })];

export const cooGroupAvailableSymbols = /[a-zа-я 0-9/\-_.,]/gi;

export enum CooGroupNameErrorMessages {
    REQUIRED = "Обязательное поле",
    MIN_LENGTH = "Наименование должно содержать не менее 5 символов",
    AVAILABLE_SYMBOLS = 'наименование может содержать только буквы кириллицы или латиницы, цифры и специальные символы: " "; "-"; "_"; "."; ","',
}
