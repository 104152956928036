import { ViewType } from "../calendar.types";

export const getDisplayTextByViewType = (date: Date, type: ViewType, locale: Intl.Locale) => {
    if (type === ViewType.Month) {
        const year = date.getFullYear();
        const month = date.toLocaleString(locale, { month: "long" });
        return `${month} ${year}`;
    }
    return date.toLocaleString(locale, { year: "numeric" });
};
