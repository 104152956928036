import { Theme } from "./enums";

export class ThemeStorageManager {
    private storageKey: string;

    constructor(id: string) {
        this.storageKey = id;
        if (!window.localStorage.getItem(this.storageKey)) {
            window.localStorage.setItem(this.storageKey, Theme.LIGHT);
        }
    }

    public get value() {
        const mode = window.localStorage.getItem(this.storageKey);
        return (mode as Theme) ?? Theme.LIGHT;
    }

    public set value(mode: Theme) {
        window.localStorage.setItem(this.storageKey, mode);
    }
}
