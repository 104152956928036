import { memo } from "react";
import { Component as UiKitPopup } from "@megapolis/uikit/popup/component";

const element = document.getElementById("root-popup");

export const Popup = memo((props: React.ComponentProps<typeof UiKitPopup>) => {
    const node = props.parentNode === undefined ? element : props.parentNode;

    if (node === undefined && !element) {
        throw new Error("there is no html element with id 'root-popup'");
    }

    return <UiKitPopup {...props} parentNode={node} />;
});
