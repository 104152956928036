export class CanvasHelper {
    static setDpi(canvas: HTMLCanvasElement) {
        if (!canvas) return null;
        const width = canvas.offsetWidth;
        const height = canvas.offsetHeight;
        const ctx = canvas.getContext("2d");
        const ratio = window.devicePixelRatio || 1;
        canvas.width = width * ratio;
        canvas.height = height * ratio;
        ctx?.scale(ratio, ratio);
        return ctx;
    }
}
