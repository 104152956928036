import { memo } from "react";
import { Icons } from "@megapolis/uikit/icons";
import { Button, ButtonContent } from "./button";

type Props = {
    onClick?: VoidFunction;
    isActive?: boolean;
};

export const SyncButton = memo((props: Props) => {
    const icon = props.isActive ? <Icons.Monochrome.TieOn /> : <Icons.Monochrome.TieOff />;
    return (
        <Button
            content={ButtonContent.Icon}
            icon={icon}
            priority={props.isActive ? "primary" : "secondary"}
            size="s"
            onClick={props.onClick}
        />
    );
});
