import styled from "styled-components/macro";

export const TransparentButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    text-align: inherit;
    &:hover {
        cursor: pointer;
    }
`;
