import { TrafficLightDomain } from "app-domain";
import { CanvasHelper } from "../canvas-helper";

export abstract class CanvasRenderer {
    private _intersectionObserver: IntersectionObserver;
    protected static TRAFFICLIGHT_GROUP_MIN_DIST = 22;
    protected static DIRECTION_BAR_HEIGHT = 20;
    protected static PIXEL_PER_METER = 0.2;
    protected static PIXEL_PER_SECOND = 6;
    protected static TIMELINE_COLORS = {
        greenBlink: "#1E7735",
        colorGreen: "#32C758",
        colorRed: "#FF3A2F",
        colorYellow: "#FECB00",
        colorGray: "rgba(0,0,0, 0.18)",
        scaleStroke: "rgba(0,0,0, 0.6)",
        scaleText: "rgba(0,0,0, 0.54)",
        gridColor: "rgba(255,255,255,.7)",
    };

    protected readonly _canvas: HTMLCanvasElement;
    protected _width: number;
    protected _height: number;
    protected _isDestroyed: boolean = false;
    protected _ctx: CanvasRenderingContext2D;

    constructor(canvas: HTMLCanvasElement, trackResizeEvent = true) {
        this._canvas = canvas;
        this._height = this._canvas.offsetHeight;
        this._width = this._canvas.offsetWidth;
        const ctx = CanvasHelper.setDpi(this._canvas);
        if (!ctx) throw new Error("CanvasRenderingContext2D error");
        this._ctx = ctx;

        if (trackResizeEvent) {
            window.addEventListener("resize", this.resize);
        }

        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) this.render();
            },
            { threshold: [0] }
        );

        intersectionObserver.observe(this._canvas);
        this._intersectionObserver = intersectionObserver;
    }

    public destroy() {
        this._isDestroyed = true;
        window.removeEventListener("resize", this.resize);
        this._intersectionObserver.disconnect();
    }

    static getPhaseColor(phase: number) {
        return TrafficLightDomain.Utils.getPhaseColor(phase);
    }

    protected resize = () => {
        this.render();
    };

    protected updateRenderingContext() {
        if (!this._canvas.offsetParent) return;
        const width = this._canvas.offsetWidth;
        const height = this._canvas.offsetHeight;

        if (this._width !== width || this._height !== height) {
            this._width = width;
            this._height = height;
            const ctx = CanvasHelper.setDpi(this._canvas);
            if (!ctx) throw new Error("CanvasRenderingContext2D error");
            this._ctx = ctx;
        }
    }

    protected clear(ctx: CanvasRenderingContext2D, width: number, height: number) {
        ctx.clearRect(0, 0, width, height);
    }

    protected render() {
        if (this._isDestroyed) return;
        this.updateRenderingContext();
        this.clear(this._ctx, this._width, this._height);
        this.innerRender(this._ctx, this._width, this._height);
    }

    protected abstract innerRender(ctx: CanvasRenderingContext2D, width: number, height: number): void;

    private contentLoad = () => {
        this.render();
    };
}
