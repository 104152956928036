import mapboxgl from "mapbox-gl";
import * as env from "env-data";

export class ActiveSubstrate {
    public readonly uid = "active-substrate";
    private readonly map: mapboxgl.Map;
    private readonly icon = "map-icons/shared/active-substrate";
    private readonly emptyGeoJSONData: mapboxgl.GeoJSONSourceRaw = {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: [],
        },
    };

    constructor(map: mapboxgl.Map) {
        this.map = map;
        this.subscribeOnStyleMissing();
        this.createSource();
        this.createLayer();
        window.dispatchEvent(new Event("cypress__map__active-substrate__initialized"));
    }

    public setVisibility = (visibility: boolean) => {
        this.map.setLayoutProperty(this.uid, "visibility", this.layoutVisibilityProperty(visibility));
        window.dispatchEvent(
            new CustomEvent("cypress__map__active-substrate__layer__visibility", { detail: visibility })
        );
    };

    public setSourceData = (data: MapController.LayerControllers.ActiveSubstrate.Data) => {
        const source = this.map.getSource(this.uid) as mapboxgl.GeoJSONSource | undefined;

        if (!source) return;

        source.setData(data);
        window.dispatchEvent(new CustomEvent("cypress__map__active-substrate__source__set-data", { detail: data }));
    };

    public destroy = () => {
        this.unSubscribeOnStyleMissing();
        this.removeLayer();
        this.removeSource();
    };

    /* #region  Создание и удаление источника данных */
    private createSource() {
        this.map.addSource(this.uid, this.emptyGeoJSONData);
    }

    private removeSource() {
        if (!this.map.getSource(this.uid)) return;

        this.map.removeSource(this.uid);
    }
    /* #endregion */

    /* #region  Работа с слоем отображения */
    private layoutVisibilityProperty(visibility: boolean): mapboxgl.Visibility {
        return visibility ? "visible" : "none";
    }

    private getZoomLinearExpression = (base: number | undefined = 1): mapboxgl.Expression => {
        return ["interpolate", ["linear"], ["zoom"], 8, base * 0.1, 22, base * 1.5];
    };

    private get layout(): mapboxgl.SymbolLayout {
        return {
            "icon-image": ["case", ["has", "icon"], ["get", "icon"], this.icon],
            "icon-size": this.getZoomLinearExpression(0.5),
            "icon-allow-overlap": true,
            "icon-offset": [0, 0],
            "icon-pitch-alignment": "map",
            "visibility": "visible",
            "symbol-z-order": "source",
        };
    }

    private createLayer() {
        const config: mapboxgl.SymbolLayer = {
            id: this.uid,
            type: "symbol",
            source: this.uid,
            layout: this.layout,
        };

        this.map.addLayer(config);
    }

    private removeLayer() {
        if (!this.map.getLayer(this.uid)) return;

        this.map.removeLayer(this.uid);
    }
    /* #endregion */

    /* #region  Подгруздка иконок для карты */
    private handleAddImageOnMap = (id: string) => (error: string, imageBitmap: ImageBitmap) => {
        if (error) return console.error(error);

        if (this.map.hasImage(id)) return;

        this.map.addImage(id, imageBitmap);
    };

    private handleStyleImageMissing = (event: mapboxgl.EventData) => {
        const id = event?.id;

        if (id !== this.icon) return;

        if (this.map.hasImage(id)) return;

        this.map.loadImage(`${env.REACT_APP_CDN_FILES}/${id}.png`, this.handleAddImageOnMap(id));
    };

    private subscribeOnStyleMissing() {
        this.map.on("styleimagemissing", this.handleStyleImageMissing);
    }

    private unSubscribeOnStyleMissing() {
        this.map.on("styleimagemissing", this.handleStyleImageMissing);
    }
    /* #endregion */
}
