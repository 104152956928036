import { makeAutoObservable } from "mobx";

export class BooleanProperty {
    private _value: boolean;

    constructor(initialValue: boolean) {
        this._value = initialValue;
        makeAutoObservable(this);
    }

    public setTruly() {
        this.value = true;
    }

    public setFalsy() {
        this.value = false;
    }

    public toggle() {
        this.value = !this.value;
    }

    public get value() {
        return this._value;
    }

    public set value(val: boolean) {
        this._value = val;
    }
}
