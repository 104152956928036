import { useCallback } from "react";
import { Store } from "app";
import { Utils } from "shared";

export const useFlyToCooGroup = () => {
    const cooGroupStore = Store.useCooGroupStore();
    const mapController = Store.useMapController();

    return useCallback(
        (id: number) => {
            const cooGroup = cooGroupStore.getById(id);
            if (!cooGroup) return;
            const coordinates = Utils.covertFeaturesToPositionList(cooGroup.geometry?.routeFeatures?.features ?? []);
            mapController.flyManager.handleCooGroupPosition(coordinates);
        },
        [cooGroupStore, mapController]
    );
};
