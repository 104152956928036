import { memo, useCallback } from "react";
import { Icons } from "@megapolis/uikit/icons";
import { Button } from "@megapolis/uikit/button";
import { useCalendarContext } from "../calendar-context";
import { useLocale } from "../locale-context";
import { ViewType } from "../calendar.types";
import * as Utils from "./date-navigation.utils";
import * as Markup from "./date-navigation.styles";

const PrevIcon = <Icons.Monochrome.ChevronLeftM />;
const NextIcon = <Icons.Monochrome.ChevronRightM />;

export const DateNavigation = memo(() => {
    const { view, date, setMonthView, setYearView, setPrevMonth, setPrevYear, setNextMonth, setNextYear } =
        useCalendarContext().viewState;
    const locale = useLocale();

    const displayText = Utils.getDisplayTextByViewType(date, view, locale);

    const handlePrevClick = useCallback(() => {
        if (view === ViewType.Month) return setPrevMonth();
        return setPrevYear();
    }, [view, setPrevMonth, setPrevYear]);

    const handleNextClick = useCallback(() => {
        if (view === ViewType.Month) return setNextMonth();
        return setNextYear();
    }, [view, setNextMonth, setNextYear]);

    const handleDisplayClick = useCallback(
        () => (view === ViewType.Month ? setYearView() : setMonthView()),
        [view, setYearView, setMonthView]
    );

    return (
        <Markup.Container>
            <Button
                content={Button.Content.Icon}
                priority="quaternary"
                size="s"
                icon={PrevIcon}
                onClick={handlePrevClick}
            />
            <Markup.Display onClick={handleDisplayClick}>{displayText}</Markup.Display>
            <Button
                content={Button.Content.Icon}
                priority="quaternary"
                size="s"
                icon={NextIcon}
                onClick={handleNextClick}
            />
        </Markup.Container>
    );
});
