import { memo } from "react";
import styled, { css } from "styled-components/macro";

export type CircleProps = {
    color?: string;
    size?: number;
    className?: string;
};

const CircleBlock = styled.span<CircleProps>(({ theme, size, color }) => {
    const finalSize = `${size || theme.spacings[3].value}px`;
    return css`
        display: block;
        width: ${finalSize};
        height: ${finalSize};
        background-color: ${color || theme.colors.content.primary};
        border-radius: 50%;
    `;
});

const CircleComponent = (props: CircleProps) => {
    return <CircleBlock {...props} />;
};

export const Circle = memo(CircleComponent);
