import { ControlMode } from "../enums";
import { lightTheme } from "@megapolis/uikit/theme";

export const ControlModeDictionaries = {
    [ControlMode.Disabled]: {
        name: "Выключена",
        code: ControlMode.Disabled,
        color: lightTheme.colors.black.basic,
    },
    [ControlMode.Inactive]: {
        name: "Неактивна",
        code: ControlMode.Inactive,
        color: lightTheme.colors.gray.basic,
    },
    [ControlMode.Scheduled]: {
        name: "Работает по расписанию",
        code: ControlMode.Scheduled,
        color: lightTheme.colors.green.basic,
    },
    [ControlMode.User]: {
        name: "Диспетчерское управление",
        code: ControlMode.User,
        color: lightTheme.colors.blue.basic,
    },
    [ControlMode.Story]: {
        name: "Сценарное управление",
        code: ControlMode.Story,
        color: lightTheme.colors.orange.basic,
    },
} as const;

export const ControlModeDictionariesList = Object.values(ControlModeDictionaries);
