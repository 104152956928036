// Дней в неделе, с учетом того, что отсчет ведется с 0(воскресенье)
export const DAYS_IN_WEEK = 6;
/** В объекте Date отсчет месяцев идет с 0 */
export const MONTHS = 11;

/** Является ли год високосным */
const isLeapYear = (date: Date) => {
    return date.getFullYear() % 4 === 0;
};

export const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
};

/** Формула была взята отсюда
 * @link https://habr.com/ru/post/261773/
 * можно, также, воспользоваться заранее приготовленным массивом
 */
const getDaysCount = (date: Date) => {
    const month = date.getMonth() + 1;
    let result = 28 + ((month + Math.floor(month / 8)) % 2) + (2 % month) + 2 * Math.floor(1 / month);
    if (month === 2 && isLeapYear(date)) {
        result += 1;
    }
    return result;
};

// День недели, который является первым в месяце
const getMonthStartDay = (date: Date) => {
    const temp = new Date(date);
    temp.setDate(1);
    return temp.getDay();
};

export const getPrevMonth = (date: Date) => {
    const temp = new Date(date);
    const currentMonth = date.getMonth();
    const newMonth = currentMonth === 0 ? MONTHS : currentMonth - 1;
    temp.setMonth(newMonth, 1);
    if (newMonth === MONTHS) {
        temp.setFullYear(date.getFullYear() - 1);
    }
    return temp;
};

export const getNextMonth = (date: Date) => {
    const temp = new Date(date);
    const currentMonth = date.getMonth();
    const newMonth = currentMonth === MONTHS ? 0 : currentMonth + 1;
    temp.setMonth(newMonth, 1);
    if (newMonth === 0) {
        temp.setFullYear(date.getFullYear() + 1);
    }
    return temp;
};

const getDaysCountFromPrevMonth = (date: Date) => {
    const monthStartDay = getMonthStartDay(date);
    if (monthStartDay === 0) return Math.abs(monthStartDay - DAYS_IN_WEEK);
    /** -1 потому что необходимо учитывать текущий день */
    return DAYS_IN_WEEK - (DAYS_IN_WEEK - (monthStartDay - 1));
};

export const getMonthCalendar = (date: Date) => {
    const prevMonth = getPrevMonth(date);
    const nextMonth = getNextMonth(date);
    const prevMonthDaysCount = getDaysCount(prevMonth);
    const currentMonthDaysCount = getDaysCount(date);
    const daysCountFromPrevMonth = getDaysCountFromPrevMonth(date);
    const startDateFromPrevMonth = prevMonthDaysCount - daysCountFromPrevMonth + 1;

    return {
        prevMonth,
        prevMonthDaysCount,
        currentMonth: date,
        nextMonth,
        startDateFromPrevMonth,
        currentMonthDaysCount,
        daysCountFromPrevMonth,
        total: daysCountFromPrevMonth + currentMonthDaysCount,
    };
};

export const transformDateListToMap = (dates: Date[]): Map<string, true> => {
    const map = new Map();
    for (const date of dates) {
        map.set(date.toDateString(), true);
    }
    return map;
};
