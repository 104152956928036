import { useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { CooGroupDetailsURLParams, CooGroupsURLParams, NavigatorParams } from "./coo-groups.types";
import { PageType, TabType } from "./coo-groups.constants";
import { COO_GROUP_DETAILS_MATCHER, COO_GROUPS_MATCHER } from "./coo-groups.paths";
import { CooGroupEditorURLBuilder } from "./coo-group-editor-url-builder";

const cooGroupsDefaultParams: CooGroupsURLParams = {
    type: PageType.List,
};

export const useCooGroupURLParams = () => {
    const match = useRouteMatch<CooGroupsURLParams>(COO_GROUPS_MATCHER);

    return useMemo(() => match?.params ?? cooGroupsDefaultParams, [match]);
};

export const useCooGroupDetailsURLParams = () => {
    const match = useRouteMatch<CooGroupDetailsURLParams>(COO_GROUP_DETAILS_MATCHER);
    return useMemo(() => {
        if (match?.params.type !== PageType.Details) {
            return null;
        }
        return match.params;
    }, [match]);
};

export const useCooGroupId = () => {
    const params = useCooGroupURLParams();

    return useMemo(() => {
        return params.type === PageType.List ? null : Number(params.id);
    }, [params]);
};

export const useCooGroupNavigator = () => {
    const {
        push,
        location: { hash },
    } = useHistory();

    return useCallback(
        (params: NavigatorParams) => {
            if (params.type === PageType.List) {
                const basePath = `/${PageType.List}/${params?.id ?? ""}`;
                return push({ pathname: basePath, hash });
            }
            if (params.type === PageType.Details) {
                const basePath = `/${PageType.Details}/${params.id}`;
                return push({ pathname: params.tab ? `${basePath}/${params.tab}` : basePath, hash });
            }

            const builder = new CooGroupEditorURLBuilder();
            if (params.type === PageType.EditorGroup) builder.withId(params.id);
            if (params.type === PageType.EditorGroupNew) builder.withNew();

            return push({
                pathname: builder.result,
                hash,
            });
        },
        [push, hash]
    );
};

export const useCooGroupListNavigator = () => {
    const navigate = useCooGroupNavigator();
    return useCallback(() => {
        navigate({ type: PageType.List });
    }, [navigate]);
};

export const useCooGroupDetailsNavigator = () => {
    const navigate = useCooGroupNavigator();
    return useCallback(
        (id: number | string, tab?: TabType) =>
            navigate({
                id,
                tab,
                type: PageType.Details,
            }),
        [navigate]
    );
};

export const useCooGroupEditorNavigator = () => {
    const navigate = useCooGroupNavigator();
    return useCallback((id: string | number) => navigate({ id, type: PageType.EditorGroup }), [navigate]);
};
