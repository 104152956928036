import * as turf from "@turf/turf";
import { Common, CooGroupDomain, RouteDomain, TrafficLightDomain } from "app-domain";
import type { APITypes, CooGroup } from "api";
import {
    CreateCooGroupInput,
    PrepareCooGroupResult,
    UpdateCooGroupInput,
    ValidateCooGroupInput,
} from "./coo-group-service.types";
import { TrafficLightFeatureCollection } from "app/store/coo-group-editor/traffic-light-feature-collection";
import { CooGroupCycleMapper } from "../mappers";

export const cooGroupMapper = (props?: Partial<APITypes.CooGroup>) => {
    const geometry = new RouteDomain.MapRouteFeatures();
    if (props?.geometry) {
        const geometryFromString: CooGroupDomain.CooGroup["geometry"] = JSON.parse(props.geometry);
        geometry.routeFeatures = geometryFromString.routeFeatures;
        geometry.trafficLightFeatures = geometryFromString.trafficLightFeatures;
        geometry.isReverseRouteSameAsDirect = geometryFromString.isReverseRouteSameAsDirect;
        geometry.reverseTrafficLightFeatures = geometryFromString.reverseTrafficLightFeatures;
        geometry.reverseRoute = geometryFromString.reverseRoute;
    }

    const group = new CooGroupDomain.CooGroup({
        id: props?.id ?? 0,
        type: props?.type ?? 0,
        name: props?.name ?? "",
        description: props?.description ?? "",
        priority: props?.priority ?? 0,
        facilities: props?.facilities ?? [],
        points: props?.points ?? [],
        isEnabled: props?.isEnabled ?? false,
        geometry,
    });

    if (props?.state) {
        group.updateState(mapCooGroupState(props.state));
    }

    if (props?.cycles) {
        const mapper = new CooGroupCycleMapper();
        group.cycles = props.cycles.map(mapper.fromAPI);
    }
    return group;
};

export const pointMapper = (point: RouteDomain.Point) => ({
    lng: point.longitude,
    lat: point.latitude,
    address: point.fullAddress,
});

export const mapCooGroupState = (state: APITypes.CooGroupState): CooGroupDomain.ICooGroupState => ({
    ...state,
    governanceInfo: state.governance
        ? new CooGroupDomain.GovernanceInfo(state.governance.id, state.governance.userId, state.governance.username)
        : null,
    stateDate: new Date(state.stateDate ?? ""),
});

export const prepareCooGroup = ({ name, facilities, route, points }: CreateCooGroupInput) => {
    const result: PrepareCooGroupResult = {
        name,
        facilities,
        points: points.map(pointMapper),
        geometry: JSON.stringify(route),
        isEnabled: false,
    };

    if (facilities.length < 2) return result;

    return result;
};

export const prepareValidateCooGroup = (data: ValidateCooGroupInput) => ({
    ...data,
    points: data.points.map(pointMapper),
});

export const prepareCooGroupFacilities = (data: CooGroup.Types.CooGroupRouteFacility[]) => {
    const facilityData: APITypes.FacilityData[] = data.map((item) => item.facility);
    const cooGroupFacilities: CooGroupDomain.Facility[] = [];

    const lineCoords = facilityData.reduce((acc: GeoJSON.Position[], item) => {
        acc.push([item.lng, item.lat]);
        return acc;
    }, []);

    if (facilityData.length && lineCoords.length > 1) {
        /** в lineCoords должно быть более 1 элемента */
        const line = turf.lineString(lineCoords);

        const startPoint = turf.nearestPointOnLine(line, lineCoords[0]);

        data.forEach((item) => {
            const endPoint = turf.nearestPointOnLine(line, [item.facility.lng, item.facility.lat]);
            const lineToPoint = turf.lineSlice(startPoint, endPoint, line);

            cooGroupFacilities.push({
                distanceFromStart: turf.length(lineToPoint) * 1000,
                facilityId: item.facility.facilityId,
                directionNumber: item.directionNumber,
                persistentEdgeFrom: item.persistentEdgeFrom,
                persistentEdgeTo: item.persistentEdgeTo,
            });
        });
    }

    return {
        facilityData,
        cooGroupFacilities,
    };
};

export const prepareRouteData = (route: RouteDomain.MapRouteFeatures) => {
    if (!route.routeFeatures)
        return {
            persistentEdges: [],
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
        };
    /** Из маршрута берется свойство persistentEdgeIdStr это id ребра */
    const persistentEdges: string[] = [];
    /** Из маршрута берутся координаты геометрии
     *  и создается bbox (кординаты квадраты),
     * что-бы база данных легко нашела светафоры */
    const coordinates: turf.Position[] = route.routeFeatures.features.reduce((acc, item) => {
        persistentEdges.push(item.properties.persistentEdgeIdStr);
        return acc.concat(item.geometry.coordinates);
    }, [] as turf.Position[]);

    const line = turf.lineString(coordinates);
    const bbox = turf.bbox(line);

    return {
        /** TODO временный костыль потому что persistentEdgeIdStr повторяется */
        persistentEdges: Array.from(new Set(persistentEdges)),
        left: bbox?.[0] ?? 0,
        top: bbox?.[3] ?? 0,
        right: bbox?.[2] ?? 0,
        bottom: bbox?.[1] ?? 0,
    };
};

export const prepareUpdateCooGroupData = (data: UpdateCooGroupInput): APITypes.CooGroup => ({
    id: data.id,
    name: data.cooGroupName,
    facilities: data.facilities,
    points: data.points.map(pointMapper),
    geometry: JSON.stringify({
        routeFeatures: data.route.routeFeatures,
        reverseRoute: data.route.reverseRoute,
        trafficLightFeatures: data.route.trafficLightFeatures,
        reverseTrafficLightFeatures: data.route.reverseTrafficLightFeatures,
        isReverseRouteSameAsDirect: data.route.isReverseRouteSameAsDirect,
    }),
});

export const trafficLightMapper = (params: { trafficLight: APITypes.FacilityData; serverInfo: Common.ServerInfo }) => {
    const { trafficLight, serverInfo } = params;

    const trafficlight = new TrafficLightDomain.TrafficLight({
        facilityId: trafficLight.facilityId,
        lng: trafficLight.lng,
        lat: trafficLight.lat,
        // @ts-ignore
        state: trafficLight.state,
        issueCount: trafficLight.issueCount,
        activeIssueCount: trafficLight.activeIssueCount,
        address: trafficLight.address,
        streets: trafficLight.streets,
        num: trafficLight.num,
        userDisplayName: trafficLight.userDisplayName,
        serverInfo: serverInfo!,
    });

    trafficlight.setMetaData({
        directions:
            trafficLight.directions?.map((item) => ({
                number: item.number,
                type: item.type,
                isDeadlock: item.isDeadlock,
                tracks: item.tracks,
                concurrentDirections: item.concurrentDirections,
                tDelay: item.tDelay,
                tGreenBlink: item.tGreenBlink,
                tYellow: item.tYellow,
                tRed: item.tRed,
                tRedYellow: item.tRedYellow,
                tGreenDeny: item.tGreenDeny,
            })) ?? [],
        phases:
            trafficLight.phases
                ?.map((item) => ({
                    number: item.number,
                    type: item.type,
                    tPhase: item.tPhase,
                    tMin: item.tMin,
                    tBasic: item.tBasic,
                    tProm: item.tProm,
                    directions: item.directions,
                    directionByNum: item.directionByNum,
                    name: item.name,
                }))
                .filter(Boolean) ?? [],
        detectors:
            trafficLight.detectors?.map((item) => ({
                number: item.number,
                type: item.type,
                videoStream: item.videoStream,
                apiUrl: item.apiUrl,
                geometry: item.geometry,
                channels: item.channels,
            })) ?? [],
        activeIssueCount: trafficLight.activeIssueCount!,
        cyclesWithWarningCount: trafficLight.cyclesWithWarningCount!,
        issueCount: trafficLight.issueCount!,
    });
    return trafficlight;
};

export const routePointMapper = (point: RouteDomain.Point) => ({ lng: point.longitude, lat: point.latitude });

export const cooGroupRouteMapper = (data: APITypes.CooGroupRoute) => {
    const result = new RouteDomain.MapRouteFeatures();
    const facilities = prepareCooGroupFacilities(data.directFacilities);
    const reverseFacilities = prepareCooGroupFacilities(data.reverseFacilities);
    result.routeFeatures = data.dtmDirectRoute.paintedPath;
    result.reverseRoute = data.dtmReverseRoute.paintedPath;
    result.trafficLightFeatures = new TrafficLightFeatureCollection(facilities.facilityData);
    result.reverseTrafficLightFeatures = new TrafficLightFeatureCollection(reverseFacilities.facilityData);
    result.isReverseRouteSameAsDirect = data.isReverseRouteSameAsDirect;

    return {
        route: result,
        facilities: facilities.cooGroupFacilities,
    };
};

export const cooGroupLogsMapper = (log: APITypes.CooGroupLog): CooGroupDomain.CooGroupLog => ({
    ...log,
    dateTime: new Date(log.timestamp).getTime() / 1000,
});
