import styled, { css } from "styled-components/macro";
import type { DividerOrientation } from "./divider.types";

type DividerProps = {
    orientation: DividerOrientation;
    thickness?: string;
    margin?: string;
    padding?: string;
};

export const Divider = styled.hr<DividerProps>((props) => {
    const {
        theme: { colors },
        margin,
        padding,
        color,
        orientation,
        thickness,
    } = props;
    return css`
        display: flex;
        flex-shrink: 0;
        margin: 0;
        padding: 0;
        border: none;
        margin: ${margin || 0};
        padding: ${padding || 0};
        background-color: ${color || colors.content.quinary};
        ${orientation === "horizontal"
            ? css`
                  width: 100%;
                  height: ${thickness || "1px"};
              `
            : css`
                  width: ${thickness || "1px"};
                  height: auto;
              `}
    `;
});
