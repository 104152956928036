import mapboxgl, { LineLayer } from "mapbox-gl";
import { lineColor, lineOffset, lineWidth } from "./dtm-traffic-layer.constants";

export class DtmTrafficLayer implements LineLayer {
    public "id": LineLayer["id"];
    public "type": LineLayer["type"] = "line";
    public "source": LineLayer["source"];
    public "source-layer": LineLayer["source-layer"] = "traffic";
    public "layout": LineLayer["layout"] = {
        "line-join": "round",
        "line-cap": "round",
        "visibility": "none",
    };
    public "paint": LineLayer["paint"] = {
        "line-color": lineColor,
        "line-offset": lineOffset,
        "line-width": lineWidth,
    };

    "constructor"(id: string, visibility: mapboxgl.Visibility) {
        this.id = id;
        this.source = id;
        this.layout = {
            "line-join": "round",
            "line-cap": "round",
            "visibility": visibility,
        };
    }
}
