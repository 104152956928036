import styled from "styled-components";
import { AccordionHeader as AccordionHeaderComponent } from "components";

export const Container = styled.div`
    position: relative;
    width: 404px;
    height: 172px;
`;

export const Placeholder = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AccordionHeader = styled(AccordionHeaderComponent)`
    padding-left: 0;
`;
