export enum MonitoringStatusCode {
    /**
     * Работает
     */
     Works = 1,

    /**
     * Нет данных
     */
    NoData = 2,

    /**
     * Работает с замечаниями
     */
    WorksWithRemarks = 3,

    /**
     * Не работает
     */
    NoWorks = 4,
}