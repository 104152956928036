import { TrafficLightDomain } from "app-domain";

export interface TPromDataItem {
    x: number;
    y: number;
    width: number;
    height: number;
    fillColor: string;
}
export interface TPromDataInput {
    x: number;
    y: number;
    pixelsPerSecond: number;
    hasBackwardDirection: boolean;
    directionBarHeight: number;
    directionBarGap: number;
    isAllow: boolean;
    direction?: TrafficLightDomain.Direction;
}

const colors = {
    greenBlink: "#1E7735",
    colorRed: "#FF3B30",
    colorYellow: "#FECB00",
};

export const createTPromDataItems = (args: TPromDataInput): TPromDataItem[] => {
    const halfSecondWidth = args.pixelsPerSecond / 2;
    const halfBarHeight = args.directionBarHeight / 2;
    const backwardY = args.y + args.directionBarHeight + args.directionBarGap;

    if (!args.direction) {
        return [];
    }

    const tPromList = [];

    if (args.isAllow) {
        let x = args.x;

        if (args.direction.tRed) {
            x -= (args.direction.tRed + args.direction.tDelay) * args.pixelsPerSecond;
            const width = args.pixelsPerSecond * args.direction.tRed;
            tPromList.push({
                x,
                y: args.y,
                width,
                height: args.directionBarHeight,
                fillColor: colors.colorRed,
            });
            if (args.hasBackwardDirection) {
                tPromList.push({
                    x,
                    y: backwardY,
                    width,
                    height: args.directionBarHeight,
                    fillColor: colors.colorRed,
                });
            }
        }

        if (args.direction.tYellow) {
            x -= args.direction.tYellow * args.pixelsPerSecond;
            tPromList.push({
                x,
                y: args.y,
                width: args.pixelsPerSecond * args.direction.tYellow,
                height: args.directionBarHeight,
                fillColor: colors.colorYellow,
            });
            if (args.hasBackwardDirection) {
                tPromList.push({
                    x,
                    y: backwardY,
                    width: args.pixelsPerSecond * args.direction.tYellow,
                    height: args.directionBarHeight,
                    fillColor: colors.colorYellow,
                });
            }
        }

        if (args.direction.tGreenBlink) {
            for (let i = 1; i <= args.direction.tGreenBlink; i++) {
                x -= args.pixelsPerSecond;
                tPromList.push({
                    x: x + halfSecondWidth,
                    y: args.y,
                    width: halfSecondWidth,
                    height: args.directionBarHeight,
                    fillColor: colors.greenBlink,
                });
                if (args.hasBackwardDirection) {
                    tPromList.push({
                        x: x + halfSecondWidth,
                        y: backwardY,
                        width: halfSecondWidth,
                        height: args.directionBarHeight,
                        fillColor: colors.greenBlink,
                    });
                }
            }
        }
    } else {
        if (args.direction.tRedYellow) {
            for (let i = 1; i <= args.direction.tRedYellow; i++) {
                const x = args.x - i * args.pixelsPerSecond + halfSecondWidth;
                tPromList.push({
                    x,
                    y: args.y + halfBarHeight,
                    width: halfSecondWidth,
                    height: halfBarHeight,
                    fillColor: colors.colorYellow,
                });
                if (args.hasBackwardDirection) {
                    tPromList.push({
                        x,
                        y: backwardY + halfBarHeight,
                        width: halfSecondWidth,
                        height: halfBarHeight,
                        fillColor: colors.colorYellow,
                    });
                }
            }
        }
    }

    return tPromList;
};
