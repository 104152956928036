import { REACT_APP_KSODD_LAYER } from "env-data";
import mapboxgl, { CustomLayerInterface, Map } from "mapbox-gl";
import { TrafficLightDispatcher } from "trafficlight-dispatcher";

const lineWidth: mapboxgl.Expression = ["interpolate", ["linear", 1], ["zoom"], 17, 0.5, 20, 2];

export interface RadarDetectorLayerProps {
    id: string;
    dispatcher: TrafficLightDispatcher;
}

export class RadarDetectorLayer implements CustomLayerInterface {
    public type: "custom" = "custom";
    public renderingMode?: "2d" | "3d" | undefined;
    public readonly id: string;
    private map: mapboxgl.Map;
    private _map?: Map;
    private _dispatcher: TrafficLightDispatcher;

    constructor(map: mapboxgl.Map, props: RadarDetectorLayerProps) {
        this.map = map;
        this.id = props.id;
        this._dispatcher = props.dispatcher;
        this.addLayer();
    }

    public readonly destroy = () => {
        if (this.map.getLayer(this.id)) {
            this.map.removeLayer(this.id);
        }
    };

    public render(): void {}

    public onAdd(map: Map) {
        this._map = map;
        const ksoddLayerId = `${REACT_APP_KSODD_LAYER}_features`;
        const radarLayerId = `${this.id}_radar`;
        const radarStrokeLayerId = `${this.id}_radar-stroke`;

        if (!this._map.getSource(ksoddLayerId)) return;

        if (map.getLayer(ksoddLayerId)) {
            map.setPaintProperty(ksoddLayerId, "fill-opacity", [
                "match",
                ["get", "feature_type"],
                "detector_zone",
                0,
                1,
            ]);
        }

        if (!map.getLayer(radarLayerId)) {
            map.addLayer({
                "id": radarLayerId,
                "type": "fill",
                "minzoom": 16,
                "source": `${REACT_APP_KSODD_LAYER}_features`,
                "source-layer": "features",
                "paint": {
                    "fill-antialias": true,
                    "fill-opacity": 0.2,
                    "fill-color": ["match", ["feature-state", "busy"], "1", "#59ff85", "#C6C6C6"],
                },
                "filter": ["all", ["==", "feature_type", "detector_zone"], ["==", "type", "radar"]],
            });
        }

        if (!map.getLayer(radarStrokeLayerId)) {
            map.addLayer({
                "id": `${this.id}_radar-stroke`,
                "type": "line",
                "source": `${REACT_APP_KSODD_LAYER}_features`,
                "source-layer": "features",
                "minzoom": 16,
                "paint": {
                    "line-width": lineWidth,
                    "line-opacity": 1.0,
                    "line-color-transition": {
                        duration: 1000,
                    },
                    "line-color": ["match", ["feature-state", "busy"], "1", "#59ff85", "#C6C6C6"],
                },
                "filter": ["all", ["==", "feature_type", "detector_zone"], ["==", "type", "radar"]],
            });
        }

        const onRadarDetectorLaneIsBusyChanged = (event: { isBusy: boolean; detectorId: number; lane: number }) => {
            const map = this._map;
            if (!map) return;
            const isBusy: boolean = event.isBusy;

            if (map.getSource(ksoddLayerId)) {
                const features = map.querySourceFeatures(`${REACT_APP_KSODD_LAYER}_features`, {
                    sourceLayer: "features",
                    filter: [
                        "all",
                        ["==", "feature_type", "detector_zone"],
                        ["==", "type", "radar"],
                        ["==", "detectorId", `${event.detectorId}`],
                        ["==", "detectorChannel", `${event.lane}`],
                    ],
                    validate: true,
                });

                features.forEach((f) =>
                    map.setFeatureState(
                        {
                            id: f.id,
                            source: `${REACT_APP_KSODD_LAYER}_features`,
                            sourceLayer: "features",
                        },
                        { busy: isBusy ? "1" : "0" }
                    )
                );
            }
        };

        this._dispatcher.off("radarDetectorLaneIsBusyChanged", onRadarDetectorLaneIsBusyChanged);
        this._dispatcher.on("radarDetectorLaneIsBusyChanged", onRadarDetectorLaneIsBusyChanged);
    }

    public onRemove(map: Map) {
        this._map = undefined;
    }

    public addLayer = () => {
        this.map.addLayer(this);
    };
}
