import { StatusCode } from "../enums";
import { CodeDictionary } from "../types";

type CodeDictionaryMap = { [key in StatusCode]: CodeDictionary<StatusCode> };

export const statusCodeDictionaryMap: CodeDictionaryMap = {
    [StatusCode.Disabled]: {
        code: StatusCode.Disabled,
        name: "Выключен",
        shortName: "ВЫКЛ",
        color: "#000000",
    },
    [StatusCode.Cycle]: {
        code: StatusCode.Cycle,
        name: "Цикл",
        shortName: "Ц",
        color: "#18AB65",
    },
    [StatusCode.YellowBlink]: {
        code: StatusCode.YellowBlink,
        name: "Желтое мигание",
        shortName: "ЖМ",
        color: "#FFD600",
    },
    [StatusCode.AllRed]: {
        code: StatusCode.AllRed,
        name: "Везде красный",
        shortName: "ВК",
        color: "#FF0000",
    },
    [StatusCode.Hold]: {
        code: StatusCode.Hold,
        name: "Удержание фазы",
        shortName: "У",
        color: "#00E9F8",
    },
    [StatusCode.CriticalError]: {
        code: StatusCode.CriticalError,
        name: "Критическая неисправность",
        shortName: "КН",
        color: "#ff7c0b",
    },
    [StatusCode.Coordination]: {
        code: StatusCode.Coordination,
        name: "Координация",
        shortName: "К",
        color: "rgb(177, 42, 210)",
    },
    [StatusCode.LocalAdaptive]: {
        code: StatusCode.LocalAdaptive,
        name: "Локальный адаптив",
        shortName: "ЛА",
        color: "#07C7A4",
    },
    [StatusCode.Unknown]: {
        code: StatusCode.Unknown,
        name: "Нет данных",
        shortName: "НД",
        color: "#C4C4C4",
    },
};

export const statusCodeDictionaryList = Object.values(statusCodeDictionaryMap);
