import { v4 } from "uuid";
import * as utils from "shared/utils";
import { FactoryCoordinationGroupsData } from "./factory-coordination-groups-data.types";

export const factoryCoordinationGroupsData: FactoryCoordinationGroupsData = (data) => ({
    caption: "Ленты координации",
    total: utils.convertNumberToFormat(data?.total),
    info: [
        {
            uid: v4(),
            type: "normal",
            caption: "Активно",
            count: utils.convertNumberToFormat(data?.activeCount),
        },
        {
            uid: v4(),
            type: "disable",
            caption: "Неактивно",
            count: utils.convertNumberToFormat(data?.inactiveCount),
        },
    ],
});
