import { CooGroupDomain } from "app-domain";
import { useMemo } from "react";
import { useCooGroupControlModeColor } from "./use-coo-group-control-mode-color";
import { useCooGroupStatusColor } from "./use-coo-group-status-color";

export const useCooGroupColors = (cooGroup: CooGroupDomain.CooGroup) => {
    const status = useCooGroupStatusColor(cooGroup.status);
    const controlMode = useCooGroupControlModeColor(cooGroup.controlMode);
    return useMemo(
        () => ({
            status,
            controlMode,
        }),
        [status, controlMode]
    );
};
