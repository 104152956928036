import { createContext } from "react";

type ContextValue = {
    limit: number;
    isShowingMore: boolean;
    isButtonVisible: boolean;
    setIsShowingMore: (value: boolean | ((prev: boolean) => boolean)) => void;
    setIsButtonVisible: (value: boolean) => void;
};

export const Context = createContext<ContextValue>({
    limit: 0,
    isShowingMore: false,
    isButtonVisible: false,
    setIsShowingMore: () => {},
    setIsButtonVisible: () => {},
});
