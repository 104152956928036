import styled from "styled-components/macro";

export const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    color: ${({ theme }) => theme.colors.content.secondary};
    line-height: 16px;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ValueContainer = styled.span`
    color: ${({ theme }) => theme.colors.content.primary};
`;
