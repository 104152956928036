import { makeObservable, observable } from "mobx";
import { Types } from "shared";
import { ActiveItemState } from "./active-item-state";
import { EntityType } from "../view-state.types";

export class ActiveTrafficLightState extends ActiveItemState<Types.ViewState.TrafficLightViewType> {
    public activeItemId: NullableNumber = null;
    public itemType = EntityType.TrafficLight;
    public viewType = Types.ViewState.TrafficLightViewType.List;

    constructor() {
        super();
        makeObservable(this, {
            activeItemId: observable,
            viewType: observable,
        });
    }

    public get isDetailsView() {
        return this.viewType === Types.ViewState.TrafficLightViewType.Details;
    }

    public get isListView() {
        return this.viewType === Types.ViewState.TrafficLightViewType.List;
    }
}
