import { memo, useEffect, useCallback, useState, useRef } from "react";
import { TagLabel } from "../../tag-label";
import * as utils from "./phase-timer.utils";

type Props = {
    startTime: number;
    isMeasure?: boolean;
};

const PhaseTimerComponent = ({ startTime, isMeasure }: Props) => {
    const [times, setTimes] = useState<number>(() => utils.getInitialTimes(startTime));

    const interval = useRef<NodeJS.Timeout | null>(null);

    const clearInterval = useCallback(() => {
        if (!interval.current) return;
        global.clearInterval(interval.current);
        interval.current = null;
    }, []);

    useEffect(() => {
        setTimes(utils.getInitialTimes(startTime));
    }, [startTime]);

    useEffect(() => {
        if (interval.current) clearInterval();
        interval.current = setInterval(() => setTimes((prev) => prev + 1), 1000);
        return clearInterval;
    }, [clearInterval]);

    return <TagLabel>{`${times} ${isMeasure ? "сек" : ""}`}</TagLabel>;
};

export const PhaseTimer = memo(PhaseTimerComponent);
