import { useMemo } from "react";
import { useRouteMatch } from "react-router";
import { MainURLDefaultParams, MainURLParams } from "./main.types";
import * as TrafficLights from "../traffic-lights";
import * as CooGroups from "../coo-groups";

const mainDefaultParams: MainURLDefaultParams = {
    id: undefined,
    type: undefined,
    mode: undefined,
    tab: undefined,
};

export const MAIN_ROUTE_MATCHER = ([] as string[]).concat(
    TrafficLights.Paths.TRAFFIC_LIGHT_ROUTE_MATCHER,
    CooGroups.Paths.COO_GROUPS_MATCHER
);

export const useMainURLParams = () => {
    const match = useRouteMatch<MainURLParams>(MAIN_ROUTE_MATCHER);
    return useMemo(() => {
        if (!match) return mainDefaultParams;
        return match.params;
    }, [match]);
};
