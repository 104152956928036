import { CycleView } from "../cycle-view";
import { DrawDataInput } from "./draw-data.types";

export const mapCycleViewData = (drawData: DrawDataInput, cycle: CycleView) => ({
    y: cycle.y,
    width: drawData.width,
    shift: cycle.shift,
    height: drawData.height,
    phases: cycle.phases,
    distance: cycle.distanceFromStart,
    forwardSpeed: cycle.directSpeed,
    backwardSpeed: cycle.reverseSpeed,
    isEditMode: drawData.isEditMode,
    defaultFont: drawData.defaultFont,
    hasOverlay: !cycle.isSmallThan,
    cycleHeadGap: drawData.cycleHeadGap,
    totalViewTime: drawData.totalViewTime,
    directionType: cycle.directionType,
    hasEmptyPart: !cycle.isGreaterThan,
    defaultFontSize: drawData.defaultFontSize,
    dragAnchorWidth: drawData.dragAnchorWidth,
    pixelsPerSecond: drawData.pixelsPerSecond,
    cycleHeadHeight: drawData.cycleHeadHeight,
    directionBarGap: drawData.directionBarGap,
    labelStrokeStyle: drawData.labelStrokeStyle,
    isFirstDirection: cycle.isFirstDirection,
    directionLabel: cycle.cycleDirectionLabel,
    trafficLightNumber: cycle.trafficLight.num,
    cycleDurationTime: cycle.cycleDurationTime,
    directionBarHeight: drawData.directionBarHeight,
    emptyPartFillStyle: drawData.emptyPartFillStyle,
    hasBackwardDirection: drawData.hasBackwardDirection,
    speedDistanceLegendY: cycle.speedDistanceLegendY,
    directionLabelFillStyle: drawData.directionLabelFillStyle,
    originCycleDurationTime: cycle.originCycleDurationTime,
    visibleCycleRepeatCount: cycle.visibleCycleRepeatCount,
    legendLabelFillStyle: drawData.legendLabelFillStyle,
    polygons: cycle.phases.map((phase) => phase.polygons).flat(),
});
