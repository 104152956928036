import { makeObservable, action, observable } from "mobx";
import { Interfaces } from "interfaces";

export class ShortCardList implements Interfaces.Store.ListShortCard.Interface {
    public collection: Interfaces.Store.ListShortCard.Interface["collection"] = [];
    public readonly emitter = new Interfaces.Warehouse.Emitter();
    private readonly itemFactory: Interfaces.Store.ListShortCard.ItemFactory;

    private currentUid: number | null = null;

    constructor(itemFactory: Interfaces.Store.ListShortCard.ItemFactory) {
        makeObservable(this, {
            collection: observable,
            add: action,
            remove: action,
        });
        this.itemFactory = itemFactory;
    }

    public add = async (arg: SourceEquipmentCardArgs) => {
        const hasInCollection = this.collection.some((item) => item.uid === arg.options.uid);

        if (hasInCollection) return;

        const item = this.itemFactory(arg, {
            handleRemove: this.remove,
            handleShowOnMap: this.handleShowOnMap,
            handleFullscreenClick: this.handleFullscreenClick,
        });

        /**
         * TODO: проверка на currentUid реализована, чтобы исключить открытие двух одинаковых
         * карточек при двойном клике по визуализатору значка на карте
         */
        if (this.currentUid !== arg.options.uid) {
            this.currentUid = arg.options.uid;
            await item.load?.();
            this.collection.push(item);
            this.emitter.emit(this.emitter.events.Adding, item.guid);
        }

        return item;
    };

    public remove = (uid: number) => {
        const item = this.collection.find((camera) => camera.uid === uid);

        if (!item) return;

        this.collection = this.collection.filter((camera) => camera.uid !== item.uid);

        this.emitter.emit(this.emitter.events.Remove, item.guid);

        this.currentUid = null;
    };

    private handleShowOnMap = (coordinate: LatLng) => {
        this.emitter.emit(this.emitter.events.ShowOnMap, coordinate);
    };

    private handleFullscreenClick = (args: SourceEquipmentCardArgs) => {
        this.emitter.emit(this.emitter.events.MaximizeCard, args);
    };
}
