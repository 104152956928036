import styled from "styled-components";
import { Typography } from "@megapolis/uikit/typography";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacings[1].str};
`;

export const Caption = styled(Typography.H5).attrs({ as: "span" })``;

export const Count = styled(Typography.H5).attrs({ as: "span" })`
    color: ${({ theme }) => theme.colors.content.secondary};
`;
