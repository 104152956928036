import { memo, useMemo } from "react";
import { FilterChip } from "components";

import { Chip, FilterChipsType } from "./filter-chips.types";
import * as Markup from "./filter-chips.styles";

export const FilterChips = memo(({ list, keyForChip, onClear }: FilterChipsType) => {
    const handlerClick = (chip: Chip) => () => chip.onClose?.(chip.code);

    const chips = useMemo(
        () =>
            list.reduce((acc: JSX.Element[], chip: Chip | null) => {
                if (!!chip) {
                    acc.push(
                        <FilterChip
                            key={`${keyForChip}-${chip.key}-${chip.name}-${chip.code}`}
                            name={chip.name}
                            code={chip.code}
                            color={chip.color}
                            isActive={true}
                            onClick={handlerClick(chip)}
                            right={<Markup.IconClose />}
                        />
                    );
                }
                return acc;
            }, []),
        [keyForChip, list]
    );

    if (onClear && chips.length > 1) {
        chips.push(
            <FilterChip
                key={`${keyForChip}-clear-all`}
                name="Очистить всё"
                code="clear-all"
                isActive={true}
                onClick={onClear}
                right={<Markup.IconClose />}
            />
        );
    }

    return <Markup.FilterInfo>{chips}</Markup.FilterInfo>;
});
