import { makeAutoObservable, observable, runInAction } from "mobx";
import { Types } from "shared";
import {
    ActiveCooGroupState,
    ActiveTrafficLightState,
    ActiveDashboardState,
    ActiveItemState,
} from "./active-view-states";
import { getStateByEntityType } from "./view-state.utils";
import { EntityType, StateViewType } from "./view-state.types";
import { BooleanProperty } from "lib";

export class ViewState {
    public isTimelineExpanded = new BooleanProperty(false);
    private activeItem: ActiveItemState<StateViewType> = new ActiveDashboardState();

    constructor() {
        makeAutoObservable(this, { isTimelineExpanded: observable.ref });
    }

    public get isTimelineAllowedToView() {
        return !!this.activeItem.activeItemId || this.isCooGroupEditorView;
    }

    public get isTrafficLightDetailsView() {
        return this.activeItem instanceof ActiveTrafficLightState && this.activeItem.isDetailsView;
    }

    public get isTrafficLightListView() {
        return this.activeItem instanceof ActiveTrafficLightState && this.activeItem.isListView;
    }

    public get isCooGroupDetailsView() {
        return this.activeItem instanceof ActiveCooGroupState && this.activeItem.isDetailsView;
    }

    public get isCooGroupListView() {
        return this.activeItem instanceof ActiveCooGroupState && this.activeItem.isListView;
    }

    public get isCooGroupEditorView() {
        return this.activeItem instanceof ActiveCooGroupState && this.activeItem.isEditorView;
    }

    public get activeCooGroupId(): NullableNumber {
        if (this.activeItem instanceof ActiveCooGroupState) {
            return this.activeItem.activeItemId;
        }
        return null;
    }

    public get activeTrafficLightId(): NullableNumber {
        if (this.activeItem instanceof ActiveTrafficLightState) {
            return this.activeItem.activeItemId;
        }
        return null;
    }

    public setActiveTrafficLight(id: NullableNumber, viewType?: Types.ViewState.TrafficLightViewType) {
        this.setActiveItem<ActiveTrafficLightState, Types.ViewState.TrafficLightViewType>(
            id,
            EntityType.TrafficLight,
            viewType
        );
    }

    public setActiveCooGroup(id: NullableNumber, viewType?: Types.ViewState.CooGroupViewType) {
        this.setActiveItem<ActiveCooGroupState, Types.ViewState.CooGroupViewType>(id, EntityType.CooGroup, viewType);
    }

    public setActiveDashboard() {
        this.setActiveItem<ActiveDashboardState, Types.ViewState.DashboardViewType>(null, EntityType.Dashboard);
    }

    private setActiveItem<T extends ActiveItemState<V>, V extends StateViewType>(
        id: NullableNumber,
        entityType: EntityType,
        viewType?: V
    ) {
        const activeItem = entityType === this.activeItem.itemType ? this.activeItem : getStateByEntityType(entityType);

        runInAction(() => {
            this.activeItem = activeItem;
            this.activeItem.activeItemId = id;
            this.activeItem.viewType = viewType as T["viewType"];
        });
    }
}
