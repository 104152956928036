import React from "react";
import * as Units from "./units";
import * as Markup from "./detector-short-card.styles";
import type { Props } from "./detector-short-card.types";

export const useContent = (props: Props) => {
    const { error, isLoaded, data, status, graph, dataCypress } = props;
    const { onCloseClick, onShowClick, onFullscreenClick } = props;

    const content = (() => {
        try {
            if (error) {
                return <Units.Error dataCypress={dataCypress} message={error} onCloseClick={onCloseClick} />;
            }

            return (
                <>
                    <Markup.Header
                        isLoaded={isLoaded}
                        statusByIssueId={status.issueId}
                        statusByIssueName={status.issueName}
                        statusByMonitoringId={status.monitoringId}
                        statusByMonitoringName={status.monitoringName}
                        number={data?.number}
                        address={data?.address}
                        onShowClick={onShowClick}
                        onFullscreenClick={onFullscreenClick}
                        onCloseClick={onCloseClick}
                        dataCypress={dataCypress}
                    />
                    <Markup.Body
                        isLoaded={isLoaded}
                        indicators={data?.indicators}
                        graph={graph}
                        dataCypress={dataCypress}
                    />
                </>
            );
        } catch (err) {
            return (
                <Units.Error
                    dataCypress={dataCypress}
                    message="Произошла внутрення ошибка, сообщите пожалуйста в службу поддержки."
                    onCloseClick={onCloseClick}
                />
            );
        }
    })();

    return { content };
};

export const useHandleFocus = (props: Props) => {
    const { guid, onFocus } = props;

    const handleFocusClick = React.useCallback(() => {
        onFocus(guid);
    }, [guid, onFocus]);

    return { handleFocusClick };
};
