import React from "react";
import * as Units from "./units";
import type { Props } from "./players.types";

export const Players = React.memo((props: Props) => {
    if (props.streams.length) {
        return (
            <Units.Multiple
                uid={props.uid}
                streams={props.streams}
                dataCypress={props.dataCypress}
                getToken={props.getToken}
            />
        );
    }

    return (
        <Units.Single uid={props.uid} number={props.number} dataCypress={props.dataCypress} getToken={props.getToken} />
    );
});
