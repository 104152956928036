import { DetectorAPI, APIRegistry } from "api";
import { Mappers } from "shared";
import { IDetectorListService, LoadDetectorListMapFilterShape } from "./detectors-list.types";
import { Detector } from "app-domain";

export class DetectorListAdapter implements IDetectorListService {
    private detectorServiceAPI: DetectorAPI = APIRegistry.detectorAPI;
    private mapper = new Mappers.DetectorListMapper();

    public async loadDetectorListMap(filter?: LoadDetectorListMapFilterShape): Promise<Detector.IDetectorListMap> {
        const responce = await this.detectorServiceAPI.getDetectors(filter);

        return this.mapper.fromAPI(responce);
    }
}
