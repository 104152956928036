import { Popup } from "../popup";
import styled, { css } from "styled-components/macro";

export const Modal = styled(Popup)`
    & > * {
        padding: 0;
    }

    & > div {
        min-width: 324px;
    }
`;

export const Container = styled.div(
    ({ theme }) =>
        css`
            display: flex;
            flex-flow: column;
            gap: ${theme.spacings[3].str};
            padding: ${theme.spacings[5].str};

            h5 {
                word-wrap: break-word;
            }
        `
);

export const Controls = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        gap: ${theme.spacings[3].str};

        & > button {
            flex-grow: 1;
        }
    `
);
