import { memo, useCallback } from "react";
import { useCalendarContext } from "../../../calendar-context";
import * as Utils from "../../../calendar.utils";
import * as Markup from "./day.styles";

type Props = {
    value: Date;
};

export const Day = memo(({ value }: Props) => {
    const {
        dateState: { selectDate, selectedDate },
        viewState,
        weekends,
    } = useCalendarContext();

    const currentMonthIndex = viewState.date.getMonth();
    const isActive = value.toDateString() === selectedDate?.toDateString();

    const handleClick = useCallback(() => {
        selectDate(value);
    }, [value, selectDate]);

    const isSameMonthDay = currentMonthIndex === value.getMonth();
    const isToday = new Date().toDateString() === value.toDateString();
    const isWeekend = weekends?.has(value.toDateString()) || Utils.isWeekend(value);

    return (
        <Markup.Day
            isWeekend={isWeekend}
            isActive={isActive}
            isToday={isToday}
            isSameMonthDay={isSameMonthDay}
            onClick={handleClick}>
            {value.getDate()}
        </Markup.Day>
    );
});
