import { newSignal, BaseApi } from "api/utils";
import { QueryParamsBuilder } from "lib";
import * as Env from "env-data";
import type { GetAddressesByLngLatParams, GetAddressesByLngLatResponse } from "./geocode.types";
import { GetAddressesBySearchResponse } from "./geocode.types";

let abortController = new AbortController();

export class GeocodeAPI extends BaseApi {
    constructor() {
        super(`${Env.REACT_APP_API_GEOCODE}`);
    }
    public getAddressesBySearch = (search: string): GetAddressesBySearchResponse => {
        abortController.abort();
        abortController = new AbortController();

        const queryParamsBuilder = new QueryParamsBuilder();

        const signal = newSignal("getAddressesBySearch");
        if (signal?.aborted || !search?.length) return;

        const queryParams = queryParamsBuilder.addParam("address", search).addParam("limit", 10).toString();

        const url = this.buildUrl(`/suggest/?${queryParams}`);
        return this.request({ url, signal: abortController.signal });
    };

    public getAddressesByLngLat = (params: GetAddressesByLngLatParams): GetAddressesByLngLatResponse => {
        const { lon, lat } = params;
        const queryParamsBuilder = new QueryParamsBuilder();

        const queryParams = queryParamsBuilder
            .addParam("longitude", lon)
            .addParam("latitude", lat)
            .addParam("limit", 1)
            .toString();

        const url = this.buildUrl(`/near?${queryParams}`);
        return this.request({ url });
    };
}
