import styled, { css } from "styled-components";
import { ControlButton } from "../components/control-button";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Display = styled(ControlButton)(
    ({ theme }) => css`
        font: ${theme.typography.h6};
        color: ${theme.colors.content.primary};
        text-transform: capitalize;
    `
);
