import { CooGroupDomain } from "app-domain";
import { Manage, APIRegistry, State, APITypes } from "api";
import { ICooGroupRemoteControlsService } from "./coo-group-remote-controls.types";

export class CooGroupRemoteControlsAdapter implements ICooGroupRemoteControlsService {
    private manageAPI: Manage.ManageAPI = APIRegistry.manageAPI;
    private stateAPI: State.StateAPI = APIRegistry.stateAPI;

    public async createControlGovernance(cooGroupId: number): Promise<CooGroupDomain.Governance> {
        const data = await this.manageAPI.createCooGroupGovernance(cooGroupId);
        return this.mapGovernanceData(data);
    }

    public async getCooGroupGovernance(cooGroupId: number): Promise<Nullable<CooGroupDomain.Governance>> {
        const data = await this.stateAPI.getCooGroupGovernance(cooGroupId);
        return data ? this.mapGovernanceData(data) : null;
    }

    public async removeControlGovernance(cooGroupId: number): Promise<void> {
        return this.manageAPI.removeCooGroupGovernance(cooGroupId);
    }

    public async getCurrentUserGovernanceList(): Promise<CooGroupDomain.Governance[]> {
        const list = await this.stateAPI.getCurrentUserCooGroupGovernanceList();
        return list.map(this.mapGovernanceData);
    }

    private mapGovernanceData(data: APITypes.CooGroupGovernance) {
        const governance = new CooGroupDomain.Governance(data.id, data.cooGroupId, data.cycleType);
        const holdExpireDate = data.holdExpireSeconds
            ? new Date(Date.now() + data.holdExpireSeconds * 1000)
            : data.holdExpireDate
            ? new Date(data.holdExpireDate)
            : void 0;
        const isValid = holdExpireDate && holdExpireDate.valueOf() > new Date().valueOf();

        governance.setData({
            ...data,
            start: data.start && isValid ? new Date(data.start) : void 0,
            finish: data.finish && isValid ? new Date(data.finish) : void 0,
            holdExpireDate: data.holdExpireDate && isValid ? new Date(data.holdExpireDate) : void 0,
            commandStart: data.commandStart && isValid ? new Date(data.commandStart) : void 0,
        });
        return governance;
    }
}
