import { APIRegistry } from "api";
import { TVCamera } from "app-domain";
import { Mappers } from "shared";
import { ITVCameraListService, LoadTVCameraListFilterShape } from "./tv-camera-list.types";

export class TVCameraListAdapter implements ITVCameraListService {
    private tvCameraAPI = APIRegistry.tvCameraAPI;
    private mapper = new Mappers.TVCameraListMapper();

    public async loadTVCameraListMap(filter?: LoadTVCameraListFilterShape): Promise<TVCamera.ITVCameraListMap> {
        const responce = await this.tvCameraAPI.getCameras(filter);

        return this.mapper.fromAPI(responce);
    }
}
