import { makeAutoObservable, runInAction } from "mobx";
import { DashboardService } from "../dashboard-service";

export class DashboardStore implements Store.Dashboard.Interface {
    public isLoading: boolean = true;

    public trafficLights: Store.Dashboard.TrafficLights;

    public coordinationGroups: Store.Dashboard.CoordinationGroups;

    public cameras: Store.Dashboard.Cameras;

    private readonly dashboardService: DashboardService;

    constructor(dashboardService: DashboardService) {
        this.dashboardService = dashboardService;
        const emptyData = this.dashboardService.empty();
        this.trafficLights = emptyData.trafficLights;
        this.coordinationGroups = emptyData.coordinationGroups;
        this.cameras = emptyData.cameras;
        makeAutoObservable(this);
    }

    public loadOrReloadData = async () => {
        const result = await this.dashboardService.summary();

        runInAction(() => {
            this.trafficLights = result.trafficLights;
            this.coordinationGroups = result.coordinationGroups;
            this.cameras = result.cameras;
            this.isLoading = false;
            this.sendNotificationEvent();
        });
    };

    private get state(): Store.Dashboard.Interface {
        return {
            trafficLights: this.trafficLights,
            coordinationGroups: this.coordinationGroups,
            cameras: this.cameras,
            isLoading: this.isLoading,
        };
    }

    private sendNotificationEvent = () => {
        window.dispatchEvent(
            new CustomEvent<Store.Dashboard.Interface>("cypress__dashboard__loaded", {
                detail: this.state,
            })
        );
    };
}
