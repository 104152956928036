import styled, { css, CSSProperties } from "styled-components/macro";

export type FlexBoxProps = {
    direction?: CSSProperties["flexDirection"];
    gap?: number;
    grow?: number;
    overflow?: CSSProperties["overflow"];
    justifyContent?: CSSProperties["justifyContent"];
    justifyItems?: CSSProperties["justifyItems"];
    justifySelf?: CSSProperties["justifySelf"];
    alignContent?: CSSProperties["alignContent"];
    alignItems?: CSSProperties["alignItems"];
    alignSelf?: CSSProperties["alignSelf"];
    wrap?: CSSProperties["flexWrap"];
    padding?: string;
    margin?: string;
    width?: CSSProperties["width"];
};

export const FlexBox = styled.div<FlexBoxProps>((props) => {
    return css`
        display: flex;
        flex-direction: ${props.direction};
        flex-wrap: ${props.wrap};
        gap: ${props.gap}px;
        flex-grow: ${props.grow};
        overflow: ${props.overflow ?? "visible"};
        justify-content: ${props.justifyContent};
        justify-items: ${props.justifyItems};
        justify-self: ${props.justifySelf};
        align-content: ${props.alignContent};
        align-items: ${props.alignItems};
        align-self: ${props.alignSelf};
        padding: ${props.padding};
        margin: ${props.margin};
        width: ${props.width ?? "auto"};
    `;
});
