import { BaseApi } from "../../utils";

export class InfrastructureAPI extends BaseApi {
    public getWorkCalendar(year: number): Promise<DateTimeString[]> {
        const url = this.buildUrl(`/schedule/workcalendar?year=${year}`);
        return this.request({ url });
    }

    protected buildUrl(endpoint: string): string {
        return super.buildUrl(`/infrastructure${endpoint}`);
    }
}
