import { makeDrawer, defaultFont } from "../drawing.common";
import { CommonDrawProps } from "../drawing.types";

export interface DrawTextProps extends CommonDrawProps {
    text: string;
    fillColor?: string;
    strokeColor?: string;
    strokeWidth?: number;
    font?: string;
    align?: CanvasTextAlign;
    baseline?: CanvasTextBaseline;
}

export const drawText = makeDrawer<DrawTextProps>((ctx, props) => {
    const {
        x,
        y,
        text,
        font = defaultFont,
        fillColor = "#000",
        strokeColor,
        strokeWidth,
        align = "start",
        baseline = "alphabetic",
    } = props;
    ctx.font = font;
    ctx.textAlign = align;
    ctx.textBaseline = baseline;
    if (strokeColor || strokeWidth) {
        ctx.strokeStyle = strokeColor ?? "#000";
        ctx.lineWidth = strokeWidth ?? 1.0;
        ctx.strokeText(text, x, y);
    }
    ctx.fillStyle = fillColor;
    ctx.fillText(text, x, y);
});
