import { ReactNode, forwardRef, ForwardedRef } from "react";
import * as Markup from "./map-control.styles";

type Props = {
    children: ReactNode;
};

export const MapControlComponent = ({ children }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return <Markup.Wrapper ref={ref}>{children}</Markup.Wrapper>;
};

export const MapControl = forwardRef(MapControlComponent);
