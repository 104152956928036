import mapboxgl from "mapbox-gl";
import * as env from "env-data";
import { BooleanStorageManager } from "lib";
import * as units from "./units";
import type { Options, VisualizationFactory } from "./visualization-equipment-on-map.types";

export class VisualizationEquipmentOnMap {
    public readonly options: Options;
    public readonly emitter = new units.Emitter();
    private readonly map: mapboxgl.Map;
    private readonly handleOpenShortCard?: (args: SourceEquipmentCardArgs) => void;
    private visualization: Visualization;
    private booleanStorageManager: BooleanStorageManager;

    constructor(map: mapboxgl.Map, options: Options, visualizationFactory: VisualizationFactory) {
        this.map = map;
        this.options = options;
        this.handleOpenShortCard = options.onFeatureClick;
        this.booleanStorageManager = new BooleanStorageManager(options.storageKey);

        this.visualization = visualizationFactory(map, {
            id: this.id,
            iconPrefix: this.options.iconPath,
            initialLayoutVisibility: this.layoutVisibilityProperty(this.booleanStorageManager.value),
        });
        this.subscribeOnEvents();
        this.subscribeOnMapEvents();
        this.subscribeOnStyleMissing();
        this.subscribeOnSourceDataLoading();
    }

    public get id() {
        return this.options.id;
    }

    public destroy = () => {
        this.unsubscribeOnEvents();
        this.unSubscribeOnMapEvents();
        this.unSubscribeOnStyleMissing();
        this.unSubscribeOnSourceDataLoading();
        this.visualization.destroy();
    };

    public readonly setFilteredId = (ids: number[]) => {
        if (!ids.length) return this.map.setFilter(this.id);

        this.map.setFilter(this.id, ["match", ["get", "Id"], ids, false, true]);
    };

    public getVisibility = () => this.booleanStorageManager.value;

    public setVisibility = (visibility: boolean) => {
        this.booleanStorageManager.value = visibility;
        this.map.setLayoutProperty(this.id, "visibility", this.layoutVisibilityProperty(visibility));

        if (!this.options.cypressVisibilityChange) return;
        window.dispatchEvent(new CustomEvent(this.options.cypressVisibilityChange, { detail: visibility }));
    };

    private layoutVisibilityProperty(visibility: boolean): mapboxgl.Visibility {
        return visibility ? "visible" : "none";
    }

    /* #region  Открытие маленькой карточки */
    private readonly handleClick = (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const features = this.map.queryRenderedFeatures(event.point, { layers: [this.id] });
        const feature = features.pop() as any;

        if (feature) {
            try {
                this.handleOpenShortCard?.({
                    options: {
                        uid: feature.properties.Id,
                        coordinate: { lat: +feature.properties.Lat, lng: +feature.properties.Lng },
                        statusOnIssues: feature.properties.AssetStatusId,
                        statusOnMonitoring: feature.properties.MonitoringStatusId,
                    },
                    defaultPosition: {
                        pageX: event.originalEvent.pageX,
                        pageY: event.originalEvent.pageY,
                    },
                });
            } catch (error) {
                console.error(error);
            }
        }
    };

    private subscribeOnEvents = () => {
        this.map.on("click", this.handleClick);
    };

    private unsubscribeOnEvents = () => {
        this.map.off("click", this.handleClick);
    };
    /* #endregion */

    /* #region  Hover */
    private subscribeOnMapEvents = () => {
        this.map.on("mousemove", this.handleMouseMove);
    };

    private unSubscribeOnMapEvents = () => {
        this.map.off("mousemove", this.handleMouseMove);
    };

    private cacheHover = false;

    /** Событие мыши для смены курсора
     *  Может использоваться с разными событиями
     */
    private handleMouseMove = (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        if (!this.map.getLayer(this.id)) return;

        const isHover = !!this.map.queryRenderedFeatures(event.point, { layers: [this.id] }).length;

        if (isHover) {
            this.cacheHover = true;
            this.map.getCanvas().style.cursor = "pointer";
            return;
        }

        if (!this.cacheHover) return;

        this.cacheHover = false;
        this.map.getCanvas().style.cursor = "default";
    };

    /* #endregion */
    /* #region Cypress уведомление о загрузке данных */
    private handleSourceDataLoading = (event: mapboxgl.MapSourceDataEvent) => {
        if (!this.options.cypressSourceDataLoaded) return;
        if (this.id !== event.sourceId) return;
        window.dispatchEvent(new CustomEvent(this.options.cypressSourceDataLoaded, { detail: event }));
    };

    private subscribeOnSourceDataLoading() {
        this.map.on("data", this.handleSourceDataLoading);
    }

    private unSubscribeOnSourceDataLoading() {
        this.map.on("data", this.handleSourceDataLoading);
    }
    /* #endregion */

    /* #region  Подгруздка иконок для карты */
    private checkIconPrefix = (icon: string) => {
        if (!this.options.iconPath) return false;
        return icon.startsWith(this.options.iconPath);
    };

    private handleAddImageOnMap = (id: string) => (error: string, imageBitmap: ImageBitmap) => {
        if (error) return console.error(error);

        if (this.map.hasImage(id)) return;

        this.map.addImage(id, imageBitmap);
    };

    private handleStyleImageMissing = (event: mapboxgl.EventData) => {
        const id = event?.id;

        if (typeof id !== "string") return;

        if (!this.checkIconPrefix(id)) return;

        if (this.map.hasImage(id)) return;

        this.map.loadImage(`${env.REACT_APP_CDN_FILES}/${id}.png`, this.handleAddImageOnMap(id));
    };

    private subscribeOnStyleMissing() {
        this.map.on("styleimagemissing", this.handleStyleImageMissing);
    }

    private unSubscribeOnStyleMissing() {
        this.map.on("styleimagemissing", this.handleStyleImageMissing);
    }
    /* #endregion */
}
