import styled from "styled-components";
import { Typography } from "@megapolis/uikit/typography";

const widthContainer = "196px";
const paddingContainer = "8px";

export const Wrapper = styled.div`
    padding: 0 ${({ theme }) => theme.spacings[1].str};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings[3].str};
    width: ${widthContainer};
    padding: ${paddingContainer};
    border: 1px solid ${({ theme }) => theme.colors.content.quinary};
    border-radius: 8px;
`;

export const Title = styled(Typography.Text1).attrs({ as: "div" })`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: ${`calc(${widthContainer} - 2 * ${paddingContainer} - 24px) 24px`};
`;

export const Body = styled.div`
    .video-player {
        width: 180px;
        height: 126px;
    }
`;
