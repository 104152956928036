import { useMemo, useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { TrafficLightsURLParams, TrafficLightDetailsURLParams } from "./traffic-lights.types";
import { TrafficLightURLTabParam } from "./traffic-lights.enums";
import * as Paths from "./traffic-lights.paths";

const trafficLightsDefaultParams: TrafficLightsURLParams = {
    type: "traffic-lights",
};

export const useTrafficLightURLParams = () => {
    const match = useRouteMatch<TrafficLightsURLParams>(Paths.TRAFFIC_LIGHT_ROUTE_MATCHER);

    return useMemo(() => match?.params ?? trafficLightsDefaultParams, [match]);
};

const trafficLightDetailsDefaults: TrafficLightDetailsURLParams = {
    id: "",
    type: "traffic-light",
    tab: TrafficLightURLTabParam.info,
};

export const useTrafficLightDetailsURLParams = () => {
    const match = useRouteMatch<TrafficLightDetailsURLParams>(Paths.TRAFFIC_LIGHT_DETAILS_ROUTE_PATH);
    return useMemo(() => (match ? { ...trafficLightDetailsDefaults, ...match.params } : trafficLightDetailsDefaults), [
        match,
    ]);
};

export const useTrafficLightId = () => {
    const params = useTrafficLightURLParams();

    return useMemo(() => {
        return params.type === "traffic-lights" ? null : Number(params.id);
    }, [params]);
};

export const useTrafficLightNavigator = () => {
    const {
        push,
        location: { hash },
    } = useHistory();

    return useCallback(
        (params: TrafficLightsURLParams) => {
            const basePath = `/${params.type}`;
            if (params.type === "traffic-lights") {
                return push({ pathname: `${basePath}/${params.id ?? ""}`, hash });
            }
            const path = `${basePath}/${params.id}`;
            return push({
                pathname: params.tab ? `${path}/${params.tab}` : path,
                hash,
            });
        },
        [push, hash]
    );
};

export const useTrafficLightDetailsNavigator = () => {
    const navigate = useTrafficLightNavigator();

    return useCallback(
        (id?: string | number) => {
            navigate({ id: String(id), type: "traffic-light" });
        },
        [navigate]
    );
};

export const useTrafficLightListNavigator = () => {
    const navigate = useTrafficLightNavigator();

    return useCallback(() => {
        navigate({ type: "traffic-lights" });
    }, [navigate]);
};
