import { createContext, useState, useEffect, useContext } from "react";

export const DEFAULT_LOCALE = new Intl.Locale(globalThis.navigator.language);

export const useInitLocale = (localeProp?: string | Intl.Locale): Intl.Locale => {
    const [locale, setLocale] = useState<Intl.Locale>(DEFAULT_LOCALE);

    useEffect(() => {
        if (!localeProp) return;
        if (localeProp instanceof Intl.Locale) {
            return setLocale(localeProp);
        }
        return setLocale(new Intl.Locale(localeProp));
    }, [localeProp]);

    return locale;
};

export const LocaleContext = createContext<Intl.Locale>(DEFAULT_LOCALE);

export const useLocale = () => {
    return useContext(LocaleContext);
};
