export enum ControlModeCode {
    /** Контроль выполняется по программе из паспорта */
    LocalPlan = 0,
    /** Контроль выполняется через рекомендации */
    Adaptive = 1,
    /** Контроль запрошен из группы координации */
    Group = 2,
    /** Контроль выполняется через запрос на управление человеком */
    User = 3,
    /** Контроль выполняется человеком с пульта светофора */
    Console = 4,
    /** Нет данных */
    Unknown = 100,
}
