import { ReactNode, useContext, memo } from "react";
import { Icons } from "@megapolis/uikit/icons";
import { AccordionContext } from "../accordion.context";
import * as Markup from "./accordion-header.styles";

export type AccordionHeaderProps = {
    /** Заголовок */
    title: ReactNode;
    /** Подзаголовок(счетчики и тп) */
    subTitle?: ReactNode;
    /**
     * Доп.контент заголовка
     * @deprecated используйте children */
    description?: ReactNode;
    /** Дополнительный контент заголовка */
    children?: ReactNode;
    className?: string;
};

const AccordionHeaderComponent = (props: AccordionHeaderProps) => {
    const { isExpanded, onToggle, isDisabled } = useContext(AccordionContext);
    const { title, subTitle, description, className, children } = props;

    const descriptionContent = description || children;

    return (
        <Markup.Container className={className} onClick={onToggle} isDisabled={isDisabled}>
            <Markup.TitleControl>
                <Markup.TitleSubTitleWrapper>
                    <Markup.TitleText>{title}</Markup.TitleText>
                    {subTitle && <Markup.SubTitle>{subTitle}</Markup.SubTitle>}
                </Markup.TitleSubTitleWrapper>
                <Markup.ExpansionIcon isExpanded={isExpanded}>
                    <Icons.Monochrome.ChevronRightS />
                </Markup.ExpansionIcon>
            </Markup.TitleControl>
            {descriptionContent && <Markup.Description>{descriptionContent}</Markup.Description>}
        </Markup.Container>
    );
};

export const AccordionHeader = memo(AccordionHeaderComponent);
