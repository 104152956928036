import type { Type } from "./factory-cameras-data.types";

export const caption = "КТО";

export const info = Object.freeze({
    type: Object.freeze({
        success: "success" as Type,
        warning: "warning" as Type,
        danger: "danger" as Type,
        disable: "disable" as Type,
    }),
    caption: Object.freeze({
        success: "Работает",
        warning: "Работает с замечаниями",
        danger: "Критические ошибки",
        disable: "Нет связи",
    }),
});
