import { useContext, useEffect } from "react";
import { CooGroupContext } from "./context";
import { Hooks } from "shared";

export const useCooGroupData = () => {
    return useContext(CooGroupContext);
};

export const useCanBeControlled = (trackUpdates = false) => {
    const { trafficLights } = useCooGroupData();
    const forceUpdate = Hooks.useForceUpdate();

    useEffect(() => {
        if (!trackUpdates) return;
        for (const trafficLight of trafficLights) {
            trafficLight.on("controlModeChanged", forceUpdate);
            trafficLight.on("statusChanged", forceUpdate);
            trafficLight.on("errorStatusChanged", forceUpdate);
            trafficLight.on("conditionChanged", forceUpdate);
            trafficLight.on("governanceChanged", forceUpdate);
        }
        return () => {
            for (const trafficLight of trafficLights) {
                trafficLight.off("controlModeChanged", forceUpdate);
                trafficLight.off("statusChanged", forceUpdate);
                trafficLight.off("errorStatusChanged", forceUpdate);
                trafficLight.off("conditionChanged", forceUpdate);
                trafficLight.off("governanceChanged", forceUpdate);
            }
        };
    }, [trafficLights, forceUpdate, trackUpdates]);

    return trafficLights.some((trafficLight) => !trafficLight.isCoordinationControllable);
};
