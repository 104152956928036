import { useState, useEffect, useCallback } from "react";
import * as Utils from "./calendar.utils";
import { ViewType, DateState, DateChangeHandler, ViewDateChangeHandler, ViewState } from "./calendar.types";

export const DEFAULT_VIEW = ViewType.Month;

export const useViewState = (initialDate?: Date | null, onViewDateChange?: ViewDateChangeHandler): ViewState => {
    const [view, setView] = useState(DEFAULT_VIEW);
    const [viewDate, setViewDate] = useState(initialDate ?? new Date());

    const updateViewDate = useCallback(
        (date: Date) => {
            setViewDate(date);
            onViewDateChange?.(date);
        },
        [onViewDateChange]
    );

    useEffect(() => {
        if (!initialDate) return;
        setViewDate(initialDate);
    }, [initialDate]);

    const setMonthView = useCallback(() => setView(ViewType.Month), []);
    const setYearView = useCallback(() => setView(ViewType.Year), []);

    const setPrevMonth = useCallback(() => {
        const newDate = Utils.getPrevMonth(viewDate);
        updateViewDate(newDate);
    }, [viewDate, updateViewDate]);

    const setNextMonth = useCallback(() => {
        const newDate = Utils.getNextMonth(viewDate);
        updateViewDate(newDate);
    }, [viewDate, updateViewDate]);

    const setNextYear = useCallback(() => {
        const newDate = new Date(viewDate);
        newDate.setFullYear(newDate.getFullYear() + 1);
        updateViewDate(newDate);
    }, [viewDate, updateViewDate]);

    const setPrevYear = useCallback(() => {
        const newDate = new Date(viewDate);
        newDate.setFullYear(newDate.getFullYear() - 1);
        updateViewDate(newDate);
    }, [viewDate, updateViewDate]);

    const setCurrentMonth = useCallback(
        (month: Date) => {
            updateViewDate(month);
        },
        [updateViewDate]
    );

    return {
        view,
        date: viewDate,
        setMonthView,
        setYearView,
        setPrevMonth,
        setNextMonth,
        setPrevYear,
        setNextYear,
        setCurrentMonth,
    };
};

export const useDateState = (initialDate?: Date | null, onChange?: DateChangeHandler): DateState => {
    const [selectedDate, setSelectedDate] = useState(initialDate || null);

    useEffect(() => {
        if (!initialDate) return setSelectedDate(null);
        setSelectedDate(initialDate);
    }, [initialDate]);

    const selectDate = useCallback(
        (date: Date) => {
            onChange?.(date);
        },
        [onChange]
    );

    return {
        selectedDate,
        selectDate,
    };
};
