import { memo, useMemo } from "react";
import { createImageSrc } from "./map-fragment.utils";
import { MapFragmentProps } from "./map-fragment.types";

const MapFragmentComponent = (props: MapFragmentProps) => {
    const options = useMemo(
        () => ({
            ...props,
            size: props.size || 125,
        }),
        [props]
    );

    const src = useMemo(() => createImageSrc(options), [options]);

    return <img src={src} alt={props.alt} width={options.size} height={options.size} />;
};

export const MapFragment = memo(MapFragmentComponent);
