import styled, { css } from "styled-components";
import { Select as SelectUiKit } from "@megapolis/uikit/select/select";
import { Typography as TypographyUiKit } from "@megapolis/uikit/typography";

export const Container = styled.div(({ theme }) => {
    const spacings = theme.spacings;
    return css`
        display: flex;
        gap: ${spacings[5].str};
        justify-content: flex-end;
        width: calc(100% - 120px);
        height: 32px;
        padding: ${spacings[1].str} 0;
    `;
});

export const Select = styled(SelectUiKit)`
    &,
    > div:first-child {
        height: 24px;
        min-height: 24px;
        max-height: 24px;
        padding-top: 0;
        padding-bottom: 0;
        > span {
            align-items: center;
        }
    }
    svg {
        width: 16px;
        height: 16px;
    }
`;

export const Typography = styled(TypographyUiKit.Text1)`
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.content.secondary};
    font-size: 11px;
`;

export const InputWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;
