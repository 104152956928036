import { createContext, useContext } from "react";
import { ViewState, DateState } from "./calendar.types";

export type CalendarContextValue = {
    dateState: DateState;
    viewState: ViewState;
    weekends?: Map<string, true>;
};

export const CalendarContext = createContext({} as CalendarContextValue);

export const useCalendarContext = () => {
    return useContext(CalendarContext);
};
