import { Detector } from "app-domain";

export class MetaDTO implements Detector.Models.FullCard.Meta {
    number = "";
    address = "";

    constructor(data?: any) {
        if (typeof data?.num === "string") this.number = data.num;

        if (data && Array.isArray(data.addresses) && data?.addresses.length) {
            this.address = data?.addresses
                .reduce((acc: string[], address: any) => {
                    if (typeof address.address === "string") {
                        acc.push(address.address);
                    }

                    return acc;
                }, [])
                .join(", ");
        }
    }
}
