import { ShortCardList } from "lib";
import { Detector } from "app-domain";
import { Interfaces } from "interfaces";
import * as Units from "./units";

export class Detectors extends ShortCardList {
    public collection: Store.ListShortCardOnWarehouseDetectorUnitsItem.Instance[] = [];

    constructor(detectorService: Detector.Services.ShortCard) {
        super(
            (args: SourceEquipmentCardArgs, handlers: Interfaces.Store.ListShortCard.Handlers) =>
                new Units.Item(detectorService, args, handlers)
        );
    }
}
