import React from "react";
import * as Markup from "./over-speed.styles";
import type { Props } from "./over-speed.types";

export const OverSpeed = React.memo((props: Props) => {
    const { isLoaded, overSpeed, dataCypress } = props;

    const content = React.useMemo(() => {
        const _dataCypress = isLoaded ? `${dataCypress}_value` : `${dataCypress}_placeholder`;

        if (isLoaded) {
            return <Markup.Value data-cypress={_dataCypress}>{overSpeed} км/ч</Markup.Value>;
        }

        return <Markup.Placeholder data-cypress={_dataCypress}>00 км/ч</Markup.Placeholder>;
    }, [isLoaded, overSpeed, dataCypress]);

    return (
        <Markup.Container className={props.className} data-cypress={dataCypress}>
            <Markup.Caption>Разрешенная скорость</Markup.Caption>
            {content}
        </Markup.Container>
    );
});
