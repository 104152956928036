import { GridTimeDrawer, Drawer } from "diagrams/drawers";
import { EmptyPartData } from "./empty-part.types";

export class EmptyPartDrawer extends Drawer<EmptyPartData> {
    protected draw = () => {
        if (!this.data) return;
        this.ctx.fillStyle = this.data.fillStyle;
        this.ctx.fillRect(this.data.x, this.data.forwardY, this.data.width, this.data.height);

        GridTimeDrawer.draw(this.ctx, {
            x: 0,
            y: this.data.forwardY,
            width: this.data.width,
            height: this.data.height,
            pixelPerSecond: this.data.pixelsPerSecond,
        });

        if (this.data.hasBackwardDirection) {
            this.ctx.fillRect(this.data.x, this.data.backwardY ?? 0, this.data.width, this.data.height);

            GridTimeDrawer.draw(this.ctx, {
                x: 0,
                y: this.data.backwardY ?? 0,
                width: this.data.width,
                height: this.data.height,
                pixelPerSecond: this.data.pixelsPerSecond,
            });
        }
    };
}
