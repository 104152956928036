import React from "react";
import * as Markup from "./placeholder.styles";
import type { Props } from "./placeholder.types";

export const Placeholder = (props: React.PropsWithChildren<Props>) => {
    return (
        <Markup.Placeholder
            $width={props.width}
            $height={props.height}
            borderRadius={props.borderRadius}
            className={props.className}
            data-cypress={props["data-cypress"]}>
            {props.children}
        </Markup.Placeholder>
    );
};
