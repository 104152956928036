import mapboxgl from "mapbox-gl";
import { MapScale, MapCenter } from "./traffic-light-layer";

const offsetScale = 0.14;

export interface TrackLabel {
    x: number;
    y: number;
    label: string;
}

export interface TrackAppearance {
    transportColor: string;
    pedestrianColor: string;
    urbantransColor: string;
    lineWidth: number;
    arrowSize: number;
    fontSize: number;
    textStroke: boolean;
}

function drawCorner(
    context: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    x3: number,
    y3: number,
    radius: number
) {
    let l = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    let r = l < radius ? l : radius;
    let sin = (x2 - x1) / l;
    let cos = (y2 - y1) / l;
    const dx1 = r * sin;
    const dy1 = r * cos;

    l = Math.sqrt(Math.pow(x3 - x2, 2) + Math.pow(y3 - y2, 2));
    r = l < radius ? l : radius;
    sin = (x3 - x2) / l;
    cos = (y3 - y2) / l;
    const dx2 = r * sin;
    const dy2 = r * cos;

    context.lineTo(x2 - dx1, y2 - dy1);
    context.quadraticCurveTo(x2, y2, x2 + dx2, y2 + dy2);
    return { x: x2 + dx2, y: y2 + dy2 };
}
type TPoint = {
    x: number;
    y: number;
};

export default class Paint {
    static drawRoundedPath(ctx: CanvasRenderingContext2D, points: TPoint[], radius: number) {
        let prevPoint;
        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            if (i === 0) {
                prevPoint = point;
                ctx.moveTo(point.x, point.y);
            } else if (i === points.length - 1) {
                ctx.lineTo(point.x, point.y);
            } else if (i < points.length - 1 && prevPoint) {
                const nextPoint = points[i + 1];
                prevPoint = drawCorner(
                    ctx,
                    prevPoint.x,
                    prevPoint.y,
                    point.x,
                    point.y,
                    nextPoint.x,
                    nextPoint.y,
                    radius
                );
            }
        }
    }

    static drawArrowHead(ctx: CanvasRenderingContext2D, points: TPoint[], size: number, angleDegr: number) {
        const pointsCount = points.length;
        if (pointsCount < 2) return;

        let p1 = points[pointsCount - 2];
        const p2 = points[pointsCount - 1];
        const angle = (angleDegr * Math.PI) / 180;
        let x1 = p1.x;
        let x2 = p2.x;
        let y1 = p1.y;
        let y2 = p2.y;
        let l = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

        if (l < 10 && pointsCount > 3) {
            p1 = points[pointsCount - 3];
            x1 = p1.x;
            y1 = p1.y;
        }

        l = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

        const r = size / 2;
        const sin = (x2 - x1) / l;
        const cos = (y2 - y1) / l;
        const dx = r * sin;
        const dy = r * cos;

        x2 = x2 + dx;
        y2 = y2 + dy;

        const lineangle = Math.atan2(y2 - y1, x2 - x1);
        const h = Math.abs(size / Math.cos(angle));
        const angle1 = lineangle + Math.PI + angle;
        const topx = x2 + Math.cos(angle1) * h;
        const topy = y2 + Math.sin(angle1) * h;
        const angle2 = lineangle + Math.PI - angle;
        const botx = x2 + Math.cos(angle2) * h;
        const boty = y2 + Math.sin(angle2) * h;
        const x3 = x2 - Math.cos(lineangle) * size * 0.7;
        const y3 = y2 - Math.sin(lineangle) * size * 0.7;

        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(x2, y2);
        ctx.lineTo(topx, topy);
        // ctx.lineTo(botx, boty);
        ctx.quadraticCurveTo(x3, y3, botx, boty);
        ctx.lineTo(x2, y2);
        ctx.fill();
        ctx.stroke();
    }

    static setDpi(canvas: HTMLCanvasElement) {
        if (!canvas) return null;
        const width = canvas.offsetWidth;
        const height = canvas.offsetHeight;
        const ctx = canvas.getContext("2d");
        const ratio = window.devicePixelRatio || 1;
        canvas.width = width * ratio;
        canvas.height = height * ratio;
        ctx?.scale(ratio, ratio);
        return ctx;
    }

    static getTrackPoints(track: any, directionType: any, center: any) {
        const points = [];
        if (track.lineData) {
            const lineData = JSON.parse(track.lineData);
            if (directionType === 2 && !Array.isArray(lineData)) {
                let p = mapboxgl.MercatorCoordinate.fromLngLat(lineData.start);
                let x = (p.x - MapCenter.x) / MapScale - center.x + track.displayOffsetX * offsetScale;
                let y = (p.y - MapCenter.y) / MapScale + center.y + track.displayOffsetY * offsetScale;
                points.push({ x: x, y: y });

                p = mapboxgl.MercatorCoordinate.fromLngLat(lineData.end);
                x = (p.x - MapCenter.x) / MapScale - center.x + track.displayOffsetX * offsetScale;
                y = (p.y - MapCenter.y) / MapScale + center.y + track.displayOffsetY * offsetScale;
                points.push({ x: x, y: y });
            } else {
                if (lineData.length > 0) {
                    for (let i = 0, len = lineData.length; i < len; i++) {
                        const p = mapboxgl.MercatorCoordinate.fromLngLat(lineData[i]);
                        const x = (p.x - MapCenter.x) / MapScale - center.x + track.displayOffsetX * offsetScale;
                        const y = (p.y - MapCenter.y) / MapScale + center.y + track.displayOffsetY * offsetScale;
                        points.push({ x: x, y: y });
                    }
                }
            }
        }
        return points;
    }

    static drawTrack(
        ctx: CanvasRenderingContext2D,
        appearence: TrackAppearance,
        directionType: number,
        points: TPoint[],
        size: number,
        radius: number,
        label?: TrackLabel
    ) {
        const scale = size / 1024;
        ctx.lineWidth = appearence.lineWidth * scale;
        const arrowSize = appearence.arrowSize * scale;

        let color: string;
        switch (directionType) {
            case 2:
                color = appearence.pedestrianColor;
                break;
            case 4:
                color = appearence.urbantransColor;
                break;
            default:
                color = appearence.transportColor;
                break;
        }

        ctx.strokeStyle = color;
        ctx.fillStyle = color;

        if (directionType === 2) {
            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);
            ctx.lineTo(points[1].x, points[1].y);
            ctx.stroke();
            ctx.lineWidth = 0.5;
            Paint.drawArrowHead(ctx, points, arrowSize, 16);
            Paint.drawArrowHead(ctx, points.reverse(), arrowSize, 16);
        } else {
            ctx.lineCap = "round";
            ctx.beginPath();
            Paint.drawRoundedPath(ctx, points, radius * scale);
            ctx.stroke();
            ctx.lineWidth = 0.5;
            Paint.drawArrowHead(ctx, points, arrowSize, 16);
        }

        if (label) {
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.font = `${appearence.fontSize * scale}px Arial`;
            if (appearence.textStroke) {
                ctx.lineWidth = 2;
                ctx.strokeText(label.label, label.x, label.y);
            } else {
                ctx.fillText(label.label, label.x, label.y);
            }
        }
    }
}
