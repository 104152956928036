import { Drawer } from "diagrams/drawers";
import { DirectionLabelData, DirectionIconsMap } from "./direction-label-drawer.types";
import { getTrafficLightDirectionIcons } from "features/timeline/icons/icons";

export class DirectionLabelDrawer extends Drawer<DirectionLabelData> {
    private directionIcons: DirectionIconsMap = getTrafficLightDirectionIcons();

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
    }

    protected draw = () => {
        if (!this.data) return;

        if (this.data.directionType !== undefined) {
            const image = this.directionIcons[this.data.directionType];
            if (image) {
                if (this.data.hasBackwardDirection) {
                    this.ctx.drawImage(image, 12 - image.width / 2, this.data.backwardY - image.height / 2);
                }
                this.ctx.drawImage(image, 12 - image.width / 2, this.data.forwardY - image.height / 2);
            }
        }

        this.ctx.save();
        this.ctx.textAlign = "left";
        this.ctx.textBaseline = "middle";
        this.ctx.font = this.data.font;
        this.ctx.fillStyle = this.data.fillStyle;
        if (this.data.hasBackwardDirection) {
            this.ctx.fillText(this.data.text, this.data.x, this.data.backwardY);
        }
        this.ctx.fillText(this.data.text, this.data.x, this.data.forwardY);
        this.ctx.restore();
    };
}
