import { REACT_APP_API_SERVICE_DTM_TRAFFIC, REACT_APP_API_SERVICE_WAREHOUSE } from "env-data";

export const transformRequest = (url: string, accessToken: string) => {
    const request: { url: string; headers?: Object } = { url };

    if (
        url.startsWith(`${REACT_APP_API_SERVICE_WAREHOUSE}/asset`) ||
        url.startsWith(REACT_APP_API_SERVICE_DTM_TRAFFIC)
    ) {
        request.headers = { Authorization: `Bearer ${accessToken}` };
    }

    return request;
};
