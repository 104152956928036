export default class Bounds {
    public top!: number;
    public left!: number;
    public height!: number;
    public width!: number;
    public right!: number;
    public bottom!: number;

    constructor(left: number, top: number, right: number, bottom: number) {
        this.set(left, top, right, bottom);
    }

    intersects(bounds: Bounds) {
        return !(
            this.right < bounds.left ||
            this.left > bounds.right ||
            this.top > bounds.bottom ||
            this.bottom < bounds.top
        );
    }

    contains(x: number, y: number) {
        return x >= this.left && x <= this.right && y >= this.top && y <= this.bottom;
    }

    set(left: number, top: number, right: number, bottom: number) {
        this.top = top;
        this.left = left;
        this.height = bottom - top;
        this.width = right - left;
        this.right = right;
        this.bottom = bottom;
    }
}
