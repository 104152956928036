import React from "react";
import * as Units from "./units";
import * as Markup from "./speedcam-short-card.styles";
import type { Props } from "./speedcam-short-card.types";

export const useContent = (props: Props) => {
    const { uid, error, isLoaded, data, status, dataCypress } = props;
    const { getToken, onCloseClick, onShowClick, onFullscreenClick } = props;

    const content = (() => {
        try {
            if (error) {
                return <Units.Error dataCypress={dataCypress} message={error} onCloseClick={onCloseClick} />;
            }

            if (isLoaded === false || !data) {
                return (
                    <>
                        <Markup.Header
                            isLoaded={false}
                            statusByIssueId={status.issueId ?? ""}
                            statusByIssueName={status.issueName}
                            statusByMonitoringId={status.monitoringId ?? ""}
                            statusByMonitoringName={status.monitoringName}
                            onShowClick={onShowClick}
                            onFullscreenClick={onFullscreenClick}
                            onCloseClick={onCloseClick}
                            dataCypress={dataCypress}
                        />
                        <Markup.Body uid={uid} isLoaded={false} getToken={getToken} dataCypress={dataCypress} />
                    </>
                );
            }

            if (isLoaded === true && !!data) {
                return (
                    <>
                        <Markup.Header
                            isLoaded={true}
                            statusByIssueId={status.issueId ?? ""}
                            statusByIssueName={status.issueName}
                            statusByMonitoringId={status.monitoringId ?? ""}
                            statusByMonitoringName={status.monitoringName}
                            number={data.number}
                            address={data.address}
                            onShowClick={onShowClick}
                            onFullscreenClick={onFullscreenClick}
                            onCloseClick={onCloseClick}
                            dataCypress={dataCypress}
                        />
                        <Markup.Body
                            isLoaded={true}
                            uid={uid}
                            issueCount={data.issueCount}
                            getToken={getToken}
                            overSpeed={data.overSpeed}
                            dataCypress={dataCypress}
                        />
                    </>
                );
            }
        } catch (err) {
            return (
                <Units.Error
                    dataCypress={dataCypress}
                    message="Произошла внутрення ошибка, сообщите пожалуйста в службу поддержки."
                    onCloseClick={onCloseClick}
                />
            );
        }
    })();

    return { content };
};

export const useHandleFocus = (props: Props) => {
    const { guid, onFocus } = props;

    const handleFocusClick = React.useCallback(() => {
        onFocus(guid);
    }, [guid, onFocus]);

    return { handleFocusClick };
};
