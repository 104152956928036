import { WarehouseAPI } from "../warehouse-api";
import { GetAssetDataParams, GetAssetDataResponse } from "./asset-traffic-light-api.types";

export class AssetTrafficLightAPI extends WarehouseAPI {
    /** Получение информации о экземпляре имущества по идентификатору */
    public getAssetData({ id }: GetAssetDataParams): Promise<GetAssetDataResponse> {
        const url = this.buildUrl(`/${id}`);
        return this.request({ url });
    }

    protected buildUrl(url: string, params = "") {
        return super.buildUrl(`/assettls${url}${params}`);
    }
}
