import styled from "styled-components";

export const Container = styled.div`
    border-radius: 6px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.content.primary};
    height: 280px;
`;

export const Screenshot = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
