import { CooGroupDomain } from "app-domain";
import { State, APITypes } from "api";
import { ISubscriberService } from "./coo-group-stream-subscriber.types";

export class Service implements ISubscriberService {
    private stateAPI = new State.StateAPI();

    public async getGovernanceById(governanceId: number): Promise<CooGroupDomain.Governance | null> {
        const response = await this.stateAPI.getCooGroupGovernanceById(governanceId);
        if (!response) return null;
        return this.mapGovernance(response);
    }

    public async getCooGroupGovernance(cooGroupId: number): Promise<CooGroupDomain.Governance | null> {
        const response = await this.stateAPI.getCooGroupGovernance(cooGroupId);
        if (!response) return null;
        return this.mapGovernance(response);
    }

    private mapGovernance(data: APITypes.CooGroupGovernance) {
        const governance = new CooGroupDomain.Governance(data.id, data.cooGroupId, data.cycleType);

        governance.setData({
            ...data,
            holdExpireDate: data.holdExpireSeconds
                ? new Date(Date.now() + data.holdExpireSeconds * 1000)
                : data.holdExpireDate
                ? new Date(data.holdExpireDate)
                : void 0,
        });
        return governance;
    }
}
