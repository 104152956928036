import { CooGroupDomain } from "app-domain";
import { ScheduleHelper } from "lib";
import { DateUtils } from "shared/utils";

export const useCycleHeadDescriptionData = (cycle?: CooGroupDomain.Cycle) => {
    if (!cycle) return;
    const processedSchedules = cycle.schedules && ScheduleHelper.formatSchedules(cycle.schedules);
    const createdDate = DateUtils.format(cycle.createdDate, {
        format: DateUtils.Formats.Date,
    });

    return {
        processedSchedules,
        createdDate,
    };
};
