import type { Directions } from "@megapolis/speed-detector-visualization";

export class DirectionsDTO {
    private directions: Directions = [];

    constructor(data?: any) {
        if (typeof data?.directions !== "object" || !Array.isArray(data.directions)) return;

        this.directions = data.directions.reduce((directions: Directions, source: any): Directions => {
            const direction = this.convertSourceToDirection(source);

            if (direction !== null) directions.push(direction);

            return directions;
        }, []);
    }

    public build = () => {
        return this.directions;
    };

    private convertSourceToDirection = (source: any): Directions[number] | null => {
        if (typeof source?.id !== "number") return null;
        if (typeof source?.kind !== "number") return null;

        const lanes = this.convertSourceToLanes(source?.lanes);
        if (!lanes.length) return null;

        return {
            id: source.id,
            type: source.kind,
            lanes,
        };
    };

    private convertSourceToLanes = (source: any) => {
        if (typeof source !== "object" || !Array.isArray(source)) return null;

        return source.reduce((lanes, item) => {
            const lane = this.convertSourceToLine(item);
            if (lane !== null) lanes.push(lane);
            return lanes;
        }, []);
    };

    private convertSourceToLine = (source: any): Directions[number]["lanes"][number] | null => {
        if (typeof source !== "number") return null;

        return {
            number: source,
            averageSpeed: 60,
        };
    };
}
