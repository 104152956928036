import { makeDrawer } from "../drawing.common";
import { CommonDrawProps } from "../drawing.types";

export enum TimescaleDirection {
    Up = "up",
    Down = "down",
}

export type DrawTimescaleTextProps = {
    second: number;
    lineX: number;
    lineStartY: number;
    lineEndY: number;
    isSegmentLine: boolean;
};

export type DrawTimescaleTextCallback = (ctx: CanvasRenderingContext2D, props: DrawTimescaleTextProps) => void;

export interface DrawTimescaleProps extends CommonDrawProps {
    /** Суммарно отрисовываемое время в секундах */
    timeSeconds: number;
    /**
     * Количество пикселей как цены деления
     * Если не указано, то рассчитывается от ширины
     * */
    pxPerSecond: number;
    /**
     * Размер отрезка шкалы(в количестве делений), для отрисовки большей по величине черточки
     * @default 5
     *
     */
    segment?: number;
    /** Размер линии отрезка в  */
    segmentLineHeight?: number;
    /** Направление отрисовки линий шкалы: вверх или вниз
     * @default TimescaleDirection.Down
     *
     */
    direction?: TimescaleDirection;
    /** @default 1 */
    lineWidth?: number;
    /** @default 5 */
    lineHeight?: number;
    /** @default #000 */
    lineColor?: string;
    /** Функция для отрисовки текста для каждой линии шкалы */
    drawText?: DrawTimescaleTextCallback;
}

export const drawTimescale = makeDrawer<DrawTimescaleProps>((ctx, props) => {
    const {
        x,
        y,
        pxPerSecond,
        timeSeconds,
        lineHeight = 5,
        segment = 5,
        segmentLineHeight = 10,
        direction = TimescaleDirection.Down,
        lineWidth = 1,
        lineColor = "#000",
        drawText,
    } = props;

    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = lineColor;
    ctx.beginPath();
    for (let currentX = x, second = 0; second <= timeSeconds; currentX += pxPerSecond, second += 1) {
        ctx.moveTo(currentX, y);
        const isSegmentLine = second % segment === 0 || second === 0 || second === timeSeconds;
        const lineSize = isSegmentLine ? segmentLineHeight : lineHeight;
        const destinationY = direction === TimescaleDirection.Down ? y + lineSize : y - lineSize;
        ctx.lineTo(currentX, destinationY);
        drawText?.(ctx, { second, lineX: currentX, lineStartY: y, lineEndY: destinationY, isSegmentLine });
    }
    ctx.stroke();
});
