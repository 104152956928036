import { memo, useCallback } from "react";
import { Chip } from "@megapolis/uikit/chip";
import { ChipTabData, ChipTabsChangeHandler } from "./chip-tabs.types";

type ChipTabProps = ChipTabData & {
    onClick: ChipTabsChangeHandler;
    isActive?: boolean;
};

export const ChipTab = memo((props: ChipTabProps) => {
    const { onClick, uid } = props;

    const handleClick = useCallback(() => {
        onClick?.(uid);
    }, [uid, onClick]);

    return (
        <Chip
            label={props.label}
            caption={props.caption}
            counter={props.counter?.toString()}
            isActive={props.isActive}
            onClick={handleClick}
            isDisabled={props.isDisabled}
        />
    );
});

ChipTab.displayName = "ChipTab";
