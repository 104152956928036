export const timelineColors = {
    greenBlink: "#1E7735",
    colorGreen: "#32C758",
    colorRed: "#FF3B30",
    colorYellow: "#FECB00",
    colorGray: "rgba(0,0,0, 0.18)",
    scaleStroke: "rgba(0,0,0, 0.6)",
    scaleText: "rgba(0,0,0, 0.54)",
    gridColor: "rgba(255,255,255,.7)",
};
