import { PhaseView } from "../../../phase-view";

export type PointerIconsDataInput = {
    y: number;
    pixelsPerSecond: number;
    phases: PhaseView[];
};

export type PointerIcon = {
    x: number;
    y: number;
    current: boolean;
    next: boolean;
};

const pointerIconWidth = 16;

export const createPointerIconsData = (args: PointerIconsDataInput): PointerIcon[] =>
    args.phases.reduce((list: PointerIcon[], phase) => {
        if (phase.pointerIconParams) {
            const phaseEndX =
                (phase.initialPhase.tPhase + phase.initialPhase.phaseShiftFromStart) * args.pixelsPerSecond;
            list.push({
                ...phase.pointerIconParams,
                x: phaseEndX - pointerIconWidth / 2,
                y: args.y - pointerIconWidth / 2,
            });
        }
        return list;
    }, []);
