import styled, { css } from "styled-components/macro";

export const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
`;

export const Title = styled.span((p) => {
    const { colors, spacings } = p.theme;

    return css`
        white-space: nowrap;
        color: ${colors.content.secondary};
        margin-bottom: ${spacings[3].str};
        padding-left: ${spacings[3].str};
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
    `;
});
