import type { Listener, Props } from "./abs-listener.types";

export class AbsListener implements Listener {
    private readonly popupFocus: Props["popupFocus"];
    private readonly detailsCardListStore: Props["listDetailsCardStore"];
    private readonly shortCardListStore: Props["listShortCardStore"];
    private readonly layerController: Props["layerController"];
    private readonly mapFlyToViewPort: Props["mapFlyToViewPort"];

    constructor(props: Props) {
        this.popupFocus = props.popupFocus;
        this.detailsCardListStore = props.listDetailsCardStore;
        this.shortCardListStore = props.listShortCardStore;
        this.layerController = props.layerController;
        this.mapFlyToViewPort = props.mapFlyToViewPort;
    }

    public subscribe = () => {
        const { shortCardListStore, detailsCardListStore, layerController } = this;

        layerController.emitter.on(layerController.emitter.events.OpenShortCard, shortCardListStore.add);

        shortCardListStore.emitter.on(shortCardListStore.emitter.events.Adding, this.handleFilteredOnMap);
        shortCardListStore.emitter.on(shortCardListStore.emitter.events.Remove, this.handleFilteredOnMap);
        shortCardListStore.emitter.on(shortCardListStore.emitter.events.ShowOnMap, this.mapFlyToViewPort);

        detailsCardListStore?.emitter.on(detailsCardListStore.emitter.events.Adding, this.handleFilteredOnMap);
        detailsCardListStore?.emitter.on(detailsCardListStore.emitter.events.Remove, this.handleFilteredOnMap);
        detailsCardListStore?.emitter.on(
            detailsCardListStore.emitter.events.ShowOnMap,
            this.handleShowOnMapByDetailsCard
        );
    };

    public unsubscribe = () => {
        const { shortCardListStore, detailsCardListStore, layerController } = this;

        layerController.emitter.off(layerController.emitter.events.OpenShortCard, shortCardListStore.add);

        shortCardListStore.emitter.off(shortCardListStore.emitter.events.Adding, this.handleFilteredOnMap);
        shortCardListStore.emitter.off(shortCardListStore.emitter.events.Remove, this.handleFilteredOnMap);
        shortCardListStore.emitter.off(shortCardListStore.emitter.events.ShowOnMap, this.mapFlyToViewPort);

        detailsCardListStore?.emitter.off(detailsCardListStore.emitter.events.Adding, this.handleFilteredOnMap);
        detailsCardListStore?.emitter.off(detailsCardListStore.emitter.events.Remove, this.handleFilteredOnMap);
        detailsCardListStore?.emitter.off(
            detailsCardListStore.emitter.events.ShowOnMap,
            this.handleShowOnMapByDetailsCard
        );
    };

    private getIdOfItems = () => {
        const { shortCardListStore, detailsCardListStore } = this;

        const ids = shortCardListStore.collection.map((item) => item.uid);

        const detailId = detailsCardListStore?.uid;
        if (typeof detailId === "number") ids.push(detailId);

        return ids;
    };

    private handleFilteredOnMap = () => {
        if (!this.layerController) return;

        const ids = this.getIdOfItems();
        this.layerController.setFilteredId(ids);
    };

    private handleShowOnMapByDetailsCard = (coordinate: LatLng) => {
        this.mapFlyToViewPort(coordinate);

        if (!this.detailsCardListStore) return;

        this.popupFocus.setActive(this.detailsCardListStore.guid);
    };
}
