export enum TrafficLightErrorStatusCode {
    NotManaged = 4,
    NotConnected = 3,
    HasErrors = 1,
    Critical = 2,
    OK = 0,
}

export const trafficLightErrorStatusCodes = Object.values(TrafficLightErrorStatusCode);

export interface TrafficLightErrorStatus {
    code: TrafficLightErrorStatusCode;
    name: string;
}

export const trafficLightErrorStatusList: { [key in TrafficLightErrorStatusCode]: TrafficLightErrorStatus } = {
    [TrafficLightErrorStatusCode.OK]: {
        code: TrafficLightErrorStatusCode.OK,
        name: "Работает",
    },
    [TrafficLightErrorStatusCode.HasErrors]: {
        code: TrafficLightErrorStatusCode.HasErrors,
        name: "Работает с замечаниями",
    },
    [TrafficLightErrorStatusCode.Critical]: {
        code: TrafficLightErrorStatusCode.Critical,
        name: "Критические ошибки",
    },
    [TrafficLightErrorStatusCode.NotConnected]: {
        code: TrafficLightErrorStatusCode.NotConnected,
        name: "Нет связи",
    },
    [TrafficLightErrorStatusCode.NotManaged]: {
        code: TrafficLightErrorStatusCode.NotManaged,
        name: "Не подключен",
    },
};
