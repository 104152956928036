import { observer } from "mobx-react-lite";
import { Radiobutton } from "@megapolis/uikit/radio-button";
import { ProductionDatePicker } from "components";
import { TimeInput } from "@megapolis/uikit/time-input";
import { useCommandLaunchPopupState } from "../../../command-launch-popup-context";
import { errorMessage } from "./completion-section.constants";
import * as Markup from "../../body.styles";

export const CompletionSection = observer(() => {
    const state = useCommandLaunchPopupState();
    const {
        isCompletionValid,
        isCompletionMode,
        completionDate,
        completionTime,
        setCompletionMode,
        changeCompletionDate,
        changeCompletionTime,
    } = state;
    return (
        <>
            <Radiobutton.ToggleLabel value={isCompletionMode} onClick={setCompletionMode}>
                Завершить управление в
            </Radiobutton.ToggleLabel>
            <Markup.FlexContainer>
                <Markup.DatePickerWrapper>
                    <ProductionDatePicker
                        value={completionDate}
                        hasError={!isCompletionValid}
                        errorText={errorMessage}
                        isDisabled={!isCompletionMode}
                        onChange={changeCompletionDate}
                    />
                </Markup.DatePickerWrapper>
                <TimeInput
                    hasError={!isCompletionValid}
                    isDisabled={!isCompletionMode}
                    value={completionTime}
                    onChange={changeCompletionTime}
                />
            </Markup.FlexContainer>
        </>
    );
});
