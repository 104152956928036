import styled, { css } from "styled-components";
import { ControlButton } from "../../../components";

type ButtonProps = {
    isActive?: boolean;
};

const activeCss = css(
    ({ theme }) => `
  background-color: ${theme.colors.containerBackground.info.default};
  color: ${theme.colors.content.inverse};
`
);

export const Button = styled(ControlButton)<ButtonProps>(
    ({ theme, isActive }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        font: ${theme.typography.text1};
        font-weight: 400;
        text-transform: capitalize;
        border-radius: ${theme.borderRadiuses[2].str};
        width: 100%;
        min-height: 32px;
        ${isActive && activeCss}
    `
);
