import styled, { css } from "styled-components";
import { Button as UiKitButton } from "@megapolis/uikit/button";

const colors = {
    primary: {
        backgroundColor: "rgba(107, 143, 171, 0.35)",
        borderColor: "transparent",
        text: "rgba(3, 40, 76, 0.66)",
    },
    secondary: {
        backgroundColor: "rgba(3, 40, 76, 0.05)",
        borderColor: "rgba(3, 40, 76, 0.1)",
        text: "rgba(3, 40, 76, 0.31)",
    },
    tertiary: {
        backgroundColor: "transparent",
        borderColor: "rgba(3, 40, 76, 0.1)",
        text: "rgba(3, 40, 76, 0.31)",
    },
    quaternary: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        text: "rgba(3, 40, 76, 0.31)",
    },
};

export const Button = styled(UiKitButton)<React.ComponentProps<typeof UiKitButton>>(({ theme, ...props }) => {
    const { priority = "primary" } = props;
    const { kind = "info" } = props;

    if (kind === "info") {
        // тут ошибки, не разобрался, как объяснить, какого типа приорити.
        return css`
            &:disabled {
                background-color: ${colors[priority].backgroundColor};
                border-color: ${colors[priority].borderColor};
                color: ${colors[priority].text};
            }
        `;
    }
});
