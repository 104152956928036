import { observer } from "mobx-react-lite";
import { Checkbox } from "@megapolis/uikit/checkbox";
import { ProductionDatePicker } from "components";
import { TimeInput } from "@megapolis/uikit/time-input";
import { useCommandLaunchPopupState } from "../../../command-launch-popup-context";
import * as Markup from "../../body.styles";

export const DelaySection = observer(() => {
    const state = useCommandLaunchPopupState();

    const {
        isDelayEnabled,
        delayStartDate,
        delayStartTime: delayStartDateTime,
        setIsDelayEnabled,
        changeStartDate,
        changeDelayStartTime: changeDelayStartDateTime,
    } = state;

    return (
        <>
            <Checkbox.ToggleLabel onClick={setIsDelayEnabled} value={isDelayEnabled}>
                Задать дату начала отложенной команды
            </Checkbox.ToggleLabel>
            <Markup.FlexContainer>
                <Markup.DatePickerWrapper>
                    <ProductionDatePicker
                        isDisabled={!isDelayEnabled}
                        value={delayStartDate}
                        onChange={changeStartDate}
                    />
                </Markup.DatePickerWrapper>
                <TimeInput
                    isDisabled={!isDelayEnabled}
                    value={delayStartDateTime}
                    onChange={changeDelayStartDateTime}
                />
            </Markup.FlexContainer>
        </>
    );
});
