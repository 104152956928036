import { useState, useCallback } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { CommandLaunchPopupState } from "./command-launch-popup-state";
import { Header } from "./header";
import { Body } from "./body";
import { Footer } from "./footer";
import { Props } from "./command-launch-popup.types";
import { Popup } from "../popup";
import { CommandLaunchPopupContext } from "./command-launch-popup-context";

const PopupWrapper = styled(Popup)`
    width: 352px;
    overflow: hidden;
`;

export const CommandLaunchPopup = observer((props: Props) => {
    const { portalContainerNode, onClose, onSubmit, ...options } = props;
    const [state] = useState(() => new CommandLaunchPopupState(options));

    const handleSubmit = useCallback(() => {
        const data = state.build();
        if (!data) return;
        onSubmit(data);
    }, [state, onSubmit]);

    return (
        <PopupWrapper
            parentNode={portalContainerNode}
            header={<Header />}
            body={
                <CommandLaunchPopupContext.Provider value={{ state }}>
                    <Body />
                </CommandLaunchPopupContext.Provider>
            }
            footer={
                <Footer
                    isSubmitButtonDisabled={state.isSubmitButtonDisabled}
                    onClose={onClose}
                    onSubmit={handleSubmit}
                />
            }
        />
    );
});
