import { ErrorCode } from "../enums";
import { Error } from "../types";

export const errorTypesMap: { [key in ErrorCode]: Error } = {
    [ErrorCode.ClockMalfunction]: {
        code: ErrorCode.ClockMalfunction,
        name: "Неисправны часы",
        isCritical: false,
    },
    [ErrorCode.NoPower]: {
        code: ErrorCode.NoPower,
        name: "Нет сетевого питания",
        isCritical: true,
    },
    [ErrorCode.LampCritical]: {
        code: ErrorCode.LampCritical,
        name: "Критическая неисправность лампочек",
        isCritical: true,
    },
    [ErrorCode.LampNotCritical]: {
        code: ErrorCode.LampNotCritical,
        name: "Некритическая неисправность лампочек",
        isCritical: true,
    },
    [ErrorCode.RemoteControlMalfunction]: {
        code: ErrorCode.RemoteControlMalfunction,
        name: "Неисправность ВПУ",
        isCritical: false,
    },
    [ErrorCode.DoorOpened]: {
        code: ErrorCode.DoorOpened,
        name: "Открыта дверь шкафа",
        isCritical: false,
    },
    [ErrorCode.ExternalCritical]: {
        code: ErrorCode.ExternalCritical,
        name: "Критическая неисправность периферии",
        isCritical: true,
    },
    [ErrorCode.ExternalNotCritical]: {
        code: ErrorCode.ExternalNotCritical,
        name: "Некритическая неисправность периферии",
        isCritical: false,
    },
    [ErrorCode.HardwareNotCritical]: {
        code: ErrorCode.HardwareNotCritical,
        name: "Некритическая неисправность оборудования",
        isCritical: false,
    },
    [ErrorCode.HardwareCritical]: {
        code: ErrorCode.HardwareCritical,
        name: "Критическая неисправность оборудования",
        isCritical: true,
    },
    [ErrorCode.SoftwareCritical]: {
        code: ErrorCode.SoftwareCritical,
        name: "Критическая неисправность ПО",
        isCritical: true,
    },
    [ErrorCode.SoftwareNotCritical]: {
        code: ErrorCode.SoftwareNotCritical,
        name: "Некритическая неисправность ПО",
        isCritical: false,
    },
};

export const errorTypesList = Object.values(errorTypesMap);
