import styled from "styled-components";
import { FlexContainer } from "../../body.styles";

export const CycleInputWrapper = styled.div`
    width: 74px;
`;

export const Message = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.content.secondary};
`;

export const Container = styled(FlexContainer)`
    align-items: center;
`;
