import { memo, ReactElement, ReactNode, useContext } from "react";
import { Icons } from "@megapolis/uikit/icons";
import { AccordionContext } from "../accordion.context";
import * as Markup from "./accordion-header.styles";

type IconComponent = <P extends { isExpanded: boolean }>(props: P) => ReactElement | null;

type Props = {
    children: ReactNode;
    className?: string;
    iconComponent?: IconComponent;
    isIconHidden?: boolean;
};

const ExpansionIcon = <Icons.Monochrome.ChevronRightS />;

const DefaultIconComponent: IconComponent = memo((props) => {
    return <Markup.ExpansionIcon isExpanded={props.isExpanded}>{ExpansionIcon}</Markup.ExpansionIcon>;
});

const AccordionHeaderBaseComponent = (props: Props) => {
    const { isIconHidden = false, iconComponent: IconComponent = DefaultIconComponent } = props;
    const { onToggle, isDisabled, isExpanded } = useContext(AccordionContext);

    return (
        <Markup.Container className={props.className} isDisabled={isDisabled} onClick={onToggle}>
            {props.children}
            {!isIconHidden && <IconComponent isExpanded={isExpanded} />}
        </Markup.Container>
    );
};

export const AccordionHeaderBase = memo(AccordionHeaderBaseComponent);
