import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
`;
