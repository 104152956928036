import styled, { css } from "styled-components/macro";

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-flow: column nowrap;
        color: ${theme.stackHeader.contentColor};
        overflow: hidden;
    `
);

export const MainRow = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-flow: row nowrap;
        gap: ${theme.spacings[5].str};
        padding: ${theme.stackHeader.mainRowPadding};
        overflow: hidden;
    `
);

const commonCss = css`
    display: flex;
    align-items: center;
`;

export const Left = styled.div`
    ${commonCss}
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
`;

export const Right = styled.div`
    ${commonCss}
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
`;

export const Center = styled.div`
    ${commonCss};
    min-height: ${({ theme }) => theme.stackHeader.centerBlockMinHeight};
    align-items: center;
    flex-grow: 1;
    font: ${({ theme }) => theme.stackHeader.centerBlockFont};
    overflow: hidden;
`;

export const SecondaryRow = styled.div`
    ${commonCss};
    flex-flow: row nowrap;
    flex-grow: 1;
    padding: ${({ theme }) => theme.stackHeader.secondaryRowPadding};
    font: ${({ theme }) => theme.stackHeader.secondaryRowFont};
    overflow: hidden;
`;
