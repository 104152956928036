export const convertNumberToFormat = (value?: number) => {
    if (typeof value !== "number") return "0";
    if (isNaN(value)) return "0";
    if (!isFinite(value)) return "0";

    try {
        const data = [];
        const arrStr = [...String(value)];
        let length = arrStr.length;

        while (0 < length) {
            let next = length - 3;
            if (next < 0) next = 0;

            const result = arrStr.slice(next, length).join("");
            data.unshift(result);
            length = next;
        }

        return data.join(" ");
    } catch (error) {
        return "0";
    }
};
