import { Warehouse, DetectorServiceAPI } from "api";
import * as units from "./units";
import type { IService } from "./detector-service.types";

export class DetectorFullCardService implements IService {
    private warehouseDetectorAPI: Warehouse.AssetDetectorAPI = new Warehouse.AssetDetectorAPI();
    private detectorServiceAPI: DetectorServiceAPI = new DetectorServiceAPI();

    getEmptyAsset = () => {
        const meta = new units.MetaDTO();
        const options = new units.OptionsDTO();
        return { meta, options };
    };

    getAssetById = async (id: number | null) => {
        if (id === null) return Promise.resolve(this.getEmptyAsset());

        try {
            const result = await this.warehouseDetectorAPI.getById(id);
            const meta = new units.MetaDTO(result);
            const options = new units.OptionsDTO(result);
            return { meta, options };
        } catch (error) {
            console.warn(error);
            return this.getEmptyAsset();
        }
    };

    getDirections = async (id: number | null) => {
        if (id === null) return Promise.resolve([]);

        try {
            const result = await this.detectorServiceAPI.getById(id);
            const directions = new units.DirectionsDTO(result).build();
            return directions;
        } catch (error) {
            console.warn(error);
            return Promise.resolve([]);
        }
    };
}
