import { TrafficLightFeature } from "./trafficlight-feature";
import { RouteProperties } from "./route.types";

export class MapRouteFeatures {
    public isReverseRouteSameAsDirect: boolean = false;
    public trafficLightFeatures?: GeoJSON.FeatureCollection<GeoJSON.Point>;
    public reverseTrafficLightFeatures?: GeoJSON.FeatureCollection<GeoJSON.Point>;
    public routeFeatures?: GeoJSON.FeatureCollection<GeoJSON.LineString, RouteProperties>;
    public reverseRoute?: GeoJSON.FeatureCollection<GeoJSON.LineString, RouteProperties>;

    public setTrafficLightCoords(trafficLightCoords: LatLng[]) {
        this.trafficLightFeatures = {
            type: "FeatureCollection",
            features: trafficLightCoords.map((coordinates) => new TrafficLightFeature(coordinates)),
        };
    }

    public setReverseTrafficLightCoords(trafficLightCoords: LatLng[]) {
        this.trafficLightFeatures = {
            type: "FeatureCollection",
            features: trafficLightCoords.map((coordinates) => new TrafficLightFeature(coordinates)),
        };
    }
}
