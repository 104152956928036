import React from "react";
import { Button } from "@megapolis/uikit/button";
import { Icons } from "assets";
import * as Markup from "./details.styles";
import { useHandleClick } from "./details.hooks";
import type { Props } from "./details.types";

export const Details = React.memo((props: Props) => {
    const { handleClick } = useHandleClick(props);

    return (
        <Markup.Container className={props.className} data-cypress={props.dataCypress}>
            <Button
                content={Button.Content.TextIcon}
                priority="quaternary"
                kind="info"
                text="Все характеристики"
                icon={<Icons.PopupIcon />}
                size="s"
                onClick={handleClick}
            />
        </Markup.Container>
    );
});
