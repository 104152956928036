import { Feature, FeatureCollection, Point } from "geojson";
import { FacilityData } from "api/endpoints/types";

export type TrafficLightProperties = {
    DistanceFromStart: number;
    FacilityId: number;
    FromWayId: number;
    ToWayId: number;
};

export class TrafficLightFeatureCollection implements FeatureCollection<Point, TrafficLightProperties> {
    public readonly type = "FeatureCollection";
    public features: Feature<Point, TrafficLightProperties>[];

    constructor(trafficLights: FacilityData[]) {
        this.features = trafficLights.map((item) => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [item.lng, item.lat],
            },
            properties: {
                FacilityId: item.facilityId,
                FromWayId: 0,
                DistanceFromStart: 0,
                ToWayId: 0,
            },
        }));
    }
}
