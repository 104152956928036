import React from "react";
import Player from "@megapolis/react-video-player";
import { REACT_APP_API_CAMERA_SERVICE } from "env-data";
import * as Markup from "./single.styles";
import type { Props } from "./single.types";

export const Single = React.memo((props: Props) => {
    const { uid } = props;
    const dataCypress = props.dataCypress ? `${props.dataCypress}__player-single` : undefined;

    return (
        <Markup.Container className={props.className} data-cypress={dataCypress}>
            <Player
                key={uid}
                id={uid}
                url={REACT_APP_API_CAMERA_SERVICE}
                caption={props.number}
                token={props.getToken}
                autoplay={false}
            />
        </Markup.Container>
    );
});
