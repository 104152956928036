import styled, { css } from "styled-components/macro";
import { MapControlGroupOrientation } from "./map-control-group.types";

type WrapperProps = {
    orientation: MapControlGroupOrientation;
};

export const Wrapper = styled.div<WrapperProps>((props) => {
    const {
        theme: { borderRadiuses, spacings, colors, shadows },
    } = props;

    const borderRadius = borderRadiuses[3].str;

    return css`
        display: flex;
        flex-flow: ${props.orientation === "horizontal" ? "row nowrap" : "column nowrap"};
        background: ${colors.stackBackground.primary};
        border-radius: ${borderRadius};
        padding: ${spacings[1].str};
        gap: ${spacings[1].str};
        box-shadow: ${shadows?.shadow1};
        pointer-events: all;

        & > * {
            border-radius: 0;
            box-shadow: none;
            padding: 0;
        }
    `;
});
