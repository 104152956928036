import { APITypes } from "api";
import { TrafficLightDomain } from "app-domain";

const secondInMilliseconds = 1000;

export class FacilityLogsMapper {
    public fromAPI(item: APITypes.FacilitySnapshot): TrafficLightDomain.TrafficLightMessage {
        return {
            id: item.id,
            dateTime: new Date(item.timestamp).getTime() / secondInMilliseconds,
            controllerDateTime: item.data?.cTimestamp
                ? new Date(item.data?.cTimestamp).getTime() / secondInMilliseconds
                : undefined,
            eventType: item.type,
            data: item.data,
            state: {
                id: item.id,
                secondsGone: 0,
                malfunctionType: item.malfunctionType,
                program: item.program,
                nextPhase: 0,
                phaseTime: item.phaseTime,
                phase: item.phase,
                prePhase: item.prePhase,
                isPromTime: item.isPromTime,
                governanceId: item.governanceId,
                governanceInfo: item.governanceId ? new TrafficLightDomain.GovernanceInfo(item.governanceId) : null,
                controlMode: item.controlMode,
                status: item.status,
                preStatus: item.preStatus,
                preControlMode: item.preControlMode,
                condition: item.condition,
                isGuidedAdaptiveAllowed: false,
                isLocalAdaptiveEnabled: false,
            },
        };
    }
}
