import { Warehouse } from "api";

export class WarehouseService {
    private assetCameraAPI = new Warehouse.AssetCameraAPI();
    private assetSpeedcamAPI = new Warehouse.AssetSpeedcamAPI();

    getAssetCameraById(id: number) {
        return this.assetCameraAPI.getById(id);
    }

    getAssetSpeedcamById(id: number) {
        return this.assetSpeedcamAPI.getById(id);
    }
}
