export const LAYER_IDS = {
    trafficLight: "TRAFFICLIGHT",
    trafficLightDetector: "TRAFFICLIGHT_DETECTOR",
    cooGroup: "TRAFFICLIGHT_GROUP",
    radarDetector: "RADAR_DETECTOR",
    radarDetectorRadar: "RADAR_DETECTOR_radar",
    warehouseCamera: "WAREHOUSE_CAMERA",
    warehouseSpeedcam: "WAREHOUSE_SPEEDCAM",
    warehouseDetector: "WAREHOUSE_DETECTOR",
    dtmTraffic: "DTM_TRAFFIC",
} as const;
