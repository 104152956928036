import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Hooks } from "shared";
import { separator, underscore } from "./date-picker.constants";
import * as Types from "./date-picker.types";

const createDateFromInputValue = (value: string) => {
    const [day, month, year] = value.split(separator);
    return new Date(`${year}.${month}.${day}`);
};

const formatDateToInputValue = (date?: Date | null) => date?.toLocaleDateString() ?? "";

type PlaceCalendarParams = {
    relativeElement: HTMLElement;
    calendarElement: HTMLElement;
    offset?: number;
    isFullWidth?: boolean;
};

const placeCalendar = (params: PlaceCalendarParams) => {
    const { offset = 0, calendarElement, relativeElement } = params;
    const { y: containerY, height: containerHeight } = relativeElement.getBoundingClientRect();
    const { height: calendarHeight } = calendarElement.getBoundingClientRect();
    let calendarY = containerY + containerHeight + offset;

    if (calendarY + calendarHeight >= global.innerHeight) calendarY = containerY - calendarHeight - offset;

    calendarElement.style.top = `${calendarY}px`;
    calendarElement.style.visibility = "visible";
};

const disposeCalendar = (calendarEl: HTMLElement) => {
    calendarEl.style.visibility = "hidden";
};

// Дополнительная простановка свойства visibility нужна для того, чтобы избежать
// лишнего "моргания" при позиционировании календаря
// TODO: Возможно стоит продумать получше момент с рендером после расчета размеров и позиции

export const useDatePicker = (props: Types.Props) => {
    const { value, onChange, formatInputDate = formatDateToInputValue } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);

    const [isCalendarOpened, setIsCalendarOpened] = useState(false);
    const [inputValue, setInputValue] = useState(() => formatInputDate(value));

    useEffect(() => {
        setInputValue(formatInputDate(value));
    }, [formatInputDate, value]);

    const placeCalendarOnFrame = useCallback(() => {
        if (!containerRef.current || !calendarRef.current) return;
        placeCalendar({
            relativeElement: containerRef.current,
            calendarElement: calendarRef.current,
            offset: 8,
        });
    }, []);

    const hideCalendar = useCallback(() => {
        if (!calendarRef.current) return;
        setIsCalendarOpened(false);
        disposeCalendar(calendarRef.current);
    }, []);

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setInputValue(value);
            if (value.length === 0 || underscore.test(value)) return;
            onChange?.(createDateFromInputValue(value));
        },
        [onChange]
    );

    const handleClear = useCallback(
        (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            e.preventDefault();
            onChange?.(null);
            hideCalendar();
        },
        [onChange, hideCalendar]
    );

    const handleCalendarChange = useCallback(
        (date: Date) => {
            onChange?.(date);
            hideCalendar();
        },
        [onChange, hideCalendar]
    );

    const handleInputFocus = useCallback(() => {
        setIsCalendarOpened(true);
        requestAnimationFrame(placeCalendarOnFrame);
    }, [placeCalendarOnFrame]);

    Hooks.useClickOutside({ ref: containerRef, callback: hideCalendar, capture: true });

    return {
        containerRef,
        calendarRef,
        isCalendarOpened,
        inputValue,
        handleClear,
        handleInputChange,
        handleCalendarChange,
        handleInputFocus,
    };
};
