import { useCallback, useEffect } from "react";
import { Hooks } from "shared";
import { useAppState } from "./use-app-state";

export const useSideBarFilter = () => {
    const state = useAppState();
    const forceUpdate = Hooks.useForceUpdate();

    const openFilter: typeof state["openFilter"] = useCallback(
        (...args) => {
            state.openFilter(...args);
        },
        [state]
    );

    const closeFilter: typeof state["closeFilter"] = useCallback(
        (...args) => {
            state.closeFilter(...args);
        },
        [state]
    );

    useEffect(() => {
        state.on("sideBarFilterChanged", forceUpdate);
        return () => {
            state.off("sideBarFilterChanged", forceUpdate);
        };
    });

    const { isOpened, filterKey } = state.sideBarFilter;

    return {
        isOpened,
        filterKey,
        openFilter,
        closeFilter,
    };
};
