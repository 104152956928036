export enum PhaseType {
    /** Основная */
    Basic = 0,
    /** Вызывная */
    Conditional = 1,
    /** Специальная */
    Special = 2,
    /** Вызывная (подменная) */
    ConditionalAlternate = 3,
}
