import { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Radiobutton } from "@megapolis/uikit/radio-button";
import { useCommandLaunchPopupState } from "../../../command-launch-popup-context";
import * as Markup from "../../body.styles";

export const DurationSection = observer(() => {
    const state = useCommandLaunchPopupState();
    const {
        isDurationMode,
        durationHours,
        durationMinutes,
        minDurationHours,
        minDurationMinutes,
        isDurationValid,
        setDurationMode,
    } = state;

    const handleChangeHours = useCallback((value?: number) => state.changeDurationHours(value), [state]);

    const handleChangeMinutes = useCallback((value?: number) => state.changeDurationMinutes(value), [state]);

    const validationError = useMemo(() => {
        if (isDurationValid) return;
        return `Минимальное время длительности: ${
            minDurationHours ? `${minDurationHours} часов,` : ""
        }${minDurationMinutes} минут`;
    }, [minDurationHours, minDurationMinutes, isDurationValid]);

    return (
        <>
            <Radiobutton.ToggleLabel
                value={isDurationMode}
                hasError={isDurationMode && !!validationError}
                error={validationError}
                onClick={setDurationMode}>
                Установить длительность управления
            </Radiobutton.ToggleLabel>
            <Markup.FlexContainer>
                <Markup.Input
                    placeholder="Часы"
                    max={state.maxDurationHours}
                    min={0}
                    isDisabled={!isDurationMode}
                    value={durationHours}
                    onChange={handleChangeHours}
                />
                <Markup.Input
                    placeholder="Минуты"
                    max={state.maxDurationMinutes}
                    min={0}
                    isDisabled={!isDurationMode}
                    value={durationMinutes}
                    onChange={handleChangeMinutes}
                />
            </Markup.FlexContainer>
        </>
    );
});
