import { DictionaryItem } from ".";

export enum TrafficLightGroupStatusCode {
    Disabled = 0,

    Plan = 1,

    GreenLine = 2,
}

export interface TrafficLightGroupStatus extends DictionaryItem<TrafficLightGroupStatusCode> {}

export const trafficLightGroupStatusList: { [key in TrafficLightGroupStatusCode]: TrafficLightGroupStatus } = {
    [TrafficLightGroupStatusCode.Disabled]: {
        code: TrafficLightGroupStatusCode.Disabled,
        name: "Выключена",
        shortName: "Выкл",
        color: "#ABABAB",
    },
    [TrafficLightGroupStatusCode.Plan]: {
        code: TrafficLightGroupStatusCode.Plan,
        name: "В режиме",
        shortName: "ПЛ",
        color: "#00C193",
    },
    [TrafficLightGroupStatusCode.GreenLine]: {
        code: TrafficLightGroupStatusCode.GreenLine,
        name: "Зеленый коридор",
        shortName: "ЗВ",
        color: "#00705B",
    },
};
