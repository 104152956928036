import styled, { CSSProperties } from "styled-components/macro";
import { ContentPosition } from "./accordion-header-description.types";

const contentPositionMap = {
    start: "flex-start",
    center: "center",
    end: "flex-end",
} as {
    [key in ContentPosition]: CSSProperties["justifyContent"];
};

export const Container = styled.div<{ contentPosition: ContentPosition }>`
    display: flex;
    justify-content: ${(props) => contentPositionMap[props.contentPosition]};
    flex-grow: 1;
`;
