import { Warehouse, Facility, Manage, State, Cycle } from "api";
import { TrafficLightDomain } from "app-domain";
import { PlannedGovernanceError, PlannedGovernanceInfo } from "app-domain/traffic-light";
import { Factories } from "shared";
import { TrafficLightData, GetTrafficLightMapOptions } from "./traffic-light-service.types";
import { TrafficLightStateMapper } from "../mappers";
import { mapCycleToCustomCycle } from "./traffic-light-service.utils";

export class TrafficLightService {
    private trafficLightStateMapper = new TrafficLightStateMapper();
    private governanceFactory = new Factories.TrafficLightGovernanceFactory();
    constructor(
        private facilityApi: Facility.FacilityAPI,
        private assetApi: Warehouse.AssetTrafficLight.AssetTrafficLightAPI,
        private manageApi: Manage.ManageAPI,
        private stateApi: State.StateAPI,
        private cycleApi: Cycle.CycleAPI
    ) {}

    /** Возвращает словарь светофоров */
    public async getTrafficLightMap({
        serverInfo,
        ...filterOptions
    }: GetTrafficLightMapOptions): Promise<TrafficLightDomain.ITrafficLightMap> {
        const response = await this.facilityApi.getFacilities(filterOptions);
        const map = new Map();

        if (Array.isArray(response)) {
            response.forEach(({ box, facilityId, state, ...rest }) => {
                let boxSize = null;
                if (box) {
                    try {
                        boxSize = JSON.parse(box);
                    } catch (e) {}
                }
                map.set(
                    facilityId,
                    new TrafficLightDomain.TrafficLight({
                        ...rest,
                        facilityId,
                        boxSize,
                        serverInfo,
                        state: state ? this.trafficLightStateMapper.fromAPI(state) : void 0,
                    })
                );
            });
        }

        return map;
    }

    public async getTrafficLightMetaData(id: number): Promise<TrafficLightDomain.TrafficLightMetaData> {
        const data = await this.getTrafficLightData(id, true);
        return {
            cycles: data.cycles?.map((cycle) =>
                mapCycleToCustomCycle({
                    cycle,
                    userDisplayName: data.userDisplayName,
                    syncDate: data.createdDate,
                })
            ),
            detectors: data.detectors ?? [],
            directions: data.directions ?? [],
            phases: data.phases ?? [],
            state: data.state ? this.trafficLightStateMapper.fromAPI(data.state) : void 0,
            issueCount: data.issueCount ?? 0,
            activeIssueCount: data.activeIssueCount ?? 0,
            cyclesWithWarningCount: data.cyclesWithWarningCount,
            userGovernance: data.userGovernance,
            cameras: data.cameras,
            assetLifecycle: data.assetLifecycle,
        };
    }

    public async getTrafficLightData(id: number, includeState?: boolean): Promise<TrafficLightData> {
        return this.facilityApi.getFacility(id, includeState, includeState);
    }

    public async getTrafficLightByIds(ids: number[], includeState: boolean = false): Promise<TrafficLightData[]> {
        const items = await this.facilityApi.getBatchedFacilities(ids, includeState);
        return items.filter(Boolean) as TrafficLightData[];
    }

    public async getTrafficLightAssetData({ id }: { id: string | number }): Promise<TrafficLightDomain.AssetDataT> {
        const response = await this.assetApi.getAssetData({ id });
        return {
            ...response,
            createdAt: response.createdAt ? new Date(response.createdAt) : null,
            epStartDate: response.epStartDate ? new Date(response.epStartDate) : null,
        };
    }

    public async startTrafficLightControl(id: string | number): Promise<TrafficLightDomain.Governance> {
        const response = await this.manageApi.createFacilityGovernance(id);
        const result = new TrafficLightDomain.Governance(response.id, response.facilityId);
        return this.governanceFactory.create(result);
    }

    public async getCycles(id: number): Promise<TrafficLightDomain.CustomCycle[]> {
        const response = await this.cycleApi.getFacilityCycles(id);
        if (!Array.isArray(response)) {
            console.error(response);
            return [];
        }
        return response.map((cycle) => {
            cycle.phases = cycle.phases?.filter(Boolean) ?? [];
            return new TrafficLightDomain.CustomCycle(cycle);
        });
    }

    public removeCycle(id: number): Promise<void> {
        return this.cycleApi.removeFacilityCycle(id);
    }

    /**
     * Валидация цикла СО
     * @returns Список ошибок в виде строк
     */
    public validateCycle(id: number, cycle: TrafficLightDomain.INewCustomCycle): Promise<string[]> {
        return this.cycleApi.validateFacilityCycle(id, cycle);
    }

    /**
     *  Создание цикла СО
     *  @returns созданный кастомный цикл
     */
    public async createCycle(
        id: number,
        cycle: TrafficLightDomain.INewCustomCycle
    ): Promise<TrafficLightDomain.CustomCycle> {
        const cycleData = await this.cycleApi.createFacilityCycle(id, cycle);
        cycleData.phases = cycleData.phases?.filter(Boolean) ?? [];
        return new TrafficLightDomain.CustomCycle(cycleData);
    }

    /** Получает активный цикл с контроллера СО */
    public async getControllerActiveCycle(id: number): Promise<Required<TrafficLightDomain.ControllerCycle>> {
        const response = await this.stateApi.getFacilityCurrentCycle(id);
        return {
            time: response.time,
            number: response.number,
            phases: response.phases ?? [],
        };
    }

    /** Возвращает все имеющиеся состояния светофорных объектов */
    public getTrafficLightsStates(): Promise<TrafficLightDomain.TrafficLightState[]> {
        // @ts-ignore
        return this.stateApi.getFacilitiesStates();
    }

    public async getTrafficLightPassport(id: number, version: number): Promise<File> {
        const response = await this.facilityApi.getFacilityPassport(id, version);
        return new File([response], `Паспорт СО ${id} #${version}`, {
            type: "application/pdf",
        });
    }

    public setLocalAdaptiveModuleEnabled(facilityId: number, value: boolean): Promise<void> {
        return this.manageApi.setLocalAdaptiveModuleEnabled(facilityId, value);
    }

    public getGovernancePlanned(facilityId: number): Promise<PlannedGovernanceInfo | PlannedGovernanceError> {
        return this.manageApi.getGovernancePlanned(facilityId);
    }

    public removePlannedGovernance(facilityId: number, facilityPlannedGovernanceId: number): Promise<void> {
        return this.manageApi.removePlannedGovernance(facilityId, facilityPlannedGovernanceId);
    }
}
