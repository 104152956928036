import { DefaultTheme } from "styled-components";
/**
 * Глобальный провайдер темы для вещей, лежащих вне контекста React
 * @singleton
 */
export class ThemeProvider {
    private static instance?: ThemeProvider;

    public theme: DefaultTheme = {} as DefaultTheme;

    public static getInstance() {
        if (!this.instance) {
            this.instance = new ThemeProvider();
        }
        return this.instance ?? new ThemeProvider();
    }
}
