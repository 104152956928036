import * as turf from "@turf/turf";
import { Feature, LineString } from "@turf/helpers";
import { GeoJSON } from "geojson";
import { MapRouteFeatures } from "../../route";

export const convertToLine = (data?: MapRouteFeatures): Feature<LineString>[] => {
    if (!data?.routeFeatures?.features.length || !data?.trafficLightFeatures?.features.length) return [];

    const coordinates = data.routeFeatures.features.reduce((acc: GeoJSON.Position[], feature) => {
        acc.push(...feature.geometry.coordinates);
        return acc;
    }, []);

    const line = turf.lineString(coordinates);

    const firstTrafficLight = data.trafficLightFeatures.features[0].geometry.coordinates;
    const lastTrafficLight =
        data.trafficLightFeatures.features[data.trafficLightFeatures.features.length - 1].geometry.coordinates;

    const startPoint = turf.nearestPointOnLine(line, firstTrafficLight);
    const endPoint = turf.nearestPointOnLine(line, lastTrafficLight);

    return [turf.lineSlice(startPoint, endPoint, line)];
};
