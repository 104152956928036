import mapboxgl, { Marker } from "mapbox-gl";
import { TypedEmitter } from "tiny-typed-emitter";
import { ruAlphabet } from "shared/constants";
import { Handlers, Events } from "./route-points.events";
import { getIconByViewType } from "./route-points.utils";
import type { MarkerType, MarkerParams } from "./route-points.types";

export class RoutePointsMarker extends TypedEmitter<Handlers> {
    public readonly events = Events;
    private markers: Map<number, MarkerType> = new Map();

    constructor(protected map: mapboxgl.Map) {
        super();
    }

    public setData = (markers: MarkerParams[]) => {
        markers.forEach((item, index) => {
            const marker = this.markers.get(item.id);

            if (!marker) return this.addMarker(item, index);

            this.removeMarker(item.id);
            this.addMarker(item, index);
        });

        this.markers.forEach((el, id) => {
            const removable = markers.every((item) => item.id !== id);
            if (removable) this.removeMarker(id);
        });
    };

    public removeMarker = (id: number) => {
        this.markers.get(id)?.marker.remove();
        this.markers.delete(id);
    };

    public addMarker = (markerParams: MarkerParams, index: number) => {
        const icon = new Image();
        icon.src = getIconByViewType(markerParams.viewType, ruAlphabet[index]);

        const marker = new Marker({
            element: icon,
            draggable: true,
            anchor: "bottom",
        })
            .setLngLat(markerParams.lngLat)
            .addTo(this.map)
            .on("dragend", (event: { type: "dragend"; target: Marker }) => {
                this.emit(this.events.dragend, { id: markerParams.id, lngLat: event.target.getLngLat() });
            });

        this.markers.set(markerParams.id, {
            marker,
            viewType: markerParams.viewType,
        });
    };
}
