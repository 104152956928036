import { ServiceRegistry } from "services";
import { TrafficLightDomain } from "app-domain";
import { EventTypeCode } from "app-domain/traffic-light/enums";
import { trafficLightStatusList } from "trafficlight-dispatcher/models/trafficlight-status";
import {
    TrafficLightDetector,
    CameraInfo,
    TrafficLightModelInfo,
    TrafficLightMessage,
    TrafficLightPhase,
    TrafficLightPhaseStart,
    TrafficLightState,
    TrafficLightError,
    trafficLightErrorTypes,
    PagedCollection,
    TrafficLightErrorCode,
    TrafficLightCyclePhase,
    TrafficLightDetectorType,
} from "./models";
import { TrafficLightControlMode, trafficLightControlModes } from "./models/trafficlight-control-mode";
import {
    TrafficLightErrorStatus,
    TrafficLightErrorStatusCode,
    trafficLightErrorStatusList,
} from "./models/trafficlight-error-status";
import { TrafficLightEventTypeCode } from "./models/trafficlight-event-type";
import { DetectorStateMessage, DetectorTriggerMessage, TrafficLightDispatcher } from "./trafficlight-dispatcher";
import { TrafficLightGroup } from "./trafficlight-group";
import {
    TrafficLightDirection,
    TrafficLightDirectionInfo,
    TrafficLightDirectionLane,
    TrafficLightDirectionLaneMetrix,
} from "./trafficlight-direction";
import { TrafficLightDispatcherItemBase, TrafficLightDispatcherItemType } from "./trafficlight-dispatcher-item";
import { phaseGroupEntries } from "./traffic-light.constants";

export type PhaseItemsGroup = {
    name: string;
    items: TrafficLightPhase[];
};

export interface LogFilter {
    /**Начальная дата */
    from?: Date;

    /**Конечная дата */
    to?: Date;

    /**Номер страницы */
    page?: number;

    /**Размер страницы */
    pageSize?: number;
}

/**Фильтр для запроса лога работы СО */
export interface TrafficLightLogFilter extends LogFilter {
    /**Список типов событий */
    eventType?: TrafficLightEventTypeCode[];

    /**Список режимов работы */
    controlMode?: TrafficLightDomain.ControlModeCode[];

    /**Состояние СО */
    status?: TrafficLightDomain.Enums.StatusCode[];

    /**Код ошибки */
    errorCode?: TrafficLightErrorCode[];
}

export interface TrafficLightProps {
    id: number;
    location: { lat: number; lng: number };
    boxSize?: number;
    num: string;
    address?: string;
    streets?: string[];
    dispatcher: TrafficLightDispatcher;
    activeCycleNum?: number;
    activeIssueCount: number;
    userDisplayName?: string;
}

export type AssetDataShape = {
    isFetching: boolean;
    data: TrafficLightDomain.AssetDataT | null;
};

export interface TrafficLightEvents {
    initialized: () => void;
    currentPhaseChanged: (args: { currentPhase: TrafficLightPhaseStart | null }) => void;
    message: (args: { message: TrafficLightMessage }) => void;
    subTactChanged: (args: { isSubTact: boolean }) => void;
    statusChanged: (args: { status: TrafficLightDomain.Enums.StatusCode }) => void;
    errorStatusChanged: () => void;
    controlModeChanged: (args: { controlMode: TrafficLightDomain.ControlModeCode }) => void;
    activeProgramChanged: (args: { value: number | null }) => void;
    controlSessionChanged: () => void;
    directionLaneIsBusyChanged: (args: { lane: TrafficLightDirectionLane; isBusy: boolean }) => void;
    directionLaneMetricsChanged: (args: {
        lane: TrafficLightDirectionLane;
        metrix: TrafficLightDirectionLaneMetrix;
    }) => void;
    adaptiveModuleEnabledChanged: (args: { enabled: boolean }) => void;
    cyclesChanged: () => void;
    assetDataChanged: () => void;
    governanceChanged: (governance: TrafficLightDomain.Governance | null) => void;
}

export class TrafficLight extends TrafficLightDispatcherItemBase<TrafficLightEvents> {
    public id: number;
    public location: {
        lat: number;
        lng: number;
    };
    /** Паспортные и кастомные циклы */
    public cycles: TrafficLightDomain.CustomCycle[] = [];
    public controlMode: TrafficLightControlMode = trafficLightControlModes[TrafficLightDomain.ControlModeCode.Unknown];
    public status: TrafficLightDomain.Types.CodeDictionary<TrafficLightDomain.Enums.StatusCode> =
        trafficLightStatusList[TrafficLightDomain.Enums.StatusCode.Unknown];
    /** @deprecated Данный статус будет переработан и будет определяться на back-end */
    public errorStatus: TrafficLightErrorStatus = trafficLightErrorStatusList[TrafficLightErrorStatusCode.NotManaged];
    public phases: TrafficLightPhase[] = [];
    public phasesByGroups: PhaseItemsGroup[] = [];
    public currentPhase: TrafficLightPhaseStart | null = null;
    public num: string;
    public boxSize?: number;
    public address?: string;
    public streets?: string[];
    public modelInfo?: TrafficLightModelInfo;
    /** Направления по паспорту */
    public directions: TrafficLightDirection[] = [];
    public isSubTact?: boolean;
    public cameras: CameraInfo[] = [];
    public activeCycleNum?: number;
    public groups: TrafficLightGroup[] = [];
    public activeIssueCount: number;
    public userDisplayName?: string;
    public readonly directionLaneByDetector: { [key: string]: TrafficLightDirectionLane } = {};

    private _initPromise: Promise<void> | null = null;
    private _isInitialized: boolean = false;
    private _errors: TrafficLightError[] = [];
    private _malfunctionType: number = 0;
    private _directionByNum: { [key: number]: TrafficLightDirection } = {};
    private _isAdaptiveModuleEnabled: boolean = false;
    private _governance: TrafficLightDomain.Governance | null = null;
    private trafficLightService = ServiceRegistry.trafficLightService;
    /** @deprecated Будет переработан */
    private assetDataShape: AssetDataShape = {
        isFetching: false,
        data: null,
    };

    constructor(args: TrafficLightProps) {
        super(args.dispatcher, TrafficLightDispatcherItemType.trafficLight);
        const {
            id,
            location,
            boxSize,
            num,
            address,
            streets,
            activeCycleNum,
            activeIssueCount,
            userDisplayName,
        } = args;

        this.id = id;
        this.location = location;
        this.boxSize = boxSize;
        this.num = num;
        this.address = address;
        this.streets = streets;
        this.activeCycleNum = activeCycleNum;
        this.activeIssueCount = activeIssueCount;
        this.userDisplayName = userDisplayName;

        this.setMaxListeners(100);
    }

    public static getErrorsFromStatusCode(statusCode: number) {
        return Object.values(trafficLightErrorTypes)
            .filter((e) => statusCode & e.code)
            .map((e) => ({ ...e }));
    }

    public async init() {
        if (this._isInitialized) return;
        if (this._initPromise === null) this._initPromise = this.initInternal();
        await this._initPromise;
    }

    public get governance() {
        return this._governance;
    }

    public set governance(governance: TrafficLightDomain.Governance | null) {
        this._governance = governance;
        this.emit("governanceChanged", this.governance);
    }

    public get assetData() {
        if (!this.assetDataShape.data && !this.assetDataShape.isFetching) {
            this.fetchAssetData();
        }
        return this.assetDataShape;
    }

    public removeGovernance() {
        this.governance = null;
        this.emit("governanceChanged", this.governance);
    }

    public getDirectionByNum(num: number) {
        return this._directionByNum[num];
    }

    /** @deprecated время цикла есть в самом цикле */
    public getCycleDuration(phases: TrafficLightCyclePhase[]) {
        const promTimes = this.getCyclePromTimes(phases);
        return phases.reduce((total, item, index) => {
            total += item.tBasic + promTimes[index];
            return total;
        }, 0);
    }

    /** @deprecated Расчет промтактов происходит на back-end */
    public getCyclePromTimes(phases: TrafficLightCyclePhase[]) {
        const promTimes: number[] = [];
        phases.forEach((p, index) => {
            const np = index < phases.length - 1 ? phases[index + 1] : phases[0];
            let tProm = 0;
            const phase = this.getPhaseByNum(p.phaseNumber);
            const nextPhase = this.getPhaseByNum(np.phaseNumber);

            if (phase && nextPhase) {
                this.directions.forEach((dir) => {
                    let tp = 0;
                    if (phase.directionByNum[dir.number] && !nextPhase.directionByNum[dir.number]) {
                        tp = dir.tGreenBlink + dir.tYellow + dir.tRed;
                    } else if (!phase.directionByNum[dir.number] && nextPhase.directionByNum[dir.number]) {
                        tp = dir.tRedYellow;
                    }
                    if (tProm < tp) tProm = tp;
                });
            }
            promTimes[index] = tProm;
        });
        return promTimes;
    }

    public getPhaseByNum(num: number) {
        return this.phases[num];
    }

    public onDetectorTriggerMessage(msg: DetectorTriggerMessage) {
        const lane = this.directionLaneByDetector[`${msg.detector}_${msg.channel}`];
        if (lane) lane.setIsBusy(msg.isBusy, true);
    }

    public onDetectorStateMessage(msg: DetectorStateMessage) {
        const lane = this.directionLaneByDetector[`${msg.detector}_${msg.channel}`];
        if (lane) lane.setMetrix(msg.metrix, true);
    }

    public get timer(): number {
        let timer = 0;
        const now = new Date().getTime();
        if (this.currentPhase) {
            const dt = Math.floor((now - this.currentPhase.start) / 500) / 2;
            if (dt < this.currentPhase!.phase.duration) {
                // const phasePercentage = dt / this.currentPhase.phase.duration;
                timer = Math.round(this.currentPhase.phase.duration - dt);
            }
        }
        return timer;
    }

    public getActiveCycle() {
        return this.cycles.find((cycle) => cycle.number === this.activeCycleNum) ?? null;
    }

    public get isAdaptiveModuleEnabled() {
        return this._isAdaptiveModuleEnabled;
    }

    /**
     * TODO: Перенести в сервис
     * @deprecated переедет в севис
     * */
    public async setAdaptiveModuleEnabled(enabled: boolean) {
        await this.dispatcher.fetchTrafficLight(`trafficlight/manage/facility/${this.id}/crt/guided`, {
            method: "PUT",
            body: enabled.toString(),
        });
    }

    public get criticalErrorCount(): number {
        return this._errors.filter((e) => e.isCritical).length;
    }

    public get nonCriticalErrorCount(): number {
        return this._errors.filter((e) => !e.isCritical).length;
    }

    /** Список ошибок СО */
    public get errors() {
        return this._errors;
    }

    public get statusCode() {
        return this._malfunctionType;
    }

    public get isInitialized() {
        return this._isInitialized;
    }

    public addCycle(cycle: TrafficLightDomain.CustomCycle) {
        this.cycles.push(cycle);
        this.emit("cyclesChanged");
    }

    public getActiveCycleTime() {
        return this.cycles?.find((item) => item.number === this.activeCycleNum)?.time;
    }

    public fetchTrafficlightLog(filter: TrafficLightLogFilter): Promise<PagedCollection<TrafficLightMessage> | null> {
        return this.dispatcher.fetchTrafficlightsLog([this.id], filter);
    }

    public async fetchDetectorLog(filter: LogFilter) {
        if (!this.dispatcher) return null;
        let url = `trafficlight/facility/${this.id}/detector/logs?count=10000`;

        if (filter.from) {
            url += `&start=${filter.from.toISOString()}`;
        }

        if (filter.to) {
            url += `&finish=${filter.to.toISOString()}`;
        }

        return await this.dispatcher.fetchTrafficLightJson(url);
    }

    /** @deprecated Фильтрация проводится в VM */
    public get isFilteredOut() {
        if (this.dispatcher && typeof this.dispatcher.trafficLightFilter === "function")
            return !this.dispatcher.trafficLightFilter(this);
        return false;
    }

    public processSignalMessage(message: TrafficLightMessage) {
        this.updateStateFromMessage(message);
        this.emit("message", { message });
    }

    /**
     * Инициализирует состояние СО в диспатчере
     * сразу после того, как запросит список СО
     */
    public initFromState(state: TrafficLightState) {
        this.activeCycleNum = state.program;

        this._isAdaptiveModuleEnabled = state.isGuidedAdaptiveAllowed;

        this._malfunctionType = state.malfunctionType;
        this._errors = TrafficLight.getErrorsFromStatusCode(this._malfunctionType);

        this.status =
            trafficLightStatusList[state.status] ?? trafficLightStatusList[TrafficLightDomain.Enums.StatusCode.Unknown];

        this.controlMode =
            trafficLightControlModes[state.controlMode] ??
            trafficLightControlModes[TrafficLightDomain.ControlModeCode.Unknown];

        if (
            this.status.code === TrafficLightDomain.Enums.StatusCode.Cycle ||
            this.status.code === TrafficLightDomain.Enums.StatusCode.Hold ||
            this.status.code === TrafficLightDomain.Enums.StatusCode.Coordination
        ) {
            if (this.isSubTact !== state.isPromTime) {
                this.isSubTact = state.isPromTime;
                this.emit("subTactChanged", { isSubTact: this.isSubTact });
            }

            this.currentPhase = {
                phase: {
                    num: state.phase,
                    duration: state.phaseTime,
                    next: state.nextPhase,
                },
                start: this.dispatcher.now() - state.secondsGone * 1000,
            };
        } else {
            this.currentPhase = null;
        }

        this._updateErrorStatus(state);

        this.updateGovernanceData(state.governance);
    }

    /** @deprecated */
    public async deleteCycle(cycle: TrafficLightDomain.CustomCycle) {
        if (!this.cycles) return;

        try {
            await this.dispatcher.fetchTrafficLight(`/cycle/${cycle.id}`, {
                method: "DELETE",
            });
            const index = this.cycles.indexOf(cycle);
            if (index !== -1) {
                this.cycles.splice(index, 1);
            }
            this.emit("cyclesChanged");
        } catch (error) {
            console.error(error);
        }
    }

    private async initInternal() {
        const response = await this.dispatcher.fetchTrafficLight(`/facility/${this.id}?includestate=true`);
        const data: {
            directions: TrafficLightDirectionInfo[];
            phases: TrafficLightPhase[];
            cameras?: CameraInfo[];
            detectors?: TrafficLightDetector[];
            streets: string[];
            address: string;
            lat: number;
            lng: number;
            num: string;
            modelInfo: TrafficLightModelInfo;
            cycles: TrafficLightDomain.CycleData[];
            state: TrafficLightState;
        } = await response.json();

        /** Временно централизованная загрузка циклов будет здесь, позже уедет в стор */
        const cycles = await this.trafficLightService.getCycles(this.id);

        if (!data || !data.directions || !data.lng) return;

        this.directions = data.directions
            .sort((a: { number: number }, b: { number: number }) => a.number - b.number)
            .map(
                (i) =>
                    new TrafficLightDirection({
                        trafficLight: this,
                        info: i,
                    })
            );

        this.directions.forEach((dir) => {
            this._directionByNum[dir.number] = dir;
        });

        if (data?.state?.malfunctions) {
            this._errors = data?.state?.malfunctions.map((m) => ({
                ...trafficLightErrorTypes[m.type],
                description: m.description,
            }));
        }

        this.address = data.address;
        this.location = { lat: data.lat, lng: data.lng };
        this.num = data.num;
        this.modelInfo = data.modelInfo;
        this.cameras = data.cameras ?? [];

        this.streets = data.streets;
        this.cycles = cycles;
        const phaseGroupMap = {} as { [key: string]: TrafficLightPhase[] };
        if (data.phases && data.phases.length) {
            this.phases = new Array(data.phases.length);
            for (let i = 0, len = data.phases.length; i < len; i++) {
                const phase = { ...data.phases[i] };
                this.phases[phase.number] = phase;
                phase.directionByNum = {};
                if (phase.directions && phase.directions.length) {
                    for (let j = 0, len1 = phase.directions.length; j < len1; j++) {
                        phase.directionByNum[phase.directions[j]] = true;
                    }
                }
                const groupName = phaseGroupEntries[phase.type];
                const groupItems = phaseGroupMap[groupName];
                if (groupItems) {
                    groupItems.push(phase);
                } else {
                    phaseGroupMap[groupName] = [phase];
                }
            }
        }

        this.phasesByGroups = Object.entries(phaseGroupMap).map(([name, items]) => ({ name, items }));

        if (data.detectors) {
            data.detectors.forEach((detector) => {
                detector.channels.forEach((channel) => {
                    const direction = this._directionByNum[channel.direction];
                    if (direction) {
                        let lane = direction.laneByNum[channel.channel];
                        if (!lane) {
                            lane = new TrafficLightDirectionLane({
                                direction,
                                num: channel.channel,
                            });
                            direction.lanes.push(lane);
                            if (detector.type === TrafficLightDetectorType.Video) direction.videoDetector = detector;
                            direction.laneByNum[channel.channel] = lane;
                        }

                        this.directionLaneByDetector[`${detector.number}_${channel.channel}`] = lane;
                    }
                });
            });
        }

        this.emit("initialized");
        this._isInitialized = true;
    }

    /** @deprecated Убрать после того, как на бекенде появится этот статус */
    private _updateErrorStatus(state: TrafficLightState) {
        if (!state.isOnline) {
            this.errorStatus = trafficLightErrorStatusList[TrafficLightErrorStatusCode.NotConnected];
        } else if (this.criticalErrorCount > 0) {
            this.errorStatus = trafficLightErrorStatusList[TrafficLightErrorStatusCode.Critical];
        } else if (this.nonCriticalErrorCount > 0) {
            this.errorStatus = trafficLightErrorStatusList[TrafficLightErrorStatusCode.HasErrors];
        } else {
            this.errorStatus = trafficLightErrorStatusList[TrafficLightErrorStatusCode.OK];
        }
    }

    /** Временная мера по обновлению объекта управления после сообщения из signalR */
    private async reloadGovernance() {
        const state: TrafficLightDomain.GovernanceData[] = await this.dispatcher.fetchTrafficLightJson(
            `trafficlight/state/${this.id}/governance`
        );
        this.updateGovernanceData(state[state.length - 1]);
    }

    /** Временный метод по обновлению данных объекта управления СО */
    private updateGovernanceData(governanceData?: TrafficLightDomain.GovernanceData | null) {
        if (!governanceData) return;
        // this.governance = new TrafficLightDomain.Governance(governanceData);
    }

    private updateStateFromMessage(message: TrafficLightMessage) {
        const { state, eventType } = message;

        if (!this.dispatcher) return;

        if (state.program && this.activeCycleNum !== state.program) {
            this.activeCycleNum = state.program;
            this.emit("activeProgramChanged", { value: this.activeCycleNum });
        }

        if (this.controlMode.code !== state.controlMode) {
            this.controlMode =
                trafficLightControlModes[state.controlMode] ??
                trafficLightControlModes[TrafficLightDomain.ControlModeCode.Unknown];
            this.emit("controlModeChanged", { controlMode: state.controlMode });
            this.dispatcher.emit("trafficLightControlModeChanged", {
                trafficLight: this,
                controlMode: this.controlMode.code,
            });
        }

        if (this.status.code !== state.status) {
            this.status =
                trafficLightStatusList[state.status] ??
                trafficLightStatusList[TrafficLightDomain.Enums.StatusCode.Unknown];
            this.emit("statusChanged", { status: state.status });
            this.dispatcher.emit("trafficLightStatusChanged", {
                trafficLight: this,
                status: this.status.code,
            });
        }

        if (this._malfunctionType !== state.malfunctionType) {
            this._malfunctionType = state.malfunctionType;
            this._errors = TrafficLight.getErrorsFromStatusCode(this._malfunctionType);
            this.emit("errorStatusChanged");
            this.dispatcher.emit("trafficLightErrorStatusChanged", {
                trafficLight: this,
                errorStatus: this.errorStatus.code,
            });
        }

        if (
            this.status.code === TrafficLightDomain.Enums.StatusCode.Cycle ||
            this.status.code === TrafficLightDomain.Enums.StatusCode.Hold ||
            this.status.code === TrafficLightDomain.Enums.StatusCode.Coordination
        ) {
            if (this.isSubTact !== state.isPromTime) {
                this.isSubTact = state.isPromTime;
                this.emit("subTactChanged", { isSubTact: this.isSubTact === true });
            }

            if (this.currentPhase?.phase?.num !== state.phase) {
                this.currentPhase = {
                    phase: {
                        num: state.phase,
                        duration: state.phaseTime,
                        next: state.nextPhase,
                    },
                    start: this.dispatcher.now() - state.secondsGone * 1000,
                };
                this.emit("currentPhaseChanged", { currentPhase: this.currentPhase });
            }
        } else {
            this.currentPhase = null;
            this.emit("currentPhaseChanged", { currentPhase: null });
        }

        if (this._isAdaptiveModuleEnabled !== state.isGuidedAdaptiveAllowed) {
            this._isAdaptiveModuleEnabled = state.isGuidedAdaptiveAllowed;
            this.emit("adaptiveModuleEnabledChanged", { enabled: this._isAdaptiveModuleEnabled });
        }

        this._updateErrorStatus(state);

        if (eventType === EventTypeCode.ReleaseGovernanceEvent) {
            this.removeGovernance();
        }

        // 12 - временно хардкоженный тип события, который сигнализирует об изменении объекта управления СО
        if ((eventType as number) === 12) {
            this.reloadGovernance();
        }
    }

    /** @deprecated Делается через сервис */
    private async fetchAssetData() {
        this.assetDataShape = { ...this.assetDataShape, isFetching: true };
        this.emit("assetDataChanged");
        const data = await this.trafficLightService.getTrafficLightAssetData({ id: this.id });
        this.assetDataShape = { isFetching: false, data };
        this.emit("assetDataChanged");
    }
}
