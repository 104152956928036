import { IssueDomain } from "app-domain";
import { Facility } from "api";

export const createIssues = (data: Facility.Types.FacilityIssuesResponseData[]): IssueDomain.Issue[] => {
    return data.map((item) => {
        const {
            id,
            status,
            num,
            descr,
            typeId,
            dateTimeStr,
            departmentName,
            statusName,
            registered,
            breakageTypeName,
        } = item;
        return new IssueDomain.Issue({
            id,
            num,
            status: {
                id: status,
                name: statusName,
                /** @note ждем пока дизайнеры скажут какие цвета надо ставить */
                //  color: `#${issueStatus.color}`,
            },
            type: {
                id: typeId,
                name: breakageTypeName,
            },
            description: descr,
            executor: departmentName,
            execute: registered ? new Date(registered) : null,
            lastUpdate: dateTimeStr ? new Date(dateTimeStr) : null,
        });
    });
};
