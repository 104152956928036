import { BaseApi } from "api/utils";
import { QueryParamsBuilder } from "lib";
import { TvCameraList, GetTvCameraFilter } from "./tv-camera-api.types";

export class TVCameraAPI extends BaseApi {
    public async getCameras(filter?: GetTvCameraFilter): Promise<TvCameraList> {
        const params = new QueryParamsBuilder()
            .addIterableParam("assetstatusid", filter?.issueStatus)
            .addIterableParam("monitoringstatusid", filter?.monitoringStatus)
            .addParam("search", filter?.search)
            .toString(true);

        const url = this.buildUrl("" + params);

        const result = await this.request({ url });
        return result;
    }

    protected buildUrl(url: string) {
        return `${this.baseUrl}/warehouse/camera${url}`;
    }
}
