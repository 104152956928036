export enum ControllerType {
    TrafficLight = "trafficLight",
    TrafficLightDetector = "trafficLightDetector",
    CooGroup = "cooGroup",
    RadarDetector = "radarDetector",
    RouteBuilding = "routeBuilding",
    ActiveSubstrate = "activeSubstrate",
    DtmTraffic = "dtmTraffic",
    Ksodd = "ksodd",
    WarehouseCamera = "warehouseCamera",
    WarehouseSpeedcam = "warehouseSpeedcam",
    WarehouseDetector = "warehouseDetector",
}

/** Режим работы слоев */
export enum WorkingMode {
    /** Режим по-умолчанию: все слои доступны для отображения и кликабельны */
    DEFAULT,
    /** Режим анализа маршрута: остальные слои не кликабельны */
    ROUTE_BUILDING,
}

/** Правила работы слоев в зависимости от режима отображения */
export const LayerVisibilityByType = {
    [WorkingMode.DEFAULT]: {
        [ControllerType.ActiveSubstrate]: true,
        [ControllerType.TrafficLight]: true,
        [ControllerType.TrafficLightDetector]: true,
        [ControllerType.RadarDetector]: true,
        [ControllerType.CooGroup]: true,
        [ControllerType.WarehouseCamera]: true,
        [ControllerType.WarehouseSpeedcam]: true,
    },
    [WorkingMode.ROUTE_BUILDING]: {
        [ControllerType.RouteBuilding]: true,
    },
} as {
    [k in WorkingMode]: {
        [t in ControllerType]: boolean;
    };
};
