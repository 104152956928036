import { makeAutoObservable } from "mobx";

export class Selection {
    private map = new Map<number, boolean>();

    constructor() {
        makeAutoObservable(this);
    }

    public get first() {
        let result = null;
        try {
            result = this.map.entries().next().value[0];
        } catch (e) {}
        return result;
    }

    public isSelected(id: number) {
        return this.map.has(id);
    }

    public add(id: number) {
        this.map.set(id, true);
    }

    public toggle(id: number) {
        if (this.isSelected(id)) return this.remove(id);
        return this.add(id);
    }

    public remove(id: number) {
        this.map.delete(id);
    }

    public clear() {
        this.map.clear();
    }
}
