import { ReactNode, memo } from "react";
import * as Markup from "./phase-group.styles";

type Props = {
    title: string;
    children: ReactNode;
};

const PhaseGroupComponent = (props: Props) => {
    const { title, children } = props;
    return (
        <Markup.Container>
            <Markup.Title>{title}</Markup.Title>
            {children}
        </Markup.Container>
    );
};

export const PhaseGroup = memo(PhaseGroupComponent);
