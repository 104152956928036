import React from "react";
import type { Props } from "./floating-modal.types";

let prevUserSelectValue: string = "";

// Отключает глобальный userSelect для того, чтобы он не мешал перемещению
// модального окна, если юзер случайно выделил текст на странице
const disableGlobalSelection = () => {
    // Берем тот стиль, который мог быть проставлен явно, а не по-умолчанию
    // если поле не было явно проставлено, то сохраненное значение будет ''
    prevUserSelectValue = document.body.style.userSelect;
    document.body.style.userSelect = "none";
};

const enableGlobalSelection = () => {
    document.body.style.userSelect = prevUserSelectValue;
};

const useUtils = () => {
    const modalRef = React.useRef<HTMLDivElement>(null);
    const isDragging = React.useRef(false);
    const offsets = React.useRef({ x: 0, y: 0 });
    const bounds = React.useRef({
        left: 0,
        top: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
    });

    const updatePosition = React.useCallback(
        (x: number, y: number) => {
            if (!modalRef.current) return;
            modalRef.current.style.left = `${x}px`;
            modalRef.current.style.top = `${y}px`;
        },
        [modalRef]
    );

    const calcPosition = React.useCallback(
        (x: number = 0, y: number = 0) => {
            if (!modalRef.current) return { nextY: 0, nextX: 0 };
            const { x: offsetX, y: offsetY } = offsets.current;
            const { width, height } = modalRef.current.getBoundingClientRect();

            let nextX = x - offsetX;
            let nextY = y - offsetY;

            if (nextX < 0) nextX = 0;
            if (nextY < 0) nextY = 0;

            if (nextX + width > bounds.current.right) {
                nextX = bounds.current.right - width;
            }

            if (nextY + height > bounds.current.bottom) {
                nextY = bounds.current.bottom - height;
            }

            return { nextX, nextY };
        },
        [offsets, bounds, modalRef]
    );

    const refreshPosition = React.useCallback(() => {
        if (!modalRef.current) return;
        offsets.current.x = 0;
        offsets.current.y = 0;
        const { x, y } = modalRef.current.getBoundingClientRect();
        const { nextX, nextY } = calcPosition(x, y);
        updatePosition(nextX, nextY);
    }, [modalRef, offsets, calcPosition, updatePosition]);

    return { modalRef, isDragging, offsets, bounds, updatePosition, calcPosition, refreshPosition };
};

export const useInitialized = (props: Props, utils: ReturnType<typeof useUtils>) => {
    const { modalRef, calcPosition, updatePosition } = utils;
    const defaultPosition = React.useRef(props.defaultPosition);

    React.useLayoutEffect(() => {
        const { nextX, nextY } = calcPosition(defaultPosition.current?.x, defaultPosition.current?.y);
        updatePosition(nextX, nextY);
    }, [modalRef, calcPosition, updatePosition]);
};

const useHandlers = (utils: ReturnType<typeof useUtils>) => {
    const { modalRef, isDragging, offsets, bounds } = utils;
    const { updatePosition, calcPosition, refreshPosition } = utils;

    const handleMove = React.useCallback(
        (event: MouseEvent) => {
            if (!isDragging.current || !modalRef.current) return;

            const { nextY, nextX } = calcPosition(event.pageX, event.pageY);
            updatePosition(nextX, nextY);
        },
        [isDragging, modalRef, calcPosition, updatePosition]
    );

    const handleMouseUp = React.useCallback(() => {
        isDragging.current = false;
        enableGlobalSelection();
        window.removeEventListener("pointermove", handleMove);
        window.removeEventListener("pointerup", handleMouseUp);
    }, [isDragging, handleMove]);

    const handleMouseDown = React.useCallback(
        (event: MouseEvent) => {
            if (!modalRef.current) return;
            disableGlobalSelection();
            const rect = modalRef.current.getBoundingClientRect();
            offsets.current.x = event.pageX - rect.x;
            offsets.current.y = event.pageY - rect.y;
            isDragging.current = true;
            window.addEventListener("pointermove", handleMove);
            window.addEventListener("pointerup", handleMouseUp);
        },
        [modalRef, offsets, isDragging, handleMove, handleMouseUp]
    );

    const handleGlobalResize = React.useCallback(() => {
        bounds.current.right = window.innerWidth;
        bounds.current.bottom = window.innerHeight;

        refreshPosition();
    }, [bounds, refreshPosition]);

    return { handleMouseDown, handleGlobalResize };
};

const useContainer = (props: Props) => {
    const { container } = props;
    const modalContainer = React.useRef(document.querySelector("#floating-modal-container"));

    const containerEl = React.useMemo(() => {
        return container ?? modalContainer.current;
    }, [container, modalContainer]);

    return { containerEl };
};

const useSubscribe = (utils: ReturnType<typeof useUtils>) => {
    const [dragElement, setDragElement] = React.useState<HTMLElement | null>(null);
    const { handleMouseDown, handleGlobalResize } = useHandlers(utils);

    React.useLayoutEffect(() => {
        if (!dragElement) return;
        dragElement.addEventListener("pointerdown", handleMouseDown);

        return () => {
            dragElement.removeEventListener("pointerdown", handleMouseDown);
        };
    }, [dragElement, handleMouseDown]);

    React.useLayoutEffect(() => {
        window.addEventListener("resize", handleGlobalResize);

        return () => {
            window.removeEventListener("resize", handleGlobalResize);
        };
    }, [handleGlobalResize]);

    return { setDragElement };
};

export const useFloatingModal = (props: Props) => {
    const { containerEl } = useContainer(props);
    const utils = useUtils();
    const { setDragElement } = useSubscribe(utils);

    useInitialized(props, utils);

    return { containerEl, modalRef: utils.modalRef, setDragElement };
};
