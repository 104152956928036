import format from "date-fns/format";
import { detector } from "shared/constants";
import { Detector } from "app-domain";

export class IndicatorsDTO implements Detector.Models.Indicators {
    /** Время показателей */
    date = { ...detector.indicators.date, value: format(new Date(), "HH:mm dd.MM.yyyy") };
    /** Скорость */
    speed = { ...detector.indicators.speed, value: "0" };
    /** Интенсивность */
    volume = { ...detector.indicators.volume, value: "0" };
    /** Загруженность */
    utilization = { ...detector.indicators.utilization, value: "0" };
    /** Занятость */
    occ = { ...detector.indicators.occ, value: "0" };

    constructor(data?: any | undefined) {
        if (typeof data?.timestamp === "string") this.date.value = format(new Date(data.timestamp), "kk:mm dd.MM.yyyy");
        if (typeof data?.speed === "number") this.speed.value = String(data.speed);
        if (typeof data?.volume === "number") this.volume.value = String(data.volume);
        if (typeof data?.utilization === "number")
            this.utilization.value = String(Math.round(data.utilization * 100) / 100);
        if (typeof data?.occ === "number") this.occ.value = String(Math.round(Math.round(data.occ * 100) / 100));
    }
}
