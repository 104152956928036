import { DashboardAPI } from "api";
import { SummaryDTO } from "./units";
import type { IService } from "./dashboard-service.types";

export class DashboardService implements IService {
    private api: DashboardAPI = new DashboardAPI();

    public empty = () => new SummaryDTO();

    public summary = async (): Promise<SummaryDTO> => {
        try {
            const result = await this.api.summary();
            return new SummaryDTO(result);
        } catch (error) {
            return new SummaryDTO();
        }
    };
}
