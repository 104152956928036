import { useCallback } from "react";
import { Routing } from "routing";
import { useAppState } from "./use-app-state";

export const useSetActiveDetector = (id: number) => {
    const { detectorListStore } = useAppState();
    const navigate = Routing.Detectors.Hooks.useDetectorDetailsNavigator();

    return useCallback(() => {
        const info = detectorListStore.detectors.get(id);

        if (!info) return;

        const args = {
            options: {
                uid: info.id,
                coordinate: { lat: info.lat, lng: info.lng },
                statusOnIssues: info.statusOnIssues.id,
                statusOnMonitoring: info.statusOnMonitoring.id,
            },
        };

        detectorListStore.selectDetector(args);
        navigate(id);
    }, [detectorListStore, navigate, id]);
};
