import { reaction } from "mobx";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightManager, TrafficLightStore } from "app/store";
import { MapController } from "map-controller";

export class TrafficLightsBroker {
    private trafficLightsChangeEffect: VoidFunction = () => {};

    constructor(
        private mapController: MapController,
        private trafficLightStore: TrafficLightStore,
        private trafficLightManager: TrafficLightManager
    ) {
        this.subscribe();
    }

    public destroy() {
        this.mapController.off(this.mapController.events.load, this.initSubs);
        this.trafficLightLayerController?.emitter?.off(
            this.trafficLightLayerController.emitter.events.trafficLightVisualRender,
            this.handleUpdateTrafficLightMetaData
        );
        this.trafficLightsChangeEffect();
    }

    private get trafficLightLayerController() {
        return this.mapController.layerControllers.trafficLightController;
    }

    private readonly handleUpdateTrafficLightMetaData = (trafficLight: TrafficLightDomain.TrafficLight) => {
        this.trafficLightManager.initTrafficLight(trafficLight);
    };

    private readonly handleUpdateTrafficLightLayerData = (trafficLights: TrafficLightDomain.TrafficLight[]) => {
        this.trafficLightLayerController?.setData(trafficLights);
    };

    private initSubs = () => {
        this.trafficLightLayerController?.emitter.on(
            this.trafficLightLayerController.emitter.events.trafficLightVisualRender,
            this.handleUpdateTrafficLightMetaData
        );
        this.trafficLightsChangeEffect = reaction(
            () => this.trafficLightStore.filteredList,
            this.handleUpdateTrafficLightLayerData,
            {
                fireImmediately: true,
            }
        );
    };

    private subscribe() {
        this.mapController.on(this.mapController.events.load, this.initSubs);
    }
}
