import { lightTheme } from "@megapolis/uikit/theme";
import { Status } from "../enums";

const { colors } = lightTheme;

export const getStatusColor = (status: Status) => {
    if (status === Status.Coordination) return colors.indigo.basic;
    if (status === Status.GreenStreet) return colors.green.basic;
    return colors.gray.basic;
};
