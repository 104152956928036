import mapboxgl from "mapbox-gl";
import { Layer } from "../layer";
import { getTrafficLightIcon } from "./trafficlights-layer.utils";

const TRAFFIC_LIGHTS_LAYER_IMAGE_KEY = "TRAFFIC_LIGHTS_LAYER_IMAGE_KEY";

export class TrafficlightsLayer extends Layer<mapboxgl.SymbolLayer> {
    protected _layerId = "ROUTE_BUILDING_TRAFFIC_LIGHTS_LAYER";
    protected layer: mapboxgl.SymbolLayer = {
        id: this.layerId,
        type: "symbol",
        source: this.layerId,
        layout: {
            "icon-image": TRAFFIC_LIGHTS_LAYER_IMAGE_KEY,
            "icon-size": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 1, 9, 1, 12, 1, 18, 1],
            "icon-rotate": ["get", "bearing"],
            "icon-allow-overlap": true,
        },
    };

    constructor(protected map: mapboxgl.Map) {
        super(map);
    }

    public destroy = () => {
        super.destroy();
        if (this.map.hasImage(TRAFFIC_LIGHTS_LAYER_IMAGE_KEY)) {
            this.map.removeImage(TRAFFIC_LIGHTS_LAYER_IMAGE_KEY);
        }
    };

    protected async addLayer() {
        const icon = new Image();
        icon.src = getTrafficLightIcon();
        icon.onload = () => {
            if (!this.map.hasImage(TRAFFIC_LIGHTS_LAYER_IMAGE_KEY)) {
                this.map.addImage(TRAFFIC_LIGHTS_LAYER_IMAGE_KEY, icon);
            }
            super.addLayer();
        };
    }
}
