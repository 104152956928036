import { useContext, memo, ReactNode } from "react";
import { AccordionContext } from "../accordion.context";
import * as Markup from "./accordion-content.styles";

export type AccordionContentProps = {
    children: ReactNode;
    className?: string;
};

const AccordionContentComponent = (props: AccordionContentProps) => {
    const { isExpanded } = useContext(AccordionContext);
    const { className, children } = props;

    if (!isExpanded) return null;

    return <Markup.Container className={className}>{children}</Markup.Container>;
};

export const AccordionContent = memo(AccordionContentComponent);
