import { DictionaryItem } from ".";
import { TrafficLightDomain } from "app-domain";

export const trafficLightControlModeCodes = Object.values(TrafficLightDomain.ControlModeCode);

export interface TrafficLightControlMode extends DictionaryItem<TrafficLightDomain.ControlModeCode> {}

export const trafficLightControlModes: { [key in TrafficLightDomain.ControlModeCode]: TrafficLightControlMode } = {
    [TrafficLightDomain.ControlModeCode.LocalPlan]: {
        code: TrafficLightDomain.ControlModeCode.LocalPlan,
        name: "Локальный режим",
        shortName: "ЛР",
        color: "#32C758",
    },
    [TrafficLightDomain.ControlModeCode.Adaptive]: {
        code: TrafficLightDomain.ControlModeCode.Adaptive,
        name: "Адаптивное управление",
        shortName: "А",
        color: "#7BD5FD",
    },
    [TrafficLightDomain.ControlModeCode.Group]: {
        code: TrafficLightDomain.ControlModeCode.Group,
        name: "Группа координации",
        shortName: "ГК",
        color: "#B051E0",
    },
    [TrafficLightDomain.ControlModeCode.User]: {
        code: TrafficLightDomain.ControlModeCode.User,
        name: "Диспетчерское управление",
        shortName: "УУ",
        color: "#027BFF",
    },
    [TrafficLightDomain.ControlModeCode.Console]: {
        code: TrafficLightDomain.ControlModeCode.Console,
        name: "Ручное управление",
        shortName: "РУ",
        color: "#0262CC",
    },
    [TrafficLightDomain.ControlModeCode.Unknown]: {
        code: TrafficLightDomain.ControlModeCode.Unknown,
        name: "Нет данных",
        shortName: "НД",
        color: "rgba(3, 40, 76, 0.31)",
    },
};
