import React from "react";
import ReactDOM from "react-dom";
import { useDropdown } from "./dropdown.hooks";
import { Props } from "./dropdown.types";
import * as Markup from "./dropdown.styles";

const PortalElement = document.getElementById("drop-down");

export const DropdownPortal: React.FC<Props> = (props) => {
    const { handleClick, handleContentClick, style, setAnchorRef, setContentRef, isOpen } = useDropdown(props);

    return (
        <div className={props.className}>
            <span ref={setAnchorRef} role="button" onClick={handleClick}>
                {props.children}
            </span>
            {isOpen &&
                PortalElement &&
                ReactDOM.createPortal(
                    <Markup.Portal>
                        <Markup.ContentWrapper ref={setContentRef} style={style} onClick={handleContentClick}>
                            {props.content}
                        </Markup.ContentWrapper>
                    </Markup.Portal>,
                    PortalElement
                )}
        </div>
    );
};
