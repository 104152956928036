import { TrafficLightDomain } from "app-domain";

export class TrafficLightGovernanceFactory {
    public create(data: TrafficLightDomain.GovernanceData): TrafficLightDomain.Governance {
        const newData = {
            ...data,
            start: typeof data.start === "string" ? new Date(data.start) : data.start,
            finish: typeof data.finish === "string" ? new Date(data.finish) : data.finish,
            holdExpireDate:
                typeof data.holdExpireDate === "string" ? new Date(data.holdExpireDate) : data.holdExpireDate,
            commandStart: typeof data.commandStart === "string" ? new Date(data.commandStart) : data.commandStart,
        };
        return new TrafficLightDomain.Governance(data.id, data.facilityId, newData);
    }
}
