import styled from "styled-components";
import { Typography } from "@megapolis/uikit/typography";

export const Container = styled.div`
    display: grid;
    gap: 4px;
`;

export const Caption = styled(Typography.Text3)`
    color: ${({ theme }) => `${theme.colors.content.secondary}`};
`;

export const Value = styled(Typography.Text2)``;

export const Placeholder = styled(Value)`
    text-shadow: ${({ theme }) => `0 0 ${theme.spacings[2].str} ${theme.colors.black.basic}`};
    color: transparent;
`;
