import mapboxgl from "mapbox-gl";
import { Layer } from "../layer";
import { RouteBorderPaint, RoutePaint } from "./route-layer.constants";

export class RouteLayer extends Layer<mapboxgl.LineLayer> {
    protected readonly _layerId = ["route-path", "route-border-path"];
    protected layer: mapboxgl.LineLayer[] = [
        {
            id: this._layerId[1],
            type: "line",
            source: this._layerId[0],
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: RouteBorderPaint,
        },
        {
            id: this._layerId[0],
            type: "line",
            source: this._layerId[0],
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: RoutePaint,
        },
    ];

    constructor(protected map: mapboxgl.Map) {
        super(map);
    }
}
