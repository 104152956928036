import styled from "styled-components/macro";

export const Text = styled.span`
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
`;

export const Wrapper = styled.div`
    & * {
        overflow: hidden;
    }
`;
