import { WarehouseAPI } from "../warehouse-api";
import { AssetSpeedcamDetails } from "./asset-speedcam.types";

export class AssetSpeedcamAPI extends WarehouseAPI {
    /** Получение информации о экземпляре имущества по идентификатору */
    public getById(id: number): Promise<AssetSpeedcamDetails> {
        const url = this.buildUrl(`/${id}`);
        const result = this.request({ url });
        return result;
    }

    protected buildUrl(url: string, params = "") {
        return super.buildUrl(`/assetspeedcam${url}${params}`);
    }
}
