export const averageTextWidth = 50;

// const initialData = {
//     x: 0,
//     text: "",
//     width: 0,
//     forwardY: 0,
//     backwardY: 0,
//     strokeStyle: "",
//     hasBackwardDirection: false,
// };

export type PhaseLabelDataInput = {
    x: number;
    phaseNumber: number;
    phaseTime: number;
    width: number;
    forwardY: number;
    backwardY: number;
    strokeStyle: string;
    hasBackwardDirection: boolean;
};

export type PhaseLabelData = {
    x: number;
    text: string;
    width: number;
    forwardY: number;
    backwardY: number;
    strokeStyle: string;
    hasBackwardDirection: boolean;
};

export const createPhaseLabelData = (args: PhaseLabelDataInput): PhaseLabelData => ({
    x: args.x,
    width: args.width,
    forwardY: args.forwardY,
    backwardY: args.backwardY,
    strokeStyle: args.strokeStyle,
    hasBackwardDirection: args.hasBackwardDirection,
    text: args.width > averageTextWidth ? `Ф${args.phaseNumber} - ${args.phaseTime}с` : `${args.phaseTime}с`,
});
