import { TrafficLightGroupGovernance } from "./models";
import {
    TrafficLightGroupStatus,
    TrafficLightGroupStatusCode,
    trafficLightGroupStatusList,
} from "./models/group-status";
import { ControllSession } from "./trafficlight-control-session";
import { TrafficLightGroup } from "./trafficlight-group";

export class TrafficLightGroupControlSession extends ControllSession {
    readonly group: TrafficLightGroup;
    readonly id: number;
    /**Актитивированный статус */
    status?: TrafficLightGroupStatus;

    constructor(group: TrafficLightGroup, governance: TrafficLightGroupGovernance) {
        super(governance);
        this.group = group;
        this.id = governance.id;
    }

    async updateInfo() {
        const governance = await this.group.dispatcher.fetchTrafficLightJson(`group/state/governance/${this.id}`);
        this.update(governance);
        this.group.emit("controlSessionChanged");
    }

    async applyStatus(status?: TrafficLightGroupStatusCode) {
        await this.group.dispatcher.fetchTrafficLight(`trafficlight/manage/group/${this.group.id}/status`, {
            method: "POST",
            body: status !== undefined ? status.toString() : "null",
        });
        await this.updateInfo();
    }

    update(governance: TrafficLightGroupGovernance) {
        super.update(governance);
        this.status = governance.status !== undefined ? trafficLightGroupStatusList[governance.status] : undefined;
    }

    async cancel() {
        await this.applyStatus();
    }

    async renew() {
        if (this.status?.code === TrafficLightGroupStatusCode.GreenLine) {
            await this.applyStatus(TrafficLightGroupStatusCode.GreenLine);
        }
    }
}
