import { TrafficLightDirectionTypeCode } from "trafficlight-dispatcher/models";
import CarIconURL from "diagrams/icons/car.svg";
import BusIconURL from "diagrams/icons/bus.svg";
import PedestrianIconURL from "diagrams/icons/pedestrian.svg";

export const DirectionTypeIconURLMap = {
    [TrafficLightDirectionTypeCode.Pedestrian]: PedestrianIconURL,
    [TrafficLightDirectionTypeCode.PublicTransport]: BusIconURL,
    [TrafficLightDirectionTypeCode.Transport]: CarIconURL,
} as {
    [key: number]: string;
};
