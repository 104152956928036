import { REACT_APP_API_DETECTOR_SERVICE } from "env-data";
import { BaseApi } from "api/utils";
import type { SummaryById, DetectorInfo, Statistics, GetDetectorLaneMetrics } from "./detector-service-api.types";
import { DateUtils } from "shared/utils";

export class DetectorServiceAPI extends BaseApi {
    constructor() {
        super(REACT_APP_API_DETECTOR_SERVICE);
    }

    public getSummaryById(id: number): Promise<SummaryById> {
        const url = this.buildUrl(`/${id}/summary`);
        const result = this.request({ url });
        return result;
    }

    public getSummaryHistoryById(id: number): Promise<SummaryById[]> {
        const url = this.buildUrl(`/${id}/summary/history`);
        const result = this.request({ url });
        return result;
    }

    public getById(id: number): Promise<DetectorInfo> {
        const url = this.buildUrl(`/${id}`);

        return this.request({ url });
    }

    public getLatestStatisticsById(id: number): Promise<Statistics> {
        const url = this.buildUrl(`/${id}/lanes`);
        const result = this.request({ url });
        return result;
    }

    public getDetectorLaneMetrics({
        id,
        interval,
        dateFrom,
        dateTo,
        signal,
    }: GetDetectorLaneMetrics): Promise<Statistics[]> | undefined {
        const from = DateUtils.format(dateFrom, { format: DateUtils.Formats.ISO });
        const to = DateUtils.format(dateTo, { format: DateUtils.Formats.ISO });
        if (signal?.aborted) return;
        const url = this.buildUrl(`/${id}/lanes?interval=${interval}&from=${from}&to=${to}`);
        return this.request({ url, signal });
    }

    protected buildUrl(url: string) {
        return `${this.baseUrl}/detector${url}`;
    }
}
