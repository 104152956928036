import { Input, InputProps } from "@megapolis/uikit/input";
import { useInputIndicator } from "@megapolis/uikit/input-indicator";
import { ChangeEvent, memo, useCallback } from "react";
import * as Markup from "./search-input.styles";

const icon = <Markup.Icon />;

type ChangeCallbackSign = (value: string, { event }: { event: ChangeEvent<HTMLInputElement> }) => void;

export type Props = Omit<InputProps, "onChange" | "value"> & {
    value?: string;
    onChange?: ChangeCallbackSign;
    onClear?: VoidFunction;
};

export const SearchInput = memo((props: Props) => {
    const { isLoading, hasError, errorText, onChange, onClear, value, ...rest } = props;
    const indicator = useInputIndicator({
        isLoading: props.isLoading,
        hasError: props.hasError,
        errorText: props.errorText,
    });

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onChange?.(event.target.value, { event });
        },
        [onChange]
    );

    const afterBlock = (
        <Markup.IndicatorWrapper>
            {indicator}
            {value && value.length > 0 && onClear && <Markup.ClearBtn onClick={onClear} />}
        </Markup.IndicatorWrapper>
    );

    return (
        <Input before={icon} after={afterBlock} hasError={hasError} value={value} onChange={handleChange} {...rest} />
    );
});
