import { ReactNode } from "react";
import styled, { css } from "styled-components/macro";
import { Button } from "@megapolis/uikit/button";

export const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacings[3].str};
    width: 100%;
`;

export const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
`;

export const CollapseButton = styled(Button)<{ text: string; icon: ReactNode }>((props) => {
    const {
        theme: { colors, typography },
    } = props;
    return css`
        color: ${colors.blue.basic};
        font: ${typography.h6};
        width: fit-content;
    `;
});
