import { createDirectionPhaseData, DirectionPhaseData } from "./direction-phase-data";
import { PhaseView } from "../../../phase-view";

export type DirectionInputData = {
    y: number;
    strokeStyle: string;
    pixelsPerSecond: number;
    dragAnchorWidth: number;
    directionBarHeight: number;
    directionBarGap: number;
    hasBackwardDirection: boolean;
    phases: PhaseView[];
};

export type DirectionData = DirectionPhaseData[];

export const createDirectionData = (args: DirectionInputData): DirectionData =>
    args.phases.map((phase) =>
        createDirectionPhaseData({
            y: args.y,
            isAllow: phase.allow,
            direction: phase.direction,
            strokeStyle: args.strokeStyle,
            phaseNumber: phase.phaseNumber,
            x: phase.drawOptions.phaseStartX,
            width: phase.drawOptions.phaseWidth,
            pixelsPerSecond: args.pixelsPerSecond,
            dragAnchorWidth: args.dragAnchorWidth,
            directionBarGap: args.directionBarGap,
            fillColor: phase.drawOptions.fillColor,
            phaseTime: phase.drawOptions.phaseTime,
            directionBarHeight: args.directionBarHeight,
            hasBackwardDirection: args.hasBackwardDirection,
            drawTProm: phase.allow !== phase.nextPhase?.allow,
        })
    );
