import { CooGroupDomain, RouteDomain } from "app-domain";
import * as constants from "./coo-group-editor.constants";

/**
 *  Генерирует id для адреса исходя из существующих,
 *  id должен быть отрицательным числом,
 *  это признак что адрес создан в ручную
 *  */
export const generateIdFromAddresses = (addresses: RouteDomain.Point[]) =>
    -Math.abs(Math.min(...addresses.map((item) => item.id)) - 1);

export const getCooGroupNameError = (name: string): Nullable<constants.CooGroupNameErrorMessages> => {
    if (!name) {
        return constants.CooGroupNameErrorMessages.REQUIRED;
    }

    if (name.length < 5) {
        return constants.CooGroupNameErrorMessages.MIN_LENGTH;
    }

    if (name.replace(constants.cooGroupAvailableSymbols, "").length) {
        return constants.CooGroupNameErrorMessages.AVAILABLE_SYMBOLS;
    }

    return null;
};

interface UpdateData {
    points: RouteDomain.Point[];
    cooGroupName: string;
    facilities: CooGroupDomain.Facility[];
    route: RouteDomain.MapRouteFeatures;
}

export const getUpdateData = (cooGroup: CooGroupDomain.CooGroup, data: UpdateData): Partial<UpdateData> | null => {
    const result: Partial<UpdateData> = {};

    if (cooGroup.name !== data.cooGroupName.trim()) {
        result.cooGroupName = data.cooGroupName;
    }

    const hasPointsChanges = cooGroup.points.some((point, index) => {
        const { longitude, latitude, fullAddress } = data.points[index];
        return point.address !== fullAddress || latitude !== point.lat || longitude !== point.lng;
    });

    if (hasPointsChanges) {
        result.points = data.points;
        result.route = data.route;
    }

    if (hasPointsChanges) {
        const hasFacilityChanges = data.facilities.some(
            (facility, index) => facility.facilityId !== data.facilities[index].facilityId
        );
        if (hasFacilityChanges) {
            result.facilities = data.facilities;
        }
    }

    if (!Object.keys(result).length) return null;

    return result;
};

export const getCycleEnableStateChanges = (cycles: CooGroupDomain.Cycle[], cyclesEnableState: Map<number, boolean>) =>
    cycles.reduce((changes: { cycleId: number; value: boolean }[], cycle) => {
        const cycleEnableState = cyclesEnableState.get(cycle.id);
        if (typeof cycleEnableState === "boolean" && cycle.isEnabled !== cycleEnableState) {
            changes.push({
                cycleId: cycle.id,
                value: !cycle.isEnabled,
            });
        }
        return changes;
    }, []);
