import { createTimescaleData, createTimeData, TimescaleData, TimeData } from "./timescale";
import { createDirectionData } from "./direction";
import { createDirectionLabelData } from "./direction-label";
import { createDirectionStartAnchorsData } from "./direction-start-anchors";
import { createEmptyPartData } from "./empty-part";
import { createGreenWaveData } from "./green-wave";
import { createHeadData } from "./head";
import { createOverlayData } from "./overlay";
import { createPointerIconsData, createResizeHandleData } from "./phase-icons";
import { createSpeedDistanceData } from "./speed-distance-legend";
import { mapCycleViewData } from "./draw-data.utils";
import { DrawDataInput, CycleDrawData } from "./draw-data.types";

export class DrawData {
    public timescale: {
        time: TimeData;
        scale: TimescaleData;
    };
    public cycles: CycleDrawData[] = [];

    constructor() {
        this.timescale = {
            time: createTimeData(),
            scale: createTimescaleData(),
        };
    }

    public update(data: DrawDataInput) {
        this.updateTimescale(data);
        this.updateCycles(data);
    }

    private updateTimescale(data: DrawDataInput) {
        this.timescale.time = createTimeData({
            height: data.height,
            pixelsPerSecond: data.pixelsPerSecond,
            totalTime: data.totalViewTime,
            width: data.width,
        });
        this.timescale.scale = createTimescaleData({
            height: data.height,
            pixelsPerSecond: data.pixelsPerSecond,
            totalTime: data.totalViewTime,
            width: data.width,
        });
    }

    private updateCycles(data: DrawDataInput) {
        this.cycles = data.cycles.map((cycle) => {
            const map = mapCycleViewData(data, cycle);
            return {
                headData: createHeadData({
                    y: map.y,
                    font: map.defaultFont,
                    fontSize: map.defaultFontSize,
                    isEditMode: map.isEditMode,
                    time: map.cycleDurationTime,
                    cycleHeadGap: map.cycleHeadGap,
                    cycleHeadHeight: map.cycleHeadHeight,
                    trafficLightNumber: map.trafficLightNumber,
                }),
                greenWaveData: map.polygons.map(createGreenWaveData),
                emptyPartData: map.hasEmptyPart
                    ? createEmptyPartData({
                          x: 0,
                          y: map.y,
                          width: map.width,
                          height: map.directionBarHeight,
                          fillStyle: map.emptyPartFillStyle,
                          pixelsPerSecond: map.pixelsPerSecond,
                          directionBarHeight: map.directionBarHeight,
                          hasBackwardDirection: map.hasBackwardDirection,
                          backwardDirectionGap: map.directionBarGap,
                      })
                    : null,
                overlayData: map.hasOverlay
                    ? createOverlayData({
                          y: map.y,
                          shift: map.shift,
                          height: map.height,
                          totalViewTime: map.totalViewTime,
                          directionBarGap: map.directionBarGap,
                          pixelsPerSecond: map.pixelsPerSecond,
                          cycleDurationTime: map.cycleDurationTime,
                          directionBarHeight: map.directionBarHeight,
                          hasBackwardDirection: map.hasBackwardDirection,
                          originCycleDurationTime: map.originCycleDurationTime,
                      })
                    : null,
                directionData: createDirectionData({
                    y: map.y,
                    phases: map.phases,
                    strokeStyle: map.labelStrokeStyle,
                    pixelsPerSecond: map.pixelsPerSecond,
                    dragAnchorWidth: map.dragAnchorWidth,
                    directionBarGap: map.directionBarGap,
                    directionBarHeight: map.directionBarHeight,
                    hasBackwardDirection: map.hasBackwardDirection,
                }),
                directionLabelData: createDirectionLabelData({
                    x: 24,
                    y: map.y,
                    font: map.defaultFont,
                    text: map.directionLabel,
                    directionType: map.directionType,
                    directionBarGap: map.directionBarGap,
                    fillStyle: map.directionLabelFillStyle,
                    directionBarHeight: map.directionBarHeight,
                    hasBackwardDirection: map.hasBackwardDirection,
                }),
                directionStartAnchorsData: createDirectionStartAnchorsData({
                    y: map.y,
                    shift: map.shift,
                    pixelsPerSecond: map.pixelsPerSecond,
                    cycleDurationTime: map.cycleDurationTime,
                    visibleCycleRepeatCount: map.visibleCycleRepeatCount,
                    hasBackwardDirection: map.hasBackwardDirection,
                }),
                phaseIconsData: data.isEditMode
                    ? {
                          pointerIcons: createPointerIconsData({
                              y: map.y,
                              phases: map.phases,
                              pixelsPerSecond: map.pixelsPerSecond,
                          }),
                          resizeHandles: createResizeHandleData({
                              y: map.y,
                              phases: map.phases,
                              hasBackwardDirection: map.hasBackwardDirection,
                          }),
                      }
                    : null,
                speedDistanceLegend:
                    data.isEditMode || map.isFirstDirection
                        ? null
                        : createSpeedDistanceData({
                              width: map.width,
                              font: map.defaultFont,
                              distance: cycle.segmentDistance,
                              fontSize: map.defaultFontSize,
                              forwardSpeed: map.forwardSpeed,
                              backwardSpeed: map.backwardSpeed,
                              fillStyle: map.legendLabelFillStyle,
                              speedDistanceLegendY: map.speedDistanceLegendY,
                          }),
            };
        });
    }
}
