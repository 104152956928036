import { useCallback } from "react";
import { Routing } from "routing";
import { useAppState } from "./use-app-state";
/**
 * #region
 * Фасад для выбора активного светофора
 * TODO: Временное решение
 * также временно лежит здесь, после пересмотра архитектурного подхода переедет в другое место
 * либо будет убран совсем
 */
export const useSetActiveTrafficLight = () => {
    const { mapController, trafficLightStore } = useAppState();
    const navigate = Routing.TrafficLights.Hooks.useTrafficLightNavigator();
    return useCallback(
        (id: NullableNumber) => {
            if (id === null) {
                return navigate({ type: "traffic-lights" });
            }
            const trafficLight = trafficLightStore.getById(id);
            if (!trafficLight) return;
            mapController.flyManager.handleTrafficLightPosition(trafficLight);
            navigate({ type: "traffic-light", id: String(trafficLight.id) });
        },
        [navigate, mapController, trafficLightStore]
    );
};
