import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import { Interfaces } from "interfaces";

export class TVCameraStore {
    public readonly emitter = new Interfaces.Warehouse.Emitter();
    public guid = v4();
    public uid: number | null = null;
    public status: Interfaces.Store.EquipmentCard["status"] = {};

    public source: SourceEquipmentCardArgs = {
        options: {
            uid: -1,
            coordinate: { lat: 0, lng: 0 },
        },
        defaultPosition: {
            pageX: 0,
            pageY: 0,
        },
    };

    constructor() {
        makeAutoObservable(this);
    }

    public onClose = () => {
        this.uid = null;
        this.emitter.emit(this.emitter.events.Remove, this.guid);
    };

    public onShowClick = () => {
        this.emitter.emit(this.emitter.events.ShowOnMap, this.source.options.coordinate);
    };

    public setData = (args: SourceEquipmentCardArgs) => {
        this.source = args;
        this.uid = args.options.uid;
        this.guid = v4();
        this.status.issueId = args.options.statusOnIssues;
        this.status.monitoringId = args.options.statusOnMonitoring;
        this.emitter.emit(this.emitter.events.Adding, this.guid);
    };
}
