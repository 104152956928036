export class BooleanStorageManager {
    private cookieKey: string;

    constructor(id: string) {
        this.cookieKey = id;
    }

    public get value() {
        return window.localStorage.getItem(this.cookieKey) === "true";
    }

    public set value(flag: boolean) {
        const value = flag ? "true" : "false";

        window.localStorage.setItem(this.cookieKey, value);
    }
}
