import { memo } from "react";
import styled from "styled-components";

const Container = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    color: ${({ theme }) => theme.colors.white.basic};
    line-height: 24px;
    font-weight: 500;
`;

export type IndicationProps = {
    text?: string;
    color?: string;
};

const IndicationComponent = (props: IndicationProps) => <Container color={props.color}>{props.text}</Container>;

export const Indication = memo(IndicationComponent);
