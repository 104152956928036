import { TrafficLightDispatcher } from "./trafficlight-dispatcher";
import { ListenerSignature, TypedEmitter } from "tiny-typed-emitter";

export interface TrafficLightDispatcherItem {
    readonly id: number;
    readonly isActive: boolean;
    readonly activate: (state?: any) => void;
    readonly type: TrafficLightDispatcherItemType;
    readonly location?: {
        lat: number;
        lng: number;
    };
}

export class TrafficLightDispatcherItemBase<T extends ListenerSignature<T>>
    extends TypedEmitter<T>
    implements TrafficLightDispatcherItem {
    readonly dispatcher: TrafficLightDispatcher;
    readonly type: TrafficLightDispatcherItemType;

    constructor(dispatcher: TrafficLightDispatcher, type: TrafficLightDispatcherItemType) {
        super();
        this.dispatcher = dispatcher;
        this.type = type;
    }

    readonly id: any;

    get isActive(): boolean {
        return this.dispatcher.activeItem === this;
    }

    activate(state?: any) {
        this.dispatcher?.setActiveItem(this, state);
    }

    get isFilteredOut() {
        return false;
    }
}

export enum TrafficLightDispatcherItemType {
    trafficLight = "trafficlight",
    trafficlightGroup = "group",
    radarDetector = "radardetector",
}
