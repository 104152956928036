import { memo, ReactNode } from "react";
import { Button } from "@megapolis/uikit/button";
import { useTheme } from "styled-components";
import { StatusIndicators } from "components";
import { Icons } from "assets";
import * as Markup from "./camera-card.styles";

type Props = {
    title: ReactNode;
    indicatorIcon: ReactNode;
    address?: string;
    indicatorInlineColor?: string;
};

const CardButtonIcon = <Icons.DetachIcon />;

const CameraCardComponent = (props: Props) => {
    const { colors } = useTheme();
    const inlineColor = props.indicatorInlineColor ?? colors.green.basic;

    return (
        <Markup.CardWrapper
            title={props.title}
            caption={<span title={props.address}>{props.address}</span>}
            icon={<StatusIndicators.BaseIndicator.Indicator icon={props.indicatorIcon} inlineColor={inlineColor} />}
            right={<Button content={Button.Content.Icon} icon={CardButtonIcon} priority="quaternary" size="s" />}
            size="m"
        />
    );
};

export const CameraCard = memo(CameraCardComponent);
