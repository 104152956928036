import styled, { css } from "styled-components";

export const Container = styled.div<{ isFullWidth?: boolean }>(
    ({ theme, isFullWidth }) => css`
        display: flex;
        flex-flow: column nowrap;
        gap: ${theme.spacings[1].str};
        background-color: ${theme.colors.stackBackground.modal};
        border-radius: ${theme.borderRadiuses[3].str};
        box-shadow: ${theme.shadows?.shadow1}, ${theme.shadows?.shadow2};
        padding: ${theme.spacings[6].str};
        min-width: 272px;
        ${isFullWidth && `width: 100%`};
    `
);
