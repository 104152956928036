import styled from "styled-components/macro";

export const Outline = styled.span<{ color?: string }>`
    display: inline-flex;
    border: 3px solid ${({ theme, color }) => color || theme.colors.teal.basic};
    border-radius: 24px;
`;

export const Inline = styled.span<{ color: string }>`
    display: inline-flex;
    border: 1px solid ${({ theme }) => theme.colors.white.basic};
    background-color: ${({ color }) => color};
    border-radius: 24px;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const Indicator = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    display: inline-flex;
    background-color: ${({ color }) => color};
    border: 1px solid ${({ theme }) => theme.colors.white.basic};
    border-radius: 50%;
    margin-left: -10px;
`;
