import mapboxgl, { Map } from "mapbox-gl";
import { TrafficLightDispatcher } from "trafficlight-dispatcher";
import { mapConstants } from "shared/constants";
import { ILayerController } from "../layer-controller";
import { TrafficcLightDetectorLayer } from "./trafficlight-detector-layer";
import { DetectorLaneBusyData, DetectorStateData } from "./traffic-light-detector.types";

export class TrafficLightDetectorController implements ILayerController {
    public isLayerVisible = true;
    private layer: TrafficcLightDetectorLayer;
    protected visibility: mapboxgl.Visibility = "visible";

    constructor(private readonly map: Map, private dispatcher: TrafficLightDispatcher) {
        this.layer = new TrafficcLightDetectorLayer(map, {
            id: mapConstants.LAYER_IDS.trafficLightDetector,
            dispatcher,
        });
        this.subscribe();
    }

    public setVisibility = async (isVisibility: boolean) => {
        this.visibility = isVisibility ? "visible" : "none";
        this.map.setLayoutProperty(this.layer.id, "visibility", this.visibility);
    };

    public updateDetectorState(data: DetectorStateData) {
        this.layer.updateDetectorState(data);
    }

    public updateDetectorLaneBusy(data: DetectorLaneBusyData) {
        this.layer.updateLaneBusy(data);
    }

    public readonly destroy = () => {
        this.layer.destroy();
        this.unsubscribe();
    };

    private handleStyleLoad = () => {
        if (this.map.getLayer(mapConstants.LAYER_IDS.radarDetector)) return;
        this.layer.addLayer();
    };

    private subscribe = () => {
        this.map.on("styledata", this.handleStyleLoad);
    };

    private unsubscribe() {
        this.map.off("styledata", this.handleStyleLoad);
    }
}
