import { Feature, FeatureCollection, GeoJSON } from "geojson";
import * as turf from "@turf/turf";
import { CooGroupDomain } from "app-domain";

export const cooGroupStatusColor = (status: CooGroupDomain.Status): string => {
    return CooGroupDomain.Constants.StatusDictionaries[status].color;
};

export const getCooGroupControlModeColor = (controlMode: CooGroupDomain.ControlMode): string => {
    return CooGroupDomain.Constants.ControlModeDictionariesList[controlMode].color;
};

export const getGroupGeoJsonGeometry = (cooGroup: CooGroupDomain.CooGroup): Feature[] => {
    const { geometry } = cooGroup;
    if (!geometry.trafficLightFeatures) return [];

    const feature = CooGroupDomain.Utils.convertToLine(geometry)[0];

    if (!geometry.routeFeatures) return [];

    const coordinates: GeoJSON.Position[] = geometry.routeFeatures?.features.reduce(
        (acc: GeoJSON.Position[], feature) => {
            acc.push(...feature.geometry.coordinates);
            return acc;
        },
        []
    );

    const line = turf.lineString(coordinates);

    return [
        {
            type: "Feature",
            id: cooGroup.id,
            geometry: feature.geometry,
            properties: {
                type: "path",
            },
        },
        {
            type: "Feature",
            id: cooGroup.id,
            geometry: line.geometry,
            properties: {
                type: "path-full",
            },
        },
    ];
};

export const getGeoJsonGeometry = (groups: CooGroupDomain.CooGroup[]): FeatureCollection => {
    const features: Feature[] = [];
    groups.forEach((group) => features.push(...getGroupGeoJsonGeometry(group)));

    return {
        type: "FeatureCollection",
        features: features,
    };
};
