import styled from "styled-components";

import * as Units from "./units";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings[4].str};
    padding: 20px;
`;

export const Header = styled(Units.Header)``;

export const Body = styled(Units.Body)``;
