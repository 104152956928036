import { Drawer } from "diagrams/drawers";
import { TimeDrawer } from "./time";
import { TimeScaleDrawer } from "./time-scale";
import { TimeLineData } from "./time-line-drawer.types";

export class TimeLineDrawer extends Drawer<TimeLineData> {
    private timeDrawer: TimeDrawer;
    private timeScaleDrawer: TimeScaleDrawer;

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.timeDrawer = new TimeDrawer(ctx);
        this.timeScaleDrawer = new TimeScaleDrawer(ctx);
    }

    protected draw = () => {
        if (!this.data) return;
        this.timeDrawer.setData(this.data.timeData);
        this.timeScaleDrawer.setData(this.data.timeScaleData);
    };
}
