import { TrafficLightGroupControlSession } from "./group-control-session";
import { TrafficLightCyclePhase, TrafficLightGroupGovernance, TrafficLightGroupMessage } from "./models";
import {
    TrafficLightGroupControlMode,
    TrafficLightGroupControlModeCode,
    trafficLightGroupControlModes,
} from "./models/group-control-mode";
import {
    TrafficLightGroupStatus,
    TrafficLightGroupStatusCode,
    trafficLightGroupStatusList,
} from "./models/group-status";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightDispatcher } from "./trafficlight-dispatcher";
import { TrafficLightDispatcherItemBase, TrafficLightDispatcherItemType } from "./trafficlight-dispatcher-item";
import { TrafficLight } from "./trafficlight";

export interface TrafficLightGroupLocation {
    address: string;
    lat?: number;
    lng?: number;
}

export interface TrafficLightGroupItem {
    pos: number;
    trafficLight: TrafficLightDomain.TrafficLight | TrafficLight;
    directionNum: number;
    speed: number;
}

export interface TrafficLightGroupCycle {
    id: number;
    name: string;
    items: TrafficLightGroupCycleItem[];
}

export interface TrafficLightGroupCycleItem {
    trafficLightId: number;
    offset: number;
    phases: TrafficLightCyclePhase[];
}

export interface TrafficLightGroupProps {
    dispatcher: TrafficLightDispatcher;
    id: number;
    name: string;
    path: number[][];
    from?: TrafficLightGroupLocation;
    to?: TrafficLightGroupLocation;
}

interface TrafficLightGroupEvents {
    statusChanged: (args: { status: TrafficLightGroupStatusCode }) => void;
    initialized: () => void;
    message: (args: { message: TrafficLightGroupMessage }) => void;
    controlModeChanged: (args: { controlMode: TrafficLightGroupControlModeCode }) => void;
    controlSessionChanged: () => void;
}

/** @deprecated */
export class TrafficLightGroup extends TrafficLightDispatcherItemBase<TrafficLightGroupEvents> {
    readonly id: number;
    readonly from?: TrafficLightGroupLocation;
    readonly to?: TrafficLightGroupLocation;
    readonly name: string;
    private _initPromise: Promise<void> | null = null;
    private _isInitialized: boolean = false;
    readonly path: number[][];

    _controlSession: TrafficLightGroupControlSession | null = null;
    items: TrafficLightGroupItem[] = [];
    status: TrafficLightGroupStatus = trafficLightGroupStatusList[TrafficLightGroupStatusCode.Disabled];
    controlMode: TrafficLightGroupControlMode =
        trafficLightGroupControlModes[TrafficLightGroupControlModeCode.Scheduled];
    activeCycle: TrafficLightGroupCycle | null = null;
    isEnabled: boolean = false;
    hasWarnings: boolean = false;
    cycles: TrafficLightGroupCycle[] = [];

    constructor(props: TrafficLightGroupProps) {
        super(props.dispatcher, TrafficLightDispatcherItemType.trafficlightGroup);
        this.id = props.id;
        this.from = props.from;
        this.to = props.to;
        this.name = props.name;
        this.path = props.path;
    }

    private async initInternal() {
        try {
            const cycleData: [
                {
                    id: number;
                    name: string;
                    cycles: [
                        {
                            facilityId: number;
                            shift: number;
                            phases: TrafficLightCyclePhase[];
                        }
                    ];
                }
            ] = await this.dispatcher.fetchTrafficLightJson(`coogroup/${this.id}/cycle`);

            if (cycleData?.length) {
                this.cycles = cycleData.map(
                    (cd) =>
                        ({
                            id: cd.id,
                            name: cd.name,
                            items: cd.cycles.map(
                                (c) =>
                                    ({
                                        trafficLightId: c.facilityId,
                                        offset: c.shift,
                                        phases: c.phases,
                                    } as TrafficLightGroupCycleItem)
                            ),
                        } as TrafficLightGroupCycle)
                );
            }
        } catch (ex) {
            console.error(ex);
        }

        this.emit("initialized");
        this._isInitialized = true;
    }

    get isInitialized() {
        return this._isInitialized;
    }

    initFromState(state: TrafficLightGroupState) {
        this.isEnabled = state.isEnabled;
        this.hasWarnings = state.isWarning;
        this.controlMode =
            trafficLightGroupControlModes[state.controlMode] ??
            trafficLightGroupControlModes[TrafficLightGroupControlModeCode.Disabled];
        this.status =
            trafficLightGroupStatusList[state.status] ??
            trafficLightGroupStatusList[TrafficLightGroupStatusCode.Disabled];

        if (state.governance) this._controlSession = new TrafficLightGroupControlSession(this, state.governance);
    }

    public async init() {
        if (this._isInitialized) return;
        if (this._initPromise === null) this._initPromise = this.initInternal();
        await this._initPromise;
    }

    onMessage(message: TrafficLightGroupMessage) {
        this.setState(message.state);
        this.emit("message", { message });
    }

    setState(state: TrafficLightGroupState) {
        if (this.controlMode.code !== state.controlMode) {
            this.controlMode =
                trafficLightGroupControlModes[state.controlMode] ??
                trafficLightGroupControlModes[TrafficLightGroupControlModeCode.Disabled];
            this.emit("controlModeChanged", { controlMode: state.controlMode });
            this.dispatcher.emit("trafficLightGroupControlModeChanged", {
                group: this,
                controlMode: this.controlMode.code,
            });
        }

        if (this.status.code !== state.status) {
            this.status =
                trafficLightGroupStatusList[state.status] ??
                trafficLightGroupStatusList[TrafficLightGroupStatusCode.Disabled];
            this.emit("statusChanged", { status: state.status });
            this.dispatcher.emit("trafficLightGroupStatusChanged", {
                group: this,
                status: this.status.code,
            });
        }
    }

    get isFilteredOut() {
        if (this.dispatcher && typeof this.dispatcher.trafficLightGroupFilter === "function")
            return !this.dispatcher.trafficLightGroupFilter(this);
        return false;
    }

    get controlSession() {
        return this._controlSession;
    }

    private setControlSession(governance?: TrafficLightGroupGovernance) {
        const oldControlSession = this.controlSession?.id ?? null;
        const newControlSession = governance?.id ?? null;
        if (oldControlSession !== newControlSession) {
            this._controlSession = governance ? new TrafficLightGroupControlSession(this, governance) : null;
            this.emit("controlSessionChanged");
        } else if (this._controlSession && governance) this._controlSession.update(governance);
    }

    async startControl() {
        const governance: TrafficLightGroupGovernance = await this.dispatcher.fetchTrafficLightJson(
            `trafficlight/manage/coogroup/${this.id}/governance`,
            { method: "POST" }
        );
        this.setControlSession(governance);
    }

    async stopControl() {
        await this.dispatcher.fetchTrafficLight(`trafficlight/manage/coogroup/${this.id}/governance`, {
            method: "DELETE",
        });
        this.setControlSession();
    }
}

export type TrafficLightGroupCollection = { [key: number]: TrafficLightGroup };

export interface TrafficLightGroupState {
    status: TrafficLightGroupStatusCode;
    controlMode: TrafficLightGroupControlModeCode;
    isEnabled: boolean;
    coomPlanId?: number;
    activeCycleId?: number;
    isWarning: false;
    warning: string;
    governance?: TrafficLightGroupGovernance;
}
