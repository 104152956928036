import { Map, MapMouseEvent } from "mapbox-gl";
import { TypedEmitter } from "tiny-typed-emitter";
import { RouteDomain, CooGroupDomain } from "app-domain";
import { RouteLayer, TrafficlightsLayer, CooGroupLayer } from "./layers";
import { RoutePointsMarker } from "./markers";
import { Events, Handlers } from "./route-building-controller.events";
import type { LngLat } from "./route-building-controller.types";
import { ILayerController } from "../layer-controller";

export class RouteBuildingController extends TypedEmitter<Handlers> implements ILayerController {
    public setMarkersData;
    public events = Events;
    private isMarkerAddable = true;
    private routePointsMarker?: RoutePointsMarker;
    private routeLayer?: RouteLayer;
    private trafficLightLayer?: TrafficlightsLayer;
    private cooGroupLayer?: CooGroupLayer;

    constructor(private readonly map: Map) {
        super();

        this.routePointsMarker = new RoutePointsMarker(map);
        this.routeLayer = new RouteLayer(map);
        this.cooGroupLayer = new CooGroupLayer(map);
        this.trafficLightLayer = new TrafficlightsLayer(map);

        this.setMarkersData = this.routePointsMarker.setData;

        this.subscribe();
    }

    public addMarker = (lngLat: LngLat) => {
        this.emit(this.events.addMarker, lngLat);
    };

    public readonly destroy = () => {
        this.routeLayer?.destroy();
        this.cooGroupLayer?.destroy();
        this.trafficLightLayer?.destroy();
        this.routePointsMarker?.setData([]);
        this.unsubscribe();
    };

    public setVisibility = async (isVisible: boolean) => {
        this.routeLayer?.setVisibility(isVisible);
        this.cooGroupLayer?.setVisibility(isVisible);
        this.trafficLightLayer?.setVisibility(isVisible);
        this.routePointsMarker?.setData([]);
    };

    public setIsMarkerAddable = (value: boolean) => {
        if (value) {
            this.map.getCanvas().style.cursor = "crosshair";
        } else {
            this.map.getCanvas().style.cursor = "";
        }
        this.isMarkerAddable = value;
    };

    public setData = (data?: RouteDomain.MapRouteFeatures) => {
        this.routeLayer?.setData(data?.routeFeatures?.features ?? []);
        this.cooGroupLayer?.setData(CooGroupDomain.Utils.convertToLine(data));
        this.trafficLightLayer?.setData(data?.trafficLightFeatures?.features ?? []);
    };

    public readonly setWorkingMode = (value: boolean) => {
        this.setVisibility(value);
        this.setIsMarkerAddable(value);
    };

    private handleClick = (event: MapMouseEvent) => {
        if (!this.isMarkerAddable) return;
        this.addMarker(event.lngLat);
    };

    private handleDragRoutePoint: Handlers[Events.moveMarker] = (props) => {
        this.emit(this.events.moveMarker, props);
    };

    private subscribe() {
        this.routePointsMarker?.on(this.routePointsMarker?.events.dragend, this.handleDragRoutePoint);
        this.map.on("click", this.handleClick);
    }

    private unsubscribe() {
        this.map.getCanvas().removeAttribute("style");
        this.routePointsMarker?.off(this.routePointsMarker?.events.dragend, (props) =>
            this.emit(this.events.moveMarker, props)
        );
        this.map.off("click", this.handleClick);
    }
}
