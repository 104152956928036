import { TrafficLightDomain } from "app-domain";
import { PhaseView } from "../phase-view";
import { InitialPhase } from "./cycle-view.types";

interface PhaseMapperParams {
    drawPhases: PhaseView[];
    phaseList: TrafficLightDomain.CyclePhase[];
    trafficLight: TrafficLightDomain.TrafficLight;
    index: number;
    pixelsPerSecond: number;
    initialPhase: InitialPhase;
    directionNumber: number;
}

interface GetPointerIconParams {
    initialPhase: InitialPhase;
    phaseShiftFromStart: number;
    tPhase: number;
    currentPhaseNumber: number;
    nextPhaseNumber: number;
    trafficLight: TrafficLightDomain.TrafficLight;
    directionNumber: number;
}

export const getPointerIconParams = (params: GetPointerIconParams) => {
    const {
        initialPhase,
        phaseShiftFromStart,
        tPhase,
        currentPhaseNumber,
        nextPhaseNumber,
        trafficLight,
        directionNumber,
    } = params;
    const initialPhaseEnd = initialPhase.tPhase + initialPhase.phaseShiftFromStart;
    const editedPhaseEnd = phaseShiftFromStart + tPhase;

    if (initialPhaseEnd === editedPhaseEnd) return null;
    const currentPhase = trafficLight.getPhaseByNum(currentPhaseNumber);
    const nextPhase = trafficLight.getPhaseByNum(nextPhaseNumber);

    return {
        current: !!currentPhase.directionByNum[directionNumber],
        next: !!nextPhase.directionByNum[directionNumber],
    };
};

export const phaseMapper = (params: PhaseMapperParams): PhaseView => {
    const { trafficLight, phaseList, index, drawPhases, initialPhase, directionNumber } = params;
    const currentPhase = phaseList[index];
    const prevPhase = drawPhases[drawPhases.length - 1];

    const phase = new PhaseView();

    const trafficLightPhase = trafficLight.getPhaseByNum(currentPhase.phaseNumber);
    phase.isLast = index === phaseList.length - 1;
    phase.isFirst = index === 0;
    phase.phaseNumber = currentPhase.phaseNumber;
    phase.tPhase = currentPhase.tPhase;
    phase.tBasic = currentPhase.tBasic;
    phase.tMin = currentPhase.tMin;
    phase.direction = trafficLight.getDirectionByNum(directionNumber);
    phase.initialPhase = initialPhase;
    phase.allow = !!trafficLightPhase?.directionByNum[directionNumber];

    if (prevPhase) {
        phase.prevPhase = prevPhase;
        prevPhase.nextPhase = phase;
    }

    if (phase.isLast) {
        drawPhases[0].prevPhase = phase;
        phase.nextPhase = drawPhases[0];
    }

    return phase;
};
