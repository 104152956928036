export const calculateTime = (time: number): string => {
    const timeMinutes = Math.floor(time / 60).toString();
    const timeMinutesStr = timeMinutes.length === 1 ? `0${timeMinutes}` : timeMinutes;
    const timeSeconds = (time % 60).toString();
    const timeSecondsStr = timeSeconds.length === 1 ? `0${timeSeconds}` : timeSeconds;

    if (time === 0) {
        return "0";
    }

    return `${timeMinutesStr}:${timeSecondsStr}`;
};
