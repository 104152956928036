import { memo } from "react";
import photo from "./photo.jpg";
import { BlockLoader } from "../../../../../block-loader";
import * as Markup from "./last-screenshot.styles";
import type { Props } from "./last-screenshot.types";

// TODO: на текущий момент оставляем заглушку, по указанию Максима Черноморца
export const LastScreenshot = memo((props: Props) => (
    <Markup.Container className={props.className} data-cypress={props.dataCypress}>
        {!props.isLoaded && <BlockLoader data-cypress={`${props.dataCypress}_placeholder`} />}
        {props.isLoaded && <Markup.Screenshot src={photo} alt="последняя фиксация" />}
    </Markup.Container>
));
