import { useState, useCallback, useEffect } from "react";
import { APIRegistry } from "api";
import { State } from "./production-date-picker.types";
import { getStoredData, updateStoredData } from "./production-date-picker.utils";

export const useProductionDatePicker = () => {
    const store = getStoredData();

    const [state, setState] = useState<State>(() => ({
        weekends: store.weekends ?? [],
        year: store.year,
        isLoading: false,
    }));

    const { year } = state;

    const setIsLoading = useCallback((isLoading: boolean) => setState((prev) => ({ ...prev, isLoading })), []);

    useEffect(() => {
        let isMounted = true;
        const updateWeekends = async (year: number) => {
            setIsLoading(true);
            try {
                const dateStrings = await APIRegistry.infrastructureAPI.getWorkCalendar(year);
                if (!isMounted) return;
                const weekends = dateStrings.map((date) => new Date(date));
                updateStoredData(weekends, year);
                setState((prev) => ({ ...prev, isLoading: false, weekends }));
            } finally {
                setIsLoading(false);
            }
        };
        if (store.weekends && store.year === year) return;
        updateWeekends(year);
        return () => {
            isMounted = false;
        };
    }, [year, setIsLoading, store]);

    const handleViewDateChange = useCallback((viewDate: Date) => {
        setState((prev) => ({ ...prev, year: viewDate.getFullYear() }));
    }, []);

    return {
        weekends: state.weekends,
        isLoading: state.isLoading,
        handleViewDateChange,
    };
};
