import { REACT_APP_MAP_SATELLITE_STYLE, REACT_APP_MAP_DARK_STYLE } from "env-data";
import { Theme } from "./enums";

type ThemeMap = { [key in Theme]: string };

export const storageKey = "map-theme";

export const themeMap: ThemeMap = {
    [Theme.LIGHT]: "",
    [Theme.DARK]: `&t=${REACT_APP_MAP_DARK_STYLE}`,
    [Theme.SATELLITE]: REACT_APP_MAP_SATELLITE_STYLE,
};
