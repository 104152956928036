import { TrafficLightDomain } from "app-domain";
import { PhaseRange, ControlModeRange, MalfunctionType, StatusRange, TimelineRanges } from "./range-utils.types";
import { getPhaseNumFromState } from "diagrams/timeline-renderer/timeline-renderer.utils";

interface GetTrafficLightRangesParams {
    trafficLight: TrafficLightDomain.TrafficLight;
    messages: TrafficLightDomain.TrafficLightMessage[];
    timeFrom: number;
    timeTo: number;
    currentTime: number;
}

export class TimelineRangesFactory implements TimelineRanges {
    public phases: PhaseRange[] = [];
    public controlModes: ControlModeRange[] = [];
    public statuses: StatusRange[] = [];
    public malfunctions: MalfunctionType[] = [];

    constructor(private params: GetTrafficLightRangesParams) {
        for (let index = 0; index < params.messages.length; index++) {
            const currentMessage = params.messages[index];
            const nextMessage = params.messages[index + 1];

            const isStatusCycle = currentMessage.state.status === TrafficLightDomain.Enums.StatusCode.Cycle;
            const isStatusHold = currentMessage.state.status === TrafficLightDomain.Enums.StatusCode.Hold;

            if ((!isStatusCycle && !isStatusHold) || currentMessage.state.phase) {
                if (!nextMessage || nextMessage.dateTime > params.timeFrom) {
                    this.malfunctions.push({
                        isCurrent: false,
                        from: currentMessage.dateTime,
                        to: nextMessage?.dateTime ?? params.currentTime,
                        type: currentMessage.state.malfunctionType,
                    });
                    this.phaseParser(currentMessage);
                    this.parseControlMode(currentMessage);
                    this.parseStatus(currentMessage);
                }
            }

            if (currentMessage.dateTime > params.timeTo) {
                break;
            }
        }
        this.prepareLastPhase();
        this.prepareLastControlMode();
        this.prepareLastStatus();
    }

    private prepareLastStatus() {
        const status = this.statuses[this.statuses.length - 1];

        if (!status) return;

        const { trafficLight, currentTime } = this.params;

        if (trafficLight.status.code !== status.status) {
            this.statuses.push({
                from: status.to,
                to: currentTime,
                status: trafficLight.status.code,
                isCurrent: true,
            });
        } else {
            status.to = currentTime;
            status.isCurrent = true;
        }
    }

    private prepareLastPhase() {
        if (!this.lastPhase) return;

        const { trafficLight, currentTime } = this.params;
        const phaseNum = getPhaseNumFromState(trafficLight.status.code, trafficLight.currentPhase?.phase.num ?? 0);

        if (this.lastPhase.phaseNum !== phaseNum) {
            this.phases.push({
                promTimeStart: null,
                promTimeEnd: null,
                from: this.lastPhase.to,
                to: currentTime,
                phaseNum,
                controlMode: trafficLight.controlMode.code,
                status: trafficLight.status.code,
                isCurrent: true,
            });
        } else {
            this.lastPhase.to = currentTime;
            this.lastPhase.isCurrent = true;
        }
    }

    private prepareLastControlMode() {
        const controlModeRange = this.controlModes[this.controlModes.length - 1];
        if (!controlModeRange) return;

        const { trafficLight, currentTime } = this.params;

        if (trafficLight.controlMode.code !== controlModeRange.controlMode) {
            this.controlModes.push({
                from: controlModeRange.to,
                to: currentTime,
                controlMode: trafficLight.controlMode.code,
                isCurrent: true,
            });
        } else {
            controlModeRange.to = currentTime;
            controlModeRange.isCurrent = true;
        }
    }

    private get lastPhase() {
        return this.phases[this.phases.length - 1];
    }

    private parseControlMode(message: TrafficLightDomain.TrafficLightMessage) {
        const lastControlMode = this.controlModes[this.controlModes.length - 1];

        if (lastControlMode && lastControlMode.controlMode !== message.state.controlMode) {
            lastControlMode.to = message.dateTime;
        }

        if (!lastControlMode || lastControlMode.controlMode !== message.state.controlMode) {
            this.controlModes.push({
                controlMode: message.state.controlMode,
                isCurrent: false,
                from: message.dateTime,
                to: message.dateTime,
            });
        } else {
            lastControlMode.to = message.dateTime;
        }
    }

    private parseStatus(message: TrafficLightDomain.TrafficLightMessage) {
        const lastStatus = this.statuses[this.statuses.length - 1];

        if (!lastStatus || lastStatus.status !== message.state.status) {
            if (lastStatus) lastStatus.to = message.dateTime;

            this.statuses.push({
                from: message.dateTime,
                to: message.dateTime,
                status: message.state.status,
                isCurrent: false,
            });
        } else {
            lastStatus.to = message.dateTime;
        }
    }

    private phaseParser(message: TrafficLightDomain.TrafficLightMessage) {
        const currentPhaseNumber = getPhaseNumFromState(message.state.status, message.state.phase);

        const isSamePhase = this.lastPhase?.phaseNum === currentPhaseNumber;

        if (this.lastPhase) {
            this.lastPhase.to = message.dateTime;
        }

        if (!this.lastPhase || !isSamePhase) {
            this.phases.push({
                phaseNum: currentPhaseNumber,
                from: message.dateTime,
                to: message.dateTime,
                controlMode: message.state.controlMode,
                status: message.state.status,
                promTimeStart: null,
                promTimeEnd: null,
                isCurrent: false,
            });
        }

        if (message.eventType === TrafficLightDomain.Enums.EventTypeCode.PromStart) {
            this.lastPhase.promTimeStart = message.dateTime;
            this.lastPhase.promTimeEnd = message.data?.promTime ? message.dateTime + message.data?.promTime ?? 0 : null;
        }
    }
}
