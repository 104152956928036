const initialData = {
    x: 0,
    width: 0,
    height: 0,
    forwardY: 0,
    fillStyle: "",
    pixelsPerSecond: 0,
    hasBackwardDirection: false,
    backwardY: undefined,
};

export type EmptyPartDataInput = {
    y: number;
    x: number;
    width: number;
    height: number;
    fillStyle: string;
    pixelsPerSecond: number;
    directionBarHeight: number;
    hasBackwardDirection: boolean;
    backwardDirectionGap: number;
};

export type EmptyPartData = {
    x: number;
    width: number;
    height: number;
    forwardY: number;
    fillStyle: string;
    pixelsPerSecond: number;
    hasBackwardDirection: boolean;
    backwardY?: number;
};

export const createEmptyPartData = (args?: EmptyPartDataInput): EmptyPartData =>
    !args
        ? initialData
        : {
              x: args.x,
              width: args.width,
              height: args.height,
              forwardY: args.y,
              fillStyle: args.fillStyle,
              pixelsPerSecond: args.pixelsPerSecond,
              hasBackwardDirection: args.hasBackwardDirection,
          };
