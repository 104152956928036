import { memo } from "react";
import { Loader } from "@megapolis/uikit/loader";
import styled from "styled-components/macro";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
`;

type Props = {
    size?: string;
};

const DEFAULT_SIZE = "32px";

export const BlockLoader = memo((props: Props) => (
    <Container>
        <Loader size={props.size ?? DEFAULT_SIZE} />
    </Container>
));

BlockLoader.displayName = "BlockLoader";
