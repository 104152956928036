import { CycleSource } from "../enums";
import { Cycle, CycleData, ICycle } from "./cycle";

export interface CustomCycleData extends CycleData {
    id: number;
    source: CycleSource;
    /** Дата синхронизации */
    syncDate: DateTimeString | Date;
    /** Внешний идентификатор программы из системы, откуда она были импортирована */
    externalId: number;
    /** Признак предупреждения для программы */
    isWarning: boolean;
    /** Описание предупреждения */
    warningDescription?: string;
    /** Смещение старта программы */
    shift: number;
    /** Идентификатор пользователя */
    userId?: string;
    /** Отображаемое имя пользователя */
    userDisplayName?: string;
    /**
     * Признак того, что программа сейчас
     * должна быть активна по-расписанию
     * (но это не значит что она запущена
     * в реальности на ленте координации)
     */
    isActiveNow: boolean;
}

export interface ICustomCycle extends ICycle {
    id: number;
    source: CycleSource;
    /** Дата синхронизации */
    syncDate: Date;
    /** Внешний идентификатор программы из системы, откуда она были импортирована */
    externalId: number;
    /** Признак предупреждения для программы */
    isWarning: boolean;
    /** Описание предупреждения */
    warningDescription?: string;
    /** Смещение старта программы */
    shift: number;
    /** Идентификатор пользователя */
    userId?: string;
    /** Отображаемое имя пользователя */
    userDisplayName?: string;
}

/** 	
    Программа, которая была синхронизирована со светофора или создана вручную
 */
export class CustomCycle extends Cycle implements ICustomCycle {
    public id: number;
    public source: CycleSource;
    public syncDate: Date;
    public externalId: number;
    public isWarning: boolean;
    public isActiveNow: boolean;
    public shift: number;
    public userId?: string;
    public userDisplayName?: string;
    public warningDescription?: string;

    constructor(data: CustomCycleData) {
        super(data);
        this.id = data.id;
        this.source = data.source;
        this.syncDate = new Date(data.syncDate);
        this.externalId = data.externalId;
        this.isWarning = data.isWarning;
        this.warningDescription = data.warningDescription;
        this.shift = data.shift;
        this.userId = data.userId;
        this.userDisplayName = data.userDisplayName;
        this.isActiveNow = data.isActiveNow;
    }
}
