import { Icons } from "@megapolis/uikit/icons";
import styled, { css } from "styled-components/macro";

export const Container = styled.div((props) => {
    const { spacings, borderRadiuses, shadows, colors } = props.theme;
    return css`
        display: flex;
        background-color: ${colors.stackBackground.modal};
        gap: ${spacings[3].str};
        padding: ${spacings[5].str};
        border-radius: ${borderRadiuses[4].str};
        box-shadow: ${shadows?.shadow2};
        align-items: flex-start;
        flex-grow: 1;
    `;
});

export const Content = styled.div`
    display: flex;
    flex-flow: column nowrap;
    gap: ${({ theme }) => theme.spacings[1].str};
    flex-grow: 1;
`;

export const Title = styled.span`
    display: flex;
    font: ${({ theme }) => theme.typography.h5};
    padding: ${({ theme }) => theme.spacings[1].str} 0;
    flex-grow: 1;
`;

export const ErrorList = styled.ul((props) => {
    const { colors, typography } = props.theme;
    return css`
        margin: 0;
        padding: 0;
        list-style: none;
        font: ${typography.text3};
        color: ${colors.content.secondary};
    `;
});

export const ErrorIcon = styled(Icons.Monochrome.Error)`
    display: flex;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.colors.red.basic};
`;
