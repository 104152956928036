type ValidatedResponseResult<Data extends object> =
    | Data
    | {
          error: string;
          detailed?: string[];
      };

type Value<Data extends object> =
    | {
          isOk: true;
          data: Data;
      }
    | {
          isOk: false;
          data: string[];
      };

export class ValidatedResult<Data extends object> {
    public value!: Value<Data>;

    constructor(responseResult: ValidatedResponseResult<Data>) {
        if ("error" in responseResult) {
            this.value = {
                isOk: false,
                data: responseResult.detailed ?? [responseResult.error],
            };
        } else {
            this.value = {
                isOk: true,
                data: responseResult,
            };
        }
    }
}
