import { encodeSvg } from "shared/utils";

export const getPointerIcon = (first: string, last: string) =>
    encodeSvg(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.27575 5.24035L5.82939 13.2092C6.78913 14.8888 9.21087 14.8888 10.1706 13.2092L14.7242 5.24035C15.6766 3.57371 14.4732 1.5 12.5536 1.5L3.44636 1.5C1.5268 1.5 0.323384 3.57371 1.27575 5.24035Z"
                fill={last}
                stroke="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00044 2H3.4468C1.91116 2 0.948423 3.65896 1.71032 4.99228L6.26396 12.9611C6.64785 13.633 7.32415 13.9689 8.00044 13.9689V2Z"
                fill={first}
            />
            <path d="M8 2V14.5" stroke="white" />
        </svg>
    );
