import { memo } from "react";
import { TrafficLightDomain } from "app-domain";
import { PhaseContainer, PhaseMedia, PhaseTimes, PhaseLabel } from "../phases";

type Props = {
    phaseNumber: number;
    directions: TrafficLightDomain.Direction[];
    location: LatLng;
    isActive?: boolean;
    times?: { tProm: number; tBase: number };
};

const TrafficLightPhaseComponent = (props: Props) => {
    const { directions, location, phaseNumber, times, isActive } = props;
    const trackDirections = TrafficLightDomain.Utils.createTracksByDirections({ directions, coords: location });

    return (
        <PhaseContainer isActive={isActive}>
            <PhaseMedia trackDirections={trackDirections} {...location} />
            <span>
                <PhaseLabel phaseNumber={phaseNumber} />
            </span>
            {times && <PhaseTimes {...times} />}
        </PhaseContainer>
    );
};

export const TrafficLightPhase = memo(TrafficLightPhaseComponent);
