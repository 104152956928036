import styled, { css } from "styled-components/macro";

export const Container = styled.span<{ color?: string }>((props) => {
    const {
        theme: { colors, spacings, borderRadiuses },
        color,
    } = props;

    return css`
        display: inline-block;
        color: ${colors.white.basic};
        padding: 0 ${spacings[1].str};
        border-radius: ${borderRadiuses[1].str};
        background-color: ${color || colors.content.secondary};
        line-height: 16px;
    `;
});
