import { memo } from "react";
import { CollapseButton } from "./traffic-light-cycle-editor.styles";
import { Icons } from "@megapolis/uikit/icons";

const UpArrow = <Icons.Monochrome.ChevronUpS />;
const DownArrow = <Icons.Monochrome.ChevronDownS />;

type Props = {
    isExpanded: boolean;
    onClick: VoidFunction;
};

const CollapseButtonComponent = ({ isExpanded, onClick }: Props) => {
    const collapseButtonText = (isExpanded ? "Скрыть" : "Развернуть") + " направления";
    const collapseIcon = isExpanded ? UpArrow : DownArrow;
    return (
        <CollapseButton
            content={CollapseButton.Content.TextIcon}
            text={collapseButtonText}
            icon={collapseIcon}
            priority="quaternary"
            onClick={onClick}
        />
    );
};

export const TrafficLightCycleEditorCollapseButton = memo(CollapseButtonComponent);
