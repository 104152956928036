import { makeObservable, observable } from "mobx";
import type { Active } from "./popup-focus.types";

export class PopupFocus {
    public active: Active = null;

    constructor() {
        makeObservable(this, {
            active: observable,
        });
    }

    public setActive = (active: Active) => {
        this.active = active;
    };
}
