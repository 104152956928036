import { memo, ReactNode } from "react";
import styled, { css } from "styled-components";

export type CanvasTooltipProps = {
    x: number;
    y: number;
    content: ReactNode;
};

const Container = styled.div(
    ({ theme: { spacings, colors, borderRadiuses, shadows } }) => css`
        position: absolute;
        padding: ${spacings[3].str} ${spacings[1].str};
        background-color: ${colors.stackBackground.modal};
        border-radius: ${borderRadiuses[1].str};
        box-shadow: ${shadows.shadow1};
    `
);

const formatSizeStyle = (size: number) => `${size}px`;

export const CanvasTooltip = memo((props: CanvasTooltipProps) => {
    const calculateStyles = (node: HTMLElement | null) => {
        if (!node) return;
        const rect = node.getBoundingClientRect();
        const top = formatSizeStyle(props.y - rect.height);
        const left = formatSizeStyle(props.x - rect.width * 0.5);
        node.style.top = top;
        node.style.left = left;
    };

    return <Container ref={calculateStyles}>{props.content}</Container>;
});
