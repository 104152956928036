import { memo } from "react";
import { Calendar } from "../calendar";
import { useDatePicker } from "./date-picker.hooks";
import { mask, placeHolder } from "./date-picker.constants";
import * as Types from "./date-picker.types";
import * as Markup from "./date-picker.styles";

export const DatePicker = memo((props: Types.Props) => {
    const picker = useDatePicker(props);

    const afterBlock = (
        <Markup.AfterBlock isClearable={props.isClearable}>
            {picker.inputValue.length > 0 && !props.isDisabled && props.isClearable && (
                <Markup.ClearIcon onClick={picker.handleClear} />
            )}
            <Markup.DatePickerIcon isDisabled={props.isDisabled} />
        </Markup.AfterBlock>
    );

    return (
        <Markup.Container ref={picker.containerRef} className={props.className} isFullWidth={props.isFullWidth}>
            <Markup.Input
                mask={mask}
                placeholder={placeHolder}
                value={picker.inputValue}
                after={afterBlock}
                isLoading={props.isLoading}
                isDisabled={props.isDisabled}
                hasError={props.hasError}
                errorText={props.errorText}
                onFocus={picker.handleInputFocus}
                onChange={picker.handleInputChange}
            />
            <Markup.CalendarWrapper ref={picker.calendarRef}>
                {picker.isCalendarOpened && (
                    <Calendar
                        value={props.value}
                        weekends={props.weekends}
                        onChange={picker.handleCalendarChange}
                        onViewDateChange={props.onViewDateChange}
                        isFullWidth
                    />
                )}
            </Markup.CalendarWrapper>
        </Markup.Container>
    );
});
