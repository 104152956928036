import styled, { css, keyframes } from "styled-components/macro";
import { Props } from "./phase-container.types";

const itemBlinkAnimation = keyframes`
    25% {
        outline-color: transparent;
    }
    50% {
        outline-color: transparent;
    }
    75% {
        outline-color: transparent;
    }
`;

type ContainerProps = Omit<Props, "className">;

const containerBlinkCss = css`
    animation: ${itemBlinkAnimation} 1s linear infinite;
    animation-play-state: running;
    outline-style: dashed;
`;

const containerNextCss = css`
    outline-style: dashed;
`;

const containerActiveCss = css<{ outlineColor?: string }>`
    outline: 3px solid ${({ theme, outlineColor }) => outlineColor || theme.colors.blue.l2};
`;

export const Container = styled.div<ContainerProps>((p) => {
    const {
        theme: { colors, spacings, borderRadiuses },
        isActive,
        isBlinking,
        isNext,
    } = p;

    return css`
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        padding: ${spacings[3].value - 1}px;
        gap: ${({ theme }) => theme.spacings[3].str};
        border: 1px solid ${colors.content.quinary};
        border-radius: ${borderRadiuses[1].str};
        outline: 3px solid transparent;
        ${isActive && containerActiveCss};
        ${isBlinking && containerBlinkCss};
        ${isNext && containerNextCss};
    `;
});
