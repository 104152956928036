import styled from "styled-components/macro";
import { Chip } from "@megapolis/uikit/chip";

export const WrappedChip = styled(Chip)`
    &:focus-within {
        box-shadow: none;
    }
    &,
    & > span {
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
    }
`;
