import React from "react";
import { Button } from "@megapolis/uikit/button";
import { Icons } from "@megapolis/uikit/icons";
import * as Markup from "./error.styles";
import type { Props } from "./error.types";

export const Error = React.memo((props: Props) => {
    const dataCypressContainer = props.dataCypress ? `${props.dataCypress}__error` : undefined;
    const dataCypressMessage = props.dataCypress ? `${props.dataCypress}__error__message` : undefined;
    const dataCypressClose = props.dataCypress ? `${props.dataCypress}__close` : undefined;

    return (
        <Markup.Container data-cypress={dataCypressContainer}>
            <Markup.Message data-cypress={dataCypressMessage}>{props.message}</Markup.Message>
            <Markup.Close data-cypress={dataCypressClose}>
                <Button
                    content={Button.Content.Icon}
                    priority="quaternary"
                    kind="info"
                    size="s"
                    icon={<Icons.Monochrome.CloseM />}
                    onClick={props.onCloseClick}
                />
            </Markup.Close>
        </Markup.Container>
    );
});
