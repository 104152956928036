import { CanvasHelper } from "../canvas/canvas-helper";
import { PainterTrackType } from "./track-painter.constants";
import {
    PainterTrack,
    MapPoint,
    ArrowHeadDrawerShape,
    CornerDrawerShape,
    RoundedTrackPathDrawerShape,
    PainterTrackLabel,
} from "./track-painter.types";

export class TrackPainter {
    public static lineSize = 120;

    private _ctx: CanvasRenderingContext2D | null = null;

    constructor(canvas: HTMLCanvasElement, size: number) {
        if (!canvas) return;
        this._ctx = CanvasHelper.setDpi(canvas);
        this._ctx?.scale(size / 102, size / 102);
        this.clear();
    }

    public drawTracks = (tracks: PainterTrack[]) => tracks.forEach(this.drawTrack);

    public clear() {
        if (this._ctx) this._ctx.clearRect(0, 0, this._ctx.canvas.width, this._ctx.canvas.height);
    }

    /**
     * Метод для отрисовки одного направления
     * @param track направление
     */
    private drawTrack = (track: PainterTrack) => {
        if (!this._ctx) return;
        const ctx = this._ctx;

        const { type, points, label, lineWidth = 16, arrowSize = 56, color = "#000" } = track;

        const scale = TrackPainter.lineSize / 1024;
        const computedArrowSize = arrowSize * scale;

        ctx.lineWidth = lineWidth * scale;
        ctx.strokeStyle = color;
        ctx.fillStyle = color;

        if (type === PainterTrackType.Bidirectional) {
            this.drawBidirectionalTrack(ctx, points.slice().reverse(), computedArrowSize);
        }
        this.drawOneDirectionalTrack(ctx, points, scale, computedArrowSize);

        if (!label) return;
        this.drawLabel(ctx, label, scale);
    };

    private drawLabel(ctx: CanvasRenderingContext2D, label: PainterTrackLabel, scale: number) {
        const fontSize = label.fontSize ?? 100;

        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = `${fontSize * scale}px Arial`;

        if (label.isTextStroken) {
            ctx.lineWidth = 2;
            ctx.strokeText(label.number, label.x, label.y);
        } else {
            ctx.fillText(label.number, label.x, label.y);
        }
    }

    private drawOneDirectionalTrack(
        ctx: CanvasRenderingContext2D,
        points: PainterTrack["points"],
        scale: number,
        arrowSize: number
    ) {
        ctx.lineCap = "round";
        ctx.beginPath();
        this.drawTrackRoundedPath({ ctx, points, radius: scale * 40 });
        ctx.stroke();
        ctx.lineWidth = 0.5;
        this.drawTrackArrowHead({ ctx, points, d: arrowSize, angleDegrees: 24 });
    }

    private drawBidirectionalTrack(ctx: CanvasRenderingContext2D, points: PainterTrack["points"], arrowSize: number) {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        ctx.lineTo(points[1].x, points[1].y);
        ctx.stroke();
        ctx.lineWidth = 0.5;
        this.drawTrackArrowHead({ ctx, points, d: arrowSize, angleDegrees: 24 });
        this.drawTrackArrowHead({ ctx, points, d: arrowSize, angleDegrees: 24 });
    }

    /** Метод для отрисовки прямой и кривой */
    private drawTrackRoundedPath = ({ ctx, points, radius }: RoundedTrackPathDrawerShape) => {
        let prevPoint: MapPoint = points[0];

        points.forEach((point: MapPoint, i: number) => {
            if (i === 0) {
                prevPoint = point;
                ctx.moveTo(point.x, point.y);
            } else if (i === points.length - 1) {
                ctx.lineTo(point.x, point.y);
            } else if (i < points.length - 1) {
                const nextPoint = points[i + 1];
                prevPoint = this.drawTrackCorner({
                    ctx,
                    x1: prevPoint.x,
                    y1: prevPoint.y,
                    x2: point.x,
                    y2: point.y,
                    x3: nextPoint.x,
                    y3: nextPoint.y,
                    radius,
                });
            }
        });
    };

    /** Метод для отрисовки стрелочки */
    private drawTrackArrowHead({ ctx, points, angleDegrees, d }: ArrowHeadDrawerShape) {
        const pointsCount = points.length;
        if (pointsCount < 2) return;

        let p1 = points[pointsCount - 2];
        const p2 = points[pointsCount - 1];
        const angle = (angleDegrees * Math.PI) / 180;
        let x1 = p1.x;
        let x2 = p2.x;
        let y1 = p1.y;
        let y2 = p2.y;
        let l = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

        if (l < 10 && pointsCount > 3) {
            p1 = points[pointsCount - 3];
            x1 = p1.x;
            y1 = p1.y;
        }

        l = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

        const r = d / 2;
        const sin = (x2 - x1) / l;
        const cos = (y2 - y1) / l;
        const dx = r * sin;
        const dy = r * cos;

        x2 = x2 + dx;
        y2 = y2 + dy;

        const lineAngle = Math.atan2(y2 - y1, x2 - x1);
        const h = Math.abs(d / Math.cos(angle));
        const angle1 = lineAngle + Math.PI + angle;
        const topX = x2 + Math.cos(angle1) * h;
        const topY = y2 + Math.sin(angle1) * h;
        const angle2 = lineAngle + Math.PI - angle;
        const botX = x2 + Math.cos(angle2) * h;
        const botY = y2 + Math.sin(angle2) * h;
        // const x3 = x2 - Math.cos(lineAngle) * d * 0.7;
        //const y3 = y2 - Math.sin(lineAngle) * d * 0.7;

        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(x2, y2);
        ctx.lineTo(topX, topY);
        // ctx.quadraticCurveTo(x3, y3, botX, botY);
        ctx.lineTo(botX, botY);
        ctx.lineTo(x2, y2);
        ctx.fill();
        ctx.stroke();

        /* ctx.fillStyle = "red";
        ctx.beginPath();
        ctx.arc(botX, botY, 2, 0, Math.PI*2);
        ctx.fill(); */
    }

    /** Метод для подсчета вспомогательных точек кривой и отрисовки */
    private drawTrackCorner({ ctx, x1, x2, x3, y1, y2, y3, radius }: CornerDrawerShape) {
        let l = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        let r = l < radius ? l : radius;
        let sin = (x2 - x1) / l;
        let cos = (y2 - y1) / l;
        const dx1 = r * sin;
        const dy1 = r * cos;

        l = Math.sqrt(Math.pow(x3 - x2, 2) + Math.pow(y3 - y2, 2));
        r = l < radius ? l : radius;
        sin = (x3 - x2) / l;
        cos = (y3 - y2) / l;
        const dx2 = r * sin;
        const dy2 = r * cos;

        ctx.lineTo(x2 - dx1, y2 - dy1);
        ctx.quadraticCurveTo(x2, y2, x2 + dx2, y2 + dy2);
        return { x: x2 + dx2, y: y2 + dy2 };
    }
}
