import { memo } from "react";
import { CooGroupDomain } from "app-domain";
import { Indicator } from "../base";
import { Icon } from "./coo-group.styles";

type Props = {
    status: CooGroupDomain.Status;
    controlMode: CooGroupDomain.ControlMode;
};

const StatusIcon = <Icon />;

export const CooGroup = memo((props: Props) => {
    const outlineColor = CooGroupDomain.Constants.ControlModeDictionaries[props.controlMode].color;
    const inlineColor = CooGroupDomain.Constants.StatusDictionariesDictionaryList[props.status].color;

    return <Indicator icon={StatusIcon} outlineColor={outlineColor} inlineColor={inlineColor} />;
});
