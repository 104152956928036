import styled, { css } from "styled-components";

export const Container = styled.div(
    ({ theme: { spacings, colors } }) => css`
        display: flex;
        gap: ${spacings[3].str};
        padding: ${spacings[5].str};
        border-top: 1px solid ${colors.content.quinary};
    `
);
