import { trafficlight } from "shared/constants";
import { DashboardDomain } from "app-domain";

export const currentStatusFactory = (
    data?: DashboardDomain.Summary["trafficLight"]
): Store.Dashboard.TrafficLights["current"] => {
    return [
        {
            uid: trafficlight.statuses.success.uid,
            caption: trafficlight.statuses.success.caption,
            color: trafficlight.statuses.success.color,
            backgroundColor: trafficlight.statuses.success.backgroundColor,
            count: data?.onlineCount ?? 0,
            percentage: data?.onlinePercent ?? 0,
        },
        {
            uid: trafficlight.statuses.warning.uid,
            caption: trafficlight.statuses.warning.caption,
            color: trafficlight.statuses.warning.color,
            backgroundColor: trafficlight.statuses.warning.backgroundColor,
            count: data?.warningCount ?? 0,
            percentage: data?.nonCriticalPercent ?? 0,
        },
        {
            uid: trafficlight.statuses.danger.uid,
            caption: trafficlight.statuses.danger.caption,
            color: trafficlight.statuses.danger.color,
            backgroundColor: trafficlight.statuses.danger.backgroundColor,
            count: data?.criticalCount ?? 0,
            percentage: data?.criticalPercent ?? 0,
        },
        {
            uid: trafficlight.statuses.disable.uid,
            caption: trafficlight.statuses.disable.caption,
            color: trafficlight.statuses.disable.color,
            backgroundColor: trafficlight.statuses.disable.backgroundColor,
            count: data?.offlineCount ?? 0,
            percentage: data?.offlinePercent ?? 0,
        },
        {
            uid: trafficlight.statuses.notConnected.uid,
            caption: trafficlight.statuses.notConnected.caption,
            color: trafficlight.statuses.notConnected.color,
            backgroundColor: trafficlight.statuses.notConnected.backgroundColor,
            count: data?.notConnectedCount ?? 0,
            percentage: data?.notConnectedPercent ?? 0,
        },
    ];
};
