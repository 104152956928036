import { Range } from "./range-utils.types";

export class RangeUtils {
    /** Возвращает диапазонов где 2 диапазонов пересекаются */
    public static getRangesIntersection(ranges: [Range, Range]): Nullable<Range> {
        const firstRange = ranges[0];
        const secondRange = ranges[1];
        const firstRangeX1 = firstRange[0];
        const firstRangeX2 = firstRange[1];
        const secondRangeX1 = secondRange[0];
        const secondRangeX2 = secondRange[1];
        /** Массив с пересечениями */
        const intersections: number[] = [];

        if (firstRangeX1 >= secondRangeX1 && firstRangeX1 <= secondRangeX2) {
            intersections.push(firstRangeX1);
        }

        if (firstRangeX2 >= secondRangeX1 && firstRangeX2 <= secondRangeX2) {
            intersections.push(firstRangeX2);
        }

        if (firstRangeX1 <= secondRangeX1 && firstRangeX2 >= secondRangeX1) {
            intersections.push(secondRangeX1);
        }

        if (firstRangeX1 <= secondRangeX2 && firstRangeX2 >= secondRangeX2) {
            intersections.push(secondRangeX2);
        }

        if (intersections.length < 2) return null;
        const unique = Array.from(new Set(intersections)).sort((a, b) => a - b);
        return [unique[0], unique[1]];
    }
}
