import styled, { css } from "styled-components/macro";
import { AccordionHeader } from "../accordion";

export const DetailsAccordionHeader = styled(AccordionHeader)((props) => {
    const {
        theme: { spacings },
    } = props;
    return css`
        padding: ${spacings[1].str} 0 0 ${spacings[3].str};
    `;
});
