import { useEffect, useState } from "react";
import { TrafficLightDomain } from "app-domain";

const checkAllInitialized = (trafficLightList: TrafficLightDomain.TrafficLight[]) =>
    trafficLightList.every((trafficLight) => trafficLight.isInitialized);

export const useTrafficLightsGroupInitialized = (trafficLights: TrafficLightDomain.TrafficLight[]) => {
    const [isAllInitialized, setAllInitialized] = useState(() => checkAllInitialized(trafficLights));

    useEffect(() => {
        const handleTrafficLightInit = () => {
            setAllInitialized(checkAllInitialized(trafficLights));
        };

        trafficLights.forEach((trafficLight) => {
            trafficLight.on("initialized", handleTrafficLightInit);
        });

        return () => {
            trafficLights.forEach((trafficLight) => {
                trafficLight.off("initialized", handleTrafficLightInit);
            });
        };
    }, [trafficLights]);

    return isAllInitialized;
};
