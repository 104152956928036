import styled from "styled-components";
import { Button as UiKitButton } from "@megapolis/uikit/button";

type Props = React.ComponentProps<typeof UiKitButton> & {
    isFullWidth?: boolean;
};

export const Button = styled(UiKitButton)<Props>`
    width: ${({ size = "medium", isFullWidth }) => {
        if (isFullWidth) return "100%";
        return size === "medium" ? "32px" : "24px";
    }};
    height: ${({ size = "medium" }) => (size === "medium" ? "32px" : "24px")};
`;
