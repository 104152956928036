import { Drawer } from "diagrams/drawers";
import { LabelData } from "./label-drawer.types";

export class LabelDrawer extends Drawer<LabelData> {
    protected draw = () => {
        if (!this.data) return;
        this.ctx.fillStyle = "#FFFFFF";
        this.ctx.font = "500 12px Inter";
        this.ctx.textBaseline = "middle";
        this.ctx.textAlign = "center";
        this.ctx.strokeStyle = this.data.strokeStyle;
        this.ctx.lineWidth = 2;

        this.ctx.strokeText(this.data.text, this.data.x, this.data.forwardY);
        if (this.ctx.measureText(this.data.text).width > this.data.width) return;
        this.ctx.fillText(this.data.text, this.data.x, this.data.forwardY);
        if (!this.data.hasBackwardDirection) return;
        this.ctx.fillText(this.data.text, this.data.x, this.data.backwardY);
    };
}
