import * as Env from "env-data";
import { BaseApi } from "api/utils";
import { SearchResult } from "./dashboard-api.types";
import * as DashboardDomain from "app-domain/dashboard";

export class DashboardAPI extends BaseApi {
    constructor() {
        super(`${Env.REACT_APP_API_BASEURL}/dashboard`);
    }

    /** Возвращает данные об оборудование */
    public summary(): Promise<DashboardDomain.Summary> {
        const url = this.buildUrl("");
        return this.request({ url });
    }

    public search(value: string): Promise<SearchResult> {
        const url = this.buildUrl(`/search?search=${value}`);
        return this.request({ url });
    }
}
