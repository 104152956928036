import { v4 } from "uuid";
import * as utils from "shared/utils";
import * as consts from "./factory-cameras-data.consts";
import type { FactoryCamerasData } from "./factory-cameras-data.types";

export const factoryCamerasData: FactoryCamerasData = (data) => ({
    caption: consts.caption,
    total: utils.convertNumberToFormat(data?.total),
    info: [
        {
            uid: v4(),
            type: consts.info.type.success,
            caption: consts.info.caption.success,
            count: utils.convertNumberToFormat(data?.onlineCount),
        },
        {
            uid: v4(),
            type: consts.info.type.warning,
            caption: consts.info.caption.warning,
            count: utils.convertNumberToFormat(data?.nonCriticalCount),
        },
        {
            uid: v4(),
            type: consts.info.type.danger,
            caption: consts.info.caption.danger,
            count: utils.convertNumberToFormat(data?.criticalCount),
        },
        {
            uid: v4(),
            type: consts.info.type.disable,
            caption: consts.info.caption.disable,
            count: utils.convertNumberToFormat(data?.offlineCount),
        },
    ],
});
