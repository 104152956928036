import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { TrafficLightDomain } from "app-domain";
import { Store } from "app";
import { BlockLoader } from "components";
import { CooGroupContext, CooGroupDetailsContext } from "./context";

type Props = {
    id: number;
};

export const Provider: FC<Props> = observer(({ id, children }) => {
    const [state, setState] = useState<CooGroupDetailsContext>();
    const trafficLightStore = Store.useTrafficLightStore();
    const trafficLightManager = Store.useTrafficLightManager();
    const remoteControls = Store.useCooGroupRemoteControls();
    const cooGroupStore = Store.useCooGroupStore();
    const remoteControl = remoteControls.getByCooGroupId(id);

    useEffect(() => {
        let isMounted = true;
        /** TODO: Придумать адекватный способ догружать необходимые данные для ЛК */
        const update = async () => {
            const cooGroup = await cooGroupStore.loadDetailsById(id);
            await remoteControls.getByCooGroupId(cooGroup.id);

            await remoteControls.loadByCooGroupId(cooGroup.id);
            const trafficLights = cooGroup.facilities.reduce((result, facility) => {
                if (!facility.facilityId) return result;
                trafficLightManager.initTrafficLightById(facility.facilityId);
                const trafficLight = trafficLightStore.getById(facility.facilityId);
                if (trafficLight) result.push(trafficLight);
                return result;
            }, [] as TrafficLightDomain.TrafficLight[]);

            if (!isMounted) return;
            setState({ cooGroup, trafficLights, hasBackwardDirection: cooGroup.geometry.isReverseRouteSameAsDirect });
        };
        update();
        return () => {
            isMounted = false;
        };
    }, [id, trafficLightStore, trafficLightManager, remoteControls, cooGroupStore]);

    if (!state) return <BlockLoader />;

    const value = { ...state, remoteControl: remoteControl ?? void 0 };

    return <CooGroupContext.Provider value={value}>{children}</CooGroupContext.Provider>;
});
