const _window: Window & typeof globalThis & { env?: { [key: string]: any } } = window;

// Authorization
export const REACT_APP_AUTH_DOMAIN = _window.env?.REACT_APP_AUTH_DOMAIN || process.env.REACT_APP_AUTH_DOMAIN;

// Map

export const REACT_APP_MAP_URL = _window.env?.REACT_APP_MAP_URL || process.env.REACT_APP_MAP_URL;

// Токен авторизации для статичных файлов карты
export const REACT_APP_MAP_STATIC_TOKEN =
    _window.env?.REACT_APP_MAP_STATIC_TOKEN || process.env.REACT_APP_MAP_STATIC_TOKEN;

export const REACT_APP_KSODD_LAYER = _window.env?.REACT_APP_KSODD_LAYER || process.env.REACT_APP_KSODD_LAYER;
export const REACT_APP_MAP_DARK_STYLE = _window.env?.REACT_APP_MAP_DARK_STYLE || process.env.REACT_APP_MAP_DARK_STYLE;
export const REACT_APP_MAP_SATELLITE_STYLE =
    _window.env?.REACT_APP_MAP_STYLE_SATELLITE || process.env.REACT_APP_MAP_STYLE_SATELLITE;

export const REACT_APP_MAP_CENTER = (() => {
    try {
        return JSON.parse(_window.env?.REACT_APP_MAP_CENTER || process.env.REACT_APP_MAP_CENTER);
    } catch (e) {
        return [37.618423, 55.74];
    }
})();

const _mapZoomStr = _window.env?.REACT_APP_MAP_ZOOM || process.env.REACT_APP_MAP_ZOOM;
let _mapZoomInt = 10;
try {
    if (typeof _mapZoomStr === "string" && _mapZoomStr.length) {
        _mapZoomInt = parseFloat(_mapZoomStr);
    }
} catch (err) {
    console.error(err);
}
export const REACT_APP_MAP_ZOOM = _mapZoomInt;

export const REACT_APP_MAP_STYLES_API = _window.env?.REACT_APP_MAP_STYLES_API || process.env.REACT_APP_MAP_STYLES_API;
export const REACT_APP_MAP_API_USER_NAME =
    _window.env?.REACT_APP_MAP_API_USER_NAME || process.env.REACT_APP_MAP_API_USER_NAME;
export const REACT_APP_MAP_FRAGMENT_STATIC_TILE_ID =
    _window.env?.REACT_APP_MAP_FRAGMENT_STATIC_TILE_ID || process.env.REACT_APP_MAP_FRAGMENT_STATIC_TILE_ID;

export const REACT_APP_API_YANDEX_GEOCODE_API_KEY =
    _window.env?.REACT_APP_API_YANDEX_GEOCODE_API_KEY || process.env.REACT_APP_API_YANDEX_GEOCODE_API_KEY;

export const REACT_APP_GEOCODE_SPAN_SEARCH_AREA =
    _window.env?.REACT_APP_GEOCODE_SPAN_SEARCH_AREA || process.env.REACT_APP_GEOCODE_SPAN_SEARCH_AREA;

// API
export const REACT_APP_WAREHOUSE_URL = _window.env?.REACT_APP_WAREHOUSE_URL || process.env.REACT_APP_WAREHOUSE_URL;
export const REACT_APP_API_BASEURL = (_window.env?.REACT_APP_API_BASEURL ||
    process.env.REACT_APP_API_BASEURL) as string;

export const REACT_APP_API_DETECTOR_SERVICE = (_window.env?.REACT_APP_API_DETECTOR_SERVICE ||
    process.env.REACT_APP_API_DETECTOR_SERVICE) as string;

export const REACT_APP_CDN_FILES = _window.env?.REACT_APP_CDN_FILES || process.env.REACT_APP_CDN_FILES;

export const REACT_APP_API_SERVICE_WAREHOUSE =
    _window.env?.REACT_APP_API_SERVICE_WAREHOUSE || process.env.REACT_APP_API_SERVICE_WAREHOUSE;

export const REACT_APP_API_SERVICE_DTM_TRAFFIC =
    _window.env?.REACT_APP_API_SERVICE_DTM_TRAFFIC || process.env.REACT_APP_API_SERVICE_DTM_TRAFFIC;

export const REACT_APP_API_GEOCODE = _window.env?.REACT_APP_API_GEOCODE || process.env.REACT_APP_API_GEOCODE;

export const REACT_APP_API_DTM_ROUTER = _window.env?.REACT_APP_API_DTM_ROUTER || process.env.REACT_APP_API_DTM_ROUTER;

export const REACT_APP_API_YANDEX_GEOCODE =
    _window.env?.REACT_APP_API_YANDEX_GEOCODE || process.env.REACT_APP_API_YANDEX_GEOCODE;

// VIDEO

export const REACT_APP_API_CAMERA_SERVICE =
    _window.env?.REACT_APP_API_CAMERA_SERVICE || process.env.REACT_APP_API_CAMERA_SERVICE;
