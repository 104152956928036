import * as Env from "env-data";
import { BaseApi } from "api/utils";
import { DtmTrafficParamsFilter } from "./dtm-traffic-params-filter";
import type { GetScoringCityResponse, GetLastPeriodResponse } from "./dtm-traffic-api.types";

export class DtmTrafficAPI extends BaseApi {
    constructor() {
        super(`${Env.REACT_APP_API_SERVICE_DTM_TRAFFIC}`);
    }

    /** Получение балла пробок в городе, разбитого по времени */
    public getScoringCity(day: string): Promise<GetScoringCityResponse> {
        const queryParams = new DtmTrafficParamsFilter(day).toString();
        const url = this.buildUrl("scoring/city/split", queryParams);
        return this.request({ url }).catch(console.log);
    }

    public getLastPeriod(): Promise<GetLastPeriodResponse> {
        const url = this.buildUrl("meta/last");
        return this.request({ url }).catch(console.log);
    }

    protected buildUrl(endPoint: string = "", params?: string) {
        return super.buildUrl(`/${endPoint}${params ? `?${params}` : ""}`);
    }
}
