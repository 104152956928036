import { APITypes } from "api";
import { CooGroupDomain } from "app-domain";

const secondInMilliseconds = 1000;

export class CooGroupLogsMapper {
    public fromAPI(log: APITypes.CooGroupLog): CooGroupDomain.CooGroupLog {
        return {
            ...log,
            dateTime: new Date(log.timestamp).getTime() / secondInMilliseconds,
        };
    }
}
