import { memo } from "react";
import { TrafficLightDomain } from "app-domain";
import { Accordion, AccordionHeader, AccordionContent, LimitedList } from "components";
import { FlexColumn } from "shared/styles";
import { TrafficLightMalfunction } from "../traffic-light-malfunction";

type Props = {
    malfunctions: TrafficLightDomain.Types.MalfunctionCodeDictionary[];
};

const MalfunctionListComponent = (props: Props) => {
    const items = props.malfunctions.map((dict, index) => (
        <TrafficLightMalfunction
            key={`${dict.code}_${dict.description}_${index}`}
            code={dict.code}
            name={dict.name}
            isCritical={dict.isCritical}
            description={dict.description}
        />
    ));

    return (
        <Accordion>
            <AccordionHeader title="Уведомления" subTitle={items.length} />
            <AccordionContent>
                <LimitedList.List limit={3}>
                    <FlexColumn gap={8} width="100%">
                        <FlexColumn gap={8}>
                            <LimitedList.Items>{items}</LimitedList.Items>
                        </FlexColumn>
                        <div>
                            <LimitedList.Button />
                        </div>
                    </FlexColumn>
                </LimitedList.List>
            </AccordionContent>
        </Accordion>
    );
};

export const TrafficLightMalfunctionList = memo(MalfunctionListComponent);
