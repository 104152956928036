import { memo, PropsWithChildren } from "react";
import styled, { css } from "styled-components/macro";
import { AutoSizer } from "../auto-sizer";

export type AdaptiveGridColumnsRuleHandler = (width: number, height: number) => string;

export type AdaptiveGridProps = PropsWithChildren<{
    getColumnsRule: AdaptiveGridColumnsRuleHandler;
    columnGap?: number;
    rowGap?: number;
}>;

const Container = styled.div<{ columnsRule?: string; columnGap?: number; rowGap?: number }>(
    (props) => css`
        display: grid;
        width: 100%;
        height: 100%;
        column-gap: ${props.columnGap}px;
        row-gap: ${props.rowGap}px;
        grid-template-columns: ${props.columnsRule};
    `
);

export const AdaptiveGrid = memo((props: AdaptiveGridProps) => (
    <AutoSizer>
        {({ width, height }) => (
            <Container
                columnGap={props.columnGap}
                rowGap={props.rowGap}
                columnsRule={props.getColumnsRule(width, height)}>
                {props.children}
            </Container>
        )}
    </AutoSizer>
));
