import { statusCodeDictionaryMap } from "../../constants";
import { StatusCode } from "../../enums";
import { GovernancePhase } from "./governance-data";

export enum GovernanceCommandType {
    HoldPhase = "phase",
    HoldStatus = "status",
    HoldCycle = "cycle",
}

export type GovernanceCommandData = {
    start: Date;
    expiresAt: Date;
    expireSeconds: number;
    isApplied?: boolean;
};

export abstract class GovernanceCommand {
    public readonly start: Date;
    public readonly expiresAt: Date;
    public readonly isApplied: boolean;

    public abstract get text(): string;

    constructor(public type: GovernanceCommandType, data: GovernanceCommandData) {
        this.start = data.start;
        this.expiresAt = data.expireSeconds ? new Date(Date.now() + data.expireSeconds * 1000) : data.expiresAt;
        this.isApplied = data.isApplied ?? false;
    }
}

export class HoldCycleCommand extends GovernanceCommand {
    public phases: GovernancePhase[];

    public get text(): string {
        return "Пользовательская программа";
    }

    constructor(data: GovernanceCommandData & { phases: GovernancePhase[] }) {
        super(GovernanceCommandType.HoldCycle, data);
        this.phases = data.phases;
    }
}

export class HoldPhaseCommand extends GovernanceCommand {
    public phase: number;

    public get text(): string {
        return `Удержание фазы ${this.phase}`;
    }
    constructor(data: GovernanceCommandData & { phase: number }) {
        super(GovernanceCommandType.HoldPhase, data);
        this.phase = data.phase;
    }
}

export class HoldStatusCommand extends GovernanceCommand {
    public status: StatusCode;

    public get text(): string {
        return statusCodeDictionaryMap[this.status].name;
    }

    constructor(data: GovernanceCommandData & { status: StatusCode }) {
        super(GovernanceCommandType.HoldStatus, data);
        this.status = data.status;
    }
}
