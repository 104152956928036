import { CooGroupDomain } from "app-domain";
import { FormTextField } from "lib";

export abstract class Form {
    public name: FormTextField;
    public cycle: CooGroupDomain.CustomCycle;
    protected initialName = "";

    constructor(cycle: CooGroupDomain.CustomCycle) {
        this.name = new FormTextField(cycle.name);
        this.initialName = cycle.name;
        this.cycle = cycle;
    }

    public get cycleId() {
        return this.cycle.id;
    }

    public abstract get hasChanges(): boolean;

    /**
     * Признак, определяющий какой цикл сейчас редактируется.
     * Новый или существующий
     */
    public get isNew() {
        return !this.cycle.id;
    }

    protected get isNameChanged() {
        return this.initialName !== this.name.value;
    }

    public abstract build(): CooGroupDomain.CustomCycle | null;
}
