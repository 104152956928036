import React from "react";
import { createPortal } from "react-dom";
import { useFloatingModal } from "./floating-modal.hooks";
import { FloatingModalContext } from "./floating-modal-context";
import type { Props } from "./floating-modal.types";
import * as Markup from "./floating-modal.styles";

export const FloatingModal = React.memo((props: Props) => {
    const { containerEl, modalRef, setDragElement } = useFloatingModal(props);

    if (!containerEl) return null;

    return (
        <FloatingModalContext.Provider value={{ setDragElement }}>
            {createPortal(
                <Markup.Container
                    style={props.sizes}
                    ref={modalRef}
                    aria-selected={props.isFocus}
                    data-cypress={props.dataCypress}
                    zIndex={props.zIndex}>
                    {props.children}
                </Markup.Container>,
                containerEl
            )}
        </FloatingModalContext.Provider>
    );
});
