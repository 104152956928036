import { memo, PropsWithChildren } from "react";
import { LoaderOverlay } from "../loader-overlay";
import { RelativeBlock } from "./loader-overlay-wrapper.styles";

type Props = PropsWithChildren<{
    isLoading: boolean;
}>;

export const LoaderOverlayWrapper = memo((props: Props) => (
    <RelativeBlock>
        {props.children}
        {props.isLoading && <LoaderOverlay />}
    </RelativeBlock>
));
