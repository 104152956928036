import { CooGroupDomain } from "app-domain";
import { action, makeObservable, observable } from "mobx";
import { Utils } from "shared";
import { Form } from "./abstract-form";

export class CoordinationCycleForm extends Form {
    public hasChanges: boolean = false;
    public editableCycle: CooGroupDomain.CustomCycle | null = null;

    constructor() {
        super();
        makeObservable(this, {
            setCycle: action,
            updateCycle: action,
            hasChanges: observable,
            editableCycle: observable.ref,
        });
    }

    public updateCycle(hasChanges: boolean, cycle: CooGroupDomain.CustomCycle) {
        if (!this.editableCycle) return;
        this.editableCycle.cycles = cycle.cycles;
        this.hasChanges = hasChanges;
    }

    public setCycle(cycle: CooGroupDomain.Cycle): void {
        super.setCycle(cycle);
        this.editableCycle = {
            ...cycle,
            cycles: cycle.cycles ? Array.from(cycle.cycles) : [],
        };
    }

    public build(): CooGroupDomain.CustomCycle | null {
        if (!this.editableCycle) return null;
        const result = Utils.omitKeys(this.editableCycle, "id", "schedules");
        return {
            ...result,
            name: this.name.value,
        };
    }

    public reset(): void {
        super.reset();
        if (!this.editableCycle || !this.originCycle) return;
        this.editableCycle = {
            ...this.originCycle,
            cycles: this.originCycle.cycles?.map((cycle) => ({
                ...cycle,
                phases: cycle.phases?.map((phase) => ({ ...phase })),
            })),
        };
    }
}
