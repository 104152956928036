import React from "react";
import { REACT_APP_WAREHOUSE_URL } from "env-data";
import { openURLInNewTab } from "shared/utils/open-url-in-new-tab";
import type { Props } from "./details.types";

export const useHandleClick = ({ uid }: Props) => {
    const handleClick = React.useCallback(() => {
        openURLInNewTab(`${REACT_APP_WAREHOUSE_URL}/speedcam/${uid}`);
    }, [uid]);

    return { handleClick };
};
