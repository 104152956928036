import { Fragment, Children, ReactNode, memo } from "react";
import { Circle } from "../circle";
import { Wrapper } from "./separated-inline-list.styles";

type Props = {
    children: ReactNode;
    className?: string;
    separator?: ReactNode;
    gap?: number;
    wrap?: boolean;
};

const defaultSeparator = <Circle size={4} />;

const SeparatedInlineListComponent = (props: Props) => {
    const { children, gap = 4, separator = defaultSeparator, wrap, className } = props;

    return (
        <Wrapper className={className} gap={gap} hasWrap={wrap}>
            {Children.toArray(children).map((child, index, arr) => (
                <Fragment key={`child-${child.toString()}-${index}`}>
                    {child}
                    {index !== arr.length - 1 ? separator : null}
                </Fragment>
            ))}
        </Wrapper>
    );
};

export const SeparatedInlineList = memo(SeparatedInlineListComponent);
