import { APITypes } from "api";
import { TVCamera } from "app-domain";
import { equipmentStatus } from "shared/constants";

export class TVCameraListMapper {
    public fromAPI(list: APITypes.TvCameraList): TVCamera.ITVCameraListMap {
        const map = new Map();

        if (Array.isArray(list)) {
            list.forEach((el) => {
                const addresses = el.addresses?.map(({ address }) => address).join(", ") ?? "";

                map.set(el.id, {
                    id: el.id,
                    num: el.num,
                    lat: el.lat,
                    lng: el.lng,
                    addresses,
                    statusOnIssues: {
                        id: el.whAssetStatus,
                        name: equipmentStatus.getStatusNameOnIssueId(el.whAssetStatus),
                        color: equipmentStatus.getStatusColorOnIssueId(el.whAssetStatus),
                    },
                    statusOnMonitoring: {
                        id: el.whMonitoringStatus,
                        name: equipmentStatus.getStatusNameOnMonitoringId(el.whMonitoringStatus),
                        color: equipmentStatus.getStatusColorOnMonitoringId(el.whMonitoringStatus),
                    },
                });
            });
        }

        return map;
    }
}
