import { ShortCodeDictionary } from "../types";
import { ConditionType } from "../enums";

export const conditionTypeDictionaryMap: { [key in ConditionType]: ShortCodeDictionary<ConditionType> } = {
    [ConditionType.Ok]: {
        code: ConditionType.Ok,
        name: "Работает",
    },
    [ConditionType.Warning]: {
        code: ConditionType.Warning,
        name: "Работает с замечаниями",
    },
    [ConditionType.Critical]: {
        code: ConditionType.Critical,
        name: "Критические ошибки",
    },
    [ConditionType.Offline]: {
        code: ConditionType.Offline,
        name: "Нет связи",
    },
    [ConditionType.NotConnected]: {
        code: ConditionType.NotConnected,
        name: "Не подключен",
    },
};

export const conditionTypeDictionaryList = Object.values(conditionTypeDictionaryMap);
