export const drawParams = {
    defaultFontSize: 12,
    directionTop: 60,
    directionBarHeight: 20,
    backwardDirectionGap: 4,
    pixelPerMeter: 0.2,
    cycleHeadHeight: 32,
    cycleHeadGap: 4,
    cycleMinDistance: 60,
    dragHandleWidth: 2,
    timeTop: 4,
    timeScaleHeight: 8,
    timeScaleTop: 16,
    timeLineHeight: 24,
    bottomTimeLineMarginTop: 8,
    strokeStyle: "rgba(3, 40, 76, 0.3)",
    phaseBackground: "rgba(3, 40, 76, 0.1)",
    directionLabelFillStyle: "#000000",
    legendLabelFillStyle: "rgba(3, 40, 76, 0.52)",
    defaultFont: "500 12px Inter",
};
