import { ControlSource, CycleType } from "../enums";

export interface GovernanceData {
    id: number;
    cooGroupId: number;
    cycleType: CycleType;
    profileId?: string;
    /** Идентификатор пользователя */
    userId?: string;
    /** Имя пользователя */
    username?: string;
    /** Дата взятия управления */
    start?: Date | DateTimeString;
    /**  Дата окончания управления */
    finish?: Date | DateTimeString;
    /**  Номер программы */
    program?: number;
    /** Время когда истечёт режим удержания зелёного коридора */
    holdExpireDate?: Date | DateTimeString;
    commandStart?: Date | DateTimeString;
    controlSource?: ControlSource;
}

export class Governance implements GovernanceData {
    public userId?: string;
    public username?: string;
    public start?: Date;
    public finish?: Date;
    public program?: number;
    public holdExpireDate?: Date;
    public commandStart?: Date;
    public controlSource?: ControlSource;
    public profileId?: string;

    constructor(public id: number, public cooGroupId: number, public cycleType: CycleType) {}

    public setData(data: GovernanceData) {
        this.userId = data.userId;
        this.username = data.username;
        this.start = this.setDateField(data.start);
        this.finish = this.setDateField(data.finish);
        this.program = data.program;
        this.holdExpireDate = this.setDateField(data.holdExpireDate);
        this.commandStart = this.setDateField(data.commandStart);
        this.controlSource = data.controlSource;
        this.profileId = data.profileId;
    }

    public get runningCommandText() {
        if (!this.commandStart) return "";

        switch (this.cycleType) {
            case CycleType.GreenStreet:
                return "Зеленая улица";
            case CycleType.Coordination:
                return "Координация";
            default:
                return "";
        }
    }

    public get isUnderUserControl() {
        return this.controlSource === ControlSource.User;
    }

    public get isRunningCommand() {
        return !!this.commandStart;
    }

    public compareUser(userId: string) {
        return this.profileId === userId;
    }

    private setDateField(data?: Date | DateTimeString) {
        return typeof data === "string" ? new Date(data) : data;
    }
}
