import * as utils from "shared/utils";
import * as units from "./units";
import { FactoryTrafficLightsData } from "./factory-trafficLights-data.types";

export const factoryTrafficLightsData: FactoryTrafficLightsData = (data) => ({
    caption: "Светофорные объекты",
    total: utils.convertNumberToFormat(data?.trafficLight.total),
    current: units.currentStatusFactory(data?.trafficLight),
    history: units.historyStatusFactory(data?.trafficlightHistory),
});
