import { RefObject, useEffect } from "react";

type ClickOutsideProps = {
    ref: RefObject<HTMLElement>;
    callback: (event: MouseEvent) => void;
    capture?: boolean;
    debugName?: string;
};

const logDebugMsg = (debugName: string, ref: RefObject<HTMLElement>, isContaining: boolean, shouldCall: boolean) => {
    console.groupCollapsed(`DEBUG CLICK OUTSIDE-${debugName}`);
    console.log("ref", ref);
    console.log("ref present: ", Boolean(ref.current));
    console.log("contains target node:", isContaining);
    console.log("should call:", shouldCall);
    console.groupEnd();
};

export const useClickOutside = ({ ref, callback, capture, debugName }: ClickOutsideProps) => {
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const isContaining = ref.current?.contains(event.target as Node) ?? false;
            const shouldCall = Boolean(ref.current) && !isContaining;
            if (debugName) logDebugMsg(debugName, ref, isContaining, shouldCall);
            if (!shouldCall) return;
            callback(event);
        };
        window.addEventListener("click", handleClick, { capture });

        return () => window.removeEventListener("click", handleClick, { capture });
    }, [ref, callback, capture, debugName]);
};
