import mapboxgl from "mapbox-gl";
import { REACT_APP_KSODD_LAYER } from "env-data";
import { BooleanStorageManager } from "lib";
import { ILayerController } from "../layer-controller";
import { storageKey } from "./ksodd-controller.constants";

export class KsoddController implements ILayerController {
    private layerPrefix = REACT_APP_KSODD_LAYER;

    private isLayerVisible = new BooleanStorageManager(storageKey);

    constructor(private map: mapboxgl.Map) {
        this.setVisibility(this.isLayerVisible.value);
    }

    public getVisibility = () => {
        return this.isLayerVisible.value;
    };

    public setVisibility(value: boolean) {
        this.isLayerVisible.value = value;
        const style = this.map.getStyle();
        const visibility = this.isLayerVisible.value ? "visible" : "none";

        if (!style.layers) return;

        for (const layer of style.layers) {
            if (!this.isAssociatedLayerId(layer.id)) continue;
            this.map.setLayoutProperty(layer.id, "visibility", visibility);
        }
    }

    private isAssociatedLayerId(layerId: string) {
        return layerId.includes(`${this.layerPrefix}_`);
    }
}
