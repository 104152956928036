import { memo } from "react";
import { Days } from "./days";
import { WeekDays } from "./weekdays";
import * as Markup from "./month-view.styles";

export const MonthView = memo(() => (
    <Markup.Container>
        <WeekDays />
        <Days />
    </Markup.Container>
));
