import { TrafficLightDomain } from "app-domain";
import { ReactComponent as ClockMalfunction } from "./clock-malfunction.svg";
import { ReactComponent as NoPower } from "./no-power.svg";
import { ReactComponent as Lamp } from "./lamp.svg";
import { ReactComponent as RemoteControlMalfunction } from "./remote-control-malfunction.svg";
import { ReactComponent as DoorOpened } from "./door-opened.svg";
import { ReactComponent as External } from "./external.svg";
import { ReactComponent as Hardware } from "./hardware.svg";
import { ReactComponent as Software } from "./software.svg";

const { MalfunctionType } = TrafficLightDomain.Enums;

export const IconsByMalfunctionType = {
    [MalfunctionType.ClockMalfunction]: <ClockMalfunction />,
    [MalfunctionType.NoPower]: <NoPower />,
    [MalfunctionType.LampCritical]: <Lamp />,
    [MalfunctionType.LampNotCritical]: <Lamp />,
    [MalfunctionType.RemoteControlMalfunction]: <RemoteControlMalfunction />,
    [MalfunctionType.DoorOpened]: <DoorOpened />,
    [MalfunctionType.ExternalCritical]: <External />,
    [MalfunctionType.ExternalNotCritical]: <External />,
    [MalfunctionType.HardwareCritical]: <Hardware />,
    [MalfunctionType.HardwareNotCritical]: <Hardware />,
    [MalfunctionType.SoftwareCritical]: <Software />,
    [MalfunctionType.SoftwareNotCritical]: <Software />,
    [MalfunctionType.FacilityControlService]: <Software />,
} as const;
