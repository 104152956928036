import * as Enums from "app-domain/traffic-light/enums";
import * as Utils from "app-domain/traffic-light/utils";
import { CanvasSVG } from "lib";
import PointerIconURL from "diagrams/icons/pointer.svg";
import SplitterIconURL from "diagrams/icons/splitter.svg";
import { timelineColors } from "diagrams/timeline-renderer/timeline-renderer.constants";
import { DirectionTypeIconURLMap } from "./cycle-drawer.constants";
import { PhaseData, DirectionIconsMap, Direction } from "../../traffic-light-cycle-editor.types";

export class CycleDrawer {
    public pixelsPerSecond = 0;
    public topOffset = 0;
    public maxWidth = 0;
    public barHeight = 0;
    public timeScaleTop = 0;
    public timeScaleHeight = 0;
    public dragHandleWidth = 0;
    public directionsBlockTop = 0;

    private static DEFAULT_FONT_SIZE = 12;
    private static DEFAULT_FONT = `500 ${CycleDrawer.DEFAULT_FONT_SIZE}px Inter`;
    private static PHASE_LABEL_PARAMS = {
        font: "500 12px Inter",
        strokeColor: "#03284C4F",
        fillColor: "#fff",
    };
    private static DIRECTION_LABEL_PARAMS = {
        font: "500 12px Inter",
        fillColor: "#000",
    };
    private static GREEN_COLOR = "#32C758";
    private static RED_COLOR = "#FF3A2F";
    private static SCALE_LINE_WIDTH = 0.5;
    private static TIME_SCALE_COLOR = "#03284C85";
    private static TIME_COLOR = "#03284C";

    private originPhasePointerIcon = new CanvasSVG(PointerIconURL);
    private dragHandlerIcon = new CanvasSVG(SplitterIconURL);
    private directionIcons: DirectionIconsMap = {};

    constructor(private ctx: CanvasRenderingContext2D) {
        this.initIcons();
    }

    /** Рисует блок направления */
    public drawDirection(options: {
        x: number;
        y: number;
        width: number;
        currentPhase: PhaseData;
        direction: Direction;
        nextPhase: PhaseData;
        drawIcon?: boolean;
    }) {
        const { x, y, width, currentPhase, direction, drawIcon, nextPhase } = options;
        this.ctx.save();
        this.ctx.fillStyle = currentPhase.directionByNum[direction.number]
            ? CycleDrawer.GREEN_COLOR
            : CycleDrawer.RED_COLOR;
        this.ctx.fillRect(x, y, width, this.barHeight);

        if (currentPhase.directionByNum?.[direction.number] !== nextPhase.directionByNum?.[direction.number]) {
            this.drawBlinks({ direction, x: x + width, y, phase: currentPhase });
        }

        this.ctx.fillStyle = "white";
        this.ctx.fillRect(x - 1, y, this.dragHandleWidth, this.barHeight);

        this.drawTimeGrid(x, y, width);

        if (drawIcon) {
            this.drawDirectionLabel(24, y + this.barHeight / 2, `${direction.number}Н`, direction.type);
        }

        this.ctx.restore();
    }

    /** Рисует блок фазы */
    public drawPhase(x: number, width: number, phase: PhaseData) {
        this.ctx.save();
        this.ctx.fillStyle = Utils.getPhaseColor(phase.phaseNumber);

        this.ctx.fillRect(x, this.topOffset, width, this.barHeight);
        this.ctx.globalAlpha = 1;

        this.ctx.fillStyle = "white";
        this.ctx.fillRect(x - 1, this.topOffset, this.dragHandleWidth, this.barHeight);

        this.drawTimeGrid(x, this.topOffset, width);
        this.drawPhaseLabel(
            `Ф${phase.phaseNumber} \u{2022} ${phase.tBasic + phase.tProm} с`,
            x + width / 2,
            this.topOffset + this.barHeight / 2
        );
        this.ctx.restore();
    }

    /** Рисует разделитель между фазами, который позволяет изменять времена фаз */
    public drawPhaseDragHandle(x: number) {
        this.ctx.drawImage(
            this.dragHandlerIcon.img,
            x - this.dragHandlerIcon.img.width / 2,
            this.topOffset + this.barHeight / 2 - this.dragHandlerIcon.img.height / 2
        );
    }

    /** Рисует временную шкалу */
    public drawTimeScale() {
        if (this.pixelsPerSecond === 0) return;
        this.ctx.lineWidth = CycleDrawer.SCALE_LINE_WIDTH;
        this.ctx.strokeStyle = CycleDrawer.TIME_SCALE_COLOR;
        this.ctx.beginPath();
        const top = this.timeScaleTop;
        for (let x = 0, time = 0; x <= this.width + 2; x += this.pixelsPerSecond) {
            this.ctx.moveTo(x, top);
            this.ctx.lineTo(x, top + (time % 10 === 0 ? this.timeScaleHeight : this.timeScaleHeight / 2));
            time++;
        }
        this.ctx.stroke();
    }

    public drawOriginPhasePointer(x: number, y: number) {
        const img = this.originPhasePointerIcon.img;
        this.ctx.drawImage(img, x - img.width / 2, this.topOffset + y - img.height / 2);
    }

    /** Рисует время */
    public drawTime(x: number, value: number, alpha = 0.52) {
        this.ctx.save();
        let finalX = x;
        const text = String(value);
        const measure = this.ctx.measureText(text);
        const y = this.timeScaleTop + this.timeScaleHeight;
        this.ctx.textAlign = "center";

        if (x + measure.width > this.width) {
            finalX = this.width;
            this.ctx.textAlign = "right";
        }

        if (x - measure.width < 0) {
            finalX = 0;
            this.ctx.textAlign = "left";
        }

        this.ctx.textBaseline = "top";
        this.ctx.font = CycleDrawer.DEFAULT_FONT;
        this.ctx.fillStyle = CycleDrawer.TIME_COLOR;
        this.ctx.globalAlpha = alpha;
        this.ctx.fillText(text, finalX, y);
        this.ctx.restore();
    }

    /** Рисует лейбл + иконку(если имеется) направления */
    private drawDirectionLabel(x: number, y: number, text: string, type: Enums.DirectionTypeCode) {
        const svg = this.directionIcons[type];
        if (svg) {
            this.ctx.drawImage(svg.img, 12 - svg.img.width / 2, y - svg.img.height / 2);
        }
        this.ctx.save();
        this.ctx.textAlign = "left";
        this.ctx.textBaseline = "middle";
        this.ctx.font = CycleDrawer.DIRECTION_LABEL_PARAMS.font;
        this.ctx.fillStyle = CycleDrawer.DIRECTION_LABEL_PARAMS.fillColor;
        this.ctx.fillText(text, x, y);
        this.ctx.restore();
    }

    /** Отрисовывает лейбл фазы(время в секундах) */
    private drawPhaseLabel(text: string, x: number, y: number) {
        this.ctx.save();
        this.ctx.textBaseline = "middle";
        this.ctx.textAlign = "center";
        this.ctx.strokeStyle = CycleDrawer.PHASE_LABEL_PARAMS.strokeColor;
        this.ctx.font = CycleDrawer.PHASE_LABEL_PARAMS.font;
        this.ctx.fillStyle = CycleDrawer.PHASE_LABEL_PARAMS.fillColor;
        this.ctx.lineWidth = 2;
        this.ctx.strokeText(text, x, y);
        this.ctx.fillText(text, x, y);
        this.ctx.restore();
    }

    /** Рисует временную сетку(по сути столбики) для каждой фазы/направления */
    private drawTimeGrid(startX: number, y: number, width: number) {
        this.ctx.lineWidth = CycleDrawer.SCALE_LINE_WIDTH;
        this.ctx.strokeStyle = "#fff";
        this.ctx.beginPath();
        for (let x = startX; x < startX + width; x += this.pixelsPerSecond) {
            this.ctx.moveTo(x, y);
            this.ctx.lineTo(x, y + this.barHeight);
        }
        this.ctx.stroke();
    }

    /**
     * Инициализация иконок для направлений
     * TODO: было бы неплохо сделать флаг, сведетельствующий об ининциализации иконок
     * после изменения которого можно было бы перезапускать процесс рендеринга
     */
    private initIcons() {
        this.originPhasePointerIcon.load();
        this.dragHandlerIcon.load();
        this.directionIcons = Object.entries(DirectionTypeIconURLMap).reduce((map, [type, url]) => {
            const svg = new CanvasSVG(url);
            svg.load();
            const code: Enums.DirectionTypeCode = Number(type);
            map[code] = svg;
            return map;
        }, {} as DirectionIconsMap);
    }

    private get width() {
        return this.ctx.canvas.offsetWidth;
    }

    private drawBlinks(options: { y: number; x: number; direction: Direction; phase: PhaseData }) {
        const { direction, phase, y } = options;
        const halfSecondWidth = this.pixelsPerSecond / 2;
        const halfBarHeight = this.barHeight / 2;

        if (phase.directionByNum[direction.number]) {
            // Отрисовка бликов во время зеленого света
            let x = options.x;

            if (direction.tRed) {
                this.ctx.fillStyle = timelineColors.colorRed;
                x -= (direction.tRed + direction.tDelay) * this.pixelsPerSecond;
                const width = this.pixelsPerSecond * direction.tRed;
                this.ctx.fillRect(x, y, width, this.barHeight);
            }

            if (direction.tYellow) {
                this.ctx.fillStyle = timelineColors.colorYellow;

                x -= direction.tYellow * this.pixelsPerSecond;

                const yellowWidth = this.pixelsPerSecond * direction.tYellow;
                this.ctx.fillRect(x, y, yellowWidth, this.barHeight);
            }

            if (direction.tGreenBlink) {
                this.ctx.fillStyle = timelineColors.greenBlink;
                for (let i = 1; i <= direction.tGreenBlink; i++) {
                    x -= this.pixelsPerSecond;
                    this.ctx.fillRect(x + halfSecondWidth, y, halfSecondWidth, this.barHeight);
                }
            }
        } else {
            // Отрисовка бликов во время красного света
            if (direction.tRedYellow) {
                this.ctx.fillStyle = timelineColors.colorYellow;
                for (let i = 1; i <= direction.tRedYellow; i++) {
                    const x = options.x - i * this.pixelsPerSecond + halfSecondWidth;
                    this.ctx.fillRect(x, y + halfBarHeight, halfSecondWidth, halfBarHeight);
                }
            }
        }
    }
}
