import { CanvasSVG } from "../canvas-svg";
import { CanvasObject, CanvasObjectProps } from "./canvas-object";
import { RectBBox } from "./bounds";

export type SpriteProps = CanvasObjectProps & {
    url: string;
    autoLoad?: boolean;
    onLoad?: VoidFunction;
};

export class Sprite extends CanvasObject {
    public isReady = false;
    private svg: CanvasSVG;

    constructor(props: SpriteProps) {
        super(props);
        this.svg = new CanvasSVG(props.url);
        if (props.autoLoad) {
            this.load()
                .then(this.onLoad)
                .catch(() => {});
        }
    }

    public getBounds() {
        return new RectBBox(this.x, this.y, this.width, this.height);
    }

    public get width() {
        return this.svg.img.width;
    }

    public get height() {
        return this.svg.img.height;
    }

    public async load() {
        try {
            await this.svg.load();
        } catch (e) {}
        this.onLoad();
    }

    protected _render(ctx: CanvasRenderingContext2D) {
        if (!this.isReady) return;
        ctx.drawImage(this.svg.img, this.x, this.y);
    }

    private onLoad = () => {
        this.isReady = true;
    };
}
