import { memo, ReactNode } from "react";
import * as Markup from "./footer-markup.styles";

type Props = {
    content: ReactNode;
    controls: ReactNode;
};

const FooterMarkupComponent = (props: Props) => {
    return (
        <Markup.Container>
            {props.content}
            <Markup.Controls>{props.controls}</Markup.Controls>
        </Markup.Container>
    );
};

export const FooterMarkup = memo(FooterMarkupComponent);
