import { memo, ReactNode } from "react";
import { Tooltip } from "@megapolis/uikit/tooltip";
import { Button } from "@megapolis/uikit/button";
import * as Markup from "./map-control-button.styles";

// Не знаю, тут с типами быть
type Props = Pick<React.ComponentProps<typeof Button>, "isLoading" | "isDisabled" | "onClick"> & {
    "icon": ReactNode;
    "title"?: string;
    "tooltipPlace"?: typeof Tooltip;
    "isActive"?: boolean;
    "data-cypress"?: string;
};

const MapControlButtonComponent = (props: Props) => {
    const { icon, title, isActive = false, tooltipPlace, ...restButtonProps } = props;

    return (
        // Не знаю, нужны ли теперь isEnabled и place
        // <Tooltip content={title} isEnabled={Boolean(title)} place={tooltipPlace}>
        <Tooltip content={title}>
            <Markup.ButtonWrapper isActive={isActive} data-cypress={props["data-cypress"]}>
                <Button content={Button.Content.Icon} priority="quaternary" icon={icon} {...restButtonProps} />
            </Markup.ButtonWrapper>
        </Tooltip>
    );
};

export const MapControlButton = memo(MapControlButtonComponent);
