export const omitKeys = <T extends object, K extends (keyof T)[]>(
    obj: T,
    ...keys: K
): Pick<T, Exclude<keyof T, K[number]>> => {
    const result = { ...obj };
    for (const key of keys) {
        if (key in result) {
            delete result[key as keyof T];
        }
    }
    return result;
};
