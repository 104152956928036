/** Метрики по полосе направления */
export interface DirectionLaneMetrics {
    total: number;
    car: number;
    bus: number;
    truck: number;
    totalWaiting: number;
    carWaiting: number;
    busWaiting: number;
    truckWaiting: number;
}

/** Полоса направления */
export class DirectionLane {
    readonly num: string;
    public isBusy?: boolean;
    private _metrics?: DirectionLaneMetrics;

    constructor(num: string, public readonly directionNumber: number) {
        this.num = num;
    }

    get metrics() {
        return this._metrics;
    }

    setMetrics(metrics: DirectionLaneMetrics) {
        if (
            !this._metrics ||
            this._metrics.total !== metrics.total ||
            this._metrics.totalWaiting !== metrics.totalWaiting ||
            this._metrics.car !== metrics.car ||
            this._metrics.bus !== metrics.bus ||
            this._metrics.truck !== metrics.truck
        ) {
            this._metrics = metrics;
        }
    }
}
