import styled, { css } from "styled-components/macro";

type Props = {
    hideOverflow?: boolean;
};

const hiddenOverflowCss = css`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const NoWrapText = styled.span<Props>((props) => {
    const { hideOverflow = true } = props;

    return css`
        display: block;
        white-space: nowrap;
        ${hideOverflow && hiddenOverflowCss};
    `;
});
