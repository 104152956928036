import { TrafficLightDomain } from "app-domain";
import styled from "styled-components/macro";
import { CycleSettings } from "./cycle-settings";
import { SpeedSettings } from "./speed-settings";
import { Cycle, SpeedKind } from "../coo-group-cycle-editor.types";

export interface Props {
    cycles: Cycle[];
    onCycleShiftChange?: (id: number, shift: number) => void;
    onCycleTimeChange?: (id: number, time: number) => void;
    onCycleChange?: (cycle: TrafficLightDomain.CustomCycle) => void;
    onCycleSpeedChange?: (id: number, kind: SpeedKind, value: number) => void;
    onApplyCycleSpeed?: (kind: SpeedKind, id: number) => void;
    onFreeRoadSpeed?: (id: number) => void;
}

const Block = styled.div<{ top: number; height: number }>`
    top: ${(props) => props.top}px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: ${(props) => props.height}px;
`;

export const ControlBlocks = (props: Props) => (
    <>
        {[...props.cycles].map((cycle, index) => {
            return (
                <Block key={`cycle-${cycle.id}-${index}`} top={cycle.settingsTop} height={cycle.settingsHeight}>
                    {index !== 0 && (
                        <SpeedSettings
                            cycleId={cycle.id}
                            facilityId={cycle.facilityId}
                            directSpeed={cycle.directSpeed}
                            reverseSpeed={cycle.reverseSpeed}
                            areDirectSpeedsApplied={cycle.areDirectSpeedsInSync}
                            areReverseSpeedsApplied={cycle.areReverseSpeedsInSync}
                            distance={cycle.segmentDistance}
                            onChange={props.onCycleSpeedChange}
                            onApplyCycleSpeed={props.onApplyCycleSpeed}
                            onFreeRoadSpeed={props.onFreeRoadSpeed}
                        />
                    )}
                    <CycleSettings
                        id={cycle.id}
                        shift={cycle.shift}
                        trafficLight={cycle.trafficLight}
                        cycleMinDurationTime={cycle.cycleMinDurationTime}
                        cycleDurationTime={cycle.cycleDurationTime}
                        onCycleShiftChange={props.onCycleShiftChange}
                        onCycleTimeChange={props.onCycleTimeChange}
                        onCycleChange={props.onCycleChange}
                    />
                </Block>
            );
        })}
    </>
);
