import { memo } from "react";
import { TrafficLightDomain } from "app-domain";
import { TagLabel } from "../../tag-label";

type Props = {
    phaseNumber: number;
};

const PhaseLabelComponent = (props: Props) => {
    const color = TrafficLightDomain.Utils.getPhaseColor(props.phaseNumber);
    const title = `Ф${props.phaseNumber}`;
    return <TagLabel color={color}>{title}</TagLabel>;
};

export const PhaseLabel = memo(PhaseLabelComponent);
