import styled from "styled-components";
import { Placeholder as PlaceholderComponent } from "components";

export const Container = styled.div`
    margin-left: -${({ theme }) => theme.spacings[3].str};
`;

export const Placeholder = styled(PlaceholderComponent)`
    width: 165px;
    height: 32px;
    border-radius: ${({ theme }) => theme.spacings[2].str};
`;
