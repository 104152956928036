import { TypeDictionaries } from "../constants";
import { Type } from "../enums/type";

type GetTypeProps = {
    isActive: boolean;
    isGreenLinePhases: boolean;
    isAdaptiveEnabled: boolean;
};

export const getType = (props: GetTypeProps) => {
    if (props.isActive) return TypeDictionaries[Type.Active];
    if (props.isGreenLinePhases) return TypeDictionaries[Type.GreenStreet];
    if (props.isAdaptiveEnabled) return TypeDictionaries[Type.AdaptiveCoordination];
    return TypeDictionaries[Type.Coordination];
};
