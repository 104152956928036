import { memo, useCallback } from "react";
import { Container } from "./chip-tabs.styles";
import { UID, ChipTabsProps } from "./chip-tabs.types";
import { ChipTab } from "./chip-tab";

export const ChipTabs = memo((props: ChipTabsProps) => {
    const { selected, onChange } = props;

    const handleClick = useCallback(
        (uid: UID) => {
            onChange?.(uid);
        },
        [onChange]
    );

    const content = props.items.map((tab) => (
        <ChipTab
            key={tab.uid}
            uid={tab.uid}
            label={tab.label}
            caption={tab.caption}
            counter={tab.counter}
            onClick={handleClick}
            isActive={tab.uid === selected}
            isDisabled={tab.isDisabled}
        />
    ));

    return <Container>{content}</Container>;
});

ChipTabs.displayName = "ChipTabs";
