import styled from "styled-components/macro";

export const Overlay = styled.div`
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
