import formatDate from "date-fns/format";
import { ru } from "date-fns/locale";
import { Locale } from "date-fns";
import intervalToDuration from "date-fns/intervalToDuration";

export enum Formats {
    Date = "dd.MM.yyyy",
    Time = "HH:mm",
    FullTime = "HH:mm:ss",
    DateTime = "dd.MM.yyyy HH:mm",
    DayOfWeek = "iiiiii",
    DayMonth = "d MMM",
    FullDateTime = "dd.MM.yyyy HH:mm:ss",
    FullDayOfWeek = "eeee",
    FullDayOfMonth = "dd MMMM",
    ExpandedFullDate = "eeee, dd MMMM",
    ExpandedFullDateTime = "eeee dd MMMM, HH:mm",
    ISO = "yyyy-MM-dd'T'HH:mm:ss",
}

export const DEFAULT_LOCALE = "ru";

type LocaleKey = "ru";

const LocaleMap = {
    ru,
} as {
    [key in LocaleKey]: Locale;
};

type FormatParams = {
    format?: string | Formats;
    locale?: LocaleKey;
};

const DEFAULT_PARAMS: FormatParams = {
    format: Formats.Date,
    locale: "ru",
};

export const format = (date?: Date | number | null, params = DEFAULT_PARAMS) => {
    if (!date) return "";
    return formatDate(date, params.format || Formats.Date, {
        locale: LocaleMap[params.locale || DEFAULT_LOCALE],
    });
};

export const calculateDuration = (start: Date, end: Date) => intervalToDuration({ start, end });

/**
 * Форматирует дату в ISO 8601 строчку БЕЗ преобразования времени и с явной установкой таймзоны
 * @example Tue Jan 31 2023 16:05:12 GMT+0300 станет 2023-01-31T16:05:12.023+03:00
 */
export const formatToISOTzString = (date: Date) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezoneHours = String(Math.floor(Math.abs(timezoneOffset / 60))).padStart(2, "0");
    const timezoneMinutes = String(timezoneOffset % 60).padStart(2, "0");
    const sign = timezoneOffset < 0 ? "+" : "-";
    return new Date(date.getTime() - timezoneOffset * 60000)
        .toISOString()
        .replace(/Z$/, `${sign}${timezoneHours}:${timezoneMinutes}`);
};
