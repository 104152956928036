import { RangeUtils, Polygon } from "diagrams/utils";
import { PolygonParams } from "./cycle-polygon-factory.types";
import { CycleView } from "../cycle-view";
import { PhaseView } from "../phase-view";
import { drawParams } from "../editor.constants";

export class CyclePolygonFactory {
    private phase: PhaseView;
    private nextPhase: PhaseView;
    private nextTimeByDistance: number;
    private prevTimeByDistance: number;
    private pixelsPerSecond: number;
    private width: number;
    private nextCycle?: CycleView;
    private prevCycle?: CycleView;

    constructor(private params: PolygonParams) {
        this.phase = params.phase;
        this.nextPhase = params.nextPhase;
        this.nextTimeByDistance = params.nextTimeByDistance;
        this.prevTimeByDistance = params.prevTimeByDistance;
        this.pixelsPerSecond = params.pixelsPerSecond;
        this.nextCycle = params.nextCycle;
        this.prevCycle = params.prevCycle;
        this.width = params.width;
    }

    private get tProm() {
        const isSameType = this.phase.allow === this.nextPhase.allow;
        const fullTProm = this.phase.tPhase - this.phase.tBasic;
        const tGreenBlink = this.phase.direction?.tGreenBlink ?? 0;
        return isSameType ? 0 : fullTProm - tGreenBlink;
    }

    public build(): Polygon[] {
        if (!this.phase.direction) return [];
        const result: Polygon[] = [];
        const directionY = this.params.currentCycle.y;
        const directionBottomY = directionY + drawParams.directionBarHeight;
        const backwardDirectionY = directionBottomY + drawParams.backwardDirectionGap;

        if (this.nextCycle) {
            const { topX1, topX2, bottomX1, bottomX2 } = this.getForwardXCoords();
            const nextDirectionY = this.nextCycle.y;
            this.nextCycle.greenWaveRange.forEach((item) => {
                if (!item.allow) return;
                const range = RangeUtils.getRangesIntersection([[bottomX1, bottomX2], item.range]);
                if (!range) return;
                result.push(
                    new Polygon(
                        [
                            {
                                x: (topX1 - (topX1 + this.nextTimeByDistance - range[0])) * this.pixelsPerSecond,
                                y: directionBottomY,
                            },
                            {
                                x: (topX2 - (topX2 + this.nextTimeByDistance - range[1])) * this.pixelsPerSecond,
                                y: directionBottomY,
                            },
                            {
                                x: range[1] * this.pixelsPerSecond,
                                y: nextDirectionY,
                            },
                            {
                                x: range[0] * this.pixelsPerSecond,
                                y: nextDirectionY,
                            },
                        ],
                        "rgba(138, 219, 74, 0.5)"
                    )
                );
            });
        }
        if (this.prevCycle && this.params.hasBackwardDirection) {
            const { topX1, topX2, bottomX1, bottomX2 } = this.getBackwardXCoords();
            const prevDirectionY = this.prevCycle.y;
            const prevDirectionBottomY = prevDirectionY + drawParams.directionBarHeight;
            const prevDirectionBackwardBottomY =
                prevDirectionBottomY + drawParams.backwardDirectionGap + drawParams.directionBarHeight;

            this.prevCycle.greenWaveRange.forEach((item) => {
                if (!item.allow) return;

                const range = RangeUtils.getRangesIntersection([[topX1, topX2], item.range]);
                if (!range) return;

                result.push(
                    new Polygon(
                        [
                            {
                                x: range[0] * this.pixelsPerSecond,
                                y: prevDirectionBackwardBottomY,
                            },
                            {
                                x: range[1] * this.pixelsPerSecond,
                                y: prevDirectionBackwardBottomY,
                            },
                            {
                                x: (bottomX2 + range[1] - bottomX2 - this.prevTimeByDistance) * this.pixelsPerSecond,
                                y: backwardDirectionY,
                            },
                            {
                                x:
                                    (bottomX1 +
                                        range[0] -
                                        this.phase.drawOptions.phaseShiftFromStart -
                                        this.prevTimeByDistance) *
                                    this.pixelsPerSecond,
                                y: backwardDirectionY,
                            },
                        ],
                        "rgba(53, 105, 240, 0.3)"
                    )
                );
            });
        }
        return result;
    }

    private getForwardXCoords = () => {
        const topX1 = this.phase.drawOptions.phaseShiftFromStart;
        const topX2 = topX1 + this.phase.tPhase;
        const bottomX1 = topX1 + this.nextTimeByDistance;
        const bottomX2 = topX2 + this.nextTimeByDistance - this.tProm;
        return { topX1, topX2, bottomX1, bottomX2 };
    };

    private getBackwardXCoords = () => {
        const topX1 = this.phase.drawOptions.phaseShiftFromStart + this.prevTimeByDistance;
        const topX2 = topX1 + this.phase.tPhase - this.tProm;
        const bottomX1 = this.phase.drawOptions.phaseShiftFromStart;
        const bottomX2 = bottomX1 + this.phase.tPhase;
        return { topX1, topX2, bottomX1, bottomX2 };
    };
}
