import styled, { css } from "styled-components/macro";

const cellCss = css`
    border: 1px solid transparent;
    padding: ${({ theme: { spacings } }) => spacings[3].value - 1}px;
    &:not(:first-child),
    &:not(:last-child) {
        border-right-width: 0;
        border-left-width: 0;
    }
`;

export const Table = styled.table`
    border-spacing: 0;
`;

export const HeaderCell = styled.th((props) => {
    const {
        theme: { typography, colors },
    } = props;
    return css`
        ${cellCss};
        font: ${typography.h6};
        letter-spacing: ${typography.h6};
        color: ${colors.content.secondary};
        border-bottom-color: ${({ theme }) => theme.colors.content.quinary};
    `;
});

export const Cell = styled.td((props) => {
    const {
        theme: { typography, colors },
    } = props;
    return css`
        ${cellCss};
        font: ${typography.text1};
        font-weight: 400;
        letter-spacing: ${typography.text1};
        color: ${colors.content.primary};
    `;
});

export const HeaderRow = styled.tr``;

export const Row = styled.tr((props) => {
    const {
        theme: { colors, spacings },
    } = props;
    return css`
        :hover {
            background-color: ${colors.containerBackground.tertiary.hover};
        }
        :active {
            background-color: ${colors.containerBackground.tertiary.active};
        }

        ${Cell} {
            padding: ${spacings[5].value - 1}px ${spacings[3].str};
            border-bottom-color: ${colors.content.quinary};
        }

        :last-child {
            ${Cell} {
                border-bottom-color: transparent;
            }
        }
    `;
});
