import { ForwardedRef, useEffect, useRef } from "react";

export const useForwardRef = <T>(ref?: ForwardedRef<T>) => {
    const targetRef = useRef<T>(null);

    useEffect(() => {
        if (!ref) return;

        if (typeof ref === "function") {
            return ref(targetRef.current);
        }
        ref.current = targetRef.current;
    }, [ref]);

    return targetRef;
};
