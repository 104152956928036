import { Drawer } from "diagrams/drawers";
import { Data, Line } from "./line-drawer.types";

export class LineDrawer extends Drawer<Data> {
    protected draw() {
        if (!this.data) return;

        this.ctx.beginPath();

        if (Array.isArray(this.data)) {
            this.data.forEach(this.drawLine);
        } else {
            this.drawLine(this.data);
        }
    }

    private drawLine = (data: Line) => {
        if (data.fillColor) {
            this.ctx.fillStyle = data.fillColor;
        }
        this.ctx.fillRect(data.x, data.y, data.width, data.height);
    };
}
