import { APITypes } from "api";
import { TrafficLightDomain } from "app-domain";

export class TrafficLightHistoryEventMapper {
    public fromAPI(snapshot: APITypes.FacilitySnapshot): TrafficLightDomain.HistoryEvent {
        const { timestamp, cTimestamp, malfunctionTimestamp, ...rest } = snapshot;
        return {
            ...rest,
            timestamp: new Date(snapshot.timestamp),
            cTimestamp: new Date(snapshot.cTimestamp),
            malfunctionTimestamp: snapshot.malfunctionTimestamp ? new Date(snapshot.malfunctionTimestamp) : new Date(),
            malfunctions:
                snapshot.malfunctions?.map((malfunction) => ({
                    code: malfunction.type,
                    name:
                        TrafficLightDomain.Constants.malfunctionCodeDictionaryMap[
                            malfunction.type as TrafficLightDomain.Enums.MalfunctionType
                        ].name ?? "",
                    description: malfunction.description,
                    isCritical: malfunction.isCritical,
                })) ?? [],
        };
    }
}
