import styled, { css } from "styled-components";
import { ControlButton } from "../../../components";

type Props = {
    isWeekend?: boolean;
    isSameMonthDay?: boolean;
    isActive?: boolean;
    isToday?: boolean;
    isDisabled?: boolean;
};

const otherMonthDayCss = css`
    color: ${(props) => props.theme.colors.content.secondary};
`;

const weekendCss = css`
    color: red;
`;

const activeCss = css`
    &,
    &:hover {
        background-color: ${(props) => props.theme.colors.containerBackground.info.default};
        color: ${(props) => props.theme.colors.content.inverse};
    }
`;

const disabledCss = css<Props>`
    text-decoration: line-through;
    color: ${(props) => props.theme.colors.content.tertiary};
    text-decoration-color: ${({ theme, isToday }) =>
        isToday ? theme.colors.semantic.info.default : theme.colors.content.tertiary};
    cursor: default;
`;

const todayCss = css`
    color: ${(props) => props.theme.colors.semantic.info.default};
`;

export const Day = styled(ControlButton)<Props>(
    (props) =>
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            font: ${props.theme.typography.text1};
            height: 32px;
            border-radius: ${props.theme.spacings[2].str};
            &:hover {
                font-weight: inherit;
                background-color: ${props.theme.colors.containerBackground.tertiary.hover};
            }
            ${!props.isSameMonthDay && otherMonthDayCss};
            ${props.isWeekend && weekendCss};
            ${props.isActive && activeCss};
            ${props.isToday && todayCss};
            ${props.isDisabled && disabledCss};
        `
);
