import { State, Manage, APIRegistry } from "api";
import { TrafficLightDomain } from "app-domain";
import { Factories } from "shared";
import { ITrafficLightRemoteControlStoreService } from "./traffic-light-remote-controls.types";

export class TrafficLightRemoteControlsAdapter implements ITrafficLightRemoteControlStoreService {
    private stateAPI: State.StateAPI = APIRegistry.stateAPI;
    private manageAPI: Manage.ManageAPI = APIRegistry.manageAPI;
    private factory = new Factories.TrafficLightGovernanceFactory();

    public async getCurrentUserControls(): Promise<TrafficLightDomain.Governance[]> {
        const result = await this.stateAPI.getCurrentUserStateGovernance();
        return result.map(this.factory.create);
    }

    public async startRemoteControl(trafficLightId: number): Promise<TrafficLightDomain.Governance> {
        const result = await this.manageAPI.createFacilityGovernance(trafficLightId);
        return this.factory.create(result);
    }

    public async getRemoteControlData(governanceId: number): Promise<TrafficLightDomain.Governance | null> {
        const result = await this.stateAPI.getFacilityGovernanceById(governanceId);
        if (!result) return null;
        return this.factory.create(result);
    }

    public stopRemoteControl(trafficLightId: number): Promise<void> {
        return this.manageAPI.deleteFacilityGovernance(trafficLightId);
    }

    public async getPlannedControls(): Promise<TrafficLightDomain.Governance[]> {
        const responce = await this.stateAPI.getPlannedControls();
        return responce.map(this.factory.create);
    }
}
