import { CustomCycle } from "app-domain/traffic-light";
import { Behavior } from "./behavior";
import { SpeedKind, CooGroupCycle, CooGroupCycleEditorParams } from "./editor.types";
import { Renderer } from "./renderer";
import { Context } from "./context";
import { InteractionManager } from "./interaction-manager";

export class Editor {
    private behavior: Behavior;
    private renderer: Renderer;
    private context: Context;
    private interactionManager: InteractionManager;

    constructor(private params: CooGroupCycleEditorParams) {
        this.context = params.context ?? new Context(params.cooGroupCycle);
        this.context.hasBackwardDirection = params.hasBackwardDirection;
        this.behavior = new Behavior({
            onChange: params.onChange,
            context: this.context,
            width: params.canvas.offsetWidth,
        });
        this.renderer = new Renderer(params.canvas, this.context);
        this.interactionManager = new InteractionManager(this.context, this.behavior, params.canvas);
    }

    public readonly reset = (cooGroupCycle: CooGroupCycle) => {
        this.context.reset(cooGroupCycle);
        this.behavior.setTotalTimeFromCycles();
        this.behavior.updateDrawData();
        this.behavior.onChange();
    };

    public getEditableCycles = () => {
        return this.context.editableCooGroupCycle.cycles;
    };

    public getControlsSettings = () => {
        return this.context.controlsSettings;
    };

    public destroy() {
        this.renderer.destroy();
        this.interactionManager.unsubscribe();
    }

    public setEditMode(value: boolean): void {
        if (value === this.context.isEditMode) return;

        this.context.isEditMode = value;

        if (value) {
            this.interactionManager.subscribe();
        } else {
            this.interactionManager.unsubscribe();
        }
    }

    public setFreeRoadSpeed(value: any): void {
        this.context.freeRoadSpeed = value;
    }

    public changeCycleShift = (id: number, shift: number) => {
        this.behavior.setCycleShift(id, shift);
    };

    public readonly changeCycleTime = (id: number, time: number) => {
        this.behavior.setCycleTime(id, time);
    };

    public readonly changeCycleSpeed = (id: number, speedKind: SpeedKind, value: number) => {
        this.behavior.setCycleSpeed(id, speedKind, value);
    };

    public readonly toggleIsSpeedInSync = (speedKind: SpeedKind, cycleId?: number) => {
        this.behavior.toggleIsSpeedInSync(speedKind, cycleId);
    };

    public readonly applyFreeRoadSpeed = (cycleId: number) => {
        const cycle = this.context.cycles.find((item) => item.id === cycleId);
        const speedData = this.context.freeRoadSpeed?.edgePairs.find((item) => item.facilityId === cycle?.facilityId);
        if (!speedData) return;
        this.behavior.setCycleSpeed(cycleId, SpeedKind.direct, speedData.freeFlowSpeed);
        this.behavior.setCycleSpeed(cycleId, SpeedKind.reverse, speedData.reverseFreeFlowSpeed);
    };

    public changeCycle = (cycle: CustomCycle) => {
        this.behavior.updateCycle(cycle);
    };

    public updateCanvasSizes() {
        this.handleResize();
        this.renderer.handleResize();
    }

    private handleResize = () => {
        this.behavior.updateRenderingData(this.params.canvas.offsetWidth);
        this.behavior.updateDrawData();
    };
}
