import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";
import type { Directions } from "@megapolis/speed-detector-visualization";
import { Interfaces } from "interfaces";
import { Detector } from "app-domain";
import { equipmentStatus } from "shared/constants";
import { BooleanStorageManager } from "lib";
import { DetectorFullCardService } from "../detector-service";

export class DetectorFullCardStore implements Detector.Models.FullCard.Instance {
    public readonly emitter = new Interfaces.Warehouse.Emitter();
    public readonly signals = new Detector.Models.Signals();

    private detectorService: DetectorFullCardService;

    public guid = v4();

    /** Ширина канваса */
    private width: number = 0;

    /** Идентификатор детектора */
    public uid: Detector.Models.FullCard.Uid = null;

    /** Параметры */
    public options: Detector.Models.FullCard.Options;

    /** Визуализация фиксаций */
    public directions: Directions = [];

    /** Показатель */
    private booleanStorageManager = new BooleanStorageManager(
        `megapolis__asudd__detector-full-card__indicators_is-expaned`
    );

    public meta?: Detector.Models.FullCard.Meta;

    public isLoaded: boolean = false;

    /**
     * Исходные данные
     *
     * @description нужны для восстановления в всплывающее окно
     */
    public source: SourceEquipmentCardArgs = {
        options: {
            uid: -1,
            coordinate: { lat: 0, lng: 0 },
        },
        defaultPosition: {
            pageX: 0,
            pageY: 0,
        },
    };

    constructor(detectorService: DetectorFullCardService) {
        this.detectorService = detectorService;

        const { meta, options } = this.detectorService.getEmptyAsset();
        this.meta = meta;
        this.options = options;

        makeAutoObservable(this);

        // makeObservable(this, {
        //     uid: observable,
        //     guid: observable,
        //     isLoaded: observable,
        //     meta: observable,
        //     options: observable,
        //     directions: observable,
        //     indicators: observable,
        //     load: action,
        // });
    }

    subscribeSignalOnEvent = () => {
        this.emitter.on(this.emitter.events.Adding, () => this.signals.setId(this.uid));
        this.emitter.on(this.emitter.events.Remove, () => this.signals.setId(this.uid));
    };

    public onBackwardClick = () => {
        this.uid = null;
        this.emitter.emit(this.emitter.events.Remove, this.guid);
        this.signals.setId(null);
    };

    public onShowClick = () => {
        this.emitter.emit(this.emitter.events.ShowOnMap, this.source.options.coordinate);
    };

    public onMinimizeScreenClick = () => {
        this.emitter.emit(this.emitter.events.MinimizeCard, this.source);
    };

    public setData = (args: SourceEquipmentCardArgs) => {
        this.source = args;

        this.uid = args.options.uid;
        this.guid = v4();

        this.options.statusIssue.id = args.options.statusOnIssues;
        this.options.statusIssue.name = equipmentStatus.getStatusNameOnIssueId(args.options.statusOnIssues);
        this.options.statusIssue.color = equipmentStatus.getStatusColorOnIssueId(args.options.statusOnIssues);

        this.options.statusMonitoring.id = args.options.statusOnMonitoring;
        this.options.statusMonitoring.name = equipmentStatus.getStatusNameOnMonitoringId(
            args.options.statusOnMonitoring
        );
        this.options.statusMonitoring.color = equipmentStatus.getStatusColorOnMonitoringId(
            args.options.statusOnMonitoring
        );

        this.isLoaded = false;

        this.emitter.emit(this.emitter.events.Adding, this.guid);
    };

    public setDefaultPosition = (position: SourceEquipmentCardArgs["defaultPosition"]) => {
        this.source.defaultPosition = position;
    };

    public load = async () => {
        try {
            const [{ meta, options }, directions] = await Promise.all([
                await this.detectorService.getAssetById(this.uid),
                await this.detectorService.getDirections(this.uid),
            ]);

            this.directions = directions;
            this.meta = meta;
            this.options = options;
        } catch (err) {
            const { meta, options } = this.detectorService.getEmptyAsset();
            this.meta = meta;
            this.options = options;
        } finally {
            this.signals.setId(this.uid);
            this.isLoaded = true;
        }
    };
}
