import { v4 } from "uuid";
import { TypedEmitter } from "tiny-typed-emitter";
import { IBounds } from "./bounds";
import { InteractionEvent } from "./canvas-interaction/interaction-event";

export type CanvasObjectEvents = {
    click: (e: InteractionEvent<CanvasObject>) => void;
    mousemove: (e: InteractionEvent<CanvasObject>) => void;
    mousedown: (e: InteractionEvent<CanvasObject>) => void;
    dragstart: (e: InteractionEvent<CanvasObject>) => void;
    dragmove: (e: InteractionEvent<CanvasObject>) => void;
    dragend: (e: InteractionEvent<CanvasObject>) => void;
    dragover: (e: InteractionEvent<CanvasObject>) => void;
    dragleave: (e: InteractionEvent<CanvasObject>) => void;
    drop: (e: InteractionEvent<CanvasObject>) => void;
};

export type CanvasObjectProps = {
    id?: string | number;
    x?: number;
    y?: number;
    isInteractive?: boolean;
    isVisible?: boolean;
    cursor?: string;
    zIndex?: number;
};

export abstract class CanvasObject extends TypedEmitter<CanvasObjectEvents> {
    public id: string | number;
    public isInteractive: boolean = false;
    public isVisible: boolean = true;
    public isDraggable: boolean = false;
    protected _zIndex: number;
    protected _x: number;
    protected _y: number;
    private _cursor: string;

    constructor(props?: CanvasObjectProps) {
        super();
        this.id = props?.id ?? v4();
        this._x = props?.x ?? 0;
        this._y = props?.y ?? 0;
        this.isInteractive = props?.isInteractive ?? false;
        this.isVisible = props?.isVisible ?? true;
        this._cursor = props?.cursor ?? "default";
        this._zIndex = props?.zIndex ?? 0;
    }

    public get cursor() {
        return this._cursor;
    }

    public set cursor(cursor: string) {
        this._cursor = cursor;
    }

    public get x() {
        return this._x;
    }

    public set x(value: number) {
        this._x = value;
    }

    public get y() {
        return this._y;
    }

    public set y(value: number) {
        this._y = value;
    }

    public get zIndex() {
        return this._zIndex;
    }

    public set zIndex(value: number) {
        this._zIndex = value;
    }

    public getBounds?(): IBounds;

    /** Будет вызван на каждый фрейм, перед вызовом render */
    public update(deltaT: number) {
        this._update?.(deltaT);
    }

    public render(ctx: CanvasRenderingContext2D) {
        if (!this.isVisible) return;
        this._render?.(ctx);
    }

    public destroy() {
        this.removeAllListeners();
    }

    /** Для переопределения в рамках наследуемого объекта */
    protected _render?(ctx: CanvasRenderingContext2D): void;
    /** Для переопределения в рамках наследуемого объекта */
    protected _update?(deltaT: number): void;
}
