import React from "react";
import { Carousel } from "components";
import * as Units from "./units";
import * as Markup from "./multiple.styles";
import type { Props } from "./multiple.types";

export const Multiple = React.memo((props: Props) => {
    const dataCypress = props.dataCypress ? `${props.dataCypress}__player-multiple` : undefined;

    return (
        <Markup.Container className={props.className} data-cypress={dataCypress}>
            <Carousel gap={0}>
                {props.streams.map((stream) => {
                    return <Units.Stream key={stream} uid={props.uid} stream={stream} getToken={props.getToken} />;
                })}
            </Carousel>
        </Markup.Container>
    );
});
