import { CanvasHelper } from "lib/canvas";
import { Context } from "./context";
import { Drawer } from "./drawing";

export class Renderer {
    public drawer: Drawer;
    private ctx: CanvasRenderingContext2D;
    private width: number = 0;
    private height: number = 0;
    private requestAnimationFrame: number = 0;

    constructor(private canvas: HTMLCanvasElement, private context: Context) {
        const ctx = CanvasHelper.setDpi(this.canvas);
        if (!ctx) throw new Error("CanvasRenderingContext2D error");
        this.ctx = ctx;
        this.drawer = new Drawer(this.ctx);
        this.updateRenderingContext();
        this.requestAnimationFrame = requestAnimationFrame(this.render);
    }

    public destroy = (): void => {
        cancelAnimationFrame(this.requestAnimationFrame);
    };

    private clear = (): void => {
        this.ctx.clearRect(0, 0, this.width, this.height);
    };

    private readonly render = (): void => {
        this.clear();
        if (this.context.view.height !== this.height) {
            this.canvas.style.height = `${this.context.view.height}px`;
            this.updateRenderingContext();
        }
        this.drawer.setData(this.context.drawData);
        this.requestAnimationFrame = requestAnimationFrame(this.render);
    };

    public handleResize = (): void => {
        this.updateRenderingContext();
    };

    private updateRenderingContext = (): void => {
        if (!this.canvas.offsetParent) return;
        const width = this.canvas.clientWidth;
        const height = this.canvas.clientHeight;

        if (this.width !== width || this.height !== height) {
            this.width = width;
            this.height = height;
            this.context.view.width = this.width;
            this.context.view.height = this.height;
            const ctx = CanvasHelper.setDpi(this.canvas);
            if (!ctx) throw new Error("CanvasRenderingContext2D error");
            this.ctx = ctx;
        }
    };
}
