export interface ServerInfoData {
    date: DateTimeString | Date;
}

export class ServerInfo {
    /** Дата и время сервера */
    public dateTime: Date;
    /** Клиентское время на момент создания объекта(мс) */
    private localTime: number;
    /** Серверное время на момент создания объекта(мс) */
    private serverTime: number;

    constructor(data: ServerInfoData) {
        this.dateTime = new Date(data.date);
        this.localTime = new Date().getTime();
        this.serverTime = this.dateTime.getTime();
    }

    /** Возвращает текущее время со сдвигом относительно серверного(мс) */
    public get now() {
        const now = new Date().getTime();
        return now - this.localTime + this.serverTime;
    }

    /** Возвращает текущее время со сдвигом относительно серверного(сек) */
    public get nowSec() {
        const now = new Date().getTime();
        return Math.round((now - this.localTime + this.serverTime) / 1000);
    }
}
