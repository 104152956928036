import styled from "styled-components";
import * as Units from "./units";

export const Container = styled.div`
    display: flex;
    width: 404px;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings[3].str};
`;

export const LastScreenshot = styled(Units.LastScreenshot)``;
export const OverSpeed = styled(Units.OverSpeed)``;
export const Details = styled(Units.Details)``;
export const Issues = styled(Units.Issues)``;
