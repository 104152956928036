import { TrafficLightDomain } from "app-domain";
import { DictionaryItem } from ".";

export const trafficLightStatusCodes = Object.values(TrafficLightDomain.Enums.StatusCode);

export interface TrafficLightStatus extends DictionaryItem<TrafficLightDomain.Enums.StatusCode> {}

export const trafficLightStatusList: { [key in TrafficLightDomain.Enums.StatusCode]: TrafficLightStatus } = {
    [TrafficLightDomain.Enums.StatusCode.Disabled]: {
        code: TrafficLightDomain.Enums.StatusCode.Disabled,
        name: "Выключен",
        shortName: "ВЫКЛ",
        color: "#272B2E",
    },
    [TrafficLightDomain.Enums.StatusCode.Cycle]: {
        code: TrafficLightDomain.Enums.StatusCode.Cycle,
        name: "Цикл",
        shortName: "Ц",
        color: "#5BD279",
    },
    [TrafficLightDomain.Enums.StatusCode.YellowBlink]: {
        code: TrafficLightDomain.Enums.StatusCode.YellowBlink,
        name: "Желтое мигание",
        shortName: "ЖМ",
        color: "#FECB00",
    },
    [TrafficLightDomain.Enums.StatusCode.AllRed]: {
        code: TrafficLightDomain.Enums.StatusCode.AllRed,
        name: "Везде красный",
        shortName: "ВК",
        color: "#FF3A2F",
    },
    [TrafficLightDomain.Enums.StatusCode.Hold]: {
        code: TrafficLightDomain.Enums.StatusCode.Hold,
        name: "Удержание фазы",
        shortName: "У",
        color: "#5ACAFD",
    },
    [TrafficLightDomain.Enums.StatusCode.CriticalError]: {
        code: TrafficLightDomain.Enums.StatusCode.CriticalError,
        name: "Критическая неисправность",
        shortName: "КН",
        color: "#FE9400",
    },
    [TrafficLightDomain.Enums.StatusCode.Coordination]: {
        code: TrafficLightDomain.Enums.StatusCode.Coordination,
        name: "Координация",
        shortName: "ЛК",
        color: "rgb(177, 42, 210)",
    },
    [TrafficLightDomain.Enums.StatusCode.LocalAdaptive]: {
        code: TrafficLightDomain.Enums.StatusCode.LocalAdaptive,
        name: "Локальный адаптив",
        shortName: "ЛА",
        color: "#07C7A4",
    },
    [TrafficLightDomain.Enums.StatusCode.Unknown]: {
        code: TrafficLightDomain.Enums.StatusCode.Unknown,
        name: "Нет данных",
        shortName: "НД",
        color: "rgba(3, 40, 76, 0.31)",
    },
};
