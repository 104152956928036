export enum ControlMode {
    /** Отключена */
    Disabled = 0,
    /** Неактивна (не выключена, но на текущий момент расписание не назначено) */
    Inactive = 1,
    /** По-расписанию */
    Scheduled = 2,
    /** Ручной пользователем */
    User = 3,
    /** Сценарное управление */
    Story = 4,
}
