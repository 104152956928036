import { memo, useMemo } from "react";
import { CooGroupDomain, TrafficLightDomain } from "app-domain";
import { Carousel } from "../carousel";
import { GreenStreetCyclePhase } from "./green-street-cycle-phase";

type Props = {
    cycle: CooGroupDomain.Cycle;
    trafficLights: TrafficLightDomain.TrafficLight[];
    facilities: CooGroupDomain.Facility[];
};

export const GreenStreetCycleCarousel = memo((props: Props) => {
    const { cycle, trafficLights, facilities } = props;

    const carouselData = useMemo(() => {
        if (cycle.type !== CooGroupDomain.CycleType.GreenStreet) return null;
        return (
            cycle.greenLinePhases?.map(({ facilityId, phaseNumber }) => {
                const facility = facilities.find((facility) => facility.facilityId === facilityId);
                const trafficLight = trafficLights.find((trafficLight) => trafficLight.id === facility?.facilityId);
                return {
                    trafficLight: trafficLight!,
                    directionNumber: facility?.directionNumber!,
                    phaseNumber,
                };
            }) ?? []
        );
    }, [trafficLights, facilities, cycle]);

    if (!carouselData) return null;

    return (
        <Carousel gap={8}>
            {carouselData.map((data) => {
                const direction = data.trafficLight.getDirectionByNum(data.directionNumber);
                return (
                    <GreenStreetCyclePhase
                        direction={direction}
                        phaseNumber={data.phaseNumber}
                        coords={data.trafficLight.location}
                        trafficLightNum={data.trafficLight.num}
                    />
                );
            })}
        </Carousel>
    );
});
