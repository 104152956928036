import styled from "styled-components";
import { Typography } from "@megapolis/uikit/typography";

export const Container = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: space-between;
`;

export const Caption = styled(Typography.Text3)`
    color: ${({ theme }) => theme.colors.content.secondary};
`;

export const Data = styled(Typography.Text3)`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => `${theme.spacings[1].str}`};
`;

export const Value = styled(Typography.Text2)`
    color: ${({ theme }) => theme.colors.content.primary};
`;

export const Legend = styled.span<{ $color: string }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ $color }) => $color};
`;

export const Placeholder = styled(Value)`
    text-shadow: ${({ theme }) => `0 0 ${theme.spacings[2].str} ${theme.colors.black.basic}`};
    color: transparent;
`;
