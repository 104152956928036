export enum MalfunctionType {
    /** Неисправны часы */
    ClockMalfunction = 1,
    /** Нет сетевого питания */
    NoPower = 2,
    /** Критическая неисправность лампочек */
    LampCritical = 4,
    /** Некритическая неисправность лампочек */
    LampNotCritical = 8,
    /** Неисправность ВПУ */
    RemoteControlMalfunction = 16,
    /** Открыта дверь шкафа */
    DoorOpened = 32,
    /** Критическая неисправность периферии */
    ExternalCritical = 64,
    /** Некритическая неисправность периферии */
    ExternalNotCritical = 128,
    /** Критическая неисправность оборудования */
    HardwareCritical = 256,
    /** Некритическая неисправность оборудования */
    HardwareNotCritical = 512,
    /** Критическая неисправность ПО */
    SoftwareCritical = 1024,
    /** Некритическая неисправность ПО */
    SoftwareNotCritical = 2048,
    /** Проблемы в работе модуля управления контроллерами */
    FacilityControlService = 4096,
}
