import styled, { css } from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
`;

export const Weekday = styled.span(
    ({ theme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        color: ${theme.colors.content.secondary};
        font: ${theme.typography.text1};
    `
);
