import { memo, useCallback } from "react";
import { SelectProps } from "@megapolis/uikit/select";
import { TrafficLightDomain } from "app-domain";
import { Cycle } from "../../coo-group-cycle-editor.types";
import { CycleTimeInput } from "./cycle-time-input";
import * as Markup from "./cycle-settings.styles";

type CycleProps = Pick<Cycle, "id" | "trafficLight" | "shift" | "cycleDurationTime" | "cycleMinDurationTime">;

type Options = SelectProps["options"];

type Props = CycleProps & {
    onCycleShiftChange?: (id: number, shift: number) => void;
    onCycleTimeChange?: (id: number, time: number) => void;
    onCycleChange?: (cycle: TrafficLightDomain.CustomCycle) => void;
};

export const CycleSettings = memo((props: Props) => {
    const {
        id,
        trafficLight,
        shift,
        cycleDurationTime,
        cycleMinDurationTime,
        onCycleChange,
        onCycleShiftChange,
        onCycleTimeChange,
    } = props;

    const options = trafficLight.cycles.reduce<Options>((result, cycle) => {
        if (cycle.source === TrafficLightDomain.CycleSource.Controller) return result;
        result.push({
            caption: cycle.name,
            value: cycle.id,
        });
        return result;
    }, []);

    const handleCycleShiftChange = useCallback(
        (value: number) => onCycleShiftChange?.(id, value),
        [id, onCycleShiftChange]
    );

    const handleCycleChange = useCallback(
        (value: number | string | null) => {
            const cycle = trafficLight.cycles.find((item) => item.id === value);
            if (!cycle) return;
            onCycleChange?.(cycle);
        },
        [trafficLight.cycles, onCycleChange]
    );

    const handleCycleTimeChange = useCallback(
        (value: number) => onCycleTimeChange?.(id, value),
        [id, onCycleTimeChange]
    );

    const validateCycleShift = useCallback((value: number) => value < cycleDurationTime, [cycleDurationTime]);

    const validateCycleTime = useCallback((value: number) => value >= cycleMinDurationTime, [cycleMinDurationTime]);

    return (
        <Markup.Container>
            <Markup.Select selected={id} onChange={handleCycleChange} options={options} />
            <Markup.InputWrapper>
                <Markup.Typography>Т сдвига</Markup.Typography>
                <CycleTimeInput value={shift} onChange={handleCycleShiftChange} validate={validateCycleShift} />
            </Markup.InputWrapper>
            <Markup.InputWrapper>
                <Markup.Typography>Т цикла</Markup.Typography>
                <CycleTimeInput
                    value={cycleDurationTime}
                    onChange={handleCycleTimeChange}
                    validate={validateCycleTime}
                />
            </Markup.InputWrapper>
        </Markup.Container>
    );
});
