import { ForwardedRef, forwardRef, memo, ReactNode } from "react";
import * as Markup from "./stack-header.styles";

export type StackHeaderProps = {
    /** Блок основной строки слева от центрального */
    left?: ReactNode;
    /** Центральный блок основной строки */
    center?: ReactNode;
    /** Правый блок основной строки */
    right?: ReactNode;
    /** Блок вторичной строки */
    secondary?: ReactNode;
    /** Кастомный класс, применяемый к основному контейнеру */
    className?: string;
};

const StackHeaderComponent = (props: StackHeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { left, center, right, secondary, className } = props;
    return (
        <Markup.Container className={className} ref={ref}>
            <Markup.MainRow>
                {left && <Markup.Left>{left}</Markup.Left>}
                <Markup.Center>{center}</Markup.Center>
                {right && <Markup.Right>{right}</Markup.Right>}
            </Markup.MainRow>
            {secondary && <Markup.SecondaryRow>{secondary}</Markup.SecondaryRow>}
        </Markup.Container>
    );
};

const StackHeaderWithRef = forwardRef(StackHeaderComponent);

export const StackHeader = memo(StackHeaderWithRef);
