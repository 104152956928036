import { TrafficScoreDomain } from "app-domain";
import * as env from "env-data";

export const getDataTypeByRange = (from: Date, to: Date) => {
    if (from.getTime() > Date.now() || to.getTime() > Date.now()) return TrafficScoreDomain.DataType.Forecast;
    return TrafficScoreDomain.DataType.Historical;
};

interface GetTileUrlInput {
    from: string;
    to: string;
    dataType: string;
}

export const getTileUrl = (input: GetTileUrlInput) =>
    `${env.REACT_APP_API_SERVICE_DTM_TRAFFIC}/tiles/traffic?from=${input.from}&to=${input.to}&data=${input.dataType}&es=2&es=7&es=14&es=15&es=17&es=3&es=16&es=6&es=100&zoom={z}&x={x}&y={y}`;
