import React, { RefObject, useEffect, useRef, useState } from "react";

const getTranslateXY = (element: HTMLElement) => {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
        translateX: matrix.m41,
        translateY: matrix.m42,
    };
};

export const useDraggableScroll = (props: {
    carouselRef: RefObject<HTMLElement>;
    wrapperWidth: number;
    containerWidth: number;
    isDisabled?: boolean;
}) => {
    const { carouselRef, wrapperWidth, containerWidth, isDisabled } = props;

    React.useEffect(() => {
        if (isDisabled) return;
        const _carouselRef = carouselRef.current;
        let isPressed = false;
        let initPosition: number | undefined;
        let clientX: number | undefined;

        const handleMouseDown = (event: MouseEvent) => {
            if (!_carouselRef) return;
            isPressed = true;
            clientX = event.clientX;
            initPosition = getTranslateXY(_carouselRef).translateX;
        };

        const handleMouseUp = () => {
            isPressed = false;
            initPosition = undefined;
            clientX = undefined;
            _carouselRef?.classList.remove("draggable");
        };

        const handleMouseMove = (event: MouseEvent) => {
            if (!isPressed || typeof clientX !== "number" || typeof initPosition !== "number") return;

            const moveDiff = clientX - event.clientX;
            let position = initPosition - moveDiff;
            if (position > 0) position = 0;
            if (position < wrapperWidth - containerWidth) position = wrapperWidth - containerWidth;
            _carouselRef!.style.transform = `translateX(${position}px)`;
            _carouselRef!.classList.add("draggable");
        };

        _carouselRef?.addEventListener("mousedown", handleMouseDown);
        _carouselRef?.addEventListener("mousemove", handleMouseMove);
        document?.addEventListener("mouseup", handleMouseUp);

        return () => {
            document?.removeEventListener("mouseup", handleMouseUp);
            _carouselRef?.removeEventListener("mousedown", handleMouseDown);
            _carouselRef?.removeEventListener("mousemove", handleMouseMove);
        };
    }, [carouselRef, wrapperWidth, containerWidth, isDisabled]);
};

export const useResize = (ref: RefObject<HTMLDivElement>) => {
    const [resize, setResize] = useState<ResizeObserverEntry>();

    const handleResize = (entries: ResizeObserverEntry[]) => {
        setResize(entries[0]);
    };

    const resizeObserver = useRef<ResizeObserver>(new ResizeObserver(handleResize));

    useEffect(() => {
        if (!ref.current) return;
        const _ref = ref.current;
        const _resizeObserver = resizeObserver.current;

        _resizeObserver.observe(_ref);

        return () => {
            _resizeObserver.disconnect();
        };
    }, [ref]);

    return resize?.contentRect?.width ?? 0;
};
