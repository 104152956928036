import styled, { css } from "styled-components/macro";

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        padding: ${theme.spacings[1].str};
        border-radius: ${theme.borderRadiuses[3].str};
        background-color: ${theme.colors.stackBackground.primary};
        box-shadow: ${theme.shadows?.shadow1};
        max-height: 40px;
    `
);
