import { RangeUtilsType } from "diagrams/utils";
import { CooGroupDomain, TrafficLightDomain } from "app-domain";
import { CycleView } from "./cycle-view";
import { PhaseView } from "./phase-view";
import { Context } from "./context";

export type Coordinate = {
    x: number;
    y: number;
};

type GreenRange = {
    isActive: boolean;
    range: RangeUtilsType.Range;
};

export type Cycle = {
    directSpeed: number;
    reverseSpeed: number;
    distance: number;
    directionNumber: number;
    trafficLight: TrafficLightDomain.TrafficLight;
} & TrafficLightDomain.ICustomCycle;

export type CooGroupCycle = Omit<CooGroupDomain.CustomCycle, "cycles"> & {
    cycles: Cycle[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ChangeData = {
    id: number;
    y: number;
    shift: number;
    cycleDurationTime: number;
    cycleMinDurationTime: number;
    phases: TrafficLightDomain.CyclePhase[];
    trafficLight: TrafficLightDomain.TrafficLight;
};

export type ChangeHandler = (data: CooGroupCycle) => void;

export type CooGroupCycleEditorParams = {
    facilities: CooGroupDomain.CooGroup["facilities"];
    canvas: HTMLCanvasElement;
    cooGroupCycle: CooGroupCycle;
    onChange: ChangeHandler;
    hasBackwardDirection: boolean;
    context?: Context;
};

export type DrawCycleData = Omit<Cycle, "phases"> & {
    phases: PhaseView[];
    visibleCycleRepeatCount: number;
    y: number;
    prevDy: number;
    nextDy: number;
    directionType: TrafficLightDomain.Direction["type"];
    prevTimeByDistance: number;
    nextTimeByDistance: number;
    cycleHeadLabel: string;
    greenRange: GreenRange[];
    /** index кратного цикла попавшего под drag */
    visibleCycleRepeatDragIndex?: number;
};

export interface GreenWaveRange {
    allow: boolean;
    range: RangeUtilsType.Range;
}

export interface PhaseDataParams {
    phase: TrafficLightDomain.CyclePhase;
    directionNumber: number;
    trafficLight: TrafficLightDomain.TrafficLight;
    index: number;
    pixelsPerSecond: number;
    x: number;
}

export interface HitTestTarget {
    type: "cycle" | "phase";
    dragElement: CycleView | [PhaseView, PhaseView];
    repeated: boolean;
    cycleIndex?: number;
}

export enum SpeedKind {
    direct = "direct",
    reverse = "reverse",
}
