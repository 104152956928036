import { DirectionTypeCode } from "../enums";
import { DirectionLane } from "./direction-lane";
import { Detector } from "./detector";
import { ITrack } from "./track";
/**
 * Направление в рамках СО
 */
export interface IDirection {
    /**Номер направления */
    number: number;

    /**Тип напрравления */
    type: DirectionTypeCode;

    /**Признак блокирующего направления */
    isDeadlock: boolean;

    /**Траектории движения в рамках направления */
    tracks: ITrack[];

    concurrentDirections: any[];

    /** Задержка движения (придержание направления в начале фазы). По умолчанию 0 */
    tDelay: number;

    /** Время зеленого мигания. Всегда 3 сек у всех светофоров */

    tGreenBlink: number;

    /** Время желтого. Всегда 3 сек для транспортного, велосипедного (при 3х секционном исполнении) */
    tYellow: number;

    /**
     * Время красного
     * по умолчанию 3 сек для всех направлений, кроме транспортного. Можно только увеличить, меньше установить нельзя
     * по умолчанию 0 сек для транспортного направления, можно увеличить
     * при увеличении значения необходимо автоматически добавлять Тк у всех направлений, с которыми выбранное направление работает в 1 фазе
     */
    tRed: number;

    /** Красно-желтый. По умолчанию 0, допустимы значения 0-2 сек */
    tRedYellow: number;

    /** Запрет зеленого. По умолчанию 0, можно увеличить */
    tGreenDeny: number;
}

/** Направления по пасспорту */
export class Direction implements IDirection {
    public readonly lanes: DirectionLane[] = [];
    public readonly laneByNum: { [key: string]: DirectionLane } = {};
    public number: number;
    public type: DirectionTypeCode;
    public isDeadlock: boolean;
    public tracks: ITrack[];
    public concurrentDirections: any[];
    public tDelay: number;
    public tGreenBlink: number;
    public tYellow: number;
    public tRed: number;
    public tRedYellow: number;
    public tGreenDeny: number;
    public videoDetector?: Detector;

    constructor(info: IDirection) {
        this.number = info.number;
        this.type = info.type;
        this.isDeadlock = info.isDeadlock;
        this.tracks = info.tracks;
        this.concurrentDirections = info.concurrentDirections;
        this.tDelay = info.tDelay;
        this.tGreenBlink = info.tGreenBlink;
        this.tYellow = info.tYellow;
        this.tRed = info.tRed;
        this.tRedYellow = info.tRedYellow;
        this.tGreenDeny = info.tGreenDeny;
    }

    public hasLane(lane: DirectionLane) {
        return !!this.laneByNum[lane.num];
    }

    // public isAllowed() {
    //     if (
    //         (this.trafficLight.status.code === TrafficLightStatusCode.Cycle ||
    //             this.trafficLight.status.code === TrafficLightStatusCode.Hold) &&
    //         this.trafficLight.currentPhase
    //     ) {
    //         const phase = this.trafficLight.phases[this.trafficLight.currentPhase.phase.num];
    //         if (phase) return phase.directionByNum[this.number] !== undefined;
    //     }
    //     return undefined;
    // }
}
