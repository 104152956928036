import mapboxgl from "mapbox-gl";
import * as env from "env-data";
import type { Options } from "./behavior.types";

export class Behavior implements Visualization {
    private readonly map: mapboxgl.Map;
    private readonly options: Options;
    private readonly tileUrl = `${env.REACT_APP_API_SERVICE_WAREHOUSE}/assetspeedcam/layerMVT/{z}/{x}/{y}?include=assetstatus,assetstatusid,lat,lng&filter=deleted eq false`;

    constructor(map: mapboxgl.Map, options: Options) {
        this.map = map;
        this.options = options;
        this.createSource();
        this.createLayer(options.initialLayoutVisibility);
        this.subscribe();
        window.dispatchEvent(new Event("cypress__warehouse__speedcam__layer-controller__initialized"));
    }

    public destroy() {
        this.removeLayer();
        this.removeSource();
        this.unsubscribe();
    }

    private handleStyleLoad = () => {
        this.createSource();
        this.createLayer(this.options.initialLayoutVisibility);
    };

    private subscribe = () => {
        this.map.on("styledata", this.handleStyleLoad);
    };

    private unsubscribe() {
        this.map.off("styledata", this.handleStyleLoad);
    }

    private removeLayer() {
        if (!this.map.getLayer(this.options.id)) return;

        this.map.removeLayer(this.options.id);
    }

    private removeSource() {
        if (!this.map.getSource(this.options.id)) return;

        this.map.removeSource(this.options.id);
    }

    private createSource() {
        if (this.map.getSource(this.options.id)) return;

        this.map.addSource(this.options.id, {
            type: "vector",
            tiles: [this.tileUrl],
        });
    }

    private getZoomLinearExpression = (base: number | undefined = 1): mapboxgl.Expression => {
        return ["interpolate", ["linear"], ["zoom"], 8, base * 0.1, 22, base * 1.5];
    };

    private layout(visibility: mapboxgl.Visibility): mapboxgl.SymbolLayout {
        return {
            "icon-image": `${this.options.iconPrefix}-{AssetStatusId}-{MonitoringStatusId}`,
            "icon-size": this.getZoomLinearExpression(0.5),
            "icon-allow-overlap": true,
            "text-size": 23,
            "icon-offset": [0, 0],
            "icon-pitch-alignment": "map",
            "visibility": visibility,
        };
    }

    private createLayer(visibility: mapboxgl.Visibility) {
        if (this.map.getLayer(this.options.id)) return;

        const config: mapboxgl.SymbolLayer = {
            "id": this.options.id,
            "type": "symbol",
            "source": this.options.id,
            "source-layer": "speedcam",
            "layout": this.layout(visibility),
        };

        this.map.addLayer(config);
    }
}
