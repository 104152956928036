import React from "react";
import { FloatingModalDrag } from "components";
import * as Units from "./units";
import * as hooks from "./header.hooks";
import * as Markup from "./header.styles";
import type { Props } from "./header.types";

export const Header = (props: Props) => {
    const { number } = hooks.useNumber(props);
    const { address } = hooks.useAddress(props);
    const dataCypress = props.dataCypress ? `${props.dataCypress}__header` : undefined;

    return (
        <FloatingModalDrag>
            <Markup.Container className={props.className} data-cypress={dataCypress}>
                <Markup.Icon>
                    <Units.Thumb
                        statusByIssueId={props.statusByIssueId}
                        statusByIssueName={props.statusByIssueName}
                        statusByMonitoringId={props.statusByMonitoringId}
                        statusByMonitoringName={props.statusByMonitoringName}
                    />
                </Markup.Icon>
                <Markup.Title>
                    <Markup.Number>
                        <Markup.NumberCaption>ДТ №</Markup.NumberCaption>
                        {number}
                    </Markup.Number>
                    {address}
                </Markup.Title>
                <Markup.Actions
                    dataCypress={props.dataCypress}
                    onShowClick={props.onShowClick}
                    onFullscreenClick={props.onFullscreenClick}
                    onCloseClick={props.onCloseClick}
                />
            </Markup.Container>
        </FloatingModalDrag>
    );
};
