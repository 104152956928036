import { TrafficLightDomain } from "app-domain";
import { useEmitterSubscription } from "./use-emitter-subscription";

export const useTrafficLightStateUpdate = (
    trafficLight: TrafficLightDomain.TrafficLight | null,
    events: (keyof TrafficLightDomain.TrafficLightEvents)[]
) => {
    useEmitterSubscription<TrafficLightDomain.TrafficLightEvents, TrafficLightDomain.TrafficLight>(
        trafficLight,
        events
    );
};
