import { TrafficLightDomain } from "app-domain";
import { HubConnectionBuilder, IHubProtocol, IStreamSubscriber } from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { TrafficLightSockets } from "api";
import { FeatureCollection, LineString } from "geojson";
import { TypedEmitter } from "tiny-typed-emitter";
import {
    PagedCollection,
    TrafficLightCollection,
    TrafficLightDispatcherProps,
    TrafficLightMessage,
    TrafficLightState,
} from "./models";
import { TrafficLightGroupControlModeCode } from "./models/group-control-mode";
import { TrafficLightGroupStatusCode } from "./models/group-status";
import { TrafficLightErrorStatusCode } from "./models/trafficlight-error-status";
import { RadarDetector, RadarDetectorCollection } from "./radar-detector";
import { TrafficLight, TrafficLightLogFilter } from "./trafficlight";
import { TrafficLightDirectionLane, TrafficLightDirectionLaneMetrix } from "./trafficlight-direction";
import { TrafficLightDispatcherItem } from "./trafficlight-dispatcher-item";
import {
    TrafficLightGroup,
    TrafficLightGroupCollection,
    TrafficLightGroupLocation,
    TrafficLightGroupState,
} from "./trafficlight-group";

const radarDetectorPresenceStreamName = "DetectorPresence";
const radarDetectorFixationStreamName = "DetectorEvent";

function messageFromLog(item: any): TrafficLightMessage {
    return {
        id: item.id,
        dateTime: new Date(item.timestamp).getTime() / 1000,
        controllerDateTime: item.data?.cTimestamp ? new Date(item.data?.cTimestamp).getTime() / 1000 : undefined,
        eventType: item.type,
        data: item.data,
        state: {
            secondsGone: 0,
            malfunctionType: item.malfunctionType,
            program: item.program,
            nextPhase: 0,
            phaseTime: item.phaseTime,
            phase: item.phase,
            prePhase: item.prePhase,
            isPromTime: item.isPromTime,
            governanceId: item.governanceId,
            controlMode: item.controlMode,
            status: item.status,
            preStatus: item.preStatus,
            preControlMode: item.preControlMode,
            isOnline: true,
            isGuidedAdaptiveAllowed: item.isGuidedAdaptiveAllowed,
        },
    };
}

export interface DetectorTriggerMessage {
    detector: number;
    channel: number;
    isBusy: boolean;
}

export interface DetectorStateMessage {
    detector: number;
    channel: number;
    metrix: TrafficLightDirectionLaneMetrix;
}

export interface TrafficLightDispatcherEvents {
    /**Событие изменения коллекций */
    itemsChanged: () => void;

    /**Событие изменения активного элемента */
    activeItemChanged: (args: { item: TrafficLightDispatcherItem | null; state: any }) => void;

    radarDetectorLaneIsBusyChanged: (args: { lane: number; detectorId: number; isBusy: boolean }) => void;
    trafficLightDirectionLaneIsBusyChanged: (args: { lane: TrafficLightDirectionLane; isBusy: boolean }) => void;
    trafficLightDirectionLaneMetrixChanged: (args: {
        lane: TrafficLightDirectionLane;
        metrix: TrafficLightDirectionLaneMetrix;
    }) => void;
    trafficLightGroupControlModeChanged: (args: {
        group: TrafficLightGroup;
        controlMode: TrafficLightGroupControlModeCode;
    }) => void;
    trafficLightGroupStatusChanged: (args: { group: TrafficLightGroup; status: TrafficLightGroupStatusCode }) => void;

    trafficLightControlModeChanged: (args: {
        trafficLight: TrafficLight;
        controlMode: TrafficLightDomain.ControlModeCode;
    }) => void;
    trafficLightStatusChanged: (args: {
        trafficLight: TrafficLight;
        status: TrafficLightDomain.Enums.StatusCode;
    }) => void;
    trafficLightErrorStatusChanged: (args: {
        trafficLight: TrafficLight;
        errorStatus: TrafficLightErrorStatusCode;
    }) => void;

    radarDetectorFixation: (args: {
        detectorId: number;
        received: Date;
        dateTime: Date;
        lane: number;
        vehicleClass: number;
        speed: number;
        vehicleLength: number;
    }) => void;
}

export class TrafficLightDispatcher extends TypedEmitter<TrafficLightDispatcherEvents> {
    private _trafficLights: TrafficLight[] = [];
    private _trafficLightById: TrafficLightCollection = {};

    private _groups: TrafficLightGroup[] = [];
    private _groupById: TrafficLightGroupCollection = {};

    private _radarDetectors: RadarDetector[] = [];
    private _radarDetectorById: RadarDetectorCollection = {};
    private _trafficLightFilter?: (item: TrafficLight) => boolean;
    private _trafficLightGroupFilter?: (item: TrafficLightGroup) => boolean;
    private readonly trafficLightBaseUrl: string;
    private readonly radarDetectorBaseUrl: string;
    private _baseServerTime!: number;
    private _baseLocalTime!: number;
    private _initPromise: Promise<void> | null = null;
    private _isInitialized: boolean = false;
    private _activeItem: TrafficLightDispatcherItem | null = null;
    private _token: string;
    private trafficLightMessageHub: TrafficLightSockets.TrafficLightMessageStreamHub;

    public readonly fetchTrafficLight: (url: string, init?: RequestInit) => Promise<Response>;
    public readonly fetchRadarDetector: (url: string, init?: RequestInit) => Promise<Response>;

    constructor(props: TrafficLightDispatcherProps) {
        super();
        this.setMaxListeners(100);
        this.trafficLightBaseUrl = props.trafficLightbaseUrl;
        this.radarDetectorBaseUrl = props.radarDetectorBaseUrl;
        this._trafficLightFilter = props.filter;
        this._token = props.token;
        this.trafficLightMessageHub = props.trafficLightMessageHub;

        const fetchWithAuth = (url: string, init?: RequestInit) => {
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this._token}`,
                ...init?.headers,
            };
            const opt: RequestInit = { ...{ headers, credentials: "same-origin" }, ...init };
            return fetch(url, opt);
        };

        this.fetchTrafficLight = (url, init) => fetchWithAuth(`${props.trafficLightbaseUrl}${url}`, init);
        this.fetchRadarDetector = (url, init) => fetchWithAuth(`${props.radarDetectorBaseUrl}/${url}`, init);

        this.init();
    }

    get trafficLightFilter() {
        return this._trafficLightFilter;
    }

    get trafficLightGroupFilter() {
        return this._trafficLightGroupFilter;
    }

    /** Получение времени сервера */
    public now = (): number => {
        const now = new Date().getTime();
        return now - this._baseLocalTime + this._baseServerTime;
    };

    /**Список радиолокационных детекторов */
    public get radarDetectors() {
        return this._radarDetectors;
    }

    /**Список светофоров */
    public get trafficLights() {
        return this._trafficLights;
    }

    /**Список групп координации */
    public get groups() {
        return this._groups;
    }

    /**
     * Получение светофора по id
     * @param id - id светофора
     */
    public getTrafficLightById(id: number) {
        if (!(id in this._trafficLightById)) return null;
        return this._trafficLightById[id];
    }

    /**
     * Получение группы координации по id
     * @param id - id группы координации
     */
    public getGroupById(id: number) {
        if (!(id in this._groupById)) return null;
        return this._groupById[id];
    }

    /**
     * Получение радарного детектора по id
     * @param id - id радарного детектора
     */
    public getRadarDetectorById(id: number) {
        if (!(id in this._radarDetectorById)) return null;
        return this._radarDetectorById[id];
    }

    get isInitialized() {
        return this._isInitialized;
    }

    get activeItem() {
        return this._activeItem;
    }

    get activeTrafficLight() {
        return this.activeItem && this.activeItem instanceof TrafficLight ? this.activeItem : null;
    }

    get activeGroup() {
        return this._activeItem && this._activeItem instanceof TrafficLightGroup ? this._activeItem : null;
    }

    get activeRadarDetector() {
        return this.activeItem && this.activeItem instanceof RadarDetector ? this.activeItem : null;
    }

    setActiveItem(item: TrafficLightDispatcherItem | null, state?: any) {
        let activeItem: TrafficLightDispatcherItem | null = null;
        if (item === null) {
            activeItem = null;
        } else activeItem = item;
        if (this._activeItem !== activeItem) {
            this._activeItem = activeItem;
            this.emit("activeItemChanged", { item: activeItem, state });
        }
    }

    setActiveTrafficLight(item: number | TrafficLight, state?: any) {
        let activeItem: TrafficLight | null = null;
        if (typeof item === "number") {
            activeItem = this._trafficLightById[item];
        } else activeItem = item;
        if (activeItem) this.setActiveItem(activeItem, state);
    }

    setActiveTrafficLightGroup(item: number | TrafficLightGroup, state?: any) {
        let activeItem: TrafficLightGroup | null = null;
        if (typeof item === "number") {
            activeItem = this._groupById[item];
        } else activeItem = item;
        if (activeItem) this.setActiveItem(activeItem, state);
    }

    setActiveRadarDetector(item: number | RadarDetector, state?: any) {
        let activeItem: RadarDetector | null = null;
        if (typeof item === "number") {
            activeItem = this._radarDetectorById[item];
        } else activeItem = item;

        if (activeItem) this.setActiveItem(activeItem, state);
    }

    public setTrafficLightFilter(filter: (item: TrafficLight) => boolean) {
        this._trafficLightFilter = filter;
        this.emit("itemsChanged");
    }

    public setTrafficLightGroupFilter(filter: (item: TrafficLightGroup) => boolean) {
        this._trafficLightGroupFilter = filter;
        this.emit("itemsChanged");
    }

    public async init() {
        if (this._isInitialized) return;
        if (this._initPromise === null) this._initPromise = this.initInternal();
        await this._initPromise;
    }

    private async initInternal() {
        await this.initTime();
        await this.initItems();
        this._isInitialized = true;
        this._initPromise = null;
    }

    private async initTime() {
        try {
            const stateInfo = await this.fetchTrafficLightJson("/state/info");
            this._baseServerTime = new Date(stateInfo.date).getTime();
            this._baseLocalTime = new Date().getTime();
        } catch (error) {
            console.error(error);
        }
    }

    async fetchTrafficLightJson(url: string, init?: RequestInit) {
        const response = await this.fetchTrafficLight(url, init);
        return await response.json();
    }

    async fetchRadarDetectorJson(url: string, init?: RequestInit) {
        const response = await this.fetchRadarDetector(url, init);
        return await response.json();
    }

    private async initItems() {
        await this.initTrafficLights();
        await this.initTrafficLightGroups();
        /** TODO: запрос детекторов транспорта (при отключенном слое детекторов) закомментирован для оптимизации работы приложения */
        // try {
        //     await this.initRadarDetectors();
        // } catch {
        //     console.error("initRadarDetectors error");
        // }

        this.emit("itemsChanged");
        this.startRadarDetectorMessageListener();
    }

    private async initTrafficLights() {
        const trafficLights: TrafficLight[] = [];
        const trafficLightsById: TrafficLightCollection = {};
        try {
            const itemsData = await this.fetchTrafficLightJson("/facility");
            if (!itemsData || !Array.isArray(itemsData)) return;

            for (let i = 0, len = itemsData.length; i < len; i++) {
                const item = itemsData[i];
                const trafficLight = new TrafficLight({
                    id: item.facilityId,
                    location: { lat: item.lat, lng: item.lng },
                    boxSize: item.box ? JSON.parse(item.box).size : null,
                    address: item.address,
                    streets: item.streets,
                    num: item.num,
                    dispatcher: this,
                    activeCycleNum: item.program,
                    activeIssueCount: item.activeIssueCount,
                });
                trafficLights.push(trafficLight);
                trafficLightsById[trafficLight.id] = trafficLight;
            }

            await this._updateTrafficLightStates(trafficLightsById);

            this._trafficLights = trafficLights;
            this._trafficLightById = trafficLightsById;
        } catch (error) {
            console.error(error);
        }
    }

    private async initTrafficLightGroups() {
        const groups: TrafficLightGroup[] = [];
        const groupById: TrafficLightGroupCollection = {};

        try {
            const groupsData = await this.fetchTrafficLightJson("/coogroup");

            if (groupsData && groupsData.length) {
                for (let i = 0, len = groupsData.length; i < len; i++) {
                    const item = groupsData[i];

                    const geom = item.geometry;

                    let from: TrafficLightGroupLocation | undefined;
                    let to: TrafficLightGroupLocation | undefined;
                    let path: number[][] = [];
                    if (geom) {
                        const data: {
                            points: {
                                address: string;
                                lngLat: { lat: number; lng: number };
                            }[];
                            path: FeatureCollection<LineString>;
                        } = JSON.parse(geom);

                        if (data.points && data.points.length) {
                            const p1 = data.points[0];
                            const p2 = data.points[data.points.length - 1];

                            from = {
                                address: p1.address,
                                lat: p1.lngLat?.lat,
                                lng: p1.lngLat?.lng,
                            };
                            to = {
                                address: p2.address,
                                lat: p2.lngLat?.lat,
                                lng: p2.lngLat?.lng,
                            };
                        }

                        if (data.path && data.path.features[0]) {
                            path = data.path.features[0].geometry.coordinates;
                        }
                    }

                    const group = new TrafficLightGroup({
                        dispatcher: this,
                        id: item.id,
                        name: item.name,
                        from: from,
                        to: to,
                        path,
                    });

                    if (item.facilities && item.facilities.length) {
                        item.facilities.forEach((tl: any) => {
                            const trafficLight = this._trafficLightById[tl.facilityId];
                            if (trafficLight) {
                                trafficLight.groups.push(group);
                                group.items.push({
                                    trafficLight,
                                    pos: tl.distanceFromStart,
                                    directionNum: tl.directionNumber,
                                    speed: 60,
                                });
                            }
                        });
                    }

                    groups.push(group);
                    groupById[group.id] = group;
                }
                await this._updateTrafficLightGroupStates(groupById);
            }
        } catch (error) {
            console.error(error);
        }
        this._groups = groups;
        this._groupById = groupById;
    }

    private async initRadarDetectors() {
        const items: RadarDetector[] = [];
        const itemById: RadarDetectorCollection = {};

        try {
            const itemsData = await this.fetchRadarDetectorJson("detector");
            if (!itemsData || !Array.isArray(itemsData)) return;

            for (let i = 0, len = itemsData.length; i < len; i++) {
                const item = itemsData[i];
                const radarDetector = new RadarDetector({
                    id: item.id,
                    num: item.num,
                    location: { lat: item.lat, lng: item.lng },
                    address: item.address,
                    directions: item.directions,
                    dispatcher: this,
                });
                items.push(radarDetector);
                itemById[radarDetector.id] = radarDetector;
            }
        } catch (error) {
            console.error(error);
        }

        this._radarDetectors = items;
        this._radarDetectorById = itemById;
    }

    public nextRetryDelayInMilliseconds() {
        return 2000;
    }

    private async _updateTrafficLightStates(itemById: TrafficLightCollection) {
        const data: (TrafficLightState & { id: number })[] = await this.fetchTrafficLightJson("/state");
        if (data && data.length) {
            for (let i = 0, len = data.length; i < len; i++) {
                const item = data[i];
                const trafficLight: TrafficLight = itemById[item.id];
                if (trafficLight) {
                    trafficLight.initFromState(item);
                }
            }
        }
    }

    private async _updateTrafficLightGroupStates(itemById: TrafficLightGroupCollection) {
        const response = await this.fetchTrafficLight("/state/coogroup");
        const data: (TrafficLightGroupState & { id: number })[] = await response.json();
        if (data && data.length) {
            for (let i = 0, len = data.length; i < len; i++) {
                const item = data[i];
                const group: TrafficLightGroup = itemById[item.id];
                if (group) {
                    group.initFromState(item);
                }
            }
        }
    }

    private processTrafficLightGroupMessage = (msg: any[]) => {
        const id = msg[0];
        const group = this._groupById[id];
        if (group) {
            group.onMessage({
                dateTime: new Date(msg[1]).getTime(),
                state: {
                    isEnabled: msg[2],
                    isWarning: msg[4],
                    warning: msg[5],
                    activeCycleId: msg[6],
                    controlMode: msg[7],
                    status: msg[8],
                },
            });
        }
    };

    private processRadarDetectorPresenceMessage = (msg: any[]) => {
        this.emit("radarDetectorLaneIsBusyChanged", {
            detectorId: msg[0],
            lane: msg[1],
            isBusy: msg[2],
        });
        const radarDetector = this.getRadarDetectorById(msg[0]);
        if (radarDetector) radarDetector.emit("lanePresence", { lane: msg[1], isBusy: msg[2] });
    };

    private processRadarDetectorFixationMessage = (msg: any[]) => {
        const eventArgs = {
            detectorId: msg[0],
            received: msg[1],
            dateTime: msg[2],
            lane: msg[3],
            vehicleClass: msg[4],
            speed: msg[5],
            vehicleLength: msg[6],
        };

        const detector = this.getRadarDetectorById(msg[0]);
        if (detector) {
            this.emit("radarDetectorFixation", eventArgs);
            detector?.emit("fixation", eventArgs);
        }
    };

    private async startRadarDetectorMessageListener() {
        const connection = new HubConnectionBuilder()
            .withUrl(`${this.radarDetectorBaseUrl}/events`)
            .withHubProtocol(new MessagePackHubProtocol() as IHubProtocol)
            .withAutomaticReconnect(this)
            .build();

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const fixationMessageListener: IStreamSubscriber<any> = {
            next: this.processRadarDetectorFixationMessage,
            error: (err) => console.error(err),
            complete: () => null,
        };

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const presenceMessageListener: IStreamSubscriber<any> = {
            next: this.processRadarDetectorPresenceMessage,
            error: (err) => console.error(err),
            complete: () => null,
        };
        await connection.start();

        const subscribe = () => {
            connection.stream(radarDetectorPresenceStreamName, null).subscribe(presenceMessageListener);
            connection.stream(radarDetectorFixationStreamName, null).subscribe(fixationMessageListener);
        };
        subscribe();

        connection.onreconnected(() => {
            subscribe();
        });
    }

    private async startTrafficlightMessageListener() {
        this.trafficLightMessageHub.subscribeToCooGroupStream({ onMessage: this.processTrafficLightGroupMessage });
    }

    /** @deprecated функционал реализован в traffic-light-history-event-store */
    async fetchTrafficlightsLog(
        id: number[],
        filter: TrafficLightLogFilter
    ): Promise<PagedCollection<TrafficLightMessage> | null> {
        const count = filter.pageSize ? filter.pageSize : 10000;

        let url = `/facility/logs?count=${count}`;

        id.forEach((val) => (url += `&ids=${val}`));

        if (filter.from) {
            url += `&start=${filter.from.toISOString()}`;
        }

        if (filter.to) {
            url += `&finish=${filter.to.toISOString()}`;
        }

        if (filter.controlMode) {
            url += `&controlmode=${filter.controlMode.join(",")}`;
        }

        if (filter.status) {
            url += `&status=${filter.status.join(",")}`;
        }

        if (filter.eventType) {
            url += `&eventtype=${filter.eventType.join(",")}`;
        }

        if (filter.errorCode && filter.errorCode.length) {
            url += `&malfunctiontype=${filter.errorCode[0]}`;
        }

        if (filter.page) {
            url += `&page=${filter.page}`;
        }

        if (filter.pageSize) {
            url += `&count=${filter.pageSize}`;
        }

        const data = await this.fetchTrafficLightJson(url);

        const items: TrafficLightMessage[] = [];
        for (let i = 0, len = data.items.length; i < len; i++) {
            const item = data.items[i];
            items.push(messageFromLog(item));
        }

        const res: PagedCollection<TrafficLightMessage> = {
            pageIndex: data.pageIndex,
            pageSize: data.pageSize,
            totalCount: data.totalCount,
            items: items,
        };
        return res;
    }
}
