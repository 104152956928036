import React, { memo, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useAppState } from "app/store";
import { MapControllerContext } from "./map-controller.context";
import { WorkingMode } from "./layer-controllers";

type Props = {
    children: React.ReactNode;
};

const MapControllerProviderComponent = (props: Props) => {
    const { mapController } = useAppState();
    /** TODO: После доработки роутинга - убрать */
    const { pathname } = useLocation();
    const isMounted = useRef(true);

    useEffect(() => {
        if (!isMounted.current) return;
        const workingMode = pathname.includes("coo-group-editor/new")
            ? WorkingMode.ROUTE_BUILDING
            : WorkingMode.DEFAULT;
        mapController.layerControllers.setWorkingMode(workingMode);
    }, [mapController, pathname]);

    return <MapControllerContext.Provider value={mapController}>{props.children}</MapControllerContext.Provider>;
};

export const MapControllerProvider = memo(MapControllerProviderComponent);
