import styled from "styled-components/macro";

export const Wrapper = styled.div<{ gap?: number; hasWrap?: boolean }>`
    display: flex;
    flex-wrap: ${({ hasWrap }) => (hasWrap ? "wrap" : "nowrap")};
    gap: ${({ gap }) => gap}px;
    align-items: center;
    color: inherit;
    font: inherit;
`;
