import styled from "styled-components/macro";
import { StackHeader } from "@megapolis/uikit/stack-header";

export const WrappedStackHeader = styled(StackHeader.Small)`
    align-items: flex-start;

    & > div {
        padding-top: 0;

        & > div {
            & > div {
                flex-basis: 100%;
                gap: ${({ theme }) => theme.spacings[5].str};
            }

            & > h4 {
                flex-basis: 100%;

                & > span:focus-within {
                    box-shadow: none;
                }
            }
        }
    }
`;

export const Caption = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.content.secondary};
`;
