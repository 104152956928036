import styled, { css } from "styled-components/macro";

type WrapperProps = {
    isActive: boolean;
};

const activeCSS = css`
    &,
    &:hover {
        svg {
            fill: ${({ theme }) => theme.colors.green.basic};
            & > * {
                fill: ${({ theme }) => theme.colors.green.basic};
            }
        }
    }
`;

export const ButtonWrapper = styled.span<WrapperProps>`
    && > button {
        max-width: 32px;
        max-height: 32px;

        ${({ isActive }) => isActive && activeCSS};
    }
`;
