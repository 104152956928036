import { CooGroupDomain } from "app-domain";
import mapboxgl from "mapbox-gl";
import { cooGroupStatusColor, getCooGroupControlModeColor } from "./coo-group-layer.utils";

interface Options {
    cooGroup: CooGroupDomain.CooGroup;
    sourceId: string;
    isVisible: boolean;
}

export class CooGroupVisual {
    constructor(private map: mapboxgl.Map, private options: Options) {
        this.init();
    }

    public destroy = () => {
        this.options.cooGroup.off(CooGroupDomain.CooGroupEventNames.controlModeChanged, this.handleControlModeChange);
        this.options.cooGroup.off(CooGroupDomain.CooGroupEventNames.statusChanged, this.handleStatusChange);
    };

    public setFeatureState = (isVisible: boolean, activeCooGroupId?: NullableNumber) => {
        const { sourceId, cooGroup } = this.options;
        this.map.setFeatureState(
            { source: sourceId, id: cooGroup.id },
            {
                isVisible,
                isActive: activeCooGroupId === cooGroup.id,
                inLineColor: cooGroupStatusColor(cooGroup.status),
                outLineColor: getCooGroupControlModeColor(cooGroup.controlMode),
            }
        );
    };

    private init(): void {
        this.subscribe();
        this.setFeatureState(this.options.isVisible);
    }

    private subscribe = () => {
        this.options.cooGroup.on(CooGroupDomain.CooGroupEventNames.controlModeChanged, this.handleControlModeChange);
        this.options.cooGroup.on(CooGroupDomain.CooGroupEventNames.statusChanged, this.handleStatusChange);
    };

    private handleControlModeChange = () => {
        const { sourceId, cooGroup } = this.options;

        this.map.setFeatureState(
            { source: sourceId, id: cooGroup.id },
            {
                inLineColor: cooGroupStatusColor(cooGroup.status),
                outLineColor: getCooGroupControlModeColor(cooGroup.controlMode),
            }
        );
    };

    private handleStatusChange = () => {
        const { sourceId, cooGroup } = this.options;

        this.map.setFeatureState(
            { source: sourceId, id: cooGroup.id },
            {
                inLineColor: cooGroupStatusColor(cooGroup.status),
                outLineColor: getCooGroupControlModeColor(cooGroup.controlMode),
            }
        );
    };
}
