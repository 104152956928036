import React from "react";
import { REACT_APP_CDN_FILES } from "env-data";
import { Image } from "./thumb.styles";
import type { Props } from "./thumb.types";

export const Thumb = React.memo((props: Props) => {
    const src = `${REACT_APP_CDN_FILES}/map-icons/detector/detector-${props.statusByIssueId ?? ""}-${
        /** TODO: временное решение */
        // props.statusByMonitoringId ?? ""
        props.statusByMonitoringId ?? 2
    }.png`;

    const alt = `Состояние по заявкам: ${props.statusByIssueName} - Состояние по мониторингу: ${props.statusByMonitoringName}`;

    return <Image className={props.className} src={src} width="32" height="32" alt={alt} />;
});
