import { Drawer } from "diagrams/drawers";
import { PhaseDrawer } from "./phase";
import { DirectionData } from "../data";

export class DirectionDrawer extends Drawer<DirectionData> {
    private phaseDrawer: PhaseDrawer;

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.phaseDrawer = new PhaseDrawer(ctx);
    }

    protected draw = () => {
        if (!this.data) return;
        for (let data of this.data) {
            this.phaseDrawer.setData(data);
        }
    };
}
