export const getDaysFromInterval = (startTimestamp: number, endTimestamp: number) => {
    const startDay = new Date(startTimestamp).setHours(0, 0, 0, 0);
    const endDay = new Date(endTimestamp).setHours(0, 0, 0, 0);
    const oneDay = 86400000;

    const allDays: number[] = [];
    let iterDay: number = startDay;

    while (iterDay < endDay) {
        allDays.push(iterDay);
        iterDay += oneDay;
    }

    allDays.push(endDay);

    return allDays;
};

export const getViewDateFromInterval = (startTimestamp: number, endTimestamp: number): Date => {
    const actualStart = new Date(startTimestamp);
    const actualEnd = new Date(endTimestamp);

    if (actualStart.getDay() !== actualEnd.getDay()) {
        if (24 - actualStart.getHours() > actualEnd.getHours()) {
            actualStart.setHours(0, 0);
            return actualStart;
        } else {
            actualEnd.setHours(0, 0);
            return actualEnd;
        }
    }
    actualStart.setHours(0, 0);
    return actualStart;
};
