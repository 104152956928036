import mapboxgl from "mapbox-gl";

export abstract class Layer<T extends mapboxgl.AnyLayer> {
    protected data: GeoJSON.Feature[] = [];
    protected visibility: mapboxgl.Visibility = "visible";
    protected layer: T | T[] = [];
    protected _layerId: string | string[] = "";
    protected beforeId?: string;

    constructor(protected map: mapboxgl.Map) {
        this.addSource();
        this.addLayer();
    }

    public get layerId(): string {
        return Array.isArray(this._layerId) ? this._layerId[0] : this._layerId;
    }

    public setData(data: GeoJSON.Feature[]) {
        this.data = data;
        const source = this.map.getSource(this.layerId) as mapboxgl.GeoJSONSource | undefined;
        if (!source) return;

        source.setData({
            type: "FeatureCollection",
            features: data,
        });
    }

    public destroy() {
        this.removeLayer();
        this.removeSource();
    }

    public setVisibility = async (isVisibility: boolean) => {
        this.visibility = isVisibility ? "visible" : "none";

        const layer = this.map.getLayer(this.layerId);

        if (!layer) return new Error();

        if (Array.isArray(this._layerId)) {
            this._layerId.forEach(this.setLayoutProperty);
        } else {
            this.setLayoutProperty(this.layerId);
        }
    };

    protected async addSource() {
        if (await this.checkSource()) return;

        this.map.addSource(this.layerId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: this.data,
            },
        });
    }

    /* Не использовать стрелочную функцию чтобы вызывать addLayer из super */
    protected async addLayer() {
        if (await this.checkLayer()) return;
        if (Array.isArray(this.layer)) {
            this.layer.forEach((item) => this.map.addLayer(item, this.beforeId));
        } else {
            this.map.addLayer(this.layer, this.beforeId);
        }
    }

    private setLayoutProperty = (layerId: string) => {
        this.map.setLayoutProperty(layerId, "visibility", this.visibility);
    };

    private removeLayer() {
        if (!this.checkLayer()) return;
        if (Array.isArray(this._layerId)) {
            return this._layerId.forEach((layerId) => {
                this.map.removeLayer(layerId);
            });
        }
        this.map.removeLayer(this._layerId);
    }

    private removeSource = () => {
        if (!this.checkSource()) return;
        this.map.removeSource(this.layerId);
    };

    private async checkLayer() {
        return this.map.getLayer(this.layerId);
    }

    private async checkSource() {
        return this.map.getSource(this.layerId);
    }
}
