export enum TrafficLightViewType {
    List = "list",
    Details = "details",
}

export enum CooGroupViewType {
    List = "list",
    Details = "details",
    Editor = "editor",
}

export enum DashboardViewType {
    Details = "details",
}
