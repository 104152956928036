import { memo } from "react";
import { Resizable, ResizableProps } from "re-resizable";

type Props = Pick<
    ResizableProps,
    "defaultSize" | "children" | "minWidth" | "maxWidth" | "minHeight" | "maxHeight" | "bounds" | "onResize" | "style"
>;
/** TODO FIXME
 * Как быстрая реализация для показа, компонент использует библиотеку
 * Позже будет своя реализация
 */
export const ResizableBox = memo((props: Props) => (
    <Resizable
        bounds={props.bounds ?? "window"}
        defaultSize={props.defaultSize}
        children={props.children}
        minHeight={props.minHeight}
        maxHeight={props.maxHeight}
        minWidth={props.minWidth}
        maxWidth={props.maxWidth}
        onResize={props.onResize}
        style={props.style}
    />
));
