export enum Events {
    ActiveTrafficLightChange = "ActiveTrafficLightChange",
    ActiveCooGroupChange = "ActiveCooGroupChange",
}

export enum TrafficLightType {
    "list" = "traffic-lights",
    "details" = "traffic-light",
}

export enum CooGroupType {
    "list" = "coogroups",
    "details" = "coogroup",
    "editor" = "coo-group-editor",
}

export type ActiveTrafficLightParams = {
    id: NullableString | NullableNumber;
    type?: keyof typeof TrafficLightType;
};

export type ActiveCooGroupParams = {
    id: NullableString | NullableNumber;
    type?: keyof typeof CooGroupType;
};

export type Handlers = {
    [Events.ActiveTrafficLightChange]: (params: ActiveTrafficLightParams) => void;
    [Events.ActiveCooGroupChange]: (params: ActiveCooGroupParams) => void;
};
