import { PhaseType } from "trafficlight-dispatcher/models";

export type ItemCycleType = {
    id: PhaseType;
    label: string;
};

export const collectionPhaseType: ItemCycleType[] = [
    { id: PhaseType.Basic, label: "Основная" },
    { id: PhaseType.Conditional, label: "Вызывная" },
    { id: PhaseType.Special, label: "Специальная" },
    { id: PhaseType.ConditionalAlternate, label: "Вызывная (подменная)" },
];
