// Минимальная информация, хранящаяся в СО об текущем активном пульте

export class GovernanceInfo {
    public id: number;
    public userId: NullableString;
    public userName: NullableString;

    constructor(id: number, userId?: NullableString, userName?: NullableString) {
        this.id = id;
        this.userId = userId ?? null;
        this.userName = userName ?? null;
    }
}
