export enum DataType {
    None = "none",
    Historical = "historical",
    Forecast = "forecast",
    Merged = "merged",
}

export type TrafficScore = {
    /** Время начала периода */
    time: number;
    /** Балльность пробки */
    score: number;
    /** Тип данных */
    type?: DataType;
    index?: number;
};

export enum Split {
    By_5_Minutes = 5,
    By_15_Minutes = 15,
    By_30_Minutes = 30,
    By_60_Minutes = 60,
}

export enum ViewMode {
    Range,
    LastPeriod,
}

export type TrafficScoreGroup = Record<Split, TrafficScore[]>;
