import React from "react";
import * as Markup from "./body.styles";
import { BlockLoader } from "../../../block-loader";
import type { Props } from "./body.types";

export const Body = React.memo((props: Props) => {
    const dataCypressBody = props.dataCypress ? `${props.dataCypress}__body` : undefined;

    const dataCypressDetails = props.dataCypress ? `${props.dataCypress}__details` : undefined;

    if (props.isLoaded === false) {
        const dataCypressPlayerPlaceholder = props.dataCypress ? `${props.dataCypress}__player-placeholder` : undefined;

        return (
            <Markup.Container className={props.className} data-cypress={dataCypressBody}>
                <Markup.PlayerPlaceholder
                    width={400}
                    height={280}
                    borderRadius="6px"
                    data-cypress={dataCypressPlayerPlaceholder}>
                    <BlockLoader />
                </Markup.PlayerPlaceholder>

                <Markup.DetailsPlaceholder data-cypress={dataCypressDetails} />
                {/* 
                TODO: Ждем пока развернут приложение
                <Markup.Issues uid={props.uid} issueCount={props.issueCount} /> 
                */}
            </Markup.Container>
        );
    }

    return (
        <Markup.Container className={props.className} data-cypress={dataCypressBody}>
            <Markup.Players
                uid={props.uid}
                number={props.number}
                streams={props.streams}
                dataCypress={props.dataCypress}
                getToken={props.getToken}
            />
            <Markup.Details uid={props.uid} dataCypress={dataCypressDetails} />
            {/* 
            TODO: Ждем пока развернут приложение
            <Markup.Issues uid={props.uid} issueCount={props.issueCount} /> 
            */}
        </Markup.Container>
    );
});
