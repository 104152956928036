import { mapConstants } from "shared/constants";
import { LayerId, LayerKey, MapControlsStateKeys } from "./map-controls-state.types";

export const { LAYER_IDS } = mapConstants;

export const layerKeyStateMap = {
    trafficLights: "isTrafficLightLayerVisible",
    cooGroups: "isCooGroupLayerVisible",
} as {
    [key in LayerKey]: MapControlsStateKeys;
};

export const layerKeyIdMap = {
    trafficLights: LAYER_IDS.trafficLight,
    cooGroups: LAYER_IDS.cooGroup,
} as {
    [key in LayerKey]: LayerId;
};

export const VISIBILITY_PROP = "visibility";
