import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Radiobutton } from "@megapolis/uikit/radio-button";
import { UI } from "components";
import { useCommandLaunchPopupState } from "../../../command-launch-popup-context";
import * as Markup from "./cycle-delay-section.styles";

export const CycleDelaySection = observer(() => {
    const {
        setCycleDurationMode,
        isCycleDurationMode,
        isCycleDurationModeAvailable,
        isCycleDurationValid,
        minDurationHours,
        minDurationMinutes,
        cycleDurationModeMessage,
        cycleDurationSeconds,
        cycleDurationRepeatCount,
        changeCycleDurationRepeatCount,
    } = useCommandLaunchPopupState();

    const [message, setMessage] = useState(() => (isCycleDurationModeAvailable ? "" : cycleDurationModeMessage));

    const validationError = useMemo(() => {
        if (isCycleDurationValid) return;
        return `Минимальное время длительности: ${
            minDurationHours ? `${minDurationHours} часов,` : ""
        }${minDurationMinutes} минут`;
    }, [isCycleDurationValid, minDurationHours, minDurationMinutes]);

    useEffect(() => {
        if (!isCycleDurationModeAvailable) return;
        if (cycleDurationSeconds && cycleDurationRepeatCount) {
            const totalSeconds = cycleDurationRepeatCount * cycleDurationSeconds;
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            setMessage(`длительность ${totalSeconds} сек (${minutes} мин. ${seconds} сек.)`);
        } else {
            setMessage("");
        }
    }, [cycleDurationSeconds, cycleDurationRepeatCount, isCycleDurationModeAvailable]);

    return (
        <>
            <Radiobutton.ToggleLabel
                hasError={isCycleDurationMode && !!validationError}
                error={validationError}
                isDisabled={!isCycleDurationModeAvailable}
                value={isCycleDurationMode}
                onClick={setCycleDurationMode}>
                Установить длительность в циклах
            </Radiobutton.ToggleLabel>
            <Markup.Container>
                <Markup.CycleInputWrapper>
                    <UI.NumberInput
                        value={cycleDurationRepeatCount}
                        min={0}
                        isDisabled={!isCycleDurationMode}
                        onChange={changeCycleDurationRepeatCount}
                    />
                </Markup.CycleInputWrapper>
                <Markup.Message>{message}</Markup.Message>
            </Markup.Container>
        </>
    );
});
