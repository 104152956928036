import { prepareCoordinates, drawCorners } from "./rounded-triangle.utils";
import type { DrawOptions } from "./rounded-triangle.types";

export class RoundedTriangle {
    public static draw(ctx: CanvasRenderingContext2D, options: DrawOptions) {
        const corners = prepareCoordinates(options);

        ctx.moveTo(corners[0][0].x, corners[0][0].y);

        drawCorners(ctx, corners);

        ctx.closePath();
    }
}
