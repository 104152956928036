import { TrafficLightDomain } from "app-domain";
import { TrafficLightGroupState } from "trafficlight-dispatcher/trafficlight-group";
import { TrafficLight } from "../trafficlight";
import { TrafficLightGroupStatusCode } from "./group-status";
import { TrafficLightState } from "./trafficlight-state";
import { TrafficLightSockets } from "api";

export interface DictionaryItem<T> {
    /**Код элемента */
    code: T;

    /** Название */
    name: string;

    /**Сокращенное название */
    shortName: string;

    /**Цвет */
    color: string;
}

/** Параметры  TrafficLightDispatcher*/
export interface TrafficLightDispatcherProps {
    trafficLightMessageHub: TrafficLightSockets.TrafficLightMessageStreamHub;
    trafficLightbaseUrl: string;
    radarDetectorBaseUrl: string;
    //** Токен авторизации */
    token: string;
    filter?: (item: TrafficLight) => boolean;
    debug?: boolean;
}

export enum PhaseType {
    /** Основная */
    Basic = 0,
    /** Вызывная */
    Conditional = 1,
    /** Специальная */
    Special = 2,
    /** Вызывная (подменная) */
    ConditionalAlternate = 3,
}

export enum TrafficLightDirectionTypeCode {
    /** Транспортное */
    Transport = 0,
    /** Поворотное */
    Turn = 1,
    /** Пешеходное */
    Pedestrian = 2,
    /** Велосипедное */
    Bicycle,
    /** Общественный транспорт */
    PublicTransport = 3,
}

export enum CycleTypeCode {
    /** Основная */
    Basic = 0,

    /** Резервная */
    Reserve = 1,

    /** Вызывная */
    OnCall = 2,

    /** Жёлтое мигание */
    YellowBlink = 3,

    /** Динамическая */
    Dynamic = 4,
    CooGroup = 5,
}

export type TrafficLightCollection = { [key: number]: TrafficLight };

export interface PagedCollection<T> {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
    items: T[];
}

export interface TrafficLightMessage {
    id: number;
    dateTime: number;
    state: TrafficLightState;
    eventType: TrafficLightDomain.Enums.EventTypeCode;
    data?: any;
    controllerDateTime?: number;
}

export interface TrafficLightGroupMessage {
    dateTime: number;
    state: TrafficLightGroupState;
}

export interface Governance {
    id: number;
    username: string;
    userId: string;
    profileId: string;
    start: string;
    holdExpireDate?: string;
    program?: number;
}

/** Информация о сессии управления СО */
export interface TrafficLightGovernance extends Governance {
    status?: TrafficLightDomain.Enums.StatusCode;
    phase?: number;
}

/** Информация о сессии управления группой координации */
export interface TrafficLightGroupGovernance extends Governance {
    status?: TrafficLightGroupStatusCode;
}

/** Камера светофорного объекта */
export interface CameraInfo {
    id: number;
    num: string;
    assetStatus: number;
    assetMonitoringStatus: number;
}

/** Фаза светофорного объекта */
export interface TrafficLightPhase {
    number: number;
    type: PhaseType;
    directions: number[];
    directionByNum: { [Key: number]: boolean };
}

export interface TrafficLightPhaseRef {
    num: number;
    duration: number;
    next: number;
}

/**
 * Информация о выполняемой фазе
 */
export interface TrafficLightPhaseStart {
    phase: TrafficLightPhaseRef;
    start: number;
}

/**
 * Трек движение внутри направления
 */
export interface TrafficLightTrack {
    edgeFrom: string;
    edgeTo: string;
    displayOffsetX: number;
    displayOffsetY: number;

    /**Данные для отображения геометрии */
    lineData: string;

    /**Название */
    displayName?: any;

    nLat: number;

    nLng: number;

    nVisible: boolean;

    /**Просачивание */
    isLeaking: boolean;
}

/**Информация о показателях детектора транспорта */
export interface DetectorData {}

type TimePeriod = {
    start: string;
    stop: string;
};

type CycleSchedule = {
    /** Дни недели */
    daysOfWeek: number[];
    periods: TimePeriod[];
};

const weekday = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
export function cycleScheduleToString(schedule: CycleSchedule) {
    let res = "";
    if (schedule.daysOfWeek) {
        schedule.daysOfWeek
            .sort((a, b) => a - b)
            .forEach((d, i) => (res += weekday[d - 1] + (i !== schedule.daysOfWeek.length - 1 ? ", " : "")));
    }

    if (schedule.periods.length) {
        res += " • ";
        schedule.periods.forEach((p, i) => {
            res +=
                ` ${p.start.replace(":00", "")}-${p.stop.replace(":00", "")}` +
                (i !== schedule.periods.length - 1 ? ", " : "");
        });
    }

    return res;
}

export interface TrafficLightCyclePhase {
    phaseNumber: number;
    tBasic: number;
    tMin: number;
    tProm: number;
    tPhase: number;
}

export interface TrafficLightCycle {
    id: number;
    phases: TrafficLightCyclePhase[];
    time?: number;
    name?: string;
    number?: number;
    syncDate?: string;
    source?: number;
    schedule?: CycleSchedule;
    schedules?: CycleSchedule[];
    type?: CycleTypeCode;
    userId?: NullableString;
    userDisplayName?: NullableString;
}

export enum TrafficLightCycleSources {
    custom = 0,
    passport = 1,
}

/** Макра и модель СО */
export interface TrafficLightModelInfo {
    typeId: number;
    modelId: number;
    type: string;
    model: string;
}

/** Детектор, установленный на светофорном объекте */
export type TrafficLightDetector = {
    /** Номер детектора */
    number: number;

    type: TrafficLightDetectorType;

    /** Видеопоток детектора */
    videoStream: string;

    /** API адрес камеры */
    apiUrl: string;

    /** Геометрия */
    geometry: string;

    channels: TrafficLightDetectorChannel[];
};

/** Тип детектора */
export enum TrafficLightDetectorType {
    /** Петлевой детектор */
    Loop = 0,
    /** Видеодектор */
    Video = 1,
}

/** Канал детектора */
export type TrafficLightDetectorChannel = {
    /** Идентификатор канала детектора */
    channel: string;
    /** Номер направления */
    direction: number;
    /** Максимальная длина очереди */
    length: number;
};

export { TrafficLightErrorCode, trafficLightErrorTypes } from "./trafficlight-error";
export type { TrafficLightError } from "./trafficlight-error";
export type { TrafficLightState } from "./trafficlight-state";
