import { TypedEmitter } from "tiny-typed-emitter";

export enum Events {
    Adding = "adding",
    Remove = "remove",
    ShowOnMap = "show-on-map",
    MaximizeCard = "maximize-card",
    MinimizeCard = "minimize-card",
    /** TODO: исключить событие из папки warehouse */
    SelectDetector = "select-detector",
    /** TODO: исключить событие из папки warehouse */
    SelectCamera = "select-detector",
}

export type Handlers = {
    [Events.Adding]: (guid: string) => void;
    [Events.Remove]: (guid: string) => void;
    [Events.ShowOnMap]: (coordinate: LatLng) => void;
    [Events.MaximizeCard]: (args: SourceEquipmentCardArgs) => void;
    [Events.MinimizeCard]: (args: SourceEquipmentCardArgs) => void;
    /** TODO: исключить handler из папки warehouse */
    [Events.SelectDetector]: (args: SourceEquipmentCardArgs) => void;
    /** TODO: исключить handler из папки warehouse */
    [Events.SelectCamera]: (args: SourceEquipmentCardArgs) => void;
};

export class Emitter extends TypedEmitter<Handlers> {
    public readonly events = Events;
}
