import { TextureCoord } from "./traffic-light-layer";
import TrafficLightVisual from "./traffic-light-visual";

export default class DynamicSprite {
    public size: number;
    public canvas: HTMLCanvasElement;
    public items: { [key: number]: TextureCoord };
    private _ctx: CanvasRenderingContext2D;

    constructor(size: number) {
        const canvas = (this.canvas = document.createElement("canvas"));
        const ctx = (this._ctx = canvas.getContext("2d")!);
        this.items = {};
        this.size = size;
        canvas.width = size * 2;
        canvas.height = size * 2;
        ctx.scale(2, 2);
    }

    update(visuals: TrafficLightVisual[], now: number) {
        const len = visuals.length;
        const size = this.size;
        const colCount = Math.ceil(Math.sqrt(len));
        const itemSize = this.size / colCount;
        const items: { [key: number]: TextureCoord } = (this.items = {});
        this._ctx.clearRect(0, 0, size, size);

        for (let i = 0; i < len; i++) {
            const visual = visuals[i];
            visual.spriteKey = i;
            const x = itemSize * (i % colCount);
            const y = itemSize * Math.trunc(i / colCount);
            items[i] = {
                x1: x / size,
                y2: y / size,
                x2: (x + itemSize) / size,
                y1: (y + itemSize) / size,
            } as TextureCoord;
            visual.draw(this._ctx, x, y, itemSize, now);
        }
    }
}
