import { Drawer as AbsDrawer } from "diagrams/drawers";
import { HeadDrawer } from "./head";
import { EmptyPartDrawer } from "./empty-part";
import { GreenWaveDrawer } from "./green-wave";
import { DirectionDrawer } from "./direction";
import { OverlayDrawer } from "./overlay";
import { DirectionLabelDrawer } from "./direction-label";
import { DirectionStartAnchorsDrawer } from "./direction-start-anchors";
import { PhaseIconsDrawer } from "./phase-icons";
import { TimeLineDrawer } from "./timescale";
import { DrawData } from "./draw-data";
import { SpeedDistanceLegendDrawer } from "./speed-distance-legend";

export class Drawer extends AbsDrawer<DrawData> {
    private headDrawer: HeadDrawer;
    private emptyPartDrawer: EmptyPartDrawer;
    private greenWaveDrawer: GreenWaveDrawer;
    private directionDrawer: DirectionDrawer;
    private overlayDrawer: OverlayDrawer;
    private directionLabelDrawer: DirectionLabelDrawer;
    private directionStartAnchorsDrawer: DirectionStartAnchorsDrawer;
    private phaseIconsDrawer: PhaseIconsDrawer;
    private timescaleDrawer: TimeLineDrawer;
    private speedDistanceLegendDrawer: SpeedDistanceLegendDrawer;

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.headDrawer = new HeadDrawer(ctx);
        this.emptyPartDrawer = new EmptyPartDrawer(ctx);
        this.greenWaveDrawer = new GreenWaveDrawer(ctx);
        this.directionDrawer = new DirectionDrawer(ctx);
        this.overlayDrawer = new OverlayDrawer(ctx);
        this.directionLabelDrawer = new DirectionLabelDrawer(ctx);
        this.directionStartAnchorsDrawer = new DirectionStartAnchorsDrawer(ctx);
        this.phaseIconsDrawer = new PhaseIconsDrawer(ctx);
        this.timescaleDrawer = new TimeLineDrawer(ctx);
        this.speedDistanceLegendDrawer = new SpeedDistanceLegendDrawer(ctx);
    }

    protected draw = () => {
        if (!this.data) return;
        this.drawTimescale(this.data.timescale);
        this.drawCycles(this.data.cycles);
    };

    private drawTimescale(data: DrawData["timescale"]) {
        this.timescaleDrawer.setData({
            timeData: data.time,
            timeScaleData: data.scale,
        });
    }

    private drawCycles(cycles: DrawData["cycles"]) {
        for (const cycle of cycles) {
            this.headDrawer.setData(cycle.headData);
            // if (cycle.emptyPartData) this.emptyPartDrawer.setData(cycle.emptyPartData);
            this.greenWaveDrawer.setData(cycle.greenWaveData);
            this.directionDrawer.setData(cycle.directionData);
            if (cycle.overlayData) this.overlayDrawer.setData(cycle.overlayData);
            this.directionLabelDrawer.setData(cycle.directionLabelData);
            this.directionStartAnchorsDrawer.setData(cycle.directionStartAnchorsData);
            if (cycle.phaseIconsData) {
                this.phaseIconsDrawer.setData(cycle.phaseIconsData);
            }

            if (cycle.speedDistanceLegend) {
                this.speedDistanceLegendDrawer.setData(cycle.speedDistanceLegend);
            }
        }
    }
}
