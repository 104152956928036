import { memo } from "react";
import { Button } from "@megapolis/uikit/button";
import * as Markup from "./footer.styles";

interface Props {
    isSubmitButtonDisabled: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const Footer = memo((props: Props) => {
    const { isSubmitButtonDisabled, onClose, onSubmit } = props;
    return (
        <Markup.Container>
            <Button onClick={onClose} priority="tertiary" content={Button.Content.Text} text="Отменить" isFullWidth />
            <Button
                isDisabled={isSubmitButtonDisabled}
                onClick={onSubmit}
                content={Button.Content.Text}
                text="Запустить"
                isFullWidth
            />
        </Markup.Container>
    );
});
