import { memo, ComponentProps, useCallback } from "react";
import VideoPlayer from "@megapolis/react-video-player";
import { useToken } from "shared/hooks";
import * as Env from "env-data";

const DEFAULT_URL: string = Env.REACT_APP_API_CAMERA_SERVICE;

type VideoPlayerProps = ComponentProps<typeof VideoPlayer>;

export type CameraVideoPlayerProps = Omit<VideoPlayerProps, "token" | "url"> & {
    url?: string;
    className?: string;
};

const CameraVideoPlayerComponent = (props: CameraVideoPlayerProps) => {
    const { url = DEFAULT_URL, id, autoplay = false, className, ...rest } = props;
    const token = useToken();

    const getToken = useCallback(() => token, [token]);

    if (!url) {
        console.error(`url property does not provided for camera ${id}`);
        return null;
    }

    return (
        <div className={className}>
            <VideoPlayer id={id} url={url} token={getToken} autoplay={autoplay} {...rest} />
        </div>
    );
};

export const CameraVideoPlayer = memo(CameraVideoPlayerComponent);
