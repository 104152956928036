import { REACT_APP_API_SERVICE_WAREHOUSE } from "env-data";
import { BaseApi } from "api/utils";

export class WarehouseAPI extends BaseApi {
    baseUrl = REACT_APP_API_SERVICE_WAREHOUSE;

    protected buildUrl(url: string) {
        return `${this.baseUrl}${url}`;
    }
}
