import { ControlModeCode } from "../enums";
import { CodeDictionary } from "../types";

export const controlModeCodeDictionaryMap: { [key in ControlModeCode]: CodeDictionary<ControlModeCode> } = {
    [ControlModeCode.LocalPlan]: {
        code: ControlModeCode.LocalPlan,
        name: "Локальный режим",
        shortName: "ЛР",
        color: "#07C7A4",
    },
    [ControlModeCode.Adaptive]: {
        code: ControlModeCode.Adaptive,
        name: "Адаптивное управление",
        shortName: "А",
        color: "#3D8AFF",
    },
    [ControlModeCode.Group]: {
        code: ControlModeCode.Group,
        name: "Координированное управление",
        shortName: "КУ",
        color: "#B12AD2",
    },
    [ControlModeCode.User]: {
        code: ControlModeCode.User,
        name: "Диспетчерское управление",
        shortName: "УУ",
        color: "#591FFF",
    },
    [ControlModeCode.Console]: {
        code: ControlModeCode.Console,
        name: "Ручное управление",
        shortName: "РУ",
        color: "#031C9B",
    },
    [ControlModeCode.Unknown]: {
        code: ControlModeCode.Unknown,
        name: "Нет данных",
        shortName: "НД",
        color: "#C4C4C4",
    },
};

export const controlModeCodeDictionaryList = Object.values(controlModeCodeDictionaryMap);
