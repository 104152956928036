import { LngLatLike } from "mapbox-gl";
import type { TrafficInput, RouteCosting, TrafficUse } from "./route-input.types";

export class RouteInput {
    /** Локации, через которые должен пройти маршрут */
    locations: LngLatLike[];
    trafficTypes: TrafficInput;
    costing: RouteCosting;
    zoom?: number;
    trafficUse?: TrafficUse;
    /** С учетом ДТП и перекрытий */
    withIncidents?: boolean;
    useTruckGraph?: boolean;
    trafficTime?: string;
    /** Сохранять ли маршрут для анализа участка */
    saveRoute?: boolean;
    /**
     * Тип транспорта в маршруте
     * 0 - строит маршрут с метро и автобусами
     * 1 - только автобусы
     * 2 - только метро
     */
    transitMode?: number;

    constructor(params: RouteInput) {
        this.locations = params.locations;
        this.trafficTime = params.trafficTime;
        this.zoom = params.zoom;
        this.trafficTypes = params.trafficTypes;
        this.trafficUse = params.trafficUse;
        this.withIncidents = params.withIncidents;
        this.useTruckGraph = params.useTruckGraph;
        this.costing = params.costing;
        this.saveRoute = params.saveRoute;
        this.transitMode = params.transitMode;
    }
}
