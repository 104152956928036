import styled from "styled-components";
import { Typography } from "@megapolis/uikit/typography";
import * as Units from "./units";

export const Icon = styled.div`
    user-select: none;
    pointer-events: none;
`;

export const Title = styled.div`
    padding: ${({ theme }) => `0 ${theme.spacings[5].str} 0 ${theme.spacings[3].str}`};
`;

export const Number = styled(Typography.H4)`
    display: flex;
    gap: ${({ theme }) => theme.spacings[1].str};
    flex-direction: row;
    align-items: center;
`;

export const NumberCaption = styled.span`
    white-space: nowrap;
`;

export const NumberValue = styled.span`
    display: block;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const NumberPlaceholder = styled(NumberValue)`
    display: block;
    text-shadow: ${({ theme }) => `0 0 ${theme.spacings[2].str} ${theme.colors.black.basic}`};
    color: transparent;
`;

export const Address = styled(Typography.Text3)`
    max-width: 100%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.content.secondary};
`;

export const AddressPlaceholder = styled(Address)`
    display: block;
    text-shadow: ${({ theme }) => `0 0 ${theme.spacings[2].str} ${theme.colors.black.basic}`};
    color: transparent;
    padding: 0 3px;
`;

export const Actions = styled(Units.Actions)``;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 32px 264px 104px;

    * {
        user-select: none;
    }
`;
