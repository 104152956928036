import { memo, useCallback, useEffect, useRef, useState } from "react";
import { reduceTime, prepareTimes, getInitialTimes } from "./timer.utils";
import * as Markup from "./timer.styles";
import { Props, Times } from "./timer.types";

const TimerComponent = ({ duration, ...formatOptions }: Props) => {
    const [times, setTimes] = useState<Times>(() => getInitialTimes(duration));

    const interval = useRef<NodeJS.Timeout | null>(null);

    const clearInterval = useCallback(() => {
        if (!interval.current) return;
        global.clearInterval(interval.current);
        interval.current = null;
    }, []);

    useEffect(() => {
        setTimes(getInitialTimes(duration));
    }, [duration]);

    useEffect(() => {
        if (interval.current) clearInterval();
        interval.current = setInterval(() => setTimes(reduceTime), 1000);
        return clearInterval;
    }, [clearInterval]);

    useEffect(() => {
        if (times.hours === 0 && times.minutes === 0 && times.seconds === 0) {
            clearInterval();
        }
    }, [times, clearInterval]);

    return <Markup.Container>{prepareTimes(times, formatOptions)}</Markup.Container>;
};

export const Timer = memo(TimerComponent);
