import { memo, useCallback, useMemo } from "react";
import { useCalendarContext } from "../../calendar-context";
import { Month } from "./month";
import { Grid } from "./months.styles";

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const Months = memo(() => {
    const { date, setCurrentMonth, setMonthView } = useCalendarContext().viewState;

    const handleClick = useCallback(
        (value: Date) => {
            setCurrentMonth(value);
            setMonthView();
        },
        [setCurrentMonth, setMonthView]
    );

    const renderedMonths = useMemo(() => {
        const activeMonthNum = date.getMonth();
        return months.map((monthNum) => {
            const monthDate = new Date(date);
            monthDate.setMonth(monthNum);
            const isActive = monthNum === activeMonthNum;
            return <Month key={monthDate.toString()} value={monthDate} onClick={handleClick} isActive={isActive} />;
        });
    }, [date, handleClick]);

    return <Grid>{renderedMonths}</Grid>;
});
