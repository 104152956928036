import styled from "styled-components";
import { Typography } from "@megapolis/uikit/typography";

export const Container = styled.div`
    display: grid;
    gap: ${({ theme }) => theme.spacings[3].str};
    grid-template-columns: 368px 24px;
`;

export const Message = styled(Typography.Text1).attrs({ as: "div" })`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.colors.red.basic};
`;

export const Close = styled.div``;
