import { Point } from "./polygon.types";

export class Polygon {
    public fillStyle: string = "";
    public points: Point[] = [];

    constructor(points: Point[], fillStyle: string) {
        this.points = points;
        this.fillStyle = fillStyle;
    }
}
