import { useCallback } from "react";
import { Button } from "@megapolis/uikit/button";
import { SearchInput } from "../search-input";
import * as Markup from "./search-field.styles";

type Props = {
    value?: string;
    className?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    hasError?: boolean;
    errorText?: string;
    onChange?: (value: string) => void;
    onCancel?: () => void;
};

export const SearchField = (props: Props) => {
    const { onChange } = props;

    const handleChange = useCallback((value: string) => onChange?.(value), [onChange]);

    const handleClear = useCallback(() => onChange?.(""), [onChange]);

    return (
        <Markup.Container>
            <SearchInput
                value={props.value ?? ""}
                isDisabled={props.isDisabled}
                isLoading={props.isLoading}
                hasError={props.hasError}
                errorText={props.errorText}
                onChange={handleChange}
                onClear={handleClear}
            />
            <Button content={Button.Content.Text} priority="quaternary" text="Отменить" onClick={props.onCancel} />
        </Markup.Container>
    );
};
