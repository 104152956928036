import { makePath2DDrawer } from "../drawing.common";
import { DrawRectProps } from "./rect";

export interface DrawRoundedRectProps extends DrawRectProps {
    radius: number;
}

export const drawRoundedRect = makePath2DDrawer<DrawRoundedRectProps>((ctx, props) => {
    const { x, y, width, height } = props;
    const rad = props.radius;
    const path = new Path2D();

    // top line
    path.moveTo(x + rad, y);
    path.lineTo(x + width - rad, y);

    // top right corner
    path.arcTo(x + width, y, x + width, y + rad, rad);

    // right line
    path.lineTo(x + width, y + height - rad);

    // bottom right corner
    path.arcTo(x + width, y + height, x + width - rad, y + height, rad);

    // bottom line
    path.lineTo(x + width - rad, y + height);

    // bottom left corner
    path.arcTo(x, y + height, x, y + height - rad, rad);

    // left line
    path.lineTo(x, y + height - rad);

    // top left corner
    path.arcTo(x, y, x + rad, y, rad);

    path.closePath();
    if (props.fillColor) {
        ctx.fillStyle = props.fillColor;
        ctx.fill(path);
    }
    if (props.border) {
        ctx.lineWidth = props.border.width;
        ctx.strokeStyle = props.border.color;
        ctx.stroke(path);
    }
    return path;
});
