export enum DirectionTypeCode {
    /** Транспортное */
    Transport = 0,
    /** Поворотное */
    Turn = 1,
    /** Пешеходное */
    Pedestrian = 2,
    /** Велосипедное */
    Bicycle = 3,
    /** Общественный транспорт */
    PublicTransport = 4,
    /** Транспортное и поворотное */
    TransportAndTurn = 5,
}
