import { ComponentProps, memo } from "react";
import { StackHeader } from "@megapolis/uikit/stack-header";
import styled from "styled-components/macro";

const WrappedStackHeader = styled(StackHeader.Small)`
    > div:first-child {
        display: flex;
        flex-grow: 1;
    }
`;

export const ListStackHeader = memo((props: ComponentProps<typeof StackHeader.Small>) => (
    <WrappedStackHeader
        className={props.className}
        title={props.title}
        secondTitle={props.secondTitle}
        info={props.info}
        left={props.left}
        right={props.right}
        bottomCaption={props.bottomCaption}
        upperCaption={props.upperCaption}
    />
));
