import mapboxgl from "mapbox-gl";
import { mapConstants } from "shared/constants";
import { VisualizationEquipmentOnMap } from "lib/visualization-equipment-on-map";
import * as units from "./units";
import * as consts from "./warehouse-detector-controller.consts";

export class WarehouseDetectorController extends VisualizationEquipmentOnMap {
    constructor(map: mapboxgl.Map) {
        super(
            map,
            {
                id: mapConstants.LAYER_IDS.warehouseDetector,
                iconPath: consts.iconPath,
                storageKey: consts.storageKey,
                onFeatureClick: (args) => this.emitter.emit(this.emitter.events.OpenShortCard, args),
                cypressVisibilityChange: "cypress__warehouse__detector__layer__visibility",
                cypressSourceDataLoaded: "cypress__warehouse__detector__source__loaded",
            },
            (map, options) => new units.Behavior(map, options)
        );
    }
}
