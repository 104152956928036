import mapboxgl from "mapbox-gl";
import { BooleanStorageManager } from "lib";
import { CooGroupDomain } from "app-domain";
import RouteArrowIcon from "assets/icons/route-arrow-icon.svg";
import { ILayerController } from "../layer-controller";
import { CooGroupLayer } from "./coo-group-layer";
import { RoutePointsMarker } from "./markers";
import { storageKey } from "./coo-group-controller.constants";
import { pointsMapper } from "./coo-group-controller.utils";
import { mapConstants } from "shared/constants";

export class CooGroupController implements ILayerController {
    private isLayerVisible = new BooleanStorageManager(storageKey);
    private layer: CooGroupLayer;
    private routePointsMarker: RoutePointsMarker;
    private cooGroups: CooGroupDomain.CooGroup[] = [];

    constructor(private readonly map: mapboxgl.Map) {
        this.initIcons();
        this.layer = new CooGroupLayer(map, {
            id: mapConstants.LAYER_IDS.cooGroup,
            isVisible: this.isLayerVisible.value,
            beforeId: mapConstants.LAYER_IDS.trafficLight,
        });
        this.routePointsMarker = new RoutePointsMarker(map);
    }

    public setCooGroups = (data: CooGroupDomain.CooGroup[]) => {
        this.cooGroups = data;
        this.layer.setCooGroups(data);
    };

    public readonly getVisibility = () => {
        return this.isLayerVisible.value;
    };

    public readonly setVisibility = async (isVisible: boolean) => {
        this.isLayerVisible.value = isVisible;
        this.layer.setVisibility(isVisible);
    };

    public readonly destroy = () => {
        this.routePointsMarker.destroy();
        this.layer.destroy();
    };

    public setActive = (id: number) => {
        if (!this.cooGroups.length) return;
        this.layer.setActive(id);
        const active = this.cooGroups.find((cooGroup) => cooGroup.id === id);
        const points = active?.points.map(pointsMapper) ?? [];
        this.routePointsMarker.setData([points]);
    };

    public resetActive() {
        this.layer.resetActive();
        this.routePointsMarker.setData([]);
    }

    private initIcons = () => {
        const imageArrow = new Image();
        imageArrow.src = RouteArrowIcon;
        imageArrow.onload = () => {
            this.map.addImage("actualRouteArrow", imageArrow);
        };
    };
}
