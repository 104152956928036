import React from "react";
import * as Units from "./units";
import * as Markup from "./info.styles";
import type { Props } from "./info.types";

export const Info = React.memo((props: Props) => {
    if (!Array.isArray(props.indicators) || !props.indicators.length) return null;

    return (
        <Markup.Container className={props.className} data-cypress={props.dataCypress}>
            {props.indicators?.map((indicator) => (
                <Units.Indicator
                    key={indicator.uid}
                    caption={indicator.caption}
                    value={indicator.value}
                    units={indicator.units}
                    color={indicator.color}
                    isLoaded={props.isLoaded}
                    dataCypress={props.dataCypress && `${props.dataCypress}-${indicator.uid}`}
                />
            ))}
        </Markup.Container>
    );
});
