import { memo } from "react";
import { TrafficLightDomain } from "app-domain";
import { InfoCard, DateIntervalTimer } from "components";
import { Utils, HOC } from "shared";
import { Container } from "./session-info.styles";

const { DateUtils } = Utils;

type Props = {
    createdAt?: Date;
    userName?: string;
    command?: TrafficLightDomain.GovernanceCommand | null;
};

const SessionInfoComponent = (props: Props) => {
    const { createdAt, command, userName } = props;

    if (!createdAt) return null;

    const startDate = DateUtils.format(createdAt, { format: DateUtils.Formats.DateTime });
    const formattedCommandStart = DateUtils.format(command?.start, { format: DateUtils.Formats.DateTime });
    const formattedExpireDate = DateUtils.format(command?.expiresAt, { format: DateUtils.Formats.DateTime });

    const runningDurationCaption =
        formattedCommandStart && formattedExpireDate ? (
            <span>
                с {formattedCommandStart} по {formattedExpireDate}
            </span>
        ) : null;

    const openedUserCaption = (
        <span>
            {userName} с {startDate}
        </span>
    );

    return (
        <Container>
            {userName && <InfoCard title="Пульт открыт" size="small" caption={openedUserCaption} />}
            {command && <InfoCard title="Команда" size="small" caption={command.text} />}
            {runningDurationCaption && <InfoCard title="Выполняется" size="small" caption={runningDurationCaption} />}
            {command?.expiresAt && (
                <HOC.ErrorBoundary>
                    <InfoCard
                        size="small"
                        title="Таймер"
                        caption={<DateIntervalTimer start={new Date()} end={command.expiresAt} hideZeroHours />}
                    />
                </HOC.ErrorBoundary>
            )}
        </Container>
    );
};

export const SessionInfo = memo(SessionInfoComponent);
