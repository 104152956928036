import { lightTheme } from "@megapolis/uikit/theme";
import { Detector } from "app-domain";

/** Время показателей */
export const date: Detector.Models.Indicator = { uid: "date", caption: "Время показателей", units: "" };

/** Скорость */
export const speed: Detector.Models.Indicator = {
    uid: "speed",
    caption: "Скорость",
    units: " км/ч",
    color: lightTheme.colors.green.d1,
    secondaryColor: lightTheme.colors.green.l3,
};

/** Интенсивность */
export const volume: Detector.Models.Indicator = {
    uid: "volume",
    caption: "Интенсивность",
    units: " ед/ч",
    color: lightTheme.colors.orange.basic,
    secondaryColor: lightTheme.colors.orange.l3,
};

/** Загруженность */
export const utilization: Detector.Models.Indicator = {
    uid: "utilization",
    caption: "Загруженность",
    units: "%",
    color: lightTheme.colors.teal.basic,
    secondaryColor: lightTheme.colors.teal.l3,
};

/** Занятость */
export const occ: Detector.Models.Indicator = {
    uid: "occ",
    caption: "Занятость",
    units: "%",
    color: lightTheme.colors.purple.basic,
    secondaryColor: lightTheme.colors.purple.l3,
};
