import mapboxgl from "mapbox-gl";
import { mapConstants } from "shared/constants";
import { TrafficScoreDomain } from "app-domain";
import { APIRegistry } from "api";
import { BooleanStorageManager } from "lib";
import { DtmTrafficLayer } from "./dtm-traffic-layer";
import { getDataTypeByRange, getTileUrl } from "./dtm-traffic-controller.utils";

const minuteTimestamp = 60000;

export class DtmTrafficController {
    /** id слоя */
    private id = mapConstants.LAYER_IDS.dtmTraffic;
    /** url для подгрузки тайлов */
    private tileUrl: string = "";
    /** режим отображения слоя */
    private viewMode: TrafficScoreDomain.ViewMode = TrafficScoreDomain.ViewMode.LastPeriod;
    /** дата начала */
    private from: NullableDate = null;
    /** дата окончния */
    private to: NullableDate = null;
    /** признак видемости слоя */
    private booleanStorageManager: BooleanStorageManager = new BooleanStorageManager("dtm_traffic_visibility");
    private isInitialized: boolean = false;

    constructor(private map: mapboxgl.Map) {
        this.init();
        this.initBindings();
    }

    public getVisibility() {
        return this.booleanStorageManager.value;
    }

    public setVisibility(visibility: boolean) {
        this.booleanStorageManager.value = visibility;
        this.map.setLayoutProperty(this.id, "visibility", this.getVisibilityProperty(visibility));
        if (!this.isInitialized) this.init();
    }

    public setViewMode(viewMode: TrafficScoreDomain.ViewMode) {
        this.viewMode = viewMode;

        this.updateSource();
    }

    public setRange(timestamp: NullableNumber, split: TrafficScoreDomain.Split) {
        this.from = timestamp ? new Date(timestamp) : null;
        this.to = timestamp ? new Date(timestamp + split * minuteTimestamp) : null;

        this.updateSource();
    }

    public destroy() {
        this.unSubscribeMapEvents();
        this.isInitialized = false;
    }

    private initBindings() {
        this.setRange.bind(this);
        this.getVisibility.bind(this);
        this.destroy.bind(this);
        this.setViewMode.bind(this);
        this.setVisibility.bind(this);
    }

    private async init() {
        if (!this.getVisibility()) return;
        const range = await this.loadLastRange();
        this.setTileUrl(range.from, range.to);
        this.addSource();
        this.addLayer();
        this.subscribeMapEvents();
        this.isInitialized = true;
    }

    private async updateSource() {
        if (this.viewMode === TrafficScoreDomain.ViewMode.LastPeriod) {
            const range = await this.loadLastRange();
            this.setTileUrl(range.from, range.to);
        }

        if (this.viewMode === TrafficScoreDomain.ViewMode.Range && this.from && this.to) {
            this.setTileUrl(this.from, this.to);
        }

        const source = this.map.getSource(mapConstants.LAYER_IDS.dtmTraffic) as mapboxgl.VectorSourceImpl;

        if (!source) return;

        source.setTiles([this.tileUrl]);
    }

    private readonly handleStyleLoad = () => {
        this.addSource();
        this.addLayer();
    };

    private setTileUrl(from: Date, to: Date) {
        const dataType = getDataTypeByRange(from, to);
        this.tileUrl = getTileUrl({ from: from.toISOString(), to: to.toISOString(), dataType });
    }

    private addSource() {
        if (this.map.getSource(this.id) || !this.tileUrl) return;

        this.map.addSource(this.id, {
            type: "vector",
            tiles: [this.tileUrl],
        });
    }

    private addLayer() {
        if (this.map.getLayer(this.id) || !this.map.getSource(this.id)) return;

        this.map.addLayer(
            new DtmTrafficLayer(this.id, this.getVisibilityProperty(this.booleanStorageManager.value)),
            mapConstants.LAYER_IDS.cooGroup
        );
    }

    private getVisibilityProperty(visibility: boolean): mapboxgl.Visibility {
        return visibility ? "visible" : "none";
    }

    private subscribeMapEvents() {
        this.map.on("styledata", this.handleStyleLoad);
    }

    private unSubscribeMapEvents() {
        this.map.off("styledata", this.handleStyleLoad);
    }

    private async loadLastRange() {
        const range = await APIRegistry.dtmTrafficAPI.getLastPeriod();
        return { from: new Date(range.start), to: new Date(range.finish) };
    }
}
