import styled, { css } from "styled-components";

interface ContainerProps {
    width?: string;
    height?: string;
}

export const Container = styled.div<ContainerProps>(
    ({ width, height, theme: { spacings, borderRadiuses, colors } }) =>
        css`
            display: flex;
            flex-direction: column;
            width: ${width ? width : "fit-content"};
            gap: ${spacings[1].str};
            padding: ${spacings[3].str};
            border-radius: ${borderRadiuses[4].str};
            background-color: ${colors.white.basic};
            box-shadow: 0 0 1px rgba(26, 32, 36, 0.32), 0 12px 24px rgba(91, 104, 113, 0.24);
        `
);
