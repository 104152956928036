import { CanvasSVG } from "lib";
import { Drawer } from "diagrams/drawers";
import SplitterIconURL from "diagrams/icons/splitter.svg";
import StartAnchorSplitterIconURL from "diagrams/icons/start-anchor-splitter.svg";
import StartAnchorSplitterLargeIconURL from "diagrams/icons/start-anchor-splitter-large.svg";
import SplitterLargeIconURL from "diagrams/icons/splitter-large.svg";
import { SplitterIconsData } from "./splitter-icons-drawer.types";

export class SplitterIconsDrawer extends Drawer<SplitterIconsData> {
    private splitterIcon = new CanvasSVG(SplitterIconURL);
    private splitterLargeIcon = new CanvasSVG(SplitterLargeIconURL);
    private startAnchorSplitterIcon = new CanvasSVG(StartAnchorSplitterIconURL);
    private startAnchorSplitterLargeIcon = new CanvasSVG(StartAnchorSplitterLargeIconURL);

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.initIcons();
    }

    protected draw = () => {
        if (!this.data) return;

        for (let coords of this.data.coords) {
            const largeIcon = coords.isStart ? this.startAnchorSplitterLargeIcon : this.splitterLargeIcon;
            const baseIcon = coords.isStart ? this.startAnchorSplitterIcon : this.splitterIcon;

            const icon = this.data.isLarge ? largeIcon : baseIcon;

            if (!icon.img) return;

            this.ctx.drawImage(icon.img, coords.x, coords.y);
        }
    };

    private initIcons() {
        this.splitterIcon.load();
        this.splitterLargeIcon.load();
        this.startAnchorSplitterIcon.load();
        this.startAnchorSplitterLargeIcon.load();
    }
}
