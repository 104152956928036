import { CooGroupDomain } from "app-domain";
import { FormTextField } from "lib";
import { action, computed, makeObservable, observable } from "mobx";

export abstract class Form {
    public name: FormTextField = new FormTextField();
    public originCycle: CooGroupDomain.Cycle | null = null;

    constructor() {
        makeObservable(this, {
            originCycle: observable,
            name: observable.ref,
            cycle: computed,
            reset: action,
            resetName: action,
        });
    }

    public get cycle(): CooGroupDomain.Cycle | null {
        return this.originCycle;
    }

    public setCycle(cycle: CooGroupDomain.Cycle) {
        this.originCycle = cycle;
    }

    public reset() {
        this.resetName();
    }

    public resetName() {
        this.name = new FormTextField();
    }
}
