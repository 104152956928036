import { memo } from "react";
import { REACT_APP_CDN_FILES } from "env-data";
import { Image } from "./monitoring.styles";
import { Props } from "./monitoring.types";

export const Monitoring = memo((props: Props) => {
    const src = `${REACT_APP_CDN_FILES}/map-icons/${props.prefix}/${props.prefix}-${props.statusByIssue ?? ""}-${
        props.monitoringStatus ?? ""
    }.png`;

    return <Image width="32" height="32" src={src} />;
});
