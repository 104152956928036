export enum SearchEntityType {
    /** Светофорный объект */
    TrafficLight = 0,
    /** Лента координации */
    CooGroup = 1,
    /** Камера теле-обзора  */
    TVCamera = 2,
    /** Камера фото-видео фиксации */
    FixationCamera = 3,
    /** Камера ЕЦХД */
    ECHDCamera = 4,
    /**  Детектор */
    Detector = 5,
    /** Табло отображения информации */
    VisualMessageBoard = 6,
}
