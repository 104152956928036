import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    /* padding: ${({ theme }) => theme.blockHeader}; */
    gap: ${({ theme }) => theme.blockHeader.gap};
    overflow: hidden;
    width: 100%;
`;

export const Content = styled.div((props) => {
    const {
        theme: { spacings },
    } = props;

    return css`
        display: flex;
        align-items: center;
        margin: ${spacings[1].str} 0;
        color: ${({ theme }) => theme.blockHeader.primaryColor};
        flex-grow: 1;
        overflow: hidden;
    `;
});

export const Right = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
`;
