import { useCallback } from "react";
import { Routing } from "routing";
import { useAppState } from "./use-app-state";

export const useSelectTrafficLight = () => {
    const { mapController, trafficLightStore } = useAppState();
    const navigate = Routing.TrafficLights.Hooks.useTrafficLightNavigator();

    return useCallback(
        (id: number, choose = false) => {
            const trafficLight = trafficLightStore.getById(id);
            if (!trafficLight) return;

            navigate({ type: "traffic-lights", id: String(trafficLight.id) });

            if (choose) {
                trafficLightStore.chooseItem(id);
                mapController.flyManager.handleTrafficLightPosition(trafficLight);
            } else trafficLightStore.toggleSelection(id);
        },
        [mapController, trafficLightStore, navigate]
    );
};
