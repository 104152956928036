export type TrafficLightAnalyticsInfo = {
    percent: number;
    direction?: number;
    phase?: number;
    seconds: number;
    status?: number;
    type: "phase" | "status";
};

export type TrafficLightAnalytics = {
    /** Дата */
    period: number;
    /** Аналитическая информация */
    info: TrafficLightAnalyticsInfo[];
};

export type TrafficLightAnalyticsError = {
    /** Ошибка */
    error: string;
};

export enum AnalyticsSplit {
    phases5m = "min5",
    phases15m = "min15",
    phases30m = "min30",
    phasesDay = "day",
    phasesHour = "hour",
}

export type AnalyticsBySplit = Record<AnalyticsSplit, TrafficLightAnalytics[]>;
