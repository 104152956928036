import { action, computed, makeAutoObservable } from "mobx";

export class CodeListFilter<T extends number | string = number> {
    private _items = new Set<T>();

    constructor() {
        makeAutoObservable(this, {
            toggleItem: action.bound,
            isEmpty: computed,
        });
    }

    public get list() {
        return Array.from(this._items);
    }

    public has(code: T) {
        return this._items.has(code);
    }

    public toggleItem(code: T) {
        if (this._items.has(code)) {
            this._items.delete(code);
            return;
        }
        this._items.add(code);
    }

    public setItem(code: T) {
        this._items = new Set<T>([code]);
    }

    public get isEmpty() {
        return this._items.size === 0;
    }

    public reset() {
        this._items.clear();
    }
}
