import { Coordinates, Corner, DrawOptions } from "./rounded-triangle.types";

/* Линейная интерполяция **/
const lerp = (a: number, b: number, x: number) => a + (b - a) * x;

const getPointsDistance = (p1: Coordinates, p2: Coordinates) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

const lerp2D = (p1: Coordinates, p2: Coordinates, t: number) => ({
    x: lerp(p1.x, p2.x, t),
    y: lerp(p1.y, p2.y, t),
});

export const prepareCoordinates = ({ points, radius = 1 }: DrawOptions): Corner[] => {
    const pointsCount = points.length;
    return points.map((point, index) => {
        const currentPoint = points[(index + 1) % pointsCount];
        const nextPoint = points[(index + 2) % pointsCount];

        const lastEdgeLength = getPointsDistance(point, currentPoint);
        const lastOffsetDistance = Math.min(lastEdgeLength / 2, radius);
        const start = lerp2D(currentPoint, point, lastOffsetDistance / lastEdgeLength);

        const nextEdgeLength = getPointsDistance(nextPoint, currentPoint);
        const nextOffsetDistance = Math.min(nextEdgeLength / 2, radius);
        const end = lerp2D(currentPoint, nextPoint, nextOffsetDistance / nextEdgeLength);

        return [start, currentPoint, end];
    });
};

export const drawCorners = (ctx: CanvasRenderingContext2D, corners: Corner[]) => {
    for (const [start, ctrl, end] of corners) {
        ctx.lineTo(start.x, start.y);
        ctx.quadraticCurveTo(ctrl.x, ctrl.y, end.x, end.y);
    }
};
