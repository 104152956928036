import { Detector } from "app-domain";
import { equipmentStatus } from "shared/constants";

export class OptionsDTO implements Detector.Models.FullCard.Options {
    /** Этап жизненного цикла */
    public readonly lifeCycleName: string = "нет данных";

    /** Координаты */
    public readonly coordinates: string = "нет данных";

    /** Состояние по заявкам */
    public readonly statusIssue: Detector.Models.FullCard.Options["statusIssue"] = {
        /** Наименование */
        name: "нет данных",
        /** Цвет */
        color: "#000000",
    };

    public readonly statusMonitoring: Detector.Models.FullCard.Options["statusMonitoring"] = {
        /** Наименование состояния по мониторингу */
        name: "нет данных",
        /** Цвет состояния по мониторингу */
        color: "#000000",
    };

    constructor(data?: any) {
        this.lifeCycleName = data?.stageLifecycle?.name ?? this.lifeCycleName;

        if (typeof data?.lng === "number" && typeof data?.lat === "number") {
            this.coordinates = `${Math.round(data.lng * 10000) / 10000}, ${Math.round(data.lat * 10000) / 10000}`;
        }

        if (typeof data?.assetStatusId === "number") {
            this.statusIssue = {
                id: data.assetStatusId,
                name: equipmentStatus.getStatusNameOnIssueId(data.assetStatusId),
                color: equipmentStatus.getStatusColorOnIssueId(data.assetStatusId),
            };
        }

        if (typeof data?.monitoringStatusId === "number") {
            this.statusMonitoring = {
                id: data.monitoringStatusId,
                name: equipmentStatus.getStatusNameOnMonitoringId(data.monitoringStatusId),
                color: equipmentStatus.getStatusColorOnMonitoringId(data.monitoringStatusId),
            };
        }
    }
}
