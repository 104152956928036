import { memo, MouseEventHandler } from "react";
import { Button } from "@megapolis/uikit/button";
import { Icons } from "assets";

const CollapseIcon = (
    <div title="Свернуть">
        <Icons.CollapseIcon />
    </div>
);
const ExpandIcon = (
    <div title="Развернуть">
        <Icons.ExpandIcon />
    </div>
);

type Props = {
    isCollapsed?: boolean;
    onClick?: MouseEventHandler;
};

const CollapseExpandButtonComponent = (props: Props) => (
    <Button
        content={Button.Content.Icon}
        priority="quaternary"
        icon={props.isCollapsed ? ExpandIcon : CollapseIcon}
        size="s"
        onClick={props.onClick}
    />
);

export const CollapseExpandButton = memo(CollapseExpandButtonComponent);
