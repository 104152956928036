export abstract class Drawer<T> {
    protected data: T | null = null;

    constructor(protected ctx: CanvasRenderingContext2D) {}

    public setData = (data: T): void => {
        this.data = data;
        this.draw();
    };

    protected abstract draw(): void;
}
