import { LngLat } from "mapbox-gl";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightPhase } from "trafficlight-dispatcher/models";
import { BBoxSize, TrafficLightLayer, Utils as MapUtils } from "./traffic-light-layer";
import Bounds from "./bounds";
import DirectionVisual from "./direction-visual";

function roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();
}

interface Point {
    x: number;
    y: number;
}

type AttrArray = [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
];

type FirstDrawHandler = (visual: TrafficLightVisual) => void;

type TrafficLightVisualProps = {
    trafficLight: TrafficLightDomain.TrafficLight;
    owner: TrafficLightLayer;
    isActive: boolean;
    onFirstDraw: FirstDrawHandler;
};

type Directions = Record<number, DirectionVisual>;

export default class TrafficLightVisual {
    public id: number;
    public trafficLight: TrafficLightDomain.TrafficLight;
    public isActive: boolean = false;
    public owner: TrafficLightLayer;
    public directions: Directions;
    public center: Point;
    public direction!: DirectionVisual;
    public bounds!: Bounds;
    public posArray: AttrArray;
    public spriteKey: number = 0;
    public isHistoricalEventViewMode = false;
    public eventData: Nullable<TrafficLightDomain.HistoryEvent> = null;
    private onFirstDraw: FirstDrawHandler;
    private _isInitialized: boolean;
    private _isClipped: boolean = false;

    constructor(props: TrafficLightVisualProps) {
        this.id = props.trafficLight.id;
        this.trafficLight = props.trafficLight;
        this.isActive = props.isActive;
        this.onFirstDraw = props.onFirstDraw;
        this.owner = props.owner;
        this.directions = {};
        this.center = MapUtils.project(this.trafficLight.location || new LngLat(55, 37));
        this.posArray = this.getPoaArray(1);
        this._updateBounds();
        this._isInitialized = false;
    }

    public getPoaArray(opacity: number): AttrArray {
        const cx = this.center.x;
        const cy = this.center.y;

        return [cx, cy, -1, -1, opacity, cx, cy, 1, -1, opacity, cx, cy, 1, 1, opacity, cx, cy, -1, 1, opacity];
    }

    _onTrafficLightInitialized() {
        const trafficLight = this.trafficLight;
        this.directions = {};
        if (trafficLight.directions) {
            for (let i = 0, len = trafficLight.directions.length; i < len; i++) {
                const direction = new DirectionVisual(this, trafficLight.directions[i]);
                this.directions[direction.num] = direction;
                if (direction.type !== 2) this.direction = direction;
            }
        }
        this._isInitialized = true;
    }

    _updateBounds() {
        const cx = this.center.x;
        const cy = this.center.y;
        const size = BBoxSize * this.owner.zoomScale!;
        const x1: number = cx - size;
        const x2: number = cx + size;
        const y1: number = cy - size;
        const y2: number = cy + size;

        if (this.bounds) {
            this.bounds.set(x1, y1, x2, y2);
        } else {
            this.bounds = new Bounds(x1, y1, x2, y2);
        }

        this._isClipped = !this.bounds.intersects(this.owner.bounds!);
    }

    getFocusArray(o: number): AttrArray {
        const cx = this.center.x;
        const cy = this.center.y;
        return [cx, cy, -2, -2, o, cx, cy, 2, -2, o, cx, cy, 2, 2, o, cx, cy, -2, 2, o];
    }

    _drawPhase(ctx: CanvasRenderingContext2D, x: number, y: number, size: number) {
        //ctx.globalAlpha = .5;
        const tl = this.trafficLight;
        if (!tl.currentPhase) return;
        const isSubTact = tl.isSubTact;
        const isBlink = this.owner.isBlink;
        const phaseNumber =
            this.isHistoricalEventViewMode && this.eventData ? this.eventData.phase : tl.currentPhase.phase.num;
        const phase = tl.phases[phaseNumber];

        if (phase === undefined) return;

        if (phase.directions && phase.directions.length) {
            const blinkDirections: Record<number, boolean> = {};
            if (!this.isHistoricalEventViewMode && isSubTact && isBlink) {
                let nextPhase: TrafficLightPhase;
                if (tl.currentPhase!.phase.next) nextPhase = tl.phases[tl.currentPhase!.phase.next];
                if (nextPhase! !== undefined) {
                    for (let i = 0, len = phase.directions.length; i < len; i++) {
                        if (nextPhase.directionByNum[phase.directions[i]] === undefined)
                            blinkDirections[phase.directions[i]] = true;
                    }
                }
            }

            for (let i = 0, len = phase.directions.length; i < len; i++) {
                const direction = this.directions[phase.directions[i]];

                if (direction !== undefined && !(isSubTact && isBlink && blinkDirections[direction.num] !== undefined))
                    direction.draw(ctx, x, y, size);
            }
        }
        //ctx.globalAlpha=1
    }

    bringToFront() {
        const visuals = this.owner.visuals;
        visuals.set(this.id, this);
    }

    isVisible() {
        return this.isActive || (!this._isClipped && this.owner.visibility === "visible");
    }

    draw(ctx: CanvasRenderingContext2D, x: number, y: number, size: number, now: number) {
        const tl = this.trafficLight;
        const scale = size / 1024;
        const bearing = (Math.PI * this.owner.map.getBearing()) / 180;
        let phasePercentage = 0;
        if (!this._isInitialized) {
            if (tl.isInitialized) this._onTrafficLightInitialized();
            else this.onFirstDraw(this);
        }

        const timer = tl.timer;
        const subTact = tl.isSubTact;
        if (tl.currentPhase) {
            phasePercentage = Math.min(1, Math.max(0, 1 - timer / tl.currentPhase.phase.duration));
        }

        // const modeDisplay: string = this.trafficLight.controlMode.shortName;
        const cx = size / 2;
        const cy = size / 2;
        const circleSize = cx * 0.4;
        const angle = 2 * Math.PI * phasePercentage;

        ctx.save();
        ctx.translate(x + cx, y + cy);
        ctx.rotate(bearing);
        ctx.translate(-x - cx, -y - cy);

        ctx.strokeStyle = "#9c9c9c";
        ctx.fillStyle = "rgba(20,20,20,0.05)";
        ctx.lineWidth = 2 * scale;
        ctx.beginPath();
        ctx.arc(x + cx, y + cy, circleSize - 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        if (!this.isHistoricalEventViewMode && this.isActive && angle > 0) {
            ctx.strokeStyle = "#4284f5";
            ctx.lineWidth = 6 * scale;

            ctx.beginPath();
            ctx.arc(x + cx, y + cy, circleSize - 8 * scale, -Math.PI / 2, angle - Math.PI / 2);
            ctx.stroke();

            if (subTact !== true || !this.owner.isBlink) {
                ctx.fillStyle = "rgba(100, 100, 100,.20)";
                ctx.beginPath();
                ctx.moveTo(x + cx, y + cy);
                ctx.arc(x + cx, y + cy, circleSize - circleSize * 0.05, -Math.PI / 2, angle - Math.PI / 2);
                ctx.lineTo(x + cx, y + cy);
                ctx.closePath();
                ctx.fill();
            }
        }

        ctx.restore();

        this._drawPhase(ctx, x, y, size);

        ctx.save();
        ctx.translate(x + cx, y + cy);
        ctx.rotate(bearing);
        ctx.translate(-x - cx, -y - cy);
        ctx.strokeStyle = "#ffffff";
        ctx.fillStyle = "rgba(20,20,20, .5)";
        ctx.lineWidth = 2 * scale;
        // if (typeof modeDisplay === "string") {
        //     ctx.beginPath();
        //     ctx.arc(x + cx + circleSize * 0.5, y + cy - circleSize * 0.5, size / 28, 0, Math.PI * 2);
        //     ctx.fill();
        //     ctx.stroke();
        // }

        ctx.fillStyle = tl.status.color;
        if (this.isHistoricalEventViewMode && this.eventData) {
            ctx.fillStyle = TrafficLightDomain.Constants.statusCodeDictionaryMap[this.eventData.status].color;
        }
        ctx.lineWidth = 0;
        ctx.beginPath();
        ctx.arc(x + cx - circleSize * 0.5, y + cy - circleSize * 0.5, size / 28 - 7 * scale, 0, Math.PI * 2);
        ctx.fill();

        ctx.strokeStyle = tl.controlMode.color;
        if (this.isHistoricalEventViewMode && this.eventData) {
            ctx.fillStyle = TrafficLightDomain.Constants.controlModeCodeDictionaryMap[this.eventData.controlMode].color;
        }
        ctx.lineWidth = 8 * scale;
        ctx.beginPath();
        ctx.arc(x + cx - circleSize * 0.5, y + cy - circleSize * 0.5, size / 28, 0, Math.PI * 2);
        ctx.stroke();

        ctx.lineWidth = 8 * scale;
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillStyle = "#ffffff";
        ctx.font = `${40 * scale}px Arial`;

        // if (
        //     tl.currentPhase &&
        //     (subTact !== true || this.owner._isBlink !== true) &&
        //     !(tl.status.code === TrafficLightStatusCode.YellowBlink && this.owner._isBlink === true) &&
        //     typeof modeDisplay === "string"
        // ) {
        //     ctx.fillText(modeDisplay, x + cx + circleSize * 0.5, y + cy - circleSize * 0.5);
        // }

        if (this.isHistoricalEventViewMode && this.eventData) {
            ctx.fillText(`${this.eventData.phase}`, x + cx - circleSize * 0.5, y + cy - circleSize * 0.5);
        } else {
            if (tl.currentPhase) {
                ctx.fillText(
                    tl.currentPhase.phase.num.toString(),
                    x + cx - circleSize * 0.5,
                    y + cy - circleSize * 0.5
                );
            }
        }

        ctx.fillStyle = "rgba(100,100,100, .4)";
        ctx.font = `${28 * scale}px Arial`;

        const caption = `CO ${tl.num}`;
        const captionWidth = ctx.measureText(caption).width;

        roundRect(
            ctx,
            x + cx - captionWidth / 2 - 8 * scale,
            y + cy - 20 * scale + circleSize * 0.7,
            captionWidth + 16 * scale,
            38 * scale,
            8 * scale
        );

        ctx.fill();
        ctx.fillStyle = "#ffffff";
        ctx.fillText(caption, x + cx, y + cy + circleSize * 0.7);
        ctx.restore();
    }

    hitTest(lngLat: any) {
        const p = MapUtils.project(lngLat);
        const s2 = this.bounds.width / 2;
        const r = this.bounds.width * 0.2;
        const dx = p.x - this.bounds.left - s2;
        const dy = p.y - this.bounds.top - s2;
        return this.bounds.contains(p.x, p.y) && dx * dx + dy * dy < r * r;
    }

    public destroy() {
        this.trafficLight.removeListener("initialized", this._onTrafficLightInitialized);
    }

    private subscribe() {
        this.trafficLight.once("initialized", this._onTrafficLightInitialized);
    }
}
