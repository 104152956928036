import { lightTheme } from "@megapolis/uikit/theme";
import { Type } from "../enums/type";

export type TypeDictionariesType = {
    name: string;
    code: Type;
    color: string;
};

export const TypeDictionaries = {
    [Type.Active]: {
        name: "Текущая",
        code: Type.Active,
        color: lightTheme.colors.blue.basic,
    },
    [Type.GreenStreet]: {
        name: "Зеленая улица",
        code: Type.GreenStreet,
        color: lightTheme.colors.green.basic,
    },
    [Type.Coordination]: {
        name: "Координация",
        code: Type.Coordination,
        color: lightTheme.colors.purple.basic,
    },
    [Type.AdaptiveCoordination]: {
        name: "Адаптивная координация",
        code: Type.AdaptiveCoordination,
        color: lightTheme.colors.teal.basic,
    },
};

export const TypeDictionariesDictionaryList = Object.values(TypeDictionaries);
