import { TrafficLightDomain } from "app-domain";
import { trafficLightStatusList } from "trafficlight-dispatcher/models/trafficlight-status";
import { Governance, TrafficLightGovernance } from "./models";
import { TrafficLight } from "./trafficlight";

export abstract class ControllSession {
    protected constructor(governance: Governance) {
        this.id = governance.id;
        this.userName = governance.username;
        this.start = new Date(governance.start);
        this.userId = governance.profileId;
        this.expiration = governance.holdExpireDate ? new Date(governance.holdExpireDate) : undefined;
        this.update(governance);
    }

    /** Ид. сессии управления*/
    readonly id: number;

    /** Ид. пользователя*/
    userId: string;

    /** ФИО пользователя*/
    userName: string;

    /** Дата и время начала управления*/
    start: Date;

    /** Дата и время истечения времени активированного статуса*/
    expiration?: Date;

    /**Номер временного цикла */
    program?: number;

    update(governance: Governance) {
        this.program = governance.program;
        this.start = new Date(governance.start);
        this.expiration = governance.holdExpireDate ? new Date(governance.holdExpireDate) : undefined;
    }

    abstract cancel(): Promise<void>;
}

/** Информация о сессии управления СО */
export class TrafficLightControlSession extends ControllSession {
    /** Ссылка на светофорный объект*/
    readonly trafficLight: TrafficLight;

    /**Номер удерживаемой фазы */
    phase?: number;

    /**Актитивированный статус */
    status?: TrafficLightDomain.Types.CodeDictionary<TrafficLightDomain.Enums.StatusCode>;

    constructor(trafficLight: TrafficLight, governance: TrafficLightGovernance) {
        super(governance);
        this.trafficLight = trafficLight;
        this.update(governance);
    }

    update(governance: TrafficLightGovernance) {
        super.update(governance);
        this.phase = governance.phase;
        this.program = governance.program;
        this.status = governance.status !== undefined ? trafficLightStatusList[governance.status] : undefined;
    }

    async updateInfo() {
        const governance = await this.trafficLight.dispatcher.fetchTrafficLightJson(
            `trafficlight/state/governance/${this.id}`
        );
        this.update(governance);
        this.trafficLight.emit("controlSessionChanged");
    }

    async applyStatus(status?: TrafficLightDomain.Enums.StatusCode) {
        await this.trafficLight.dispatcher.fetchTrafficLight(
            `trafficlight/manage/facility/${this.trafficLight.id}/status`,
            {
                method: "POST",
                body: status !== undefined ? status.toString() : "null",
            }
        );
        await this.updateInfo();
    }

    async holdPhase(phaseNum: number) {
        await this.trafficLight.dispatcher.fetchTrafficLight(
            `trafficlight/manage/facility/${this.trafficLight.id}/phase`,
            {
                method: "POST",
                body: phaseNum.toString(),
            }
        );
        await this.updateInfo();
    }

    async cancel() {
        await this.applyStatus();
    }

    async renew() {
        if (this.status?.code === TrafficLightDomain.Enums.StatusCode.AllRed) await this.applyStatus(this.status.code);
        else if (this.status?.code === TrafficLightDomain.Enums.StatusCode.Hold && this.phase)
            await this.holdPhase(this.phase);
    }
}
