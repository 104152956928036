import { makeAutoObservable, runInAction } from "mobx";
import { Interfaces } from "interfaces";
import { v4 } from "uuid";
import { equipmentStatus } from "shared/constants";
import { WarehouseService } from "services/warehouse";
import type { Status, DefaultPosition, Utils } from "./item.types";

export class Item implements Interfaces.Store.EquipmentCard {
    /** Уникальный идентификатор сущность */
    public readonly guid = v4();
    public readonly uid: number;
    public readonly status: Status;
    public readonly coordinate: LatLng;
    public error: string | null = null;
    public loaded: boolean = false;
    public number: string = "";
    public address: string = "";
    public streams: string[] = [];
    public issues?: number;
    public defaultPositions?: DefaultPosition;

    private utils: Utils;

    constructor(data: SourceEquipmentCardArgs, utils: Utils) {
        this.utils = utils;

        this.defaultPositions = data.defaultPosition;
        this.uid = data.options.uid;
        this.coordinate = data.options.coordinate;
        this.status = {
            issueId: data.options.statusOnIssues,
            issueName: equipmentStatus.getStatusNameOnIssueId(data.options.statusOnIssues),
            monitoringId: data.options.statusOnMonitoring,
            monitoringName: equipmentStatus.getStatusNameOnMonitoringId(data.options.statusOnMonitoring),
        };

        makeAutoObservable(this);
    }

    public load = async () => {
        try {
            const result = await new WarehouseService().getAssetCameraById(this.uid);

            runInAction(() => {
                this.number = result.num;
                this.address = result.addresses?.map(({ address }) => address).join(", ") ?? "";
                this.streams = result.streamNums;
                this.issues = result.issueCount;
            });
        } catch (error) {
            runInAction(() => {
                this.error = "Не удалось загрузить данные объекта";
            });
        } finally {
            runInAction(() => {
                this.loaded = true;
            });
        }
    };

    public readonly remove = () => {
        this.utils.handleRemove(this.uid);
    };

    public readonly showOnMap = () => {
        this.utils.handleShowOnMap(this.coordinate);
    };

    public readonly handleFullscreenClick = () => {
        this.utils.handleFullscreenClick({
            options: {
                uid: this.uid,
                coordinate: this.coordinate,
                statusOnIssues: this.status.issueId,
                statusOnMonitoring: this.status.monitoringId,
            },
            defaultPosition: this.defaultPositions ?? { pageX: 0, pageY: 0 },
        });
    };
}
