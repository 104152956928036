import styled from "styled-components/macro";

export const ListContainer = styled.div`
    width: 100%;
    height: 100%;
    & .ReactVirtualized__List {
        padding: ${({ theme }) => theme.spacings[3].str} 0;
    }
    & .ReactVirtualized__Grid__innerScrollContainer {
        overflow: visible !important;
    }
`;
