export type SpeedDistanceLegendDataInput = {
    font: string;
    width: number;
    distance: number;
    fontSize: number;
    fillStyle: string;
    forwardSpeed: number;
    backwardSpeed: number;
    speedDistanceLegendY: number;
};

export type SpeedDistanceLegendData = {
    distance: number;
    forwardSpeed: number;
    backwardSpeed: number;
    width: number;
    fillStyle: string;
    font: string;
    textAlign: string;
    fontSize: number;
    speedDistanceLegendY: number;
};

export const initialData = {
    distance: 0,
    forwardSpeed: 0,
    backwardSpeed: 0,
    width: 0,
    fillStyle: "",
    font: "",
    textAlign: "",
    fontSize: 0,
    speedDistanceLegendY: 0,
};

export const createSpeedDistanceData = (args: SpeedDistanceLegendDataInput): SpeedDistanceLegendData =>
    args
        ? {
              font: args.font,
              textAlign: "left",
              width: args.width,
              distance: args.distance,
              fontSize: args.fontSize,
              fillStyle: args.fillStyle,
              forwardSpeed: args.forwardSpeed,
              backwardSpeed: args.backwardSpeed,
              speedDistanceLegendY: args.speedDistanceLegendY,
          }
        : initialData;
