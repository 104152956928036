export type TimeData = {
    totalTime: number;
    width: number;
    pixelsPerSecond: number;
    font: string;
    fillStyle: string;
    top: number;
    bottom: number;
};

export type TimeDataInput = {
    totalTime: number;
    width: number;
    pixelsPerSecond: number;
    height: number;
};

const fontSize = 12;
const fillStyle = "#03284C";
const top = 4;
const font = `400 ${fontSize}px Inter`;

const initialData = {
    fillStyle,
    top,
    font,
    totalTime: 0,
    width: 0,
    pixelsPerSecond: 0,
    bottom: 0,
};

export const createTimeData = (args?: TimeDataInput): TimeData =>
    args
        ? {
              ...initialData,
              totalTime: args.totalTime,
              width: args.width,
              pixelsPerSecond: args.pixelsPerSecond,
              bottom: args.height - fontSize,
          }
        : initialData;
