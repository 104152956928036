import styled, { css, useTheme } from "styled-components";
import { TooltipPropsTheme } from "./tooltip.types";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const Tooltip = styled.div(({ position, visible, left, heightTooltip }: TooltipPropsTheme) => {
    const theme = useTheme();
    const borderPositionColor = `border-${position}-color: ${theme.colors.white.basic}`;
    const customBoxShadow =
        position === "top"
            ? theme.shadows?.shadow1
            : `0px -4px 8px rgba(0, 0, 0, 0.10), 0px 0px 1px rgba(255, 255, 255, 0.35)`;

    const offset = 8;
    const offsetTop = heightTooltip + offset;

    return css`
        display: ${visible ? "block" : "none"};
        position: absolute;
        ${position === "top" ? `top: -${offsetTop}px` : `bottom: -${offsetTop}px`};
        left: ${left}px;
        width: max-content;
        height: max-content;
        max-width: 300px;
        z-index: 9999;
        background: ${theme.colors.white.basic};
        padding: 4px ${offset}px;
        border-radius: 4px;
        box-shadow: ${customBoxShadow};

        :before {
            content: "";
            border: solid transparent;
            position: absolute;
            left: 16px;
            ${position}: 100%;
            ${borderPositionColor};
            border-width: 5px;
            margin-left: -5px;
        }
    `;
});
