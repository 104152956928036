import { ChartOptions, ChartDataset } from "chart.js";
import format from "date-fns/format";
import { Detector } from "app-domain";
import { detector } from "shared/constants";
import type { DataSetCollection, IndicatorUid } from "./graph-dto.types";

export class GraphDTO implements Detector.Models.ShortCard.Graph {
    public readonly type: "line" = "line";

    public readonly options: ChartOptions;

    public readonly data: Detector.Models.ShortCard.GraphData = {
        labels: [],
        datasets: [],
    };

    constructor(data?: any) {
        this.options = this.optionsFactory([]);

        if (Array.isArray(data) && data.length) {
            const _data = this.dataFactory(data);
            this.data.labels = _data.labels;
            this.data.datasets = _data.datasets;
            this.options = this.optionsFactory(_data.labels);
        }
    }

    private getAxisID = (uid: Detector.Models.IndicatorUid) => {
        if (uid === "volume") return "yRight";
        return "yLeft";
    };

    private createEmptyDatasets = (): DataSetCollection => {
        return [
            detector.indicators.speed,
            detector.indicators.volume,
            detector.indicators.utilization,
            detector.indicators.occ,
        ].reduce((acc: DataSetCollection, indicator) => {
            acc[indicator.uid as IndicatorUid] = {
                label: ` ${indicator.caption} (${String(indicator.units).trim()})`,
                data: [],
                borderColor: indicator.color,
                pointRadius: 0,
                pointHitRadius: 10,
                pointHoverRadius: 2,
                pointBackgroundColor: indicator.color,
                borderWidth: 2,
                clip: { left: 3, top: 3, right: 3, bottom: 3 },
                yAxisID: this.getAxisID(indicator.uid),
            };

            return acc;
        }, {});
    };

    private dataFactory(data: Array<any>): Detector.Models.ShortCard.GraphData {
        const labels: Detector.Models.ShortCard.GraphData["labels"] = [];
        const collection = this.createEmptyDatasets();
        const keys = Object.keys(collection) as IndicatorUid[];

        data.forEach(
            (item) => {
                if (!item.timestamp) return;

                try {
                    const label = format(new Date(item.timestamp), "HH:mm");
                    if (!labels.includes(label)) labels.push(label);

                    keys.forEach((key) => {
                        const value = item[key] ?? null;
                        collection[key]?.data.push(value);
                    });
                } catch (err) {
                    console.error(err);
                }
            },
            { labels, collection }
        );

        const datasets = keys.map((key) => collection[key] as ChartDataset);

        return { labels, datasets };
    }

    private optionsFactory(labels: string[]): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
            },
            animation: {
                duration: 0,
            },
            scales: {
                x: {
                    ticks: {
                        callback: function (_, index) {
                            const value = labels?.[index];
                            if (value?.endsWith?.(":00")) return value;
                            return undefined;
                        },
                    },
                },
                yLeft: {
                    position: "left",
                    suggestedMax: 120,
                    beginAtZero: true,
                    ticks: {
                        maxTicksLimit: 7,
                        color: "#cccccc",
                        font: {
                            size: 7,
                        },
                    },
                },
                yRight: {
                    position: "right",
                    beginAtZero: true,
                    ticks: {
                        maxTicksLimit: 7,
                        color: "#cccccc",
                        font: {
                            size: 7,
                        },
                    },
                },
            },
        };
    }
}
