import { MalfunctionType } from "../enums";
import { MalfunctionCodeDictionary } from "../types";

export const malfunctionCodeDictionaryMap: { [key in MalfunctionType]: MalfunctionCodeDictionary } = {
    [MalfunctionType.ClockMalfunction]: {
        code: MalfunctionType.ClockMalfunction,
        name: "Неисправны часы",
        isCritical: true,
    },
    [MalfunctionType.NoPower]: {
        code: MalfunctionType.NoPower,
        name: "Нет сетевого питания",
        isCritical: true,
    },
    [MalfunctionType.LampCritical]: {
        code: MalfunctionType.LampCritical,
        name: "Критическая неисправность лампочек",
        isCritical: true,
    },
    [MalfunctionType.LampNotCritical]: {
        code: MalfunctionType.LampNotCritical,
        name: "Некритическая неисправность лампочек",
        isCritical: false,
    },
    [MalfunctionType.RemoteControlMalfunction]: {
        code: MalfunctionType.RemoteControlMalfunction,
        name: "Неисправность ВПУ",
        isCritical: false,
    },
    [MalfunctionType.DoorOpened]: {
        code: MalfunctionType.DoorOpened,
        name: "Открыта дверь шкафа",
        isCritical: false,
    },
    [MalfunctionType.ExternalCritical]: {
        code: MalfunctionType.ExternalCritical,
        name: "Критическая неисправность периферии",
        isCritical: true,
    },
    [MalfunctionType.ExternalNotCritical]: {
        code: MalfunctionType.ExternalNotCritical,
        name: "Некритическая неисправность периферии",
        isCritical: false,
    },
    [MalfunctionType.HardwareNotCritical]: {
        code: MalfunctionType.HardwareNotCritical,
        name: "Некритическая неисправность оборудования",
        isCritical: false,
    },
    [MalfunctionType.HardwareCritical]: {
        code: MalfunctionType.HardwareCritical,
        name: "Критическая неисправность оборудования",
        isCritical: true,
    },
    [MalfunctionType.SoftwareCritical]: {
        code: MalfunctionType.SoftwareCritical,
        name: "Критическая неисправность ПО",
        isCritical: true,
    },
    [MalfunctionType.SoftwareNotCritical]: {
        code: MalfunctionType.SoftwareNotCritical,
        name: "Некритическая неисправность ПО",
        isCritical: false,
    },
    [MalfunctionType.FacilityControlService]: {
        code: MalfunctionType.FacilityControlService,
        name: "Подсистема мониторинга",
        isCritical: false,
    },
};

export const malfunctionCodeDictionaryList = Object.values(malfunctionCodeDictionaryMap);
