import React from "react";
import { useDropdown } from "./dropdown.hooks";
import { Props } from "./dropdown.types";
import * as Markup from "./dropdown.styles";

export const DropdownBase: React.FC<Props> = (props) => {
    const { handleClick, handleContentClick, style, setAnchorRef, setContentRef, isOpen } = useDropdown(props);

    return (
        <div className={props.className}>
            <span ref={setAnchorRef} onClick={handleClick}>
                {props.children}
            </span>
            {isOpen && (
                <Markup.ContentWrapper ref={setContentRef} style={style} onClick={handleContentClick}>
                    {props.content}
                </Markup.ContentWrapper>
            )}
        </div>
    );
};
