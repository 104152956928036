import mapboxgl from "mapbox-gl";
import { Options } from "./route-layer.types";

/** Слой отображает статус */
export class RouteLayer implements mapboxgl.LineLayer {
    public readonly id: string;
    public readonly type: "line" = "line";
    public readonly source: string;
    public readonly layout: mapboxgl.LineLayout = {
        "line-join": "round",
        "line-cap": "round",
    };
    public readonly paint: mapboxgl.LinePaint = {
        "line-color": [
            "case",
            ["boolean", ["feature-state", "isVisible"], false],
            ["feature-state", "inLineColor"],
            ["boolean", ["feature-state", "isActive"], false],
            ["feature-state", "inLineColor"],
            "transparent",
        ],
        "line-width": ["interpolate", ["exponential", 1.5], ["zoom"], 8, 2, 12, 14, 18, 20],
    };

    public readonly filter = ["all", ["==", "type", "path"]];

    constructor(options: Options) {
        this.id = options.id;
        this.source = options.source;
    }
}
