import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Loader } from "@megapolis/uikit/loader";
import { CycleType } from "app-domain/traffic-light/enums/cycle-type";
import { SeparatedInlineList } from "../separated-inline-list";
import { Props } from "./traffic-light-cycle-visualizer.types";
import {
    Visualizer,
    YellowBlinkCycleVisualizer,
    DefaultCycleVisualizer,
    VisualizationData,
    RenderedPhaseData,
} from "./visualizers";
import { createVisualizationData } from "./traffic-light-cycle-visualizer.utils";
import { CanvasTooltip, CanvasTooltipProps } from "./canvas-tooltip";
import * as Markup from "./traffic-light-cycle-visualizer.styles";

export const TrafficLightCycleVisualizer = memo((props: Props) => {
    const [visualizationData, setVisualizationData] = useState<VisualizationData | null>(null);
    const container = useRef<HTMLDivElement>(null);
    const visualizer = useRef<Visualizer | null>(null);
    const [tooltipProps, setTooltipProps] = useState<CanvasTooltipProps | null>(null);

    const handlePhaseHover = useCallback((data: RenderedPhaseData) => {
        setTooltipProps({
            x: data.box.x1 + data.box.x2 * 0.5,
            y: data.box.y1 - 5,
            content: <SeparatedInlineList>{[`Ф${data.phaseNumber}`, `${data.tPhase}c`]}</SeparatedInlineList>,
        });
    }, []);

    const handleMouseLeave = useCallback(() => {
        setTooltipProps(null);
    }, []);

    const { cycleType, cyclePhases, directions, passportPhases, cycleTime, areDirectionsVisible = false } = props;

    // Установка визуализатора в зависимости от типа цикла
    useEffect(() => {
        const containerEl = container.current;
        if (!containerEl) return;

        switch (cycleType) {
            case CycleType.YellowBlink:
                visualizer.current = new YellowBlinkCycleVisualizer({ containerEl });
                break;
            default:
                visualizer.current = new DefaultCycleVisualizer({
                    containerEl,
                    onPhaseHover: handlePhaseHover,
                });
        }

        return () => {
            visualizer.current?.destroy();
            visualizer.current = null;
        };
    }, [cycleType, handlePhaseHover, handleMouseLeave]);

    // Маппинг входных данных, на данные визуализации
    useEffect(() => {
        let isMounted = true;
        const process = async () => {
            const data = await createVisualizationData({ directions, passportPhases, cyclePhases, cycleTime });
            if (isMounted) {
                setVisualizationData(data);
            }
        };
        process();
        return () => {
            isMounted = false;
        };
    }, [directions, passportPhases, cyclePhases, cycleTime]);

    useEffect(() => {
        if (!visualizer.current) return;
        visualizer.current.areDirectionsVisible = areDirectionsVisible;
    }, [areDirectionsVisible]);

    useEffect(() => {
        if (!visualizer.current || !visualizationData) return;
        visualizer.current.data = visualizationData;
        visualizer.current.visualize();
    }, [visualizationData]);

    return (
        <Markup.Wrapper onMouseLeave={handleMouseLeave}>
            <Markup.CanvasContainer ref={container} />
            {visualizationData === null && (
                <Markup.LoaderWrapper>
                    <Loader />
                </Markup.LoaderWrapper>
            )}
            {tooltipProps && <CanvasTooltip x={tooltipProps.x} y={tooltipProps.y} content={tooltipProps.content} />}
        </Markup.Wrapper>
    );
});
