import { State } from "api";
import { Common } from "app-domain";

export class StateService {
    constructor(protected api: State.StateAPI) {}

    /** Возвращает информацию о сервере, времени и т.п */
    public async getServerInfo() {
        const response = await this.api.getStateInfo();
        return new Common.ServerInfo(response);
    }

    public getCooGroupById(id: number) {
        return this.api.getStateCooGroupById(id);
    }
}
