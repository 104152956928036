import { makeAutoObservable } from "mobx";

interface InputChangeEvent {
    target: { value: string };
}

export class FormTextField {
    public value: string;

    constructor(defaultValue = "") {
        this.value = defaultValue;
        makeAutoObservable(this);
    }

    public handleChange = (e: InputChangeEvent) => {
        this.value = e.target.value;
    };
}
