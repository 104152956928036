import { WarehouseAPI } from "../warehouse-api";
import { Details } from "./asset-detector.types";

export class AssetDetectorAPI extends WarehouseAPI {
    /** Получение информации о экземпляре имущества по идентификатору */
    public getById(id: number): Promise<Details> {
        const url = this.buildUrl(`/${id}`);
        const result = this.request({ url });
        return result;
    }

    protected buildUrl(url: string, params = "") {
        return super.buildUrl(`/assetdetector${url}${params}`);
    }
}
