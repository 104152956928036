import styled, { css } from "styled-components/macro";

const commonCss = css`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings[1].str};
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.content.primary};
`;

export const ExpansionIcon = styled.span<{ isExpanded: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
        width: 24px;
        height: 24px;
        fill: ${({ theme }) => theme.colors.blue.basic};
        transform: ${({ isExpanded }) => (isExpanded ? `rotate(90deg)` : `none`)};
        transition: transform ${({ theme }) => theme.transitions.duration.standard.str};
    }
`;

export const TitleControl = styled.span`
    ${commonCss};
`;

export const TitleText = styled.span`
    margin: ${({ theme }) => theme.spacings[1].str} 0;
`;

export const TitleSubTitleWrapper = styled.span`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings[1].str};
`;

export const SubTitle = styled.span`
    color: ${({ theme }) => theme.colors.content.secondary};
`;

export const Description = styled.span`
    ${commonCss};
    flex-grow: 1;
`;

const containerDisabledCss = css`
    ${TitleControl}, ${Description} {
        color: ${({ theme }) => theme.colors.content.tertiary};
        cursor: default;
    }
    ${ExpansionIcon} {
        svg {
            fill: ${({ theme }) => theme.colors.content.quinary};
        }
    }
`;

export const Container = styled.div<{ isDisabled?: boolean }>`
    ${commonCss};
    flex-grow: 1;
    width: 100%;
    padding: ${({ theme }) => `${theme.spacings[1]} 0 ${theme.spacings[1]} ${theme.spacings[3]}`};
    background-color: transparent;
    border: none;
    &:hover {
        cursor: pointer;
    }
    ${({ isDisabled }) => isDisabled && containerDisabledCss};
`;
