export function delayToLast<F extends (...args: any) => any>(func: F, ms: number) {
    let timeout: NodeJS.Timeout | null = null;
    let lastArgs: any[];
    let lastThis: any;

    function wrapper<T>(this: T, ...args: Parameters<F>) {
        if (timeout) {
            clearTimeout(timeout);
        }

        lastArgs = args;
        lastThis = this;

        timeout = setTimeout(() => {
            func.apply(lastThis, lastArgs);
        }, ms);
    }
    return wrapper;
}
