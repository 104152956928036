import React from "react";
import { Loader } from "@megapolis/uikit/loader";
import { Accordion, AccordionContent } from "components";
import * as Markup from "./details.styles";
import type { Props } from "./details.types";

export const Graph = React.memo((props: Props) => {
    const { isLoaded, graph } = props;
    const [isExpaned, setExpanded] = React.useState(false);
    const handleChange = React.useCallback(({ value }) => setExpanded(value), []);

    const content = React.useMemo(() => {
        if (!isLoaded) {
            return (
                <Markup.Placeholder>
                    <Loader size="32px" />
                </Markup.Placeholder>
            );
        }

        if (!graph) return null;

        return graph;
    }, [isLoaded, graph]);

    if (!content) return null;

    return (
        <Accordion isExpanded={isExpaned} onChange={handleChange}>
            <Markup.AccordionHeader title="График" />
            <AccordionContent>
                <Markup.Container className={props.className} data-cypress={props.dataCypress}>
                    {content}
                </Markup.Container>
            </AccordionContent>
        </Accordion>
    );
});
