import styled from "styled-components/macro";

export const Portal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
`;

export const ContentWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: fit-content;
    block-size: fit-content;
`;
