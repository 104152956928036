import { makeAutoObservable, action, runInAction } from "mobx";
import { IssueDomain } from "app-domain";
import { Issues } from "services";
import { IssueStoreTypes } from "./issue-store.types";

export class IssueStore {
    public items: IssueDomain.Issue[] = [];
    public isLoading = false;
    public error: NullableString = null;
    private prevParams: Nullable<IssueStoreTypes.LoadIssuesParams> = null;

    constructor(private _apiService: Issues.IssueService) {
        makeAutoObservable(this, {
            loadIssues: action,
        });
    }

    public async loadIssues(params: IssueStoreTypes.LoadIssuesParams) {
        if (!this.checkParamsChanged(params)) return this.items;
        this.isLoading = true;
        const issues = await this._apiService.getIssues({
            facilityId: params.id,
            isActive: !!params.statusFilter,
        });
        runInAction(() => {
            this.isLoading = false;
            this.items = issues;
        });
        this.savePrevParams(params);
    }

    private checkParamsChanged(newParams: IssueStoreTypes.LoadIssuesParams) {
        return this.prevParams?.id !== newParams.id || this.prevParams?.statusFilter !== newParams.statusFilter;
    }

    private savePrevParams(params: IssueStoreTypes.LoadIssuesParams) {
        this.prevParams = params;
    }
}
