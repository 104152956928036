import React from "react";
import styled, { css } from "styled-components";
import { AccordionContent, AccordionContentProps } from "../accordion";

export type DetailsAccordionContentProps = AccordionContentProps & {
    padding?: string;
    noPadding?: boolean;
};

const StyledAccordionContent = styled(AccordionContent)<Pick<DetailsAccordionContentProps, "padding" | "noPadding">>(
    (props) => {
        const { padding, noPadding, theme } = props;
        const finalPadding = noPadding ? 0 : padding || `0 0 0 ${theme.spacings[3]}`;

        return css`
            padding: ${finalPadding};
        `;
    }
);

export const DetailsAccordionContent = (props: DetailsAccordionContentProps) => <StyledAccordionContent {...props} />;
