export enum CycleType {
    /** Основная */
    Basic = 0,

    /** Резервная */
    Reserve = 1,

    /** Вызывная */
    OnCall = 2,

    /** Жёлтое мигание */
    YellowBlink = 3,

    /** Динамическая */
    Dynamic = 4,

    /** Созданная в рамках группы координации */
    CooGroup = 5,
}
