import { Facility } from "api";
import { GetIssuesOptions } from "./issue-service.types";
import { createIssues } from "./issue-service.utils";

export class IssueService {
    constructor(private api: Facility.FacilityAPI) {}

    public async getIssues(options: GetIssuesOptions) {
        const response = await this.api.getFacilityIssues(options);
        return createIssues(response ?? []);
    }
}
