import { AppStateManager } from "app/store";
import { MapController } from "map-controller";
import { Broker } from "./broker";
import {
    CooGroupEditorBroker,
    TrafficLightsBroker,
    WarehouseEquipmentBroker,
    TrafficLightHistoryBroker,
    TrafficForecastHistoryBroker,
} from "./brokers";
import { CooGroupBroker } from "./brokers/coo-group";

export class GlobalBroker extends Broker {
    private readonly cooGroupEditorBroker: CooGroupEditorBroker;
    private readonly trafficLightsBroker: TrafficLightsBroker;
    private readonly warehouseEquipmentBroker: WarehouseEquipmentBroker;
    private readonly cooGroupBroker: CooGroupBroker;
    private readonly trafficLightHistoryBroker: TrafficLightHistoryBroker;
    private readonly trafficForecastHistoryBroker: TrafficForecastHistoryBroker;

    constructor(mapController: MapController, uiStore: AppStateManager) {
        super(mapController, uiStore);
        this.cooGroupEditorBroker = new CooGroupEditorBroker(this.mapController, this.uiStore);
        this.warehouseEquipmentBroker = new WarehouseEquipmentBroker(this.mapController, this.uiStore);
        this.trafficLightsBroker = new TrafficLightsBroker(
            this.mapController,
            this.uiStore.trafficLightStore,
            this.uiStore.trafficLightManager
        );
        this.cooGroupBroker = new CooGroupBroker(this.mapController, this.uiStore);
        this.trafficLightHistoryBroker = new TrafficLightHistoryBroker(
            this.mapController,
            this.uiStore.trafficLightHistoryStore
        );
        this.trafficForecastHistoryBroker = new TrafficForecastHistoryBroker(
            this.mapController,
            this.uiStore.trafficForecastHistory
        );
    }

    public destroy = () => {
        this.cooGroupEditorBroker.destroy();
        this.trafficLightsBroker.destroy();
        this.warehouseEquipmentBroker.destroy();
        this.cooGroupBroker.destroy();
        this.trafficLightHistoryBroker.destroy();
        this.trafficForecastHistoryBroker.destroy();
    };
}
