import { memo, ReactNode } from "react";
import styled from "styled-components/macro";
import { Button } from "@megapolis/uikit/button";
import { Icons } from "@megapolis/uikit/icons";

const CloseIcon = <Icons.Monochrome.CloseM />;

const WrappedButton = styled(Button)<{ icon: ReactNode }>`
    svg,
    path {
        fill: ${({ theme }) => theme.colors.content.secondary};
    }
`;

type Props = Omit<React.ComponentProps<typeof Button>, "content" | "priority" | "icon">;

export const CloseButton = memo((props: Props) => (
    <WrappedButton {...props} content={Button.Content.Icon} priority="quaternary" icon={CloseIcon} />
));
