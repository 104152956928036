import { memo } from "react";
import { useLocale } from "../../../locale-context";
import * as Markup from "./month.styles";

type Props = {
    /** Дата, включающая конкретный месяц */
    value: Date;
    onClick?: (value: Date) => void;
    isActive?: boolean;
};

export const Month = memo((props: Props) => {
    const { value, onClick } = props;
    const locale = useLocale();

    const handleClick = () => {
        onClick?.(value);
    };

    const displayValue = value.toLocaleDateString(locale, {
        month: "long",
    });

    return (
        <Markup.Button isActive={props.isActive} onClick={handleClick}>
            {displayValue}
        </Markup.Button>
    );
});
