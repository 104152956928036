import mapboxgl from "mapbox-gl";
import { Options } from "./full-route-layer.types";

/** Слой отображает полный путь */
export class FullRouteLayer implements mapboxgl.LineLayer {
    public readonly id: string;
    public readonly type: "line" = "line";
    public readonly source: string;
    public readonly layout: mapboxgl.LineLayout = {};
    public readonly paint: mapboxgl.LinePaint = {
        "line-color": ["case", ["boolean", ["feature-state", "isActive"], false], "#000000", "transparent"],
        "line-width": 4,
    };
    public readonly filter = ["all", ["==", "type", "path-full"]];

    constructor(options: Options) {
        this.id = options.id;
        this.source = options.source;
    }
}
