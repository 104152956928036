import { StoredData } from "./production-date-picker.types";

const store: StoredData = {
    weekends: null,
    year: new Date().getFullYear(),
};

export const getStoredData = () => store;

export const updateStoredData = (dates: Date[] | null, year: number) => {
    store.weekends = dates;
    store.year = year;
};
