import { memo } from "react";
import { TrafficLightDomain } from "app-domain";
import { PhaseContainer, PhaseLabel, PhaseMedia } from "../../phases";
import { TagLabel } from "../../tag-label";
import * as Markup from "./green-street-cycle-phase.styles";

type Props = {
    phaseNumber: number;
    direction: TrafficLightDomain.Direction;
    coords: LatLng;
    trafficLightNum?: string;
};

export const GreenStreetCyclePhase = memo((props: Props) => {
    const { direction, coords } = props;
    const trackDirections = TrafficLightDomain.Utils.createTracksByDirection({ direction, coords });
    return (
        <PhaseContainer>
            <PhaseMedia trackDirections={trackDirections} lat={coords.lat} lng={coords.lng} />
            <Markup.Caption>
                <TagLabel>СО: {props.trafficLightNum}</TagLabel>
                <PhaseLabel phaseNumber={props.phaseNumber} />
            </Markup.Caption>
        </PhaseContainer>
    );
});
