import ReactDOM from "react-dom";
import { App } from "app";
import * as serviceWorker from "./serviceWorker";
import { Authentication } from "@megapolis/react-auth";
import { REACT_APP_AUTH_DOMAIN } from "env-data";
import { BlockLoader } from "components";

ReactDOM.render(
    <Authentication
        client_id="trafficlight-web"
        scope="warehouse.api boardservice.api cameraservice.api trafficlight.api dtm.traffic.api dlines.api"
        authority={REACT_APP_AUTH_DOMAIN}
        debug={process.env.NODE_ENV === "development"}
        loader={<BlockLoader size="48px" />}>
        <App />
    </Authentication>,
    document.getElementById("root")
);

serviceWorker.unregister();
