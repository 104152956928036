export const nameOfStatusOnIssues = new Map<number, string>()
    .set(0, "Демонтаж")
    .set(1, "Работает")
    .set(2, "Работает с замечаниями")
    .set(3, "Не работает")
    .set(4, "Регламентные работы");

export const getStatusNameOnIssueId = (id?: number) => {
    const name = nameOfStatusOnIssues.get(id ?? -1);
    return name ?? "Нет статуса";
};

export const colorOfStatusOnIssues = new Map<number, string>()
    /** Демонтаж **/
    .set(0, "#83909A")
    /** Работает **/
    .set(1, "#289F46")
    /** Работает с замечаниями **/
    .set(2, "#FECB00")
    /** Не работает **/
    .set(3, "#FF3A2F")
    /** Регламентные работы **/
    .set(4, "#5ACAFD");

export const getStatusColorOnIssueId = (id?: number) => {
    const color = colorOfStatusOnIssues.get(id ?? -1);
    return color ?? "#000000";
};

export const nameOfStatusOnMonitoring = new Map<number, string>()
    .set(1, "Работает")
    .set(2, "Нет данных")
    .set(3, "Работает с замечаниями")
    .set(4, "Не работает");

export const getStatusNameOnMonitoringId = (id?: number) => {
    const name = nameOfStatusOnMonitoring.get(id ?? -1);
    return name ?? "Нет статуса";
};

export const colorOfStatusOnMonitoring = new Map<number, string>()
    /** Работает **/
    .set(1, "#289F46")
    /** Нет данных **/
    .set(2, "#83909A")
    /** Работает с замечаниями **/
    .set(3, "#FECB00")
    /** Не работает **/
    .set(4, "#FF3A2F");

export const getStatusColorOnMonitoringId = (id?: number) => {
    const color = colorOfStatusOnMonitoring.get(id ?? -1);
    return color ?? "#000000";
};
