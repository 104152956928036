import mapboxgl from "mapbox-gl";

export const RouteBorderPaint: mapboxgl.LinePaint = {
    "line-color": [
        "match",
        ["get", "traffic"],
        100,
        "#0A820A",
        4,
        "#B00000",
        3,
        "#F8000A",
        2,
        "#FFC203",
        1,
        "#0ABE0A",
        -1,
        "#014A99",
        "#FFFFFF",
    ],
    "line-gap-width": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 7, 12, 7, 18, 14],
    "line-width": 2,
};

export const RoutePaint: mapboxgl.LinePaint = {
    "line-color": [
        "match",
        ["get", "traffic"],
        100,
        "#0A820A",
        4,
        "#B00000",
        3,
        "#F8000A",
        2,
        "#FFC203",
        1,
        "#0ABE0A",
        -1,
        "#67B0FF",
        "#FFFFFF",
    ],
    "line-width": ["interpolate", ["exponential", 1.5], ["zoom"], 5, 7, 12, 7, 18, 14],
};
