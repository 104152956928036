import { memo, useCallback, ChangeEvent, useRef, useEffect, FocusEvent } from "react";
import { Input, InputProps } from "@megapolis/uikit/input";

type InputChangeEvent = ChangeEvent<HTMLInputElement>;

type ChangeHandler = (value: number | undefined, event: InputChangeEvent) => void;

export type NumberInputProps = Omit<InputProps, "min" | "max" | "value" | "onChange"> & {
    min?: number;
    max?: number;
    value?: number;
    onChange?: ChangeHandler;
    autoFormatOnBlur?: boolean;
};

const getNewValue = (input: string) => (input === "" ? void 0 : Number(input));

export const NumberInput = memo((props: NumberInputProps) => {
    const { value, min, max, onChange, onBlur, autoFormatOnBlur = false, ...rest } = props;

    const valueRef = useRef(value);

    useEffect(() => {
        valueRef.current = value;
    }, [value]);

    const handleChange = useCallback(
        (event: InputChangeEvent) => {
            const currentValue = valueRef.current;
            const inputValue = event.target.value;
            let newValue = getNewValue(inputValue);
            if (currentValue === 0 && inputValue.includes("0")) return (event.target.value = "0");
            if (typeof max === "number" && newValue && newValue > max) return;
            if (typeof min === "number" && min >= 0 && inputValue.includes("-")) return;
            onChange?.(inputValue.length > 0 ? Number(inputValue) : void 0, event);
        },
        [onChange, max, min]
    );

    const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const newValue = getNewValue(event.target.value);
            if (onBlur) return onBlur(event);
            if (!autoFormatOnBlur) return;
            if (typeof min === "number" && (!newValue || (newValue && newValue < min))) {
                onChange?.(min, event);
            }
        },
        [onChange, min, onBlur, autoFormatOnBlur]
    );

    return (
        <Input
            {...rest}
            type="number"
            value={value ?? ""}
            min={min}
            max={max}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
});
