import { Icons } from "@megapolis/uikit/icons";
import styled from "styled-components";

export const FilterInfo = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: ${({ theme: { spacings } }) => `${spacings[3].str} ${spacings[6].str}`};
    gap: ${({ theme }) => theme.spacings[3].str};
`;

export const IconClose = styled(Icons.Monochrome.CloseS)`
    fill: ${({ theme }) => theme.colors.blue.d4}; ;
`;
