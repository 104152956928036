import { makeDrawer } from "../drawing.common";
import { RectLikeProps } from "../drawing.types";

export interface DrawGridColProps extends RectLikeProps {
    step: number;
    lineWidth?: number;
    lineColor?: string;
}

export const drawGridColumns = makeDrawer<DrawGridColProps>((ctx, props) => {
    ctx.lineWidth = props.lineWidth ?? 1;
    ctx.strokeStyle = props.lineColor ?? "rgba(255,255,255,0.3)";
    ctx.beginPath();
    for (let x = props.x; x < props.x + props.width; x += props.step) {
        ctx.moveTo(x, props.y);
        ctx.lineTo(x, props.y + props.height);
    }
    ctx.stroke();
});
