import styled, { css, CSSProperties } from "styled-components/macro";
import { FlexBox, FlexBoxProps } from "./flex-box";

export const FlexRowCss = css`
    display: flex;
    flex-direction: row;
`;

type Props = Omit<FlexBoxProps, "direction" | "wrap"> & {
    hasWrap?: boolean;
    gap?: number;
    grow?: number;
    overflow?: CSSProperties["overflow"];
    isFullWidth?: boolean;
};

export const FlexRow = styled(FlexBox)<Props>((props) => {
    const { hasWrap, grow, gap, overflow, isFullWidth } = props;

    return css`
        ${FlexRowCss};
        flex-wrap: ${hasWrap ? "wrap" : "nowrap"};
        flex-grow: ${typeof grow === "number" ? grow : "unset"};
        gap: ${gap ? `${gap}px` : "unset"};
        overflow: ${overflow ? overflow : "visible"};
        width: ${isFullWidth ? "100%" : "auto"};
    `;
});
