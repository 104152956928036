const phaseColors = [
    "#B051E0",
    "#027BFF",
    "#5ACAFD",
    "#FE9400",
    "#5654D4",
    "#CC6666",
    "#00CC99",
    "#99231C",
    "#006666",
    "#F475F4",
    "#84DD9B",
    "#669999",
    "#996600",
    "#3300CC",
    "#6699FF",
];

export const getPhaseColor = (phase: number | undefined) => {
    if (phase === undefined) return "rgba(0, 0, 0, 0.16)";
    return phaseColors[(phase - 1) % phaseColors.length];
};
