import { EntityType, StateViewType } from "./view-state.types";
import { ActiveItemState } from "./active-view-states/active-item-state";
import { ActiveDashboardState } from "./active-view-states/active-dashboard-state";
import { ActiveTrafficLightState } from "./active-view-states/active-traffic-light-state";
import { ActiveCooGroupState } from "./active-view-states/active-coo-group-state";

export const getStateByEntityType = (type: EntityType): ActiveItemState<StateViewType> => {
    switch (type) {
        case EntityType.Dashboard:
            return new ActiveDashboardState();
        case EntityType.TrafficLight:
            return new ActiveTrafficLightState();
        case EntityType.CooGroup:
            return new ActiveCooGroupState();
        default:
            return new ActiveTrafficLightState();
    }
};
