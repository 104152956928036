import { History } from "history";
import { TypedEmitter } from "tiny-typed-emitter";
import { matchCooGroupRegExp, matchTrafficLightRegExp } from "./history-facade.constants";
import {
    Events,
    Handlers,
    ActiveTrafficLightParams,
    TrafficLightType,
    ActiveCooGroupParams,
    CooGroupType,
} from "./history-facade.types";

export class HistoryFacade extends TypedEmitter<Handlers> {
    public events = Events;

    constructor(private history: History) {
        super();
    }

    private get activeEntity() {
        return this.history.location.pathname.split("/")[1];
    }

    public setActiveTrafficLight = (params: ActiveTrafficLightParams) => {
        let activeEntity = this.activeEntity;
        if (params.type) {
            activeEntity = TrafficLightType[params.type];
        } else if (!activeEntity.match(matchTrafficLightRegExp)) {
            activeEntity = TrafficLightType.details;
        }

        const path = `/${activeEntity}/${params.id}`;
        this.history.push(path);
        this.emit(this.events.ActiveTrafficLightChange, params);
    };

    public setActiveCooGroup = (params: ActiveCooGroupParams) => {
        let activeEntity = this.activeEntity;
        if (params.type) {
            activeEntity = CooGroupType[params.type];
        } else if (!activeEntity.match(matchCooGroupRegExp)) {
            activeEntity = CooGroupType.details;
        }

        const path = `/${activeEntity}/${params.id}`;
        this.history.push(path);
        this.emit(this.events.ActiveCooGroupChange, params);
    };
}
