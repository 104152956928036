import { CanvasObject } from "./canvas-object";

export class CanvasObjectRenderer {
    private raf?: number | null;

    constructor(private ctx: CanvasRenderingContext2D, private rootObject: CanvasObject) {}

    public start() {
        this.raf = requestAnimationFrame(() => {
            this.render();
            this.start();
        });
    }

    public renderFrame() {
        this.raf = requestAnimationFrame(this.render);
    }

    public stop() {
        if (this.raf) cancelAnimationFrame(this.raf);
        this.raf = null;
    }

    public destroy() {
        this.stop();
        // @ts-ignore
        this.ctx = null;
        // @ts-ignore
        this.objects = null;
    }

    private render = () => {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        if (!this.rootObject.isVisible) return;
        this.ctx.save();
        this.rootObject.render?.(this.ctx);
        this.ctx.restore();
    };
}
