import { Card } from "@megapolis/uikit/card";
import styled from "styled-components/macro";

export const CardWrapper = styled(Card.ListItem)`
    > button {
        padding: 0;
        &:hover {
            cursor: default;
        }
    }
    &,
    &:hover,
    &:focus-within {
        background: none;
        box-shadow: none;
        border: none;
    }
    && svg {
        fill: ${({ theme }) => theme.colors.white.basic};
    }
`;

export const PlayerWrapper = styled.div`
    &,
    & > .video-player {
        width: 100%;
        height: 163px;
        border-radius: ${({ theme }) => theme.borderRadiuses[2].str};
        overflow: hidden;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 218px;
`;
