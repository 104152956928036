import { MalfunctionType } from "../enums";
import { malfunctionCodeDictionaryList } from "../constants";
import { GroupedMalfunctionDictionaries } from "../types";

export const isCriticalMalfunction = (type: MalfunctionType) =>
    type === MalfunctionType.ClockMalfunction ||
    type === MalfunctionType.ExternalCritical ||
    type === MalfunctionType.HardwareCritical ||
    type === MalfunctionType.LampCritical ||
    type === MalfunctionType.NoPower ||
    type === MalfunctionType.SoftwareCritical;

/**
 * Получает список ошибок по битовой маске из malfunctionType
 */
export const getMalfunctionDictionaries = (malfunctionType: number) => {
    if (malfunctionType === 0) return [];
    return malfunctionCodeDictionaryList.filter(
        (dictionary) => (dictionary.code & malfunctionType) === dictionary.code
    );
};

export const getMalfunctionsDataByType = (malfunctionType: number) => {
    const result = {
        critical: [],
        nonCritical: [],
        all: [],
    } as GroupedMalfunctionDictionaries;

    if (malfunctionType === 0) return result;

    return malfunctionCodeDictionaryList.reduce((result, malfunctionDict) => {
        if ((malfunctionDict.code & malfunctionType) !== malfunctionDict.code) return result;

        if (malfunctionDict.isCritical) {
            result.critical.push(malfunctionDict);
        } else {
            result.nonCritical.push(malfunctionDict);
        }

        result.all.push(malfunctionDict);

        return result;
    }, result);
};
