import { memo } from "react";
import { Divider } from "@megapolis/uikit/divider";
import { DurationSection, CompletionSection, DelaySection, CycleDelaySection } from "./components";
import * as Markup from "./body.styles";

export const Body = memo(() => {
    return (
        <Markup.Container>
            <Markup.ManagementContainerWrapper>
                <Markup.ManagementContainer>
                    <DurationSection />
                </Markup.ManagementContainer>
                <Markup.ManagementContainer>
                    <CompletionSection />
                </Markup.ManagementContainer>
                <Markup.ManagementContainer>
                    <CycleDelaySection />
                </Markup.ManagementContainer>
            </Markup.ManagementContainerWrapper>
            <Divider />
            <Markup.ManagementContainer>
                <DelaySection />
            </Markup.ManagementContainer>
        </Markup.Container>
    );
});
