import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Props, TooltipState } from "./tooltip.types";

import * as Markup from "./tooltip.styles";

export const Tooltip = memo((props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);
    const offsetTop = useMemo<number>(() => props.offsetTop || 0, [props.offsetTop]);
    const [propsStyle, setPropsStyle] = useState<TooltipState>();
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const parentClientRect = parentRef?.current?.getBoundingClientRect();
        const clientRect = ref?.current?.getBoundingClientRect();
        const left = parentRef?.current?.offsetLeft ?? 0;
        const top = parentClientRect?.top ?? 0;
        const tooltipHeight = clientRect?.height ?? 0;
        const position = top - offsetTop - tooltipHeight > 20 ? "top" : "bottom";
        setPropsStyle(() => ({ position, left, heightTooltip: tooltipHeight }));
    }, [offsetTop, visible]);

    const showTooltip = useCallback(() => setVisible(true), []);
    const hideTooltip = useCallback(() => setVisible(false), []);

    return (
        <Markup.Wrapper ref={parentRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {props.children}
            {props.content && (
                <Markup.Tooltip
                    ref={ref}
                    position={propsStyle?.position || "top"}
                    visible={visible}
                    left={propsStyle?.left || 0}
                    heightTooltip={propsStyle?.heightTooltip || 0}>
                    {props.content}
                </Markup.Tooltip>
            )}
        </Markup.Wrapper>
    );
});
