import { ComponentProps, memo } from "react";
import { Button as UikitButton, ButtonContent } from "@megapolis/uikit/button";

export { ButtonContent };

type ButtonProps = ComponentProps<typeof UikitButton>;

export const Button = memo((props: ButtonProps) => {
    return <UikitButton {...props} size="s" onClick={props.onClick} />;
});
