export class TrafficLightFeature implements GeoJSON.Feature<GeoJSON.Point> {
    public type: "Feature" = "Feature";
    public geometry: GeoJSON.Point;
    public properties = null;

    constructor(coords: LatLng) {
        this.geometry = {
            type: "Point",
            coordinates: [coords.lat, coords.lng],
        };
    }
}
