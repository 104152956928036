import { reaction, makeAutoObservable, runInAction, observable } from "mobx";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightHistory } from "services";
import { Filter } from "./filter";

export class TrafficLightHistoryStore {
    public collection: Map<number, TrafficLightDomain.HistoryEvent> | null = null;
    public isLoading = false;
    public isFileDownloading = false;
    public filter: Filter;
    public selectedEvent: TrafficLightDomain.HistoryEvent | null = null;
    private trafficLightId: number | null = null;
    private filterChangeEffect: VoidFunction | null = null;
    private _search = "";

    constructor(private service: TrafficLightHistory.TrafficLightHistoryService) {
        makeAutoObservable(this, {
            collection: observable.ref,
            selectedEvent: observable.ref,
            filter: false,
        });
        this.filter = new Filter();
    }

    public set search(search: string) {
        this._search = search;
    }

    public get items() {
        return this.collection ? Array.from(this.collection.values()) : [];
    }

    public setTrafficLightId(trafficLightId: number) {
        this.trafficLightId = trafficLightId;
        this.reset();
        this.loadEvents();
    }

    public refreshEvents() {
        this.resetSelectedEvent();
        this.loadEvents();
    }

    public selectEvent(id: TrafficLightDomain.HistoryEvent["id"]) {
        this.selectedEvent = this.collection?.get(id) || null;
    }

    public resetSelectedEvent() {
        this.selectedEvent = null;
    }

    public exportEventsToExcel = async () => {
        if (!this.trafficLightId) return;
        this.isFileDownloading = true;
        await this.service.downloadHistorySnapshotFile({
            trafficLightId: this.trafficLightId,
            filter: this.filter.toServiceFilter(),
        });
        this.isFileDownloading = false;
    };

    public destroy() {
        this.destroyEffects();
    }

    private loadEvents = async () => {
        if (!this.trafficLightId) return;
        this.isLoading = true;
        const filter = this.filter.toServiceFilter();
        const data = await this.service.getHistoryEvents({ trafficLightId: this.trafficLightId, filter });

        runInAction(() => {
            this.isLoading = false;
            this.collection = data.items;
        });
    };

    private reset() {
        this.collection = null;
        this.filter.reset();
        this.resetSelectedEvent();
        this.destroyEffects();
        this.initEffects();
    }

    private initEffects() {
        this.filterChangeEffect = reaction(
            () => this.filter.toServiceFilter(),
            () => this.loadEvents()
        );
    }

    private destroyEffects() {
        this.filterChangeEffect?.();
        this.filterChangeEffect = null;
    }
}
