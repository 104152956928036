import { trafficlight } from "shared/constants";

export const emptyModelFactory = (): Store.Dashboard.TrafficLights["history"] => {
    return {
        labels: [],
        statuses: {
            success: {
                uid: trafficlight.statuses.success.uid,
                caption: trafficlight.statuses.success.caption,
                color: trafficlight.statuses.success.color,
                values: [],
            },
            warning: {
                uid: trafficlight.statuses.warning.uid,
                caption: trafficlight.statuses.warning.caption,
                color: trafficlight.statuses.warning.color,
                values: [],
            },
            danger: {
                uid: trafficlight.statuses.danger.uid,
                caption: trafficlight.statuses.danger.caption,
                color: trafficlight.statuses.danger.color,
                values: [],
            },
            disable: {
                uid: trafficlight.statuses.disable.uid,
                caption: trafficlight.statuses.disable.caption,
                color: trafficlight.statuses.disable.color,
                values: [],
            },
            notConnected: {
                uid: trafficlight.statuses.notConnected.uid,
                caption: trafficlight.statuses.notConnected.caption,
                color: trafficlight.statuses.notConnected.color,
                values: [],
            },
        },
    };
};
