import styled from "styled-components/macro";

export const Panel = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const Header = styled.div`
    width: 100%;
`;

export const Tabs = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    flex-grow: 1;
    padding: ${({ theme }) => theme.spacings[3].str} 0;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const Footer = styled.div`
    width: 100%;
`;
