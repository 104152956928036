import React from "react";
import { FloatingModal } from "components";
import * as hooks from "./detector-short-card.hooks";
import * as Markup from "./detector-short-card.styles";
import { Props } from "./detector-short-card.types";

export const DetectorShortCard = React.memo((props: Props) => {
    const { content } = hooks.useContent(props);
    const { handleFocusClick } = hooks.useHandleFocus(props);

    React.useLayoutEffect(() => {
        window.dispatchEvent(new Event("cypress__detector-short-card__mount"));
        return () => {
            window.dispatchEvent(new Event("cypress__detector-short-card__unmount"));
        };
    }, []);

    return (
        <FloatingModal
            container={props.containerNode}
            dataCypress={props.dataCypress}
            defaultPosition={props.defaultPosition}
            isFocus={props.isFocus}>
            <Markup.Container onMouseDown={handleFocusClick}>
                {content}
            </Markup.Container>
        </FloatingModal>
    );
});
