import { TypedEmitter } from "tiny-typed-emitter";
import { TrafficLightDomain } from "app-domain";

export enum Events {
    activeChanged = "@asudd.active-trafficlight.change",
    trafficLightVisualRender = "trafficLightVisualRender",
}

export type Handlers = {
    [Events.activeChanged]: (trafficLight: TrafficLightDomain.TrafficLight) => void;
    [Events.trafficLightVisualRender]: (trafficLight: TrafficLightDomain.TrafficLight) => void;
};

export class Emitter extends TypedEmitter {
    events = Events;
}
