import { createContext } from "react";
import { CooGroupDomain, TrafficLightDomain } from "app-domain";
import { Store } from "app";

export type CooGroupDetailsContext = {
    trafficLights: TrafficLightDomain.TrafficLight[];
    cooGroup: CooGroupDomain.CooGroup;
    hasBackwardDirection: boolean;
    remoteControl?: Store.CooGroupRemoteControl;
};

export const CooGroupContext = createContext<CooGroupDetailsContext>({} as CooGroupDetailsContext);
