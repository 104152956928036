import styled from "styled-components";
import * as Units from "./units";

export const Container = styled.div`
    display: flex;
    width: 404px;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacings[3].str};
`;

export const Info = styled(Units.Info)``;
export const Graph = styled(Units.Graph)``;
