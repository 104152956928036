import { useEffect, useState } from "react";

const second = 1000;

export const useTimer = () => {
    const [startTime, setStartTime] = useState<NullableNumber>(null);
    const [time, setTime] = useState<NullableNumber>(null);

    useEffect(() => {
        if (!startTime) return;

        const interval = setInterval(() => {
            /** TODO: проверка Date.now() - startTime < 0 выполнена в качестве временной меры, чтобы исключить старт таймера с отрицательного числа
             * (иногда возникает при смонтированном компоненте со списокм фаз в момент переключения фаз).
             */
            if (Date.now() - startTime < 0) {
                setTime((prev) => (prev === null ? 0 : prev + 1));
            } else {
                const next = Math.round((Date.now() - startTime) / second);
                setTime(next);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [startTime]);

    return { time, setStartTime };
};