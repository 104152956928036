import { memo } from "react";
import { useLocale } from "../../locale-context";
import * as Markup from "./weekdays.styles";

const days = [...Array(7)];
// 1 января 2018г берется за основу потому, что необходимо, чтобы 1й день месяца был понедельником
const date = new Date(2018, 0, 1);

export const WeekDays = memo(() => {
    const locale = useLocale();

    return (
        <Markup.Container>
            {days.map((_, index) => {
                date.setDate(index + 1);
                const label = date.toLocaleDateString(locale, {
                    weekday: "short",
                });
                return <Markup.Weekday key={label}>{label}</Markup.Weekday>;
            })}
        </Markup.Container>
    );
});
