import { CooGroup } from "api";
import { CooGroupDomain } from "app-domain";
import { CycleEditorTypes } from "app/store";
import { CooGroupCycleMapper } from "../mappers";

export class CooGroupCycleService implements CycleEditorTypes.ICooGroupCycleService {
    private mapper = new CooGroupCycleMapper();

    constructor(private api: CooGroup.CooGroupAPI) {}

    public async createCycle(
        cooGroupId: number,
        cycle: CooGroupDomain.CustomCycle
    ): Promise<CycleEditorTypes.ICycleResult> {
        const response = await this.api.createCooGroupCycle(cooGroupId, this.mapper.toCycleInput(cycle));
        return response.type === "cycle"
            ? {
                  type: "cycle",
                  data: this.mapper.fromAPI(response.data),
              }
            : {
                  type: "errors",
                  data: response.data.detailed ?? [response.data.error],
              };
    }

    public async updateCycle(
        cooGroupId: number,
        cycle: CooGroupDomain.CustomCycle
    ): Promise<CycleEditorTypes.ICycleResult> {
        const response = await this.api.updateCooGroupCycle(cooGroupId, this.mapper.toCycleInput(cycle));
        return response.type === "cycle"
            ? {
                  type: "cycle",
                  data: this.mapper.fromAPI(response.data),
              }
            : {
                  type: "errors",
                  data: response.data.detailed ?? [response.data.error],
              };
    }

    public async deleteCycle(cooGroupId: number, cycleId: number): Promise<void> {
        return this.api.deleteCooGroupCycle(cooGroupId, cycleId);
    }
}
