import styled from "styled-components";

export const ControlButton = styled.button`
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
    user-select: none;
    &:focus {
        outline: none;
    }
`;
