import { Warehouse, DetectorServiceAPI } from "api";
import { Detector } from "app-domain";
import { AssetDTO, IndicatorsDTO, GraphDTO } from "./units";

export class DetectorService implements Detector.Services.ShortCard {
    private warehouseService: Warehouse.AssetDetectorAPI = new Warehouse.AssetDetectorAPI();
    private detectorService: DetectorServiceAPI = new DetectorServiceAPI();

    getEmptyAsset = () => new AssetDTO();

    getAssetById = async (id: number) => {
        try {
            const result = await this.warehouseService.getById(id);
            return new AssetDTO(result);
        } catch (error) {
            return new AssetDTO();
        }
    };

    getEmptyStatistics = () => ({ indicators: new IndicatorsDTO(), graph: new GraphDTO() });

    getLatestStatistics = async (id: number): Promise<{ indicators: IndicatorsDTO; graph: GraphDTO }> => {
        try {
            const [summary, history] = await Promise.all([
                this.detectorService.getSummaryById(id),
                this.detectorService.getSummaryHistoryById(id),
            ]);
            return { indicators: new IndicatorsDTO(summary), graph: new GraphDTO(history) };
        } catch (error) {
            return { indicators: new IndicatorsDTO(), graph: new GraphDTO() };
        }
    };
}
