import { createContext } from "react";

export type ContextValue = {
    isExpanded: boolean;
    isDisabled: boolean;
    onToggle: React.MouseEventHandler<HTMLDivElement>;
};

export const AccordionContext = createContext<ContextValue>({
    isExpanded: false,
    isDisabled: false,
    onToggle: () => {},
});
