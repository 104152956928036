import { memo, useRef, useCallback, useEffect, useState } from "react";
import { AutoSizer, Index, List } from "react-virtualized";
import { ListRowRenderer, RenderedRows, ListRowProps, ListProps } from "react-virtualized/dist/es/List";
import * as Markup from "./virtualized-list.styles";

export interface VirtualizedListProps<T> {
    collection: T[];
    rowHeight: number | ((params: Index) => number);
    rowRenderer: ListRowRenderer;
    scrollToItem?: T | T[];
    className?: string;
    padding?: string;
    listRef?: React.MutableRefObject<Nullable<List>>;
}

export type RowRendererProps = ListRowProps;

const VirtualizedListComponent = <T,>(props: VirtualizedListProps<T>) => {
    const [scrollToIndex, setScrollToIndex] = useState(0);
    const listRef = useRef<List | null>(null);
    const startIndexRef = useRef<number>(0);
    const stopIndexRef = useRef<number>(0);

    const { scrollToItem, collection, className, rowHeight, rowRenderer } = props;

    const handleRowsRendered = useCallback(({ startIndex, stopIndex }: RenderedRows) => {
        startIndexRef.current = startIndex;
        stopIndexRef.current = stopIndex;
    }, []);

    const handleOverscanIndicies: ListProps["overscanIndicesGetter"] = useCallback(
        ({ startIndex, stopIndex, cellCount }) => ({
            overscanStartIndex: Math.max(startIndex - 10, 0),
            overscanStopIndex: Math.min(stopIndex + 10, cellCount - 1),
        }),
        []
    );

    const ref = useCallback(
        (list) => {
            listRef.current = list;
            if (props.listRef) {
                props.listRef.current = list;
            }
        },
        [props.listRef]
    );

    useEffect(() => {
        if (!scrollToItem || Array.isArray(scrollToItem) || !listRef.current) {
            return;
        }
        const index = collection.findIndex((item) => item === scrollToItem);
        if (index === -1) return;
        setScrollToIndex(index);
    }, [scrollToItem, collection]);

    return (
        <Markup.ListContainer className={className}>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        overscanIndicesGetter={handleOverscanIndicies}
                        data={collection}
                        ref={ref}
                        width={width}
                        height={height}
                        rowCount={collection.length}
                        rowHeight={rowHeight}
                        rowRenderer={rowRenderer}
                        onRowsRendered={handleRowsRendered}
                        scrollToAlignment="auto"
                        scrollToIndex={scrollToIndex}
                    />
                )}
            </AutoSizer>
        </Markup.ListContainer>
    );
};

export const VirtualizedList = memo(VirtualizedListComponent);
