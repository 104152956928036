import React from "react";
import * as hooks from "./detector-chart-for-short-card.hooks";
import * as Markup from "./detector-chart-for-short-card.styles";
import type { Props } from "./detector-chart-for-short-card.types";

const canvasStyle: React.CSSProperties = {
    minHeight: 172,
    maxHeight: 172,
    minWidth: 404,
    maxWidth: 404,
};

export const ChartDetectorShort = React.memo((props: Props) => {
    const { canvasRef } = hooks.useDrawChart(props);

    return (
        <Markup.Container className={props.className} data-cypress={props.dataCypress}>
            <canvas ref={canvasRef} height={172} width={404} style={canvasStyle} />
        </Markup.Container>
    );
});
