import React from "react";
import * as utils from "./body.utils";
import * as Markup from "./body.styles";
import type { Props } from "./body.types";

export const Body = React.memo((props: Props) => {
    const { isLoaded, dataCypress } = props;
    const dataCypressBody = dataCypress ? `${dataCypress}__body` : undefined;
    const dataCypressLastScreenshot = utils.getDataCypress({ moduleName: "last-screenshot", isLoaded, dataCypress });
    const dataCypressOverSpeed = utils.getDataCypress({ moduleName: "over-speed", isLoaded, dataCypress });
    const dataCypressDetails = utils.getDataCypress({ moduleName: "details", isLoaded, dataCypress });

    return (
        <Markup.Container className={props.className} data-cypress={dataCypressBody}>
            <Markup.LastScreenshot
                uid={props.uid}
                getToken={props.getToken}
                isLoaded={props.isLoaded}
                dataCypress={dataCypressLastScreenshot}
            />
            <Markup.OverSpeed
                isLoaded={props.isLoaded}
                overSpeed={props.overSpeed}
                dataCypress={dataCypressOverSpeed}
            />
            <Markup.Details uid={props.uid} dataCypress={dataCypressDetails} />
            {/* 
            TODO: Ждем пока развернут приложение
            <Markup.Issues uid={props.uid} issueCount={props.issueCount} /> 
            */}
        </Markup.Container>
    );
});
