import * as DashboardDomain from "app-domain/dashboard";
import { factoryTrafficLightsData } from "./units";
import { factoryCoordinationGroupsData } from "./units";
import { factoryCamerasData } from "./units";
import { Interface } from "./summary-dto.types";

export class SummaryDTO implements Interface {
    public trafficLights: ReturnType<typeof factoryTrafficLightsData>;

    public coordinationGroups: ReturnType<typeof factoryCoordinationGroupsData>;

    public cameras: ReturnType<typeof factoryCamerasData>;

    constructor(data?: DashboardDomain.Summary) {
        this.trafficLights = factoryTrafficLightsData(data);
        this.coordinationGroups = factoryCoordinationGroupsData(data?.cooGroup);
        this.cameras = factoryCamerasData(data?.camera);
    }
}
