import { useCallback } from "react";
import { Store } from "app";

export const useFlyToTrafficLight = () => {
    const trafficLightStore = Store.useTrafficLightStore();
    const mapController = Store.useMapController();

    return useCallback(
        (id: number) => {
            const trafficLight = trafficLightStore.getById(id);
            if (!trafficLight) return;
            mapController.flyManager.handleTrafficLightPosition(trafficLight);
        },
        [trafficLightStore, mapController]
    );
};
