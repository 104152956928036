export enum ViewType {
    Month,
    Year,
}

export type DateChangeHandler = (date: Date) => void;

export type ViewDateChangeHandler = (viewDate: Date) => void;

export type DateState = {
    selectedDate: Date | null;
    selectDate: DateChangeHandler;
};

export type ViewState = {
    view: ViewType;
    date: Date;
    setMonthView: VoidFunction;
    setYearView: VoidFunction;
    setPrevMonth: VoidFunction;
    setNextMonth: VoidFunction;
    setPrevYear: VoidFunction;
    setNextYear: VoidFunction;
    setCurrentMonth: (month: Date) => void;
};

export type Props = {
    value?: Date | null;
    locale?: string | Intl.Locale;
    /** Дополнительные выходные дни(помимо сб и вс) */
    weekends?: Date[];
    onChange?: DateChangeHandler;
    onViewDateChange?: ViewDateChangeHandler;
    isFullWidth?: boolean;
};
