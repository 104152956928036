import { Drawer } from "diagrams/drawers";
import { PointerIconDrawer } from "./pointer-icon";
import { SplitterIconsDrawer } from "./splitter-icons";
import { PhaseIconsDrawerData } from "./phase-icons-drawer.types";

export class PhaseIconsDrawer extends Drawer<PhaseIconsDrawerData> {
    private pointerIconsDrawer: PointerIconDrawer;
    private splitterIconsDrawer: SplitterIconsDrawer;

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.pointerIconsDrawer = new PointerIconDrawer(ctx);
        this.splitterIconsDrawer = new SplitterIconsDrawer(ctx);
    }

    protected draw = () => {
        if (!this.data) return;
        if (this.data.pointerIcons) this.pointerIconsDrawer.setData(this.data.pointerIcons);
        if (this.data.resizeHandles) this.splitterIconsDrawer.setData(this.data.resizeHandles);
    };
}
