import { useCallback, useState, useRef, useEffect } from "react";

export const useForceUpdate = () => {
    const [, setState] = useState(false);
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => {
        if (isMounted.current) setState((prev) => !prev);
    }, []);
};
