const isUpperCase = (str: string) => str === str.toUpperCase();

/**
 * Преобразовывает наименование создаваемой сущности:
 * 1. пробелы и символы переноса/возврата каретки очищаются
 * 2. 2 пробела и более заменяются на 1
 * 3. если вся строка состоит из заглавных символов,то
 * все символы, кроме первого заменяются на строчные
 */
export const transformEntityName = (value: string) =>
    value
        // заменяем 2 пробела на 1
        .replace(/\s\s+/g, " ")
        // удаляем фронтавые пробелы
        .trim()
        // Преабразобываем в масив
        .split(" ")
        // оставляем заглавым только первый символ если есть
        .map((item) => (isUpperCase(item[0]) ? item[0] + item.slice(1).toLowerCase() : item.toLowerCase()))
        .join(" ");
