import mapboxgl from "mapbox-gl";
import { REACT_APP_MAP_CENTER, REACT_APP_KSODD_LAYER, REACT_APP_MAP_URL } from "env-data";
import { TypedEmitter } from "tiny-typed-emitter";
import { TrafficLightDispatcher } from "trafficlight-dispatcher";
import { Events, Handlers } from "./map-controller.events";
import { transformRequest } from "./utils";
import { ControllerCollection } from "./layer-controllers";
import { FlyManager } from "./fly-manager";
import { ThemeManager } from "./theme-manager";

/** Контроллер карты, лениво инициализирует карту и инициализирует контроллеры слоев */
class MapController extends TypedEmitter<Handlers> {
    public events = Events;
    public isInitialized = false;
    public layerControllers = new ControllerCollection();
    public flyManager = new FlyManager();
    public themeManager = new ThemeManager();
    private map?: mapboxgl.Map;

    constructor(private readonly options: { accessToken: string; dispatcher: TrafficLightDispatcher }) {
        super();
    }

    public set accessToken(value: string) {
        this.options.accessToken = value;
    }

    public destroy() {
        this.unsubscribe();
        this.layerControllers.destroy();
        this.flyManager.destroy();
    }

    public resizeMap() {
        this.map?.resize();
    }

    public createMap = (container: HTMLElement) => {
        this.map = new mapboxgl.Map({
            container,
            zoom: 10,
            fadeDuration: 0,
            hash: true,
            antialias: true,
            interactive: true,
            attributionControl: false,
            transformRequest: (url: string) => transformRequest(url, this.options.accessToken),
            center: REACT_APP_MAP_CENTER as [number, number],
            style: this.style,
        });
        this.subscribe();
    };

    public zoomIn() {
        this.map?.zoomIn();
    }

    public zoomOut() {
        this.map?.zoomOut();
    }

    public getCenter() {
        return this.map?.getCenter();
    }

    private get style() {
        const baseUrl = `${REACT_APP_MAP_URL}?`;
        const layers = `l=vector&l=${REACT_APP_KSODD_LAYER}`;
        const ksoddLayerVisibility = this.layerControllers.ksoddController?.getVisibility()
            ? ""
            : `&h=${REACT_APP_KSODD_LAYER}`;

        return `${baseUrl}${layers}${this.themeManager.themeParams}${ksoddLayerVisibility}`;
    }

    private handleMapLoad = () => {
        if (!this.map) return;
        this.isInitialized = true;
        this.layerControllers.init(this.map, this.options.dispatcher);
        this.flyManager.init({
            map: this.map,
            layerControllers: this.layerControllers,
        });
        this.emit(this.events.load, this.map);
    };

    private handleThemeChange = () => {
        this.map?.setStyle(this.style, { diff: false });
    };

    private subscribe = () => {
        this.map?.on("load", this.handleMapLoad);
        this.themeManager.on(this.themeManager.events.Change, this.handleThemeChange);
    };

    private unsubscribe() {
        this.map?.off("load", this.handleMapLoad);
        this.themeManager.off(this.themeManager.events.Change, this.handleThemeChange);
        this.isInitialized = false;
    }
}

export { MapController };
