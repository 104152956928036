import { MercatorCoordinate, LngLatLike } from "mapbox-gl";
import { Direction, IDirection, ITrack } from "../models";
import { DirectionTypeCode } from "../enums";
import { TrackPainter } from "lib";

type FacilityPhaseDirectionLabel = {
    x: number;
    y: number;
    number: string;
};

const colorsMap = {
    transportColor: "#00a816",
    pedestrianColor: "#8f0000",
    urbanTransColor: "#35acb5",
};

const getTrackColorByDirectionType = (type: DirectionTypeCode | number) => {
    if (type === DirectionTypeCode.Pedestrian) return colorsMap.pedestrianColor;
    if (type === 4) return colorsMap.urbanTransColor;
    return colorsMap.transportColor;
};

export const trackAppearance = {
    lineWidth: 16,
    arrowSize: 56,
    fontSize: 100,
    textStroke: false,
};

const DEFAULT_SIZE = 102;

type MapPoint = {
    x: number;
    y: number;
};

/** TODO: Разобраться как определяются данные значения */
const center = { lat: 55.758934, lng: 37.558131 };
const offsetScale = 0.14;
const MapScale = 5.41843220338983e-8;
const MapCenter = MercatorCoordinate.fromLngLat(center);

const calcCenter = (latLng: LngLatLike): MapPoint => {
    const p = MercatorCoordinate.fromLngLat(latLng);
    return {
        x: (p.x - MapCenter.x) / MapScale,
        y: -(p.y - MapCenter.y) / MapScale,
    };
};

const getTrackPoints = (track: ITrack, directionType: IDirection["type"], center: MapPoint): MapPoint[] => {
    const points: MapPoint[] = [];

    if (track.lineData) {
        const lineData = JSON.parse(track.lineData);
        if (directionType === 2 && !Array.isArray(lineData)) {
            let p = MercatorCoordinate.fromLngLat(lineData.start);
            let x = (p.x - MapCenter.x) / MapScale - center.x + track.displayOffsetX * offsetScale;
            let y = (p.y - MapCenter.y) / MapScale + center.y + track.displayOffsetY * offsetScale;
            points.push({ x, y });

            p = MercatorCoordinate.fromLngLat(lineData.end);
            x = (p.x - MapCenter.x) / MapScale - center.x + track.displayOffsetX * offsetScale;
            y = (p.y - MapCenter.y) / MapScale + center.y + track.displayOffsetY * offsetScale;
            points.push({ x, y });
        } else if (lineData.length > 0) {
            for (let i = 0, len = lineData.length; i < len; i++) {
                const p = MercatorCoordinate.fromLngLat(lineData[i]);
                const x = (p.x - MapCenter.x) / MapScale - center.x + track.displayOffsetX * offsetScale;
                const y = (p.y - MapCenter.y) / MapScale + center.y + track.displayOffsetY * offsetScale;
                points.push({ x, y });
            }
        }
    }

    return points;
};

const convertTrackType = (trafficLightDirectionType: DirectionTypeCode) => {
    if (trafficLightDirectionType === DirectionTypeCode.Pedestrian) {
        return TrackPainter.PainterTrackType.Bidirectional;
    }
    return TrackPainter.PainterTrackType.OneDirectional;
};

type GetMappedDirectionDataArgs = {
    facilityLocation: LngLatLike;
    boxSize: number;
    direction: Direction;
};

const getMappedDirectionTracks = (args: GetMappedDirectionDataArgs): TrackPainter.PainterTrack[] => {
    const { facilityLocation, boxSize, direction } = args;
    const { number, type } = direction;
    const center = calcCenter(facilityLocation);
    const trackType = convertTrackType(type);

    const avgSize = boxSize - DEFAULT_SIZE;
    const widthScale = avgSize <= 0 ? 1 : avgSize / DEFAULT_SIZE;
    const tracks = direction.tracks.reduce((collection: TrackPainter.PainterTrack[], track) => {
        const points = getTrackPoints(track, direction.type, center);

        for (let i = 0, len = points.length; i < len; i++) {
            const p = points[i];
            points[i].x = p.x * widthScale + DEFAULT_SIZE / 2;
            points[i].y = p.y * widthScale + DEFAULT_SIZE / 2;
        }

        let label: FacilityPhaseDirectionLabel | undefined;

        if (track.nVisible === true && typeof track.nLat === "number" && typeof track.nLng === "number") {
            const labelPos = calcCenter({ lat: track.nLat, lng: track.nLng });
            label = {
                x: labelPos.x - center.x + DEFAULT_SIZE / 2,
                y: -labelPos.y + center.y + DEFAULT_SIZE / 2,
                number: `${number}`,
            } as FacilityPhaseDirectionLabel;
        }

        const color = getTrackColorByDirectionType(type);

        collection.push({ points, label, type: trackType, color, ...trackAppearance });

        return collection;
    }, []);

    return tracks;
};

export const createTracksByDirection = ({
    direction,
    coords,
    size = DEFAULT_SIZE,
}: {
    direction: Direction;
    coords: LngLatLike;
    size?: number;
}) => {
    if (!Array.isArray(direction.tracks) || typeof direction.type !== "number") return [];
    const tracks = getMappedDirectionTracks({ direction, facilityLocation: coords, boxSize: size });
    return [tracks];
};

export const createTracksByDirections = ({
    directions,
    coords,
    size = DEFAULT_SIZE,
}: {
    directions: Direction[];
    coords: LngLatLike;
    size?: number;
}) => {
    return directions.reduce((directions: TrackPainter.PainterTrack[][], direction) => {
        if (!direction || !Array.isArray(direction.tracks) || typeof direction.type !== "number") return directions;
        const mappedTracks = getMappedDirectionTracks({ direction, facilityLocation: coords, boxSize: size });
        directions.push(mappedTracks);
        return directions;
    }, []);
};
