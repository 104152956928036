import * as Env from "env-data";
import { configuration } from "api/config";

type ResponseResultType = "json" | "text" | "blob" | "arrayBuffer" | "formData" | "raw";

type RequestOptions = RequestInit & {
    url: string;
    as?: ResponseResultType;
};

export class BaseApi {
    baseUrl?: string;

    constructor(baseUrl?: string) {
        this.baseUrl = baseUrl ?? `${Env.REACT_APP_API_BASEURL}`;
    }

    protected async request({ url, as, ...options }: RequestOptions) {
        const reqOpts: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${configuration.accessToken}`,
            },
            ...options,
        };

        const response = await fetch(url, reqOpts);

        if (as === "raw") return response;

        const result = response[as || "json"]();

        return result;
    }

    protected buildUrl(endpoint: string) {
        return `${this.baseUrl}${endpoint}`;
    }
}
