import { APITypes } from "api";
import { TrafficScoreDomain } from "app-domain";

const getDataType = (value: APITypes.DtmTrafficScore["type"]) => {
    switch (value) {
        case "none":
            return TrafficScoreDomain.DataType.None;
        case "historical":
            return TrafficScoreDomain.DataType.Historical;
        case "forecast":
            return TrafficScoreDomain.DataType.Forecast;
        case "merged":
            return TrafficScoreDomain.DataType.Merged;
    }
};

export class TrafficScoreMapper {
    public fromAPI(groups: APITypes.DtmTrafficScoreGroup[]) {
        return groups?.reduce(
            (groups: TrafficScoreDomain.TrafficScoreGroup, group: APITypes.DtmTrafficScoreGroup) => {
                groups[group.split as TrafficScoreDomain.Split] = group.scores.map((item, index) => ({
                    index,
                    score: item.score,
                    time: new Date(item.time).valueOf(),
                    type: getDataType(item.type),
                }));
                return groups;
            },
            {
                [TrafficScoreDomain.Split.By_5_Minutes]: [],
                [TrafficScoreDomain.Split.By_15_Minutes]: [],
                [TrafficScoreDomain.Split.By_30_Minutes]: [],
                [TrafficScoreDomain.Split.By_60_Minutes]: [],
            }
        );
    }
}
