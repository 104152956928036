import { memo } from "react";
import { Utils } from "shared";
import { DatePicker } from "../ui";
import { Props } from "./production-date-picker.types";
import { useProductionDatePicker } from "./production-date-picker.hooks";

export const ProductionDatePicker = memo((props: Props) => {
    const { isLoading, weekends, handleViewDateChange } = useProductionDatePicker();

    return (
        <DatePicker
            {...props}
            isLoading={isLoading}
            weekends={weekends}
            formatInputDate={Utils.DateUtils.format}
            onViewDateChange={handleViewDateChange}
        />
    );
});
