import React, { memo, ReactNode, useContext, useEffect, useMemo } from "react";
import { Context } from "./context";

type Props = {
    children: ReactNode | ReactNode[];
};

const ItemsComponent = ({ children }: Props) => {
    const { limit, isShowingMore, setIsButtonVisible } = useContext(Context);
    const childArr = useMemo(() => React.Children.toArray(children), [children]);

    useEffect(() => {
        setIsButtonVisible(childArr.length > limit);
    }, [childArr, limit, setIsButtonVisible]);

    return <>{childArr.slice(0, isShowingMore ? void 0 : limit)}</>;
};

export const Items = memo(ItemsComponent);
