interface Props {
    icon: React.FC;
    title: string;
}

export const IconWithTitle = ({ icon: Icon, title }: Props) => (
    <div title={title}>
        <Icon />
    </div>
);
