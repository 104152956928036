import * as Env from "env-data";
import { BaseApi } from "api/utils";
import type { GetRouteResponse } from "./dtm-router-api.types";

export class DtmRouterAPI extends BaseApi {
    constructor() {
        super(`${Env.REACT_APP_API_DTM_ROUTER}`);
    }

    /** Поиск пути */
    public getRoute(body: string): Promise<GetRouteResponse> {
        const url = this.buildUrl("/route");
        return this.request({ url, method: "POST", body }).catch(console.log);
    }
}
