import { Alert } from "@megapolis/uikit/alert";

export enum SeverityEnum {
    info = "info",
    danger = "danger",
    success = "success",
    warning = "warning",
}

export const SeverityMap = {
    [SeverityEnum.info]: Alert.Info,
    [SeverityEnum.danger]: Alert.Danger,
    [SeverityEnum.success]: Alert.Success,
    [SeverityEnum.warning]: Alert.Warning,
};

export { Alert };
