import styled, { css } from "styled-components";
import type { StyleProps } from "./placeholder.types";

export const Placeholder = styled.span<StyleProps>(
    ({ borderRadius, $width, $height }) => css`
        display: block;
        width: ${$width ?? 16}px;
        height: ${$height ?? 16}px;
        border-radius: ${borderRadius};
        background-color: ${({ theme }) => theme.colors.gray.l4};
    `
);
