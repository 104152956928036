import styled, { css } from "styled-components";
import { Size } from "./info-card.types";

export const Container = styled.div<{ size: Size }>(({ theme, size }) => {
    const verticalPaddingIndex = size === "medium" ? 3 : 1;
    return css`
        display: flex;
        flex-flow: column nowrap;
        gap: ${theme.spacings[verticalPaddingIndex].str};
        padding: ${theme.spacings[verticalPaddingIndex].str} ${theme.spacings[3].str};
    `;
});

export const Title = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.content.secondary};
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
`;

export const Caption = styled.div<{ color?: string }>`
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${({ theme, color }) => color || theme.colors.content.primary};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
`;
