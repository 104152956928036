import { memo, useRef, useState, ReactNode, useLayoutEffect } from "react";
import styled from "styled-components/macro";

export type AutoSizerData = {
    width: number;
    height: number;
};

export type AutoSizerProps = {
    children: (data: AutoSizerData) => ReactNode;
};

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const AutoSizer = memo((props: AutoSizerProps) => {
    const [sizes, setSizes] = useState<AutoSizerData | null>(null);
    const container = useRef<HTMLDivElement | null>(null);
    const resizeObserver = useRef<ResizeObserver | null>(null);

    useLayoutEffect(() => {
        if (!container.current) return;
        resizeObserver.current = new ResizeObserver(([entry]) => {
            const { width, height } = entry.contentRect;
            setSizes({ width, height });
        });
        resizeObserver.current.observe(container.current);
        return () => {
            resizeObserver.current?.disconnect();
            resizeObserver.current = null;
        };
    }, []);

    const content = sizes === null ? null : props.children(sizes);

    return <Container ref={container}>{content}</Container>;
});
