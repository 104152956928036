import { CycleView } from "../cycle-view";
import { drawParams } from "../editor.constants";
import { Cycle } from "../editor.types";

interface GetCycleYInput {
    currentCycle: Cycle;
    prevCycle: CycleView;
    hasBackwardDirection: boolean;
    pixelPerMin: number;
}

export const getCycleY = (params: GetCycleYInput): number => {
    const { currentCycle, prevCycle } = params;

    if (!prevCycle) return drawParams.directionTop + drawParams.cycleHeadGap;

    const prevCycleTopY = prevCycle.y;

    const prevCycleBottomY = prevCycleTopY + drawParams.directionBarHeight;

    const currentCycleTopY = prevCycleBottomY + currentCycle.distance * params.pixelPerMin;

    const backwardDirectionHeight = params.hasBackwardDirection
        ? drawParams.directionBarHeight + drawParams.backwardDirectionGap
        : 0;

    return currentCycleTopY + backwardDirectionHeight;
};
