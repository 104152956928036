import { useCallback } from "react";
import { Routing } from "routing";
import { Utils } from "shared";
import { useAppState } from "./use-app-state";

export const useSetActiveCooGroup = () => {
    const { mapController, cooGroupStore } = useAppState();
    const navigate = Routing.CooGroups.Hooks.useCooGroupNavigator();
    return useCallback(
        (id: NullableNumber) => {
            if (id === null) {
                return navigate({ type: Routing.CooGroups.PageType.List });
            }
            const cooGroup = cooGroupStore.getById(id);
            if (!cooGroup) return;
            navigate({ type: Routing.CooGroups.PageType.Details, id: cooGroup.id });
            const coordinates = Utils.covertFeaturesToPositionList(cooGroup.geometry?.routeFeatures?.features ?? []);
            mapController.flyManager.handleCooGroupPosition(coordinates);
        },
        [navigate, mapController, cooGroupStore]
    );
};
