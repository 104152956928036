import { IssueStatusCode } from "../enums";
import { CodeDictionary } from "../detectors";

type CodeDictionaryMap = { [key in IssueStatusCode]: CodeDictionary<IssueStatusCode> };

export const issueStatusCodeDictionaryMap: CodeDictionaryMap = {
    [IssueStatusCode.Dismantling]: {
        name: "Демонтаж",
        color: "#83909A",
        code: IssueStatusCode.Dismantling,
    },
    [IssueStatusCode.Works]: {
        name: "Работает",
        color: "#289F46",
        code: IssueStatusCode.Works,
    },
    [IssueStatusCode.WorksWithRemarks]: {
        name: "Работает с замечаниями",
        color: "#FECB00",
        code: IssueStatusCode.WorksWithRemarks,
    },
    [IssueStatusCode.NoWorks]: {
        name: "Не работает",
        color: "#FF3A2F",
        code: IssueStatusCode.NoWorks,
    },
    [IssueStatusCode.RoutineWork]: {
        name: "Регламентные работы",
        color: "#5ACAFD",
        code: IssueStatusCode.RoutineWork,
    },
};

export const issueStatusCodeDictionaryList = Object.values(issueStatusCodeDictionaryMap);
