import { CanvasRenderer } from "lib/canvas";
import { CycleDrawer } from "./cycle-drawer";
import { CycleData, PhaseData, ChangeHandler } from "../traffic-light-cycle-editor.types";

type Params = {
    cycle: CycleData;
    canvas: HTMLCanvasElement;
    isEditMode?: boolean;
    areDirectionsVisible?: boolean;
    onChange?: ChangeHandler;
};

type DragState = {
    x: number;
    phaseIndex: number;
};
export class CycleEditor extends CanvasRenderer {
    private static BAR_HEIGHT = 20;
    private static TOP_OFFSET = 10;
    private static DIRECTIONS_BLOCK_GAP = 4;
    private static DIRECTIONS_BLOCK_TOP = CycleEditor.TOP_OFFSET + CycleEditor.BAR_HEIGHT + 30;
    private static DRAG_HANDLE_WIDTH = 2;
    private static TIME_SCALE_HEIGHT = 8;

    private originCycle: CycleData;
    private currentCycle: CycleData;
    private isEditMode: boolean;
    private areDirectionsVisible: boolean;
    private pixelsPerSecond: number = 0;
    private dragState: DragState | null = null;
    private cycleDrawer!: CycleDrawer;
    private hasChanges: boolean = false;
    private onChange?: ChangeHandler;

    constructor(params: Params) {
        super(params.canvas, false);
        this.isEditMode = params.isEditMode ?? false;
        this.areDirectionsVisible = params.areDirectionsVisible ?? false;
        this.onChange = params.onChange;
        if (params.isEditMode) {
            this.subscribe();
        }
        this.initDrawer();
        this.originCycle = this.copyCycle(params.cycle);
        this.currentCycle = this.copyCycle(params.cycle);
    }

    public readonly updateCanvasSize = () => {
        this.resize();
    };

    public setOriginCycle(cycle: CycleData) {
        this.originCycle = this.copyCycle(cycle);
        this.currentCycle = this.copyCycle(cycle);
        this.render();
    }

    public setCurrentCycle(cycle: CycleData) {
        this.currentCycle = this.copyCycle(cycle);
        this.checkForChanges();
        this.render();
    }

    public setDirectionsVisibility(value: boolean) {
        this.areDirectionsVisible = value;
        this.render();
    }

    public destroy(): void {
        this.unsubscribe();
        super.destroy();
    }

    public setEditMode(value: boolean): void {
        this.isEditMode = value;

        this.innerRender();

        if (this.isEditMode) {
            this.subscribe();
        } else {
            this.unsubscribe();
        }
    }

    protected innerRender(): void {
        this.updateRenderingData();
        this.cycleDrawer.drawTimeScale();
        this.renderCurrentCycle();
        this.renderOriginCycle();
        this.renderStartFinishTimes();
    }

    private get ctx() {
        return this._ctx;
    }

    private initDrawer() {
        this.cycleDrawer = new CycleDrawer(this.ctx);
        this.cycleDrawer.topOffset = CycleEditor.TOP_OFFSET;
        this.cycleDrawer.barHeight = CycleEditor.BAR_HEIGHT;
        this.cycleDrawer.directionsBlockTop = CycleEditor.DIRECTIONS_BLOCK_TOP;
        this.cycleDrawer.timeScaleTop = CycleEditor.TOP_OFFSET + CycleEditor.BAR_HEIGHT;
        this.cycleDrawer.timeScaleHeight = CycleEditor.TIME_SCALE_HEIGHT;
        this.cycleDrawer.dragHandleWidth = CycleEditor.DRAG_HANDLE_WIDTH;
    }

    private updateHeight() {
        const height = this.areDirectionsVisible
            ? this.currentCycle.directions.length * (CanvasRenderer.DIRECTION_BAR_HEIGHT + 4) +
              CanvasRenderer.DIRECTION_BAR_HEIGHT +
              30
            : CanvasRenderer.DIRECTION_BAR_HEIGHT + 25;
        this._canvas.style.height = `${CycleEditor.TOP_OFFSET + height}px`;
    }

    private updateRenderingData() {
        this.updateHeight();
        this.pixelsPerSecond = this._width / this.currentCycle.time;
        this.cycleDrawer.pixelsPerSecond = this.pixelsPerSecond;
    }

    /** Основной цикл отрисовки программы СО */
    private renderCurrentCycle() {
        /** Начальное время фазы в секундах, для первой фазы равно 0 */
        let startTime = 0;
        this.currentCycle.phases.forEach((phase, phaseIndex, arr) => {
            const renderData = this.getPhaseRenderData(startTime, phase);
            const phaseWidth = renderData.baseWidth;

            this.cycleDrawer.drawPhase(renderData.startX, phaseWidth, phase);

            if (phaseIndex > 0) {
                if (this.isEditMode) {
                    this.cycleDrawer.drawPhaseDragHandle(renderData.startX);
                }
                this.cycleDrawer.drawTime(renderData.startX, startTime);
            }
            if (this.areDirectionsVisible) {
                const nextPhase = phaseIndex === arr.length - 1 ? arr[0] : arr[phaseIndex + 1];
                this.drawPhaseDirections({ x: renderData.startX, width: phaseWidth, phase, phaseIndex, nextPhase });
            }
            startTime += phase.tBasic + phase.tProm;
        });
    }

    /** Рисует времена и указатели изменений оригинального цикла, относительно текущего */
    private renderOriginCycle() {
        if (!this.hasChanges) return;
        const firstPhase = this.originCycle.phases[0];
        let startTime = firstPhase.tBasic + firstPhase.tProm;
        for (let i = 1; i < this.originCycle.phases.length; i++) {
            const currentPhase = this.currentCycle.phases[i];
            const currentPrevPhase = this.currentCycle.phases[i - 1];
            const originPrevPhase = this.originCycle.phases[i - 1];
            const originPhase = this.originCycle.phases[i];
            const originRenderData = this.getPhaseRenderData(startTime, originPhase);
            if (originPhase.tBasic !== currentPhase.tBasic && originPrevPhase.tBasic !== currentPrevPhase.tBasic) {
                this.cycleDrawer.drawOriginPhasePointer(originRenderData.startX, 0);
                this.cycleDrawer.drawTime(originRenderData.startX, startTime, 0.32);
            }
            startTime += originPhase.tBasic + originPhase.tProm;
        }
    }

    private renderStartFinishTimes() {
        this.cycleDrawer.drawTime(0, 0);
        this.cycleDrawer.drawTime(this._width, this.currentCycle.time);
    }

    private getPhaseRenderData(startTime: number, phase: PhaseData) {
        /** Полное время фазы с учетом промтакта */
        const fullTime = phase.tBasic + phase.tProm;
        /** Стартовая позиция фазы по X в пикселях */
        const startX = startTime * this.pixelsPerSecond;
        /** Базовая ширина блока фазы без учета разделителя */
        const baseWidth = (startTime + fullTime) * this.pixelsPerSecond - startTime * this.pixelsPerSecond;
        return {
            fullTime,
            startX,
            baseWidth,
        };
    }

    /** Рисует направления для фазы */
    private drawPhaseDirections(options: {
        x: number;
        width: number;
        phase: PhaseData;
        phaseIndex: number;
        nextPhase: PhaseData;
    }) {
        const { x, width, phase, phaseIndex, nextPhase } = options;
        this.ctx.save();
        let y = CycleEditor.DIRECTIONS_BLOCK_TOP;
        this.currentCycle.directions.forEach((direction) => {
            this.cycleDrawer.drawDirection({
                x,
                y,
                width,
                direction,
                nextPhase,
                currentPhase: phase,
                drawIcon: phaseIndex === 0,
            });
            y += CycleEditor.BAR_HEIGHT + CycleEditor.DIRECTIONS_BLOCK_GAP;
        });
        this.ctx.restore();
    }

    private copyCycle(cycle: CycleData) {
        return {
            ...cycle,
            phases: cycle.phases.map((p) => ({ ...p })),
            directions: cycle.directions.slice(),
            time: cycle.time,
        };
    }

    private checkForChanges() {
        this.hasChanges = this.originCycle.phases.some(
            (originPhase, index) => this.currentCycle.phases[index].tBasic !== originPhase.tBasic
        );
    }

    private hitTest = (x: number, y: number): number | null => {
        if (y > CycleEditor.TOP_OFFSET + CycleEditor.BAR_HEIGHT || y < CycleEditor.TOP_OFFSET) return null;

        const phases = this.currentCycle.phases;

        let start = 0;
        for (let i = 0; i < phases.length; i++) {
            const p = phases[i];
            if (i > 0) {
                const xp = start * this.pixelsPerSecond;

                if (x > xp - 6 && x < xp + 6) {
                    return i;
                }
            }
            start += p.tBasic + p.tProm;
        }
        return null;
    };

    private setCursor(value: "resize" | "default") {
        document.body.style.cursor = value === "resize" ? "ew-resize" : "default";
    }

    private handleMouseMove = (e: MouseEvent) => {
        const testIndex = this.hitTest(e.offsetX, e.offsetY);
        if (this.dragState || (e.target === this._canvas && testIndex)) {
            this.setCursor("resize");
            e.preventDefault();
        } else {
            this.setCursor("default");
        }

        if (!this.dragState) return;

        const phases = this.currentCycle.phases;
        const { phaseIndex, x } = this.dragState;

        const phaseRight = phases[phaseIndex];
        const phaseLeft = phases[phaseIndex - 1];

        let timeDelta = Math.round((e.pageX - x) / this.pixelsPerSecond);

        if (timeDelta === 0) return;

        const [rightTMin, leftTMin] = [phaseRight.tMin || 3, phaseLeft.tMin || 3];

        if (phaseRight.tBasic - timeDelta < rightTMin) {
            timeDelta = phaseRight.tBasic - rightTMin;
        }
        if (phaseLeft.tBasic + timeDelta < leftTMin) {
            timeDelta = leftTMin - phaseLeft.tBasic;
        }
        const currentPhase = this.currentCycle.phases[phaseIndex];
        currentPhase.tBasic = phaseRight.tBasic - timeDelta;
        currentPhase.tPhase = phaseRight.tPhase - timeDelta;
        this.currentCycle.phases[phaseIndex - 1].tBasic = phaseLeft.tBasic + timeDelta;
        this.dragState.x = x + this.pixelsPerSecond * timeDelta;
        this.checkForChanges();
        this.render();
    };

    private handleMouseEnter = () => {
        document.addEventListener("mousemove", this.handleMouseMove);
    };

    private handleMouseLeave = () => {
        document.removeEventListener("mousemove", this.handleMouseMove);
    };

    private handleMouseDown = (e: MouseEvent) => {
        if (e.target !== this._canvas) return;
        const phaseIndex = this.hitTest(e.offsetX, e.offsetY);
        document.addEventListener("mouseup", this.handleMouseUp);
        if (!phaseIndex) return;
        this.dragState = {
            x: e.pageX,
            phaseIndex,
        };
    };

    private handleMouseUp = (e: MouseEvent) => {
        if (this.dragState) {
            this.dragState = null;
            if (e.target !== this._canvas) this.setCursor("default");
        }
        this.onChange?.({
            newCycle: this.currentCycle,
            oldCycle: this.originCycle,
            hasChanges: this.hasChanges,
        });
        document.removeEventListener("mouseup", this.handleMouseUp);
    };

    private subscribe() {
        this._canvas.addEventListener("mousedown", this.handleMouseDown);
        this._canvas.addEventListener("mouseenter", this.handleMouseEnter);
        this._canvas.addEventListener("mouseleave", this.handleMouseLeave);
    }

    private unsubscribe() {
        this._canvas.removeEventListener("mousedown", this.handleMouseDown);
        this._canvas.removeEventListener("mouseenter", this.handleMouseEnter);
        this._canvas.removeEventListener("mouseleave", this.handleMouseLeave);
    }
}
