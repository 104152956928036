import React from "react";
import type { Props } from "./detector-chart-for-short-card.types";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export const useDrawChart = (props: Props) => {
    const { config } = props;
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

    React.useLayoutEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext("2d");
        if (!ctx) return console.warn("not found context");

        const chart = new Chart(ctx, config);

        return () => {
            chart.destroy();
        };
    }, [config]);

    return { canvasRef };
};
