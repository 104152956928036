export enum Type {
    /** Не активна */
    Active = 0,
    /**  Координация */
    Coordination = 1,
    /** Зелёная улица */
    GreenStreet = 2,
    /** Адаптивная координация */
    AdaptiveCoordination = 4,
}
