export class URLBuilder {
    protected pathname = "";

    constructor(basePath: string) {
        this.pathname = basePath;
    }

    public get result() {
        return this.pathname;
    }

    public withPath(value: string | number) {
        this.pathname += "/" + value;
        return this;
    }

    public withNew() {
        return this.withPath("new");
    }

    public withOptionalPath(value?: string) {
        if (!value || value.length === 0) return;
        return this.withPath(value);
    }

    public withId(id: string | number) {
        return this.withPath(id);
    }
}
