import React from "react";
import type { Props } from "./issues.types";

export const useHandleClick = (props: Props) => {
    const { uid } = props;

    const handleClick = React.useCallback(() => {
        console.info("uid:", uid);
    }, [uid]);

    return { handleClick };
};
