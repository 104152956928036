class ApiConfiguration {
    private _accessToken: string = "";

    setToken(accessToken: string) {
        this._accessToken = accessToken;
    }

    get accessToken() {
        return this._accessToken;
    }
}

export const configuration = new ApiConfiguration();
