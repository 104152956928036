import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { DetectorsURLParams } from "./detectors.types";

export const useDetectorNavigator = () => {
    const {
        push,
        location: { hash },
    } = useHistory();

    return useCallback(
        (params: DetectorsURLParams) => {
            const basePath = `/${params.type}`;

            if (params.type === "detectors") {
                return push({ pathname: `${basePath}/${params.id ?? ""}`, hash });
            }

            return push({ pathname: `${basePath}/${params.id}`, hash });
        },
        [push, hash]
    );
};

export const useDetectorDetailsNavigator = () => {
    const navigate = useDetectorNavigator();

    return useCallback(
        (id: string | number) => {
            navigate({ id: String(id), type: "detector" });
        },
        [navigate]
    );
};

export const useDetectorListNavigator = () => {
    const navigate = useDetectorNavigator();

    return useCallback(() => {
        navigate({ type: "detectors" });
    }, [navigate]);
};
