import styled from "styled-components";
import { Icons } from "@megapolis/uikit/icons";

export const Icon = styled(Icons.Monochrome.Search)`
    fill: ${(props) => props.theme.colors.content.secondary};
`;

export const IndicatorWrapper = styled.span`
    display: flex;
`;

export const ClearBtn = styled(Icons.Monochrome.CloseM)`
    fill: ${(props) => props.theme.colors.content.secondary};
    &:hover {
        cursor: pointer;
    }
`;
