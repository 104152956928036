import { Props, Times } from "./timer.types";

export const getInitialTimes = (duration: Props["duration"]): Times => ({
    days: duration.days ?? 0,
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
    seconds: duration.seconds,
});

const formatTime = (time: number, addSeparator = false, hideZeroValue = false) => {
    if (time === 0 && hideZeroValue) return "";
    const newTime = String(time).padStart(2, "0");
    return addSeparator ? newTime + ":" : newTime;
};

type PrepareTimesOptions = Pick<Props, "hideZeroHours" | "hideZeroMinutes">;

export const prepareTimes = (times: Times, options: PrepareTimesOptions) => {
    const { hours, minutes, seconds, days } = times;
    if (hours === 0 && minutes === 0 && seconds === 0 && days === 0) return null;

    return `${days ? `${days}д ` : ""}${formatTime(hours, true, options.hideZeroHours)}${formatTime(
        minutes,
        true,
        options.hideZeroMinutes
    )}${formatTime(seconds)}`;
};
export const reduceTime = ({ minutes, hours, seconds, days }: Times): Times => {
    let newSeconds = seconds - 1;

    if (days > 0 && hours === 0 && minutes === 0 && newSeconds <= 0) {
        days--;
        hours = 23;
        minutes = 59;
        newSeconds = 59;
    }
    if (hours > 0 && minutes === 0 && newSeconds <= 0) {
        hours -= 1;
        minutes = 59;
        newSeconds = 59;
    }
    if (seconds <= 0 && minutes > 0) {
        minutes -= 1;
        newSeconds = 59;
    }
    newSeconds = newSeconds < 0 ? 0 : newSeconds;

    return {
        seconds: newSeconds,
        minutes,
        hours,
        days,
    };
};
