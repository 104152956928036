import { DictionaryItem } from ".";

/**Код режима управления группой координации */
export enum TrafficLightGroupControlModeCode {
    /** Отключена */
    Disabled = 0,

    /**Календарный план */
    Scheduled = 1,

    /**Ручное управлени */
    Manual = 2,
}

export interface TrafficLightGroupControlMode extends DictionaryItem<TrafficLightGroupControlModeCode> {}

export const trafficLightGroupControlModes: {
    [key in TrafficLightGroupControlModeCode]: TrafficLightGroupControlMode;
} = {
    [TrafficLightGroupControlModeCode.Scheduled]: {
        code: TrafficLightGroupControlModeCode.Scheduled,
        name: "Календарный план",
        shortName: "ПР",
        color: "#01DBB4",
    },
    [TrafficLightGroupControlModeCode.Manual]: {
        code: TrafficLightGroupControlModeCode.Manual,
        name: "Ручное управление",
        shortName: "РУ",
        color: "#A938E2",
    },

    [TrafficLightGroupControlModeCode.Disabled]: {
        code: TrafficLightGroupControlModeCode.Disabled,
        name: "Отключена",
        shortName: "НД",
        color: "#CECECE",
    },
};
