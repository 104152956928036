import { CommonDrawProps, DrawFn, DrawPath2DFn } from "./drawing.types";

export const defaultFont = "500 12px Inter";

export const makeDrawer =
    <Props extends CommonDrawProps = CommonDrawProps>(drawFn: DrawFn<Props>): DrawFn<Props> =>
    (ctx, props) => {
        ctx.save();
        drawFn(ctx, props);
        ctx.restore();
    };

export const makePath2DDrawer =
    <Props extends CommonDrawProps = CommonDrawProps>(drawFn: DrawPath2DFn<Props>): DrawPath2DFn<Props> =>
    (ctx, props): Path2D => {
        ctx.save();
        const path = drawFn(ctx, props);
        ctx.restore();
        return path;
    };
