import { EventTypeCode } from "../enums";
import { CodeDictionary } from "../types";

type Map = { [key in EventTypeCode]: Omit<CodeDictionary<EventTypeCode>, "shortName" | "color"> };

export const eventTypeCodeDictionaryMap: Map = {
    // [EventTypeCode.BaseEvent]: {
    //     code: EventTypeCode.BaseEvent,
    //     name: "Общее событие",
    // },
    [EventTypeCode.Sync]: {
        code: EventTypeCode.Sync,
        name: "Синхронизация",
    },
    [EventTypeCode.PhaseSwitch]: {
        code: EventTypeCode.PhaseSwitch,
        name: "Переключение фазы",
    },
    [EventTypeCode.PromStart]: {
        code: EventTypeCode.PromStart,
        name: "Начало промтакта",
    },
    [EventTypeCode.ControlModeChange]: {
        code: EventTypeCode.ControlModeChange,
        name: "Смена режима управления",
    },
    [EventTypeCode.TakeGovernanceEvent]: {
        code: EventTypeCode.TakeGovernanceEvent,
        name: "Активирован режим диспетчерского управления",
    },
    [EventTypeCode.ReleaseGovernanceEvent]: {
        code: EventTypeCode.ReleaseGovernanceEvent,
        name: "Отключен режим диспетчерского управления",
    },
    [EventTypeCode.BaseMalfunction]: {
        code: EventTypeCode.BaseMalfunction,
        name: "Неисправность",
    },
    [EventTypeCode.StatusSwitch]: {
        code: EventTypeCode.StatusSwitch,
        name: "Смена состояния",
    },
    [EventTypeCode.TVPButton]: {
        code: EventTypeCode.TVPButton,
        name: "Нажатие кнопки ТВП",
    },
    [EventTypeCode.CoomgmtPlanState]: {
        code: EventTypeCode.CoomgmtPlanState,
        name: "Применение временного плана",
    },
    [EventTypeCode.BaseRepair]: {
        code: EventTypeCode.BaseRepair,
        name: "Устранение неисправности",
    },
    [EventTypeCode.BaseCommand]: {
        code: EventTypeCode.BaseCommand,
        name: "Управление",
    },
    [EventTypeCode.SwitchCycleCommand]: {
        code: EventTypeCode.SwitchCycleCommand,
        name: "Переключение программы",
    },
    [EventTypeCode.SwitchPhaseCommand]: {
        code: EventTypeCode.SwitchPhaseCommand,
        name: "Команда на переключение фазы",
    },
    [EventTypeCode.SwitchStatusCommand]: {
        code: EventTypeCode.SwitchStatusCommand,
        name: "Команда на переключение режима",
    },
    [EventTypeCode.TakeGovernanceCommand]: {
        code: EventTypeCode.TakeGovernanceCommand,
        name: "Запрос режима диспетчерского управления",
    },
    [EventTypeCode.ReleaseGovernanceCommand]: {
        code: EventTypeCode.ReleaseGovernanceCommand,
        name: "Запрос отключения режима диспетчерского управления",
    },
    [EventTypeCode.FacilityControlModeAppliedChange]: {
        code: EventTypeCode.FacilityControlModeAppliedChange,
        name: "Режим применён",
    },
    [EventTypeCode.UpdateGovernanceEvent]: {
        code: EventTypeCode.UpdateGovernanceEvent,
        name: "Обновлены данные диспетчерского управления",
    },
    [EventTypeCode.PlannedGovernanceTimeEvent]: {
        code: EventTypeCode.PlannedGovernanceTimeEvent,
        name: "Запуск отложенной команды произойдет через час",
    },
    [EventTypeCode.SwitchCooGroupModeCommand]: {
        code: EventTypeCode.SwitchCooGroupModeCommand,
        name: "Переключение режима работы группы координации",
    },
    [EventTypeCode.SetEnabledCooGroupCommand]: {
        code: EventTypeCode.SetEnabledCooGroupCommand,
        name: "Включение/отключение группы координации",
    },
    [EventTypeCode.QueryCyclesCommand]: {
        code: EventTypeCode.QueryCyclesCommand,
        name: "Запрос программ с контроллера",
    },
    [EventTypeCode.CoordinatedManagementCommand]: {
        code: EventTypeCode.CoordinatedManagementCommand,
        name: "Включение координированного управления",
    },
    [EventTypeCode.QueryCurrentCycleCommand]: {
        code: EventTypeCode.QueryCurrentCycleCommand,
        name: "Запрос текущей программы",
    },
    [EventTypeCode.ApplyTemporaryCycleCommand]: {
        code: EventTypeCode.ApplyTemporaryCycleCommand,
        name: "Применение временного плана",
    },
    [EventTypeCode.SetGuidedAdaptiveAllowanceCommand]: {
        code: EventTypeCode.SetGuidedAdaptiveAllowanceCommand,
        name: "Включение/отключение адаптивного режима",
    },
    [EventTypeCode.DetectorEvent]: {
        code: EventTypeCode.DetectorEvent,
        name: "Событие с детектора",
    },
};

export const eventTypeCodeDictionaryList = Object.values(eventTypeCodeDictionaryMap);
