import styled, { css } from "styled-components/macro";
import { Icons } from "@megapolis/uikit/icons";

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacings[4].str};
`;

export const SyncBlock = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacings[1].str};
`;

export const Distance = styled.span(
    ({ theme }) => css`
        font: ${theme.typography.h6};
        color: ${theme.colors.content.secondary};
    `
);

export const DirectSpeedIcon = styled(Icons.Monochrome.ArrowNarrowRight)`
    fill: ${(props) => props.theme.colors.green.basic};
`;

export const ReverseSpeedIcon = styled(Icons.Monochrome.ArrowNarrowLeft)`
    fill: ${(props) => props.theme.colors.blue.basic};
`;
