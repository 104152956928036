import { memo, useCallback, useMemo } from "react";
import { SearchField } from "@megapolis/uikit/search-field";
import { Button } from "@megapolis/uikit/button";
import { WrappedStackHeader, Caption } from "./search-header.styles";

export type SearchHeaderProps = {
    value: string;
    onChange: (value: string) => void;
    itemsCount?: number;
    onApply?: VoidFunction;
    onCancel?: VoidFunction;
    onClear?: VoidFunction;
    className?: string;
};

const SearchHeaderComponent = (props: SearchHeaderProps) => {
    const { value, onChange, onCancel, onApply, itemsCount, className } = props;

    const handleSearchChange = useCallback(
        ({ value }: { value: string }) => {
            onChange(value);
        },
        [onChange]
    );

    const caption = useMemo(() => {
        return itemsCount ? <Caption>Результатов поиска: {itemsCount}</Caption> : null;
    }, [itemsCount]);

    return (
        <WrappedStackHeader
            className={className}
            title={<SearchField value={value} onChange={handleSearchChange} onApply={onApply} />}
            right={<Button content={Button.Content.Text} priority="quaternary" onClick={onCancel} text="Отменить" />}
            bottomCaption={caption}
        />
    );
};

export const SearchHeader = memo(SearchHeaderComponent);
