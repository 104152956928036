import { CanvasObject, CanvasObjectProps } from "./canvas-object";

export type TextObjectProps = CanvasObjectProps & {
    text?: string;
    maxWidth?: number;
    /**
     * параметры шрифта
     * @example "500 12px Inter"
     */
    font?: string;
    inlineColor?: string;
    outlineColor?: string;
    outlineWidth?: number;
    /**
     * @default "start"
     */
    align?: CanvasTextAlign;
    /**
     * @default "alphabetic"
     */
    baseLine?: CanvasTextBaseline;
};

export class Text extends CanvasObject {
    /** цвет текста */
    public inlineColor: string;
    /** цвет обводки */
    public outlineColor?: string;
    public outlineWidth: number;
    public text: string;
    public maxWidth: number | null;
    public align: CanvasTextAlign;
    public baseLine: CanvasTextBaseline;
    public font?: string;

    constructor(props?: TextObjectProps) {
        super(props);
        this.text = props?.text ?? "";
        this.maxWidth = props?.maxWidth ?? null;
        this.inlineColor = props?.inlineColor ?? "#000";
        this.outlineColor = props?.outlineColor;
        this.font = props?.font;
        this.align = props?.align ?? "start";
        this.baseLine = props?.baseLine ?? "alphabetic";
        this.outlineWidth = props?.outlineWidth ?? 1;
    }

    protected _render(ctx: CanvasRenderingContext2D) {
        ctx.textAlign = this.align;
        ctx.textBaseline = this.baseLine;
        if (this.font) ctx.font = this.font;
        if (this.outlineColor) {
            ctx.lineWidth = this.outlineWidth;
            ctx.strokeStyle = this.outlineColor;
            ctx.strokeText(this.text, this.x, this.y, this.maxWidth ?? undefined);
        }
        ctx.fillStyle = this.inlineColor;
        ctx.fillText(this.text, this.x, this.y, this.maxWidth ?? undefined);
    }
}
