import { Geocode } from "api";
import { RouteDomain } from "app-domain";

export class GeocodeService {
    private getAddressesBySearchTimer: number = 0;

    constructor(private api: Geocode.GeocodeAPI) {}

    public getAddressesBySearch = async (search: string): Promise<RouteDomain.Point[]> => {
        if (this.getAddressesBySearchTimer) window.clearTimeout(this.getAddressesBySearchTimer);
        return new Promise((resolve) => {
            this.getAddressesBySearchTimer = window.setTimeout(async () => {
                const result = await this.api.getAddressesBySearch(search);
                if (Array.isArray(result) && result.length) {
                    resolve(result.map((item) => new RouteDomain.Point(item)));
                } else {
                    resolve([]);
                }
            }, 300);
        });
    };

    public getAddressesByLngLat = async (lngLat: { lng: number; lat: number }) => {
        const result = await this.api.getAddressesByLngLat({ lon: lngLat.lng, lat: lngLat.lat });
        if (Array.isArray(result) && result.length) {
            return new RouteDomain.Point(result[0]);
        }
        return null;
    };
}
