import { TrafficLightDomain } from "app-domain";
import { TextureCoord } from "./traffic-light-layer";

const canvasSize = 2048;
const iconSize = 128;
const iconSize2 = iconSize / 2;
const strokeWidth = 6;
const controlModeBorderWidth = 12;
const fontSize = 72;

// Для разного зума меняется радиус светофор относительно iconsize
// zoom : [коэффициент умножения радиуса, смещение для фокуса]
const zoomMultiplier: { [key: number]: number[] } = {
    14: [1, 20],
    13: [0.9, 18],
    12: [0.8, 16],
    11: [0.7, 14],
    10: [0.6, 12],
    9: [0.5, 10],
};

export default class StaticSprite {
    items: { [key: string]: TextureCoord } = {};
    readonly canvas: HTMLCanvasElement;
    private _ctx: CanvasRenderingContext2D;

    constructor(zoom: number) {
        this.canvas = document.createElement("canvas");
        const ctx = this.canvas.getContext("2d");
        if (!ctx) throw new Error("getContext2d error");

        this._ctx = ctx;
        this.canvas.width = canvasSize;
        this.canvas.height = canvasSize;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = `${fontSize}px Arial`;
        ctx.strokeStyle = "#fff";
        ctx.lineWidth = strokeWidth;

        this.update = this.update.bind(this);

        this.update(zoom);
    }

    private drawFocus(ctx: CanvasRenderingContext2D, x: number, y: number, radius: number) {
        const size2 = iconSize / 2;
        ctx.strokeStyle = "rgba(3,102,252,0.4)";
        ctx.lineWidth = 26;
        ctx.beginPath();
        ctx.arc(x + size2, y + size2, radius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.stroke();

        return {
            x1: x / canvasSize,
            y2: y / canvasSize,
            x2: (x + iconSize) / canvasSize,
            y1: (y + iconSize) / canvasSize,
        };
    }

    private drawTextIcon(ctx: CanvasRenderingContext2D, x: number, y: number, text: string) {
        ctx.fillStyle = "#fff";
        ctx.fillText(text, x + iconSize2, y + iconSize2 + controlModeBorderWidth / 2);

        return {
            x1: x / canvasSize,
            y2: y / canvasSize,
            x2: (x + iconSize) / canvasSize,
            y1: (y + iconSize) / canvasSize,
        };
    }

    private drawControlModeIcon(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        radius: number,
        status: TrafficLightDomain.CodeDictionary<TrafficLightDomain.ControlModeCode>
    ) {
        const size2 = iconSize / 2;

        ctx.fillStyle = "#FFF";

        ctx.lineWidth = 0;
        ctx.beginPath();
        ctx.arc(x + size2, y + size2, radius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = "transparent";
        ctx.strokeStyle = status.color;
        ctx.lineWidth = controlModeBorderWidth;
        ctx.beginPath();
        ctx.arc(x + size2, y + size2, radius - controlModeBorderWidth / 2 - 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.stroke();

        return {
            x1: x / canvasSize,
            y2: y / canvasSize,
            x2: (x + iconSize) / canvasSize,
            y1: (y + iconSize) / canvasSize,
        };
    }

    private drawStatusIcon(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        radius: number,
        status: TrafficLightDomain.CodeDictionary<TrafficLightDomain.StatusCode>
    ) {
        const size2 = iconSize / 2;
        ctx.fillStyle = status.color;
        ctx.strokeStyle = "#fff";
        ctx.lineWidth = 0;
        ctx.beginPath();
        ctx.arc(x + size2, y + size2, radius - controlModeBorderWidth / 2 - 10, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
        //  ctx.stroke();

        return {
            x1: x / canvasSize,
            y2: y / canvasSize,
            x2: (x + iconSize) / canvasSize,
            y1: (y + iconSize) / canvasSize,
        };
    }

    update(zoom: number) {
        this._ctx.clearRect(0, 0, canvasSize, canvasSize);

        const [multiplier, focusOffset] = zoomMultiplier[zoom] || [0.5, 10];

        const iconRadius = Math.round((iconSize / 2 - strokeWidth) * multiplier);

        const focusRadius = Math.round((iconSize / 2 - focusOffset) * multiplier);

        this.items = {};
        let phase = 1;
        // let mode = TrafficLightModeCode.Disabled;

        let controlModeIndex = 0;
        let statusIndex = 0;

        const controlModes = Object.keys(TrafficLightDomain.Constants.controlModeCodeDictionaryMap).map(
            (k) => Number(k) as TrafficLightDomain.ControlModeCode
        );
        const statusList = Object.keys(TrafficLightDomain.Constants.statusCodeDictionaryMap).map(
            (k) => Number(k) as TrafficLightDomain.Enums.StatusCode
        );

        this.items.focus = this.drawFocus(this._ctx, canvasSize - iconSize, canvasSize - iconSize, focusRadius);
        this._ctx.strokeStyle = "#fff";
        this._ctx.lineWidth = strokeWidth;
        const lineCount = canvasSize / iconSize;

        for (let i = 0; i < lineCount; i++) {
            for (let j = 0; j < lineCount; j++) {
                if (controlModeIndex < controlModes.length) {
                    const code = controlModes[controlModeIndex];
                    this.items[`controlmode_${code}`] = this.drawControlModeIcon(
                        this._ctx,
                        j * iconSize,
                        i * iconSize,
                        iconRadius,
                        TrafficLightDomain.Constants.controlModeCodeDictionaryMap[code]
                    );
                    controlModeIndex++;
                } else if (statusIndex < statusList.length) {
                    const code = statusList[statusIndex];
                    this.items[`status_${code}`] = this.drawStatusIcon(
                        this._ctx,
                        j * iconSize,
                        i * iconSize,
                        iconRadius,
                        TrafficLightDomain.Constants.statusCodeDictionaryMap[code]
                    );
                    statusIndex++;
                } else {
                    this.items[`phase_${phase}`] = this.drawTextIcon(
                        this._ctx,
                        j * iconSize,
                        i * iconSize,
                        phase.toString()
                    );
                    phase++;
                    if (phase >= 100) return;
                }
            }
        }
    }
}
