import mapboxgl, { Marker } from "mapbox-gl";
import { ruAlphabet } from "shared/constants";
import { getIconByViewType } from "./route-points.utils";
import type { MarkerType, MarkerParams } from "./route-points.types";

export class RoutePointsMarker {
    private markers: Map<string, MarkerType> = new Map();

    constructor(protected map: mapboxgl.Map) {}

    public setData = (data: MarkerParams[][]) => {
        this.markers.forEach(this.removeMarker);
        for (let markers of data) {
            markers.forEach(this.addMarker);
        }
    };

    public destroy = () => {
        this.markers.forEach(this.removeMarker);
    };

    private removeMarker = (item: MarkerType, id: string) => {
        item.marker.remove();
        this.markers.delete(id);
    };

    private addMarker = (markerParams: MarkerParams, index: number) => {
        const icon = new Image();
        icon.src = getIconByViewType(markerParams.viewType, ruAlphabet[index]);

        const marker = new Marker({
            element: icon,
            anchor: "bottom",
        })
            .setLngLat(markerParams.lngLat)
            .addTo(this.map);

        this.markers.set(markerParams.id, {
            marker,
            viewType: markerParams.viewType,
        });
    };
}
