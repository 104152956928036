import { CanvasSVG } from "lib";
import { Drawer } from "diagrams/drawers";
import ArrowNarrowRightIconUrl from "diagrams/icons/arrow-narrow-right.svg";
import ArrowNarrowLeftIconUrl from "diagrams/icons/arrow-narrow-left.svg";
import { SpeedDistanceLegendData } from "./speed-distance-legend-drawer.types";

export class SpeedDistanceLegendDrawer extends Drawer<SpeedDistanceLegendData> {
    private arrowNarrowRightIcon = new CanvasSVG(ArrowNarrowRightIconUrl);
    private arrowNarrowLeftIcon = new CanvasSVG(ArrowNarrowLeftIconUrl);

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.initIcons();
    }

    protected draw = () => {
        if (!this.data) return;
        const iconsOptions = {
            width: 16,
            height: 16,
            marginRight: 4,
            marginLeft: 8,
        };
        const distanceGap = 8;

        const distanceText = `${this.data.distance} м`;
        const distanceTextMeasure = this.ctx.measureText(distanceText);
        const distanceTextX = this.data.width - distanceTextMeasure.width;

        const backwardText = `${this.data.backwardSpeed} км/ч`;
        const backwardMeasure = this.ctx.measureText(backwardText);
        const backwardTextX = distanceTextX - backwardMeasure.width - distanceGap;

        const forwardText = `${this.data.forwardSpeed} км/ч`;
        const forwardTextMeasure = this.ctx.measureText(forwardText);
        const forwardTextX =
            backwardTextX -
            forwardTextMeasure.width -
            iconsOptions.width -
            iconsOptions.marginRight -
            iconsOptions.marginLeft;

        this.ctx.fillStyle = this.data.fillStyle;
        this.ctx.font = this.data.font;
        this.ctx.textAlign = this.data.textAlign as CanvasTextDrawingStyles["textAlign"];

        this.ctx.fillText(distanceText, distanceTextX, this.data.speedDistanceLegendY);
        this.ctx.fillText(backwardText, backwardTextX, this.data.speedDistanceLegendY);
        this.ctx.fillText(forwardText, forwardTextX, this.data.speedDistanceLegendY);

        this.ctx.drawImage(
            this.arrowNarrowLeftIcon.img,
            backwardTextX - iconsOptions.width - iconsOptions.marginRight,
            this.data.speedDistanceLegendY - iconsOptions.height / 2
        );

        this.ctx.drawImage(
            this.arrowNarrowRightIcon.img,
            forwardTextX - iconsOptions.width - iconsOptions.marginRight,
            this.data.speedDistanceLegendY - iconsOptions.height / 2
        );
    };

    private initIcons() {
        this.arrowNarrowRightIcon.load();
        this.arrowNarrowLeftIcon.load();
    }
}
