import { MonitoringStatusCode } from "../enums";
import { CodeDictionary } from "../tv-camera";

type CodeDictionaryMap = { [key in MonitoringStatusCode]: CodeDictionary<MonitoringStatusCode> };

export const monitoringStatusCodeDictionaryMap: CodeDictionaryMap = {
    [MonitoringStatusCode.Works]: {
        name: "Работает",
        color: "#289F46",
        code: MonitoringStatusCode.Works,
    },
    [MonitoringStatusCode.NoData]: {
        name: "Нет данных",
        color: "#83909A",
        code: MonitoringStatusCode.NoData,
    },
    [MonitoringStatusCode.WorksWithRemarks]: {
        name: "Работает с замечаниями",
        color: "#FECB00",
        code: MonitoringStatusCode.WorksWithRemarks,
    },
    [MonitoringStatusCode.NoWorks]: {
        name: "Не работает",
        color: "#FF3A2F",
        code: MonitoringStatusCode.NoWorks,
    },
};

export const monitoringStatusCodeDictionaryList = Object.values(monitoringStatusCodeDictionaryMap);
