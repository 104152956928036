import { MessageStreamHub } from "../message-hub";
import {
    TrafficLightStreamSubscriber,
    CooGroupStreamSubscriber,
    DetectorStateStreamSubscriber,
    DetectorTriggerStreamSubscriber,
} from "./traffic-light-message-stream-hub.types";
import { StreamName, url } from "./traffic-light-message-stream-hub.constants";

export class TrafficLightMessageStreamHub extends MessageStreamHub<StreamName> {
    constructor() {
        super(url);
    }

    public async init() {
        await super.init();
        this.createStreams();
    }

    public subscribeToTrafficLightStream(sub: TrafficLightStreamSubscriber) {
        this.subscribe(StreamName.trafficLight, sub);
    }

    public subscribeToCooGroupStream(sub: CooGroupStreamSubscriber) {
        this.subscribe(StreamName.cooGroup, sub);
    }

    public subscribeToDetectorStateStream(sub: DetectorStateStreamSubscriber) {
        this.subscribe(StreamName.detectorState, sub);
    }

    public subscribeToDetectorTriggerStream(sub: DetectorTriggerStreamSubscriber) {
        this.subscribe(StreamName.detectorTrigger, sub);
    }

    private createStreams() {
        this.createStream(StreamName.trafficLight);
        this.createStream(StreamName.cooGroup);
        this.createStream(StreamName.detectorState);
        this.createStream(StreamName.detectorTrigger);
    }
}
