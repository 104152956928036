import { useMemo } from "react";

import { Chip, Dict } from "./filter-chips.types";

export const useCreateFilterChipList = (key: string, list: number[], onClose: (code: number) => void, dict: Dict) =>
    useMemo(
        () =>
            list.reduce((acc: Chip[], type) => {
                const name = dict[type]?.name ?? "";
                const color = dict[type]?.color;

                acc.push({
                    code: type,
                    name,
                    color,
                    key,
                    onClose,
                });
                return acc;
            }, []),
        [list, dict, key, onClose]
    );
