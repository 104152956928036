import { PageType } from "./coo-groups.constants";

export const COO_GROUP_LIST = `/${PageType.List}/:id?`;
export const COO_GROUP_DETAILS = `/${PageType.Details}/:id/:tab?`;
export const COO_GROUP_EDITOR = `/${PageType.EditorGroup}/:id`;

export const COO_GROUP_LIST_MATCHER = "/:type";
export const COO_GROUP_DETAILS_MATCHER = "/:type/:id/:tab";
export const COO_GROUP_EDITOR_MATCHER = "/:type/:id";

export const COO_GROUPS_MATCHER = "/:type/:id?/:tab?";
