import { Drawer } from "diagrams/drawers";
import { CanvasSVG } from "lib";
import { pointerIcons } from "./pointer-icon-drawer.constants";
import { PointerIconData, PointerIconState } from "./pointer-icon-drawer.types";

export class PointerIconDrawer extends Drawer<PointerIconData> {
    private pointerIcons: PointerIconState[] = [];

    constructor(protected ctx: CanvasRenderingContext2D) {
        super(ctx);
        this.initIcons();
    }

    protected draw = () => {
        if (!this.data) return;
        for (let item of this.data) {
            const current = item.current;
            const next = item.next;
            const pointerIcon = this.pointerIcons.find((item) => item.current === current && item.next === next);
            if (!pointerIcon?.icon) return;
            this.ctx.drawImage(pointerIcon.icon.img, item.x, item.y);
        }
    };

    private initIcons() {
        this.pointerIcons = pointerIcons.map((item) => {
            const svg = new CanvasSVG(item.icon);
            svg.load();
            return {
                ...item,
                icon: svg,
            };
        });
    }
}
