import React from "react";
import * as Markup from "./body.styles";
import type { Props } from "./body.types";

export const Body = React.memo((props: Props) => {
    const dataCypressBody = props.dataCypress ? `${props.dataCypress}__body` : undefined;
    const dataCypressInfo = props.dataCypress ? `${props.dataCypress}__info` : undefined;
    const dataCypressGraph = props.dataCypress ? `${props.dataCypress}__graph` : undefined;

    return (
        <Markup.Container className={props.className} data-cypress={dataCypressBody}>
            <Markup.Info indicators={props.indicators} isLoaded={props.isLoaded} dataCypress={dataCypressInfo} />
            <Markup.Graph graph={props.graph} isLoaded={props.isLoaded} dataCypress={dataCypressGraph} />
        </Markup.Container>
    );
});
