import { memo } from "react";
import { DateUtils } from "shared/utils";
import { Timer } from "../timer";

type Props = {
    start: Date;
    end: Date;
    hideZeroHours?: boolean;
    hideZeroMinutes?: boolean;
};

const DateIntervalTimerComponent = (props: Props) => {
    const times = DateUtils.calculateDuration(props.start, props.end);
    const duration = {
        days: times.days,
        hours: times.hours,
        minutes: times.minutes,
        seconds: times.seconds || 0,
    };
    return <Timer duration={duration} hideZeroHours={props.hideZeroHours} hideZeroMinutes={props.hideZeroMinutes} />;
};

export const DateIntervalTimer = memo(DateIntervalTimerComponent);
