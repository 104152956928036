import { TrafficLightDomain } from "app-domain";
import { TrafficLightDirectionTypeCode, TrafficLightDetector, TrafficLightTrack } from "./models";
import { TrafficLight } from "./trafficlight";

/**
 * Направление в рамках СО
 */
export interface TrafficLightDirectionInfo {
    /**Номер направления */
    number: number;

    /**Тип напрравления */
    type: TrafficLightDirectionTypeCode;

    /**Признак блокирующего направления */
    isDeadlock: boolean;

    /**Траектории движения в рамках направления */
    tracks: TrafficLightTrack[];

    concurrentDirections: any[];

    /** Задержка движения (придержание направления в начале фазы). По умолчанию 0 */
    tDelay: number;

    /** Время зеленого мигания. Всегда 3 сек у всех светофоров */

    tGreenBlink: number;

    /** Время желтого. Всегда 3 сек для транспортного, велосипедного (при 3х секционном исполнении) */
    tYellow: number;

    /**
     * Время красного
     * по умолчанию 3 сек для всех направлений, кроме транспортного. Можно только увеличить, меньше установить нельзя
     * по умолчанию 0 сек для транспортного направления, можно увеличить
     * при увеличении значения необходимо автоматически добавлять Тк у всех направлений, с которыми выбранное направление работает в 1 фазе
     */
    tRed: number;

    /** Красно-желтый. По умолчанию 0, допустимы значения 0-2 сек */
    tRedYellow: number;

    /** Запрет зеленого. По умолчанию 0, можно увеличить */
    tGreenDeny: number;
}

/** Направления по пасспорту */
export class TrafficLightDirection implements TrafficLightDirectionInfo {
    readonly trafficLight: TrafficLight;
    readonly lanes: TrafficLightDirectionLane[] = [];
    readonly laneByNum: { [key: string]: TrafficLightDirectionLane } = {};
    number: number;
    type: TrafficLightDirectionTypeCode;
    isDeadlock: boolean;
    tracks: TrafficLightTrack[];
    concurrentDirections: any[];
    tDelay: number;
    tGreenBlink: number;
    tYellow: number;
    tRed: number;
    tRedYellow: number;
    tGreenDeny: number;
    videoDetector?: TrafficLightDetector;

    constructor(props: { trafficLight: TrafficLight; info: TrafficLightDirectionInfo }) {
        this.trafficLight = props.trafficLight;
        const info = props.info;

        this.number = info.number;
        this.type = info.type;
        this.isDeadlock = info.isDeadlock;
        this.tracks = info.tracks;
        this.concurrentDirections = info.concurrentDirections;
        this.tDelay = info.tDelay;
        this.tGreenBlink = info.tGreenBlink;
        this.tYellow = info.tYellow;
        this.tRed = info.tRed;
        this.tRedYellow = info.tRedYellow;
        this.tGreenDeny = info.tGreenDeny;
    }

    get isAllowed() {
        if (
            (this.trafficLight.status.code === TrafficLightDomain.Enums.StatusCode.Cycle ||
                this.trafficLight.status.code === TrafficLightDomain.Enums.StatusCode.Hold ||
                this.trafficLight.status.code === TrafficLightDomain.Enums.StatusCode.Coordination) &&
            this.trafficLight.currentPhase
        ) {
            const phase = this.trafficLight.phases[this.trafficLight.currentPhase.phase.num];
            if (phase) return phase.directionByNum[this.number] !== undefined;
        }
        return undefined;
    }

    // get hasInductiveLoopDetector() {
    //     return this.lanes.filter((l) => l.hasInductiveLoopDetector).length > 0;
    // }

    // get hasVideoDetector() {
    //     return this.lanes.filter((l) => l.hasVideoDetector).length > 0;
    // }
}

export interface TrafficLightDirectionLaneMetrix {
    total: number;
    car: number;
    bus: number;
    truck: number;
    totalWaiting: number;
    carWaiting: number;
    busWaiting: number;
    truckWaiting: number;
}

export class TrafficLightDirectionLane {
    readonly direction: TrafficLightDirection;
    readonly num: string;
    private _isBusy?: boolean;
    private _metrix?: TrafficLightDirectionLaneMetrix;

    // hasInductiveLoopDetector: boolean = false;
    // hasVideoDetector: boolean = false;

    constructor(props: { direction: TrafficLightDirection; num: string }) {
        this.direction = props.direction;
        this.num = props.num;
    }

    setIsBusy(isBusy: boolean, fireEvent: boolean = false) {
        if (this._isBusy !== isBusy) {
            this._isBusy = isBusy;
            if (fireEvent) {
                this.direction.trafficLight.emit("directionLaneIsBusyChanged", {
                    lane: this,
                    isBusy,
                });
                this.direction.trafficLight.dispatcher.emit("trafficLightDirectionLaneIsBusyChanged", {
                    lane: this,
                    isBusy,
                });
            }
        }
    }

    get isBusy() {
        return this._isBusy;
    }

    get metrix() {
        return this._metrix;
    }

    setMetrix(metrix: TrafficLightDirectionLaneMetrix, fireEvent: boolean = false) {
        if (
            !this._metrix ||
            this._metrix.total !== metrix.total ||
            this._metrix.totalWaiting !== metrix.totalWaiting ||
            this._metrix.car !== metrix.car ||
            this._metrix.bus !== metrix.bus ||
            this._metrix.truck !== metrix.truck
        ) {
            this._metrix = metrix;
            if (fireEvent) {
                this.direction.trafficLight.emit("directionLaneMetricsChanged", {
                    lane: this,
                    metrix,
                });
                this.direction.trafficLight.dispatcher.emit("trafficLightDirectionLaneMetrixChanged", {
                    lane: this,
                    metrix,
                });
            }
        }
    }
}
