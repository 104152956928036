import { ReactNode, memo } from "react";
import { CloseButton } from "../close-button";
import * as Markup from "./validation-errors-container.styles";

type Props = {
    title: ReactNode;
    errors: string[];
    onClose: VoidFunction;
};

export const ValidationErrorsContainer = memo((props: Props) => (
    <Markup.Container>
        <Markup.ErrorIcon />
        <Markup.Content>
            <Markup.Title>{props.title}</Markup.Title>
            <Markup.ErrorList>
                {props.errors.map((errorText) => (
                    <li key={errorText}>{errorText}</li>
                ))}
            </Markup.ErrorList>
        </Markup.Content>
        <CloseButton size="s" onClick={props.onClose} />
    </Markup.Container>
));
